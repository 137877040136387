import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import axios from 'axios';
import {Redirect, Route, Switch} from 'react-router-dom';
import qs from 'qs';
import {fromJS} from 'immutable';

import TopNav from './top-nav/top-nav';
import LeftNav from './left-nav/left-nav';
import Lists from './lists/lists';
import List from './lists/list/list';
import Hubs from './hubs/hubs';
import Hub from './hubs/hub/hub';
import Interstitial from '../../../components/interstitial/interstitial';
import {login as loginAction, saveUser as saveUserAction} from '../../../redux/users/users-actions';
import {createUrl} from '../../../util/formatters';
import {openModal as _openModal} from '../../../redux/modal/modal-actions';
import {INTERSTITIAL_KEYS} from '../../../util/constants';

import './dashboard.css';

export class Dashboard extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string,
        authenticated: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        saveUser: PropTypes.func.isRequired,
        user: PropTypes.object
    };

    onListMount = () => {
        if (this.launch === 'giftRectification') {
            this.openGiftRectificationModal();
            delete this.launch;
        }
    };

    openGiftRectificationModal = () => this.props.openModal(
        'GIFT_RECTIFICATION_MODAL',
        {},
        {title: 'Your List Cleanup Crew', width: 600}
    );

    GiftRectificationBanner = () => {
        let {user} = this.props;
        let {giftRectificationNeeded} = this.state;
        if (!giftRectificationNeeded || !user) return null;
        let firstName = user.getIn(['name', 'first']);
        return (
            <div className="dashboard-giftRectificationBanner">
                <div className="dashboard-giftRectificationBannerMessage">
                    <b>Hey {firstName}, welcome back!</b> It looks like it might be time to update some of your gift ideas.
                    If you have a moment, we'd like to walk you through some of them so that we can get things ready for your friends and family to claim your ideas! - <i>Your List Cleanup Crew</i>
                </div>
                <button
                    className="dashboard-giftRectificationButton"
                    type="button"
                    onClick={this.openGiftRectificationModal}
                >
                    Get&nbsp;Started
                </button>
            </div>
        );
    };

    HubLinkButton = ({onClick, children}) => (
        <button onClick={onClick} className="dashboard-hubLinkButton" type="button">
            {children}
        </button>
    );

    HubLinks = () => {
        let {location, history} = this.props;
        let {joinedHubs, showAllHubs} = this.state;
        let {HubLinkButton} = this;
        if (location.pathname.includes('hubs') || !joinedHubs) return null;
        let links;
        if (!joinedHubs.length) {
            links = (
                <HubLinkButton onClick={() => history.push('/gift-tags/hubs/')}>
                    Create or Join a Tag Hub
                </HubLinkButton>
            )
        } else {
            links = joinedHubs.map(hub => (
                <HubLinkButton onClick={() => history.push('/gift-tags/hubs/' + hub.id)} key={hub.id}>
                    {hub.name}
                </HubLinkButton>
            ));
            if (joinedHubs.length > 3) {
                if (!showAllHubs) links = links.slice(0, 2);
                links.push(
                    <HubLinkButton
                        onClick={() => this.setState(previousState => ({showAllHubs: !previousState.showAllHubs}))}
                        key="showMoreOrLessButton"
                    >
                        {showAllHubs ? 'Show Less' : 'Show More'}
                    </HubLinkButton>
                );
            }
        }
        return (
            <div className="dashboard-hubLinks">
                <span className="dashboard-hubLinksTitle">Tag Hub(s):</span>
                {links}
            </div>
        );
    };

    state = {};

    loadPendingRequestStats = async () => {
        let pendingRequestStats = (await axios.get(createUrl('/requests/recipient-stats'))).data;
        this.setState({pendingRequestStats: fromJS(pendingRequestStats)});
    };

    loadGiftRectifications = async () => {
        let giftRectifications = (await axios.get(createUrl('/lists/gift-rectifications'))).data;
        this.setState({giftRectificationNeeded: giftRectifications.length});
    };

    loadJoinedHubs = async ()  => {
        let joinedHubs = (await axios.get(createUrl('/hubs?myRole=member'))).data;
        joinedHubs.sort((hubA, hubB) => hubA.name.localeCompare(hubB.name));
        this.setState({joinedHubs});
    };

    loadData = async () => {
        let {saveUser, location} = this.props;
        let response = await axios.get(createUrl('/users/current'));
        let {user} = response.data;
        saveUser(user);
        let {launch} = qs.parse(location.search.slice(1));
        this.launch = launch;
        await Promise.all([
            this.loadPendingRequestStats(),
            this.loadGiftRectifications(),
            this.loadJoinedHubs()
        ]);
    };

    async componentDidMount() {
        let {authenticated, login, history, location} = this.props;
        let jwtToken = sessionStorage.getItem('jwtToken');
        if (!authenticated) {
            if (!jwtToken) {
                let queryParams = qs.stringify({redirectTo: location.pathname + location.search});
                history.push(`/gift-tags/login?` + queryParams);
                return;
            } else {
                login(jwtToken);
            }
        }
        try {
            await Interstitial.interstitialController(INTERSTITIAL_KEYS.DASHBOARD, this.loadData());
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 401) {
                let queryParams = qs.stringify({expiredToken: true, redirectTo: location.pathname + location.search});
                history.push(`/gift-tags/login?` + queryParams);
            }
        }
    }

    render() {
        let {GiftRectificationBanner, HubLinks} = this;
        let {authenticated, activeUserId, history} = this.props;
        let {pendingRequestStats} = this.state;
        let content;
        if (authenticated && activeUserId && pendingRequestStats) {
            content = (
                <>
                    <LeftNav history={history} pendingRequestStats={pendingRequestStats} />
                    <div className="dashboard-main">
                        <TopNav pendingRequestStats={pendingRequestStats} />
                        <GiftRectificationBanner />
                        <HubLinks />
                        <Switch>
                            <Route path="/gift-tags/lists/:listId" render={props => <List {...props} onMount={this.onListMount} />} />
                            <Route path="/gift-tags/lists" component={Lists} />
                            <Route path="/gift-tags/hubs/:hubId" render={props => <Hub {...props} pendingRequestStats={pendingRequestStats} loadPendingRequestStats={this.loadPendingRequestStats} />} />
                            <Route path="/gift-tags/hubs" render={props => <Hubs {...props} pendingRequestStats={pendingRequestStats} />} />
                            <Redirect to="/gift-tags/lists" />
                        </Switch>
                    </div>
                </>
            )
        }
        return (
            <Interstitial className="dashboard" interstitialKey={INTERSTITIAL_KEYS.DASHBOARD} interstitialSize={Interstitial.INTERSTITIAL_SIZES.LARGE}>
                {content}
            </Interstitial>
        );
    }
}

let mapStateToProps = state => ({
    activeUserId: state.users.getIn(['user', 'id']),
    authenticated: state.users.get('authenticated'),
    user: state.users.get('user')
});

let actions = {
    saveUser: saveUserAction,
    login: loginAction,
    openModal: _openModal
};

export default connect(mapStateToProps, actions)(Dashboard);
