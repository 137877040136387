import React, {useState, useEffect} from 'react';
import axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';

import ContentCard from '../../components/content-card/content-card';
import allBookIcons from '../../../../images/react-icons/books/all-book-icons';
import {COOKBOOK_COLORS} from '../../../../util/constants';
import {createUrl} from '../../../../util/formatters';
import RecipePreview from '../../recipes/recipe-preview/recipe-preview';

import './cookbook-preview.css';

export default function CookbookPreview({cookbook, history, displayRecipes = true}) {
    let {id: cookbookId, icon, theme, pendingDeletionAsOf} = cookbook;
    let [data, setData] = useState();

    let loadData = async () => {
        try {
            let recipes;
            if (displayRecipes) {
                recipes = (await axios.get(createUrl(`/cookbooks/${cookbookId}/recipes`), {params: {limit: 4}})).data;
            }
            setData({recipes});
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {loadData()}, [cookbookId]);

    let CookbookIcon = allBookIcons[icon];
    let colors = COOKBOOK_COLORS[theme];
    let authorNames = cookbook.authors.map(author => author.name.fullName).join(', ');
    let deletionWarning;
    if (pendingDeletionAsOf) {
        let daysUntilDeletion = moment(pendingDeletionAsOf).add(30, 'days').diff(moment(), 'days');
        deletionWarning = <div className="cookbookPreview-deletionWarning">{Math.max(daysUntilDeletion, 0)} days until permanently deleted</div>;
    }
    let recipePreviews;
    if (displayRecipes && data) {
        let {recipes} = data;
        if (recipes.length) {
            recipePreviews = recipes.map((recipe, index) => (
                <RecipePreview
                    className={classnames('cookbookPreview-recipePreview', `cookbookPreview-recipePreview--${index + 1}`)}
                    recipe={recipe}
                    history={history}
                    key={recipe.id}
                />
            ));
        } else {
            recipePreviews = <span className="cookbookPreview-noRecipesMessage">This cookbook doesn't have any recipes yet</span>;
        }
    }
    return (
        <div className={classnames('cookbookPreview', {'cookbookPreview--noRecipes': !displayRecipes})}>
            <button onClick={() => history.push(`/sous-chef/cookbooks/${cookbookId}`)} className="cookbookPreview-cookbookButton">
                <ContentCard className="cookbookPreview-cookbookContent">
                    <div className="cookbookPreview-cookbookIconWrapper">
                        <CookbookIcon colors={colors} className="cookbookPreview-cookbookIcon" />
                    </div>
                    <div className="cookbookPreview-cookbookText">
                        <div className="cookbookPreview-cookbookTitle">{cookbook.name}</div>
                        <div className="cookbookPreview-cookbookSubtext">By: {authorNames}</div>
                    </div>
                    {deletionWarning}
                </ContentCard>
            </button>
            {displayRecipes && (
                <>
                    <div className="cookbookPreview-line" />
                    <div className="cookbookPreview-recipePreviews">{recipePreviews}</div>
                    <div className="cookbookPreview-buttons">
                        <button
                            onClick={() => history.push(`/sous-chef/cookbooks/${cookbookId}/recipes/new`)}
                            className="cookbookPreview-addRecipeButton"
                        >Add&nbsp;a&nbsp;Recipe</button>
                        {data?.recipes?.length >= 4 && (
                            <button
                                onClick={() => history.push(`/sous-chef/cookbooks/${cookbookId}`)}
                                className="cookbookPreview-viewMoreButton"
                            >View&nbsp;More</button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
