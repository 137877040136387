import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push}) => {
    let {giftLabel, ownedBy, originList} = alert.event.data;

    let [ownedByUser, setOwnedByUser] = useState();
    let [reopenedByUser, setReopenedByUser] = useState();
    useEffect(() => {
        loadUser(ownedBy, setOwnedByUser);
        loadUser(alert.event.generatedBy, setReopenedByUser);
    }, []);
    let originListLink = '/gift-tags/lists/' + originList;

    let message;
    if (ownedByUser && reopenedByUser) {
        let reopenedByName = reopenedByUser.name.fullName;
        let ownerName = ownedByUser.name.fullName;
        message = <span><b>{reopenedByName}</b> requested more contributors to pitch in on a gift for <b>{ownerName}</b> - <b>"{giftLabel}"</b></span>
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={originListLink}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}