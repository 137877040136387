import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push}) => {
    let {ownedBy, newLabel, edits, originList} = alert.event.data;

    let editedFields = Object.keys(edits);
    if (editedFields.length > 1) editedFields[editedFields.length - 1] = 'and ' + editedFields[editedFields.length - 1];
    editedFields = editedFields.length > 2 ? editedFields.join(', ') : editedFields.join(' ');
    let giftIdentifier = edits.hasOwnProperty('label')
        ? `"${edits.label.oldValue}" (old) / "${edits.label.newValue}" (new)`
        : `"${newLabel}"`;
    let originListLink = '/gift-tags/lists/' + originList;

    let [ownedByUser, setOwnedByUser] = useState();
    useEffect(() => {
        loadUser(ownedBy, setOwnedByUser);
    }, []);

    let message;
    if (ownedByUser) {
        let ownerName = ownedByUser.name.fullName;
        message = <span><b>{ownerName}</b> edited the {editedFields} for their idea - <b>{giftIdentifier}</b></span>
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={originListLink}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}