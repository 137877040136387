import React from 'react';
import classnames from 'classnames';

import './field-label.css';

export default function FieldLabel({fieldName, className, children, infoMessage, form}) {
    let {touched, submitError, error} = form.getFieldState(fieldName) || {};
    let errorText = touched && (submitError || error);
    if (typeof errorText !== 'string') errorText = undefined;
    return (
        <div className={classnames('fieldLabel', className)}>
            <div className="fieldLabel-errorTextAndLabel">
                {errorText && <div className="fieldLabel-errorText">{errorText}</div>}
                <label>{children}</label>
            </div>
            {infoMessage && <div className="fieldLabel-infoMessage">{infoMessage}</div>}
        </div>
    )
};
