import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import Toggle from 'react-toggle';

import {createUrl} from '../../../../../util/formatters';
import {openModal as _openModal} from '../../../../../redux/modal/modal-actions';
import Dropdown from '../../../../../components/dropdown/dropdown';

import './settings-view.css';

export class SettingsView extends React.Component {
    static propTypes = {
        hub: PropTypes.shape({
            directory: PropTypes.array.isRequired
        }).isRequired,
        loadPendingRequestStats: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                hubId: PropTypes.string.isRequired
            }).isRequired,
            url: PropTypes.string.isRequired
        }).isRequired,
        reloadHub: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired
    };

    Section = ({title, children}) => {
        return (
            <div className="settingsView-section">
                <div className="settingsView-sectionHeader">{title}</div>
                {children}
            </div>
        );
    };

    HubApplication = ({request}) => {
        if (request.type !== 'hubApplication') return null;
        let createdAt = moment(request.createdAt);
        let sender = request.sender.name.fullName;
        let confirmationMessage = `You are about to delete ${sender}'s request to join this hub. Are you sure you want to proceed?`;
        let disabled = this.state[request.id] === 'submitting';
        return (
            <div className="settingsView-request settingsView-request--attention">
                <div>
                    <div className="settingsView-requestSubtext">Request to Join From:</div>
                    <div className="settingsView-requestTitle">{sender}</div>
                    <div className="settingsView-requestSubtext">sent {createdAt.from(moment())}</div>
                </div>
                <div className="settingsView-requestButtons">
                    <button
                        className="settingsView-declineButton"
                        onClick={this.confirmChange('Decline Request to Join', confirmationMessage, this.declineApplication(request.id), true)}
                        disabled={disabled}
                    >Reject</button>
                    <button
                        className="settingsView-acceptButton"
                        onClick={this.acceptApplication(request.id)}
                        disabled={disabled}
                    >Accept</button>
                </div>
            </div>
        );
    };

    HubInvite = ({request}) => {
        if (request.type !== 'hubInvite') return null;
        let {hub} = this.props;
        let submittedBy = hub.directory.find(record => record.member.id === request.createdBy);
        let submittedByName = submittedBy ? submittedBy.member.name.fullName : 'a previous admin';
        let createdAt = moment(request.createdAt);
        let recipient = request.recipientType === 'User' ? request.recipient.name.fullName : request.recipient;
        let confirmationMessage = `You are about to cancel the invite that was sent to ${recipient}. Are you sure you want to proceed?`;
        let resendEmailButton;
        let disabled = this.state[request.id] === 'submitting';
        if (request.resendEmailEligible) {
            resendEmailButton = (
                <button type="button" className="settingsView-resendEmailButton" onClick={this.resendEmailRequest(request.id)} disabled={disabled}>
                    Resend Email
                </button>
            );
        }
        let linkStatus;
        if (request.recipientType === 'Email') {
            let linkStatusClass = request.recipientAuthEnabled ? 'settingsView-linkStatus--active' : 'settingsView-linkStatus--expired';
            linkStatus = (
                <div className="settingsView-requestSubtext">
                    Emailed Invite Link is: <span className={linkStatusClass}>{request.recipientAuthEnabled ? 'Active' : 'Expired'}</span>
                </div>
            );

        }
        return (
            <div className="settingsView-request">
                <div className="settingsView-requestLeftColumn">
                    <div>
                        <div className="settingsView-requestSubtext">Invite Pending Response From:</div>
                        <div className="settingsView-requestTitle">{recipient}</div>
                        {linkStatus}
                        <div className="settingsView-requestSubtext">sent {createdAt.from(moment())} by {submittedByName}</div>
                    </div>
                    {resendEmailButton}
                </div>
                <button
                    className="settingsView-cancelRequestButton"
                    onClick={this.confirmChange('Cancel Invite', confirmationMessage, this.cancelInvite(request.id), true)}
                >
                    <div className="settingsView-cancelIcon" />
                </button>
            </div>
        )
    };

    Requests = () => {
        let {Section, HubInvite, HubApplication, DefaultRole} = this;
        let {hub, openModal, reloadHub} = this.props;
        let {pendingRequests, showingAllRequests} = this.state;
        if (hub.memberDetails.role !== 'admin') return null;
        // View/update Pending Requests (Incoming & Outgoing)
        // Send requests to users
        let openInviteModal = () => openModal('INVITE_MODAL', {hub, pendingRequests, loadRequests: this.loadRequests}, {title: 'Invite Members', width: 500});
        let openInviteLinkModal = () => openModal('INVITE_LINK_MODAL', {hub, reloadHub}, {title: 'Invite Link', width: 500});
        let actions = (
            <div className="settingsView-requestActions">
                <button className="settingsView-inviteButton" onClick={openInviteModal}>Send Invite</button>
                or
                <button className="settingsView-linkButton" onClick={openInviteLinkModal}>Get Invite Link</button>
            </div>
        );
        let title = (
            <div className="settingsView-requestsHeader">
                <div>
                    <div>Add Members</div>
                    {hub.inviteAuth.enabled && (
                        <div className="settingsView-requestsSubHeader">
                            The hub's "Invite Link" is currently <span className="settingsView-linkStatus--active">enabled</span>. Any user with the link can join automatically.{' '}
                            <button className="settingsView-manageLinkButton" onClick={openInviteLinkModal}>Click here to manage</button>
                        </div>
                    )}
                </div>
                {pendingRequests && actions}
            </div>
        );
        let requests, toggleButton;
        if (pendingRequests) {
            if (pendingRequests.length) {
                const DEFAULT_MAX_REQUESTS = 5;
                requests = pendingRequests.map(request => {
                    let RequestType = request.type === 'hubInvite' ? HubInvite : HubApplication;
                    return <RequestType request={request} key={request.id} />;
                });
                if (requests.length > DEFAULT_MAX_REQUESTS) {
                    toggleButton = (
                        <button
                            className="settingsView-requestToggleButton"
                            onClick={() => this.setState({showingAllRequests: !showingAllRequests})}
                        >
                            {showingAllRequests ? 'Show Fewer Requests' : 'Show All Requests'} ({pendingRequests.length})
                        </button>
                    );
                    if (!showingAllRequests) requests = requests.slice(0, DEFAULT_MAX_REQUESTS);
                }
            }
        }
        return (
            <Section title={title}>
                {requests}
                {toggleButton}
                <DefaultRole />
            </Section>
        );
    };

    Rank = ({record}) => {
        let {hub} = this.props;
        let memberId = record.member.id;
        let rank = memberId === hub.ownedBy ? 'owner' : record.role;
        let rankComponent = <div className="settingsView-rank">{rank}</div>;
        let {first, last} = record.member.name;
        if (hub.memberDetails.role === 'admin' && rank !== 'owner') {
            let options = [];
            if (rank === 'admin') {
                let message = <span>Are you sure that you want to <b>remove {first}'s admin status</b>? {first} will no longer be able to edit the hub or its members.</span>;
                if (hub.memberDetails.member.id === memberId) {
                    message = <span>Are you sure that you want to <b>remove your admin status</b>? You will no longer be able to edit the hub or its members.</span>;
                }
                options.push(
                    <button
                        className="settingsView-rankOption"
                        key="member"
                        onClick={this.confirmChange(
                            'Modify Member Role',
                            message,
                            this.changeRole(memberId, 'member'),
                            true
                        )}
                    >
                        Member
                    </button>
                );
            }
            if (rank === 'member') {
                options.push(
                    <button
                        className="settingsView-rankOption"
                        key="admin"
                        onClick={this.confirmChange(
                            'Modify Member Role',
                            <span>By making {first} an admin, they will be able to <b>edit the hub and its members</b>. Do you want to continue?</span>,
                            this.changeRole(memberId, 'admin')
                        )}
                    >
                        Admin
                    </button>
                );
            }
            if (hub.memberDetails.isOwner) {
                options.push(
                    <button
                        className="settingsView-rankOption"
                        key="owner"
                        onClick={this.confirmChange(
                            'Modify Owner',
                            <span>Are you sure that you want to <b>transfer ownership of the hub to {first} {last}</b>? This will <b>remove you as the owner</b>.</span>,
                            this.changeOwner(memberId),
                            true
                        )}
                    >
                        Owner
                    </button>
                );
            }
            options.push(
                <button
                    className="settingsView-rankOption"
                    key="delete"
                    onClick={this.confirmChange(
                        'Remove from Hub',
                        <span>Are you sure that you want to <b>remove {first} {last} from the hub</b>? {first} will no longer be able to view or claim new ideas from lists on this Hub, and the remaining members will also be denied access to {first}'s lists.</span>,
                        this.removeMember(memberId),
                        true
                    )}
                >
                    Remove
                </button>
            );
            rankComponent = (
                <Dropdown buttonClassName="settingsView-rank" iconClassName="settingsView-rankIcon" value={rank}>
                    {options}
                </Dropdown>
            )
        }
        return rankComponent;
    };

    Member = ({record}) => {
        let {Rank} = this;
        let {first, last} = record.member.name;

        return (
            <div className="settingsView-member">
                <div>{first} {last}</div>
                <Rank record={record} />
            </div>
        );
    };

    DefaultRole = () => {
        let {hub} = this.props;
        if (hub.memberDetails.role !== 'admin') return null;
        return (
            <div className="settingsView-defaultRole">
                <Toggle checked={hub.defaultRole === 'admin'} onChange={this.onDefaultRoleChange} />
                <div className="settingsView-defaultRoleText">
                    <div className="settingsView-defaultRoleMainText">Automatically Promote New Members to Admin</div>
                    <div className="settingsView-defaultRoleSubText">Admins can edit the hub's members, name, and description</div>
                </div>
            </div>
        );
    };

    Directory = () => {
        let {Section, Member} = this;
        let {hub} = this.props;
        let {showingAllMembers} = this.state;
        // remove other members
        // default role
        let members = hub.directory.sort(this.sortDirectoryRecords).map((record, index) => <Member record={record} key={index} />);
        let totalMembers = members.length;
        const DEFAULT_MAX_MEMBERS = 5;
        let toggleButton;
        if (members.length > DEFAULT_MAX_MEMBERS) {
            toggleButton = (
                <button
                    className="settingsView-memberToggleButton"
                    onClick={() => this.setState({showingAllMembers: !showingAllMembers})}
                >
                    {showingAllMembers ? 'Show Fewer Members' : 'Show All Members'}
                </button>
            );
            if (!showingAllMembers) members = members.slice(0, DEFAULT_MAX_MEMBERS);
        }
        let title = (
            <div>
                <div>Members - {totalMembers}</div>
                <div className="settingsView-directorySubHeader">Only admins or the owner can edit the member list</div>
            </div>
        );
        return (
            <Section title={title}>
                {members}
                {toggleButton}
            </Section>
        );
    };

    AnonymizeClaimers = () => {
        let {hub} = this.props;
        return (
            <div className="settingsView-anonymizeClaimers">
                <Toggle checked={hub.anonymizeClaimers} onChange={this.onAnonymizeClaimersChange} />
                <div className="settingsView-anonymizeClaimersText">
                    <div className="settingsView-anonymizeClaimersMainText">Enable Secret Claim Mode</div>
                    <div className="settingsView-anonymizeClaimersSubText">When claiming a gift, other members will not know who claimed it. They will only know that it has been claimed.</div>
                </div>
            </div>
        );
    };

    Preferences = () => {
        let {Section, AnonymizeClaimers} = this;
        let {hub} = this.props;
        if (hub.memberDetails.role !== 'admin') return null;
        return (
            <Section title="Preferences">
                <AnonymizeClaimers />
            </Section>
        );
    };

    LeaveHub = () => {
        let {Section} = this;
        let {hub, openModal} = this.props;
        let disabled = hub.memberDetails.isOwner;
        let cautionMessage = 'Caution: You will no longer be able to view or claim new ideas from lists on this Hub, and members will also be denied access to your lists.';
        return (
            <Section title="Leave Hub">
                {disabled && <div className="settingsView-caution">As the owner, you cannot leave the Hub. You must first transfer ownership.</div>}
                {!disabled && <div className="settingsView-caution">{cautionMessage}</div>}
                <button
                    className="settingsView-destructiveButton"
                    disabled={disabled}
                    onClick={() => openModal(
                        'CONFIRMATION_MODAL',
                        {
                            message: (
                                <div>
                                    <div className="settingsView-modalMain">Are you sure you want to leave the Hub?</div>
                                    <div className="settingsView-modalSubMain">{cautionMessage}</div>
                                </div>
                            ),
                            onConfirm: this.onLeaveHub,
                            destructive: true
                        },
                        {title: 'Leave Hub', width: 400}
                    )}
                >Leave Hub</button>
            </Section>
        );
    };

    DeleteHub = () => {
        let {Section} = this;
        let {hub, openModal} = this.props;
        if (!hub.memberDetails.isOwner) return null;
        let cautionMessage = 'Caution: Members will no longer be able to view or claim new ideas from each others lists, and all Hub messages will be deleted.';
        return (
            <Section title="Delete Hub">
                <div className="settingsView-caution">{cautionMessage}</div>
                <div className="settingsView-caution">(Only the current owner can delete the Hub)</div>
                <button
                    className="settingsView-destructiveButton"
                    onClick={() => openModal(
                        'CONFIRMATION_MODAL',
                        {
                            message: (
                                <div>
                                    <div className="settingsView-modalMain">Are you sure you want to delete the Hub?</div>
                                    <div className="settingsView-modalSubMain">{cautionMessage}</div>
                                </div>
                            ),
                            onConfirm: this.onDeleteHub,
                            destructive: true
                        },
                        {title: 'Delete Hub', width: 400}
                    )}
                >
                    Delete Hub
                </button>
            </Section>
        );
    };

    static requestTypeValues = {
        hubApplication: 1,
        hubInvite: 2,
        DEFAULT: 10
    };

    sortPendingRequests = (requestA, requestB) => {
        let {type: typeA, createdAt: createdAtA} = requestA;
        let {type: typeB, createdAt: createdAtB} = requestB;

        let requestTypeValueA = SettingsView.requestTypeValues[typeA] || SettingsView.requestTypeValues.DEFAULT;
        let requestTypeValueB = SettingsView.requestTypeValues[typeB] || SettingsView.requestTypeValues.DEFAULT;

        let typeDifference = requestTypeValueA - requestTypeValueB;
        if (typeDifference) return typeDifference;

        if (typeA === 'hubInvite') {
            return moment(createdAtB).diff(moment(createdAtA));
        } else {
            return moment(createdAtA).diff(moment(createdAtB));
        }
    };

    sortDirectoryRecords = (recordA, recordB) => {
        let {first: firstA, last: lastA} = recordA.member.name;
        let {first: firstB, last: lastB} = recordB.member.name;

        let firstDifference = firstA.localeCompare(firstB);
        if (firstDifference) return firstDifference;

        return lastA.localeCompare(lastB);
    };

    onAnonymizeClaimersChange = async event => {
        let {hub, reloadHub} = this.props;
        let {checked} = event.target;
        await axios.put(createUrl(`/hubs/${hub.id}`), {anonymizeClaimers: checked});
        await reloadHub();
    };

    setDefaultRole = (defaultRole, promoteExistingRoles) => async () => {
        let {hub, reloadHub} = this.props;
        await axios.put(createUrl(`/hubs/${hub.id}`), {defaultRole, promoteExistingRoles});
        await reloadHub();
    };

    onDefaultRoleChange = async event => {
        let {hub} = this.props;
        let {checked} = event.target;
        let foundMember = hub.directory.some(record => record.role === 'member');
        let defaultRole = checked ? 'admin' : 'member';
        await this.setDefaultRole(defaultRole, false)();
        if (checked && foundMember) {
            let title = 'Promote Current Members';
            let message = 'Do you want to promote all existing members as well?';
            this.confirmChange(title, message, this.setDefaultRole(defaultRole, true))();
        }
    };

    removeMember = memberId => async () => {
        let {reloadHub, hub} = this.props;
        await axios.delete(createUrl(`/hubs/${hub.id}/directory/${memberId}`));
        await reloadHub();
    };

    onLeaveHub = async () => {
        let {hub, history} = this.props;
        let memberId = hub.memberDetails.member.id;
        await axios.delete(createUrl(`/hubs/${hub.id}/directory/${memberId}`));
        history.push('/gift-tags/hubs');
    };

    onDeleteHub = async () => {
        let {hub, history} = this.props;
        await axios.delete(createUrl(hub.links.self));
        history.push('/gift-tags/hubs');
    };

    changeRole = (memberId, role) => async () => {
        let {hub, reloadHub} = this.props;
        await axios.put(createUrl(`/hubs/${hub.id}/directory/${memberId}`), {role});
        await reloadHub();
    };

    changeOwner = memberId => async () => {
        let {hub, reloadHub} = this.props;
        await axios.put(createUrl(`/hubs/${hub.id}/owner`), {newOwner: memberId});
        await reloadHub();
    };

    confirmChange = (title, message, onConfirm, destructive) => () => {
        let {openModal} = this.props;
        openModal('CONFIRMATION_MODAL', {message, onConfirm, destructive}, {title, width: 400});
    };

    cancelInvite = requestId => async () => {
        await axios.put(createUrl(`/requests/${requestId}/rescind`));
        await this.loadRequests();
    };

    acceptApplication = requestId => async () => {
        let {reloadHub, loadPendingRequestStats} = this.props;
        try {
            this.setState({[requestId]: 'submitting'});
            await axios.put(createUrl(`/requests/${requestId}/respond`), {status: 'accepted'});
            await Promise.all([
                this.loadRequests(),
                reloadHub(),
                loadPendingRequestStats()
            ]);
            this.setState({[requestId]: 'done'});
        } catch (error) {
            this.setState({[requestId]: 'done'});
        }
    };

    declineApplication = requestId => async () => {
        let {loadPendingRequestStats} = this.props;
        try {
            this.setState({[requestId]: 'submitting'});
            await axios.put(createUrl(`/requests/${requestId}/respond`), {status: 'rejected'});
            await Promise.all([
                this.loadRequests(),
                loadPendingRequestStats()
            ]);
            this.setState({[requestId]: 'done'});
        } catch (error) {
            this.setState({[requestId]: 'done'});
        }
    };

    resendEmailRequest = requestId => async () => {
        try {
            this.setState({[requestId]: 'submitting'});
            await axios.put(createUrl(`/requests/${requestId}/resend-email`));
            await this.loadRequests();
            this.setState({[requestId]: 'done'});
        } catch (error) {
            this.setState({[requestId]: 'done'});
        }
    };

    loadRequests = async () => {
        let {hub} = this.props;
        let pendingRequests = (await axios.get(createUrl('/requests'), {
            params: {
                partyType: 'hub',
                partyId: hub.id
            }
        })).data.sort(this.sortPendingRequests);
        this.setState({pendingRequests});
    };

    state = {
        showingAllMembers: false,
        showingAllRequests: false
    };

    componentDidMount = async () => {
        let {match, hub, history} = this.props;
        let {hubId} = match.params;
        if (!hub.hasJoined) {
            history.replace(`/gift-tags/hubs/${hubId}/join`);
            return;
        }
        await this.loadRequests();
    };

    render() {
        let {Requests, Directory, Preferences, LeaveHub, DeleteHub} = this;
        let {hub} = this.props;
        if (!hub.hasJoined) return null;
        return (
            <div className="settingsView">
                <Requests />
                <Directory />
                <Preferences />
                <LeaveHub />
                <DeleteHub />
            </div>
        );
    }
}

export default connect(null, {openModal: _openModal})(SettingsView);
