export const BREAKPOINT_NAMES = {
    desktop4k: 'desktop4k',
    desktopXXl: 'desktopXXl',
    desktopXl: 'desktopXl',
    desktopLg: 'desktopLg',
    desktop: 'desktop',
    tablet: 'tablet',
    tabletSm: 'tabletSm',
    mobileLg: 'mobileLg',
    mobileMd: 'mobileMd',
    mobileSm: 'mobileSm'
};
export const BREAKPOINT_WIDTHS = {
    [BREAKPOINT_NAMES.desktop4k]: 2560,
    [BREAKPOINT_NAMES.desktopXXl]: 2165,
    [BREAKPOINT_NAMES.desktopXl]: 1840,
    [BREAKPOINT_NAMES.desktopLg]: 1490,
    [BREAKPOINT_NAMES.desktop]: 1140,
    [BREAKPOINT_NAMES.tablet]: 840,
    [BREAKPOINT_NAMES.tabletSm]: 596,
    [BREAKPOINT_NAMES.mobileLg]: 425,
    [BREAKPOINT_NAMES.mobileMd]: 374,
    [BREAKPOINT_NAMES.mobileSm]: 320
};

export let screenIsNoLargerThan = (activeBreakpoint, targetBreakpoint) => BREAKPOINT_WIDTHS[activeBreakpoint] <= BREAKPOINT_WIDTHS[targetBreakpoint];

export let screenIsNoSmallerThan = (activeBreakpoint, targetBreakpoint) => BREAKPOINT_WIDTHS[activeBreakpoint] >= BREAKPOINT_WIDTHS[targetBreakpoint];
