import React from 'react';
import PropTypes from 'prop-types';

import './field-wrapper.css';

export default class FieldWrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    render() {
        let {children, className, meta} = this.props;
        let error = meta.error && meta.touched ? <div className="fieldWrapper-error">{meta.error}</div> : null;
        return (
            <div className={className}>
                {error}
                {children}
            </div>
        );
    }
}
