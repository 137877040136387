import {fromJS} from "immutable";

import {actionTypes} from './view-actions';
import {actionTypes as userActionTypes} from "../users/users-actions";

let INITIAL_STATE = fromJS({
    leftNavExpanded: false
});

let toggleLeftNav = state => {
    return state.set('leftNavExpanded', !state.get('leftNavExpanded'));
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.toggleLeftNav: return toggleLeftNav(state, action);
        case userActionTypes.login:
        case userActionTypes.logout:
            return INITIAL_STATE;
        default: return state;
    }
};