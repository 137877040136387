import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push, activeUserId}) => {
    let {hubId, hubName} = alert.event.data;
    let newMemberId = alert.event.generatedBy;
    let [newMember, setNewMember] = useState();
    useEffect(() => {
        loadUser(newMemberId, setNewMember);
    }, []);

    let message, link;
    if (newMember) {
        message = <span><b>{newMember.name.fullName}</b> has joined <b>{hubName}</b></span>;
        link = `/gift-tags/hubs/${hubId}`;
        if (activeUserId === newMemberId) {
            message = <span>Welcome to <b>{hubName}</b>! You can now view and claim tags for the members of this hub.</span>;
        }
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={link}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}