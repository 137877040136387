import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Route, Switch} from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';

import TitleBar from '../../components/title-bar/title-bar';
import RecipeForm from '../../recipes/recipe-form/recipe-form';
import allBookIcons from '../../../../images/react-icons/books/all-book-icons';
import {COOKBOOK_COLORS} from '../../../../util/constants';
import {createUrl} from '../../../../util/formatters';
import RenderRoute from '../../components/render-route/render-route';
import CookbookForm from '../cookbook-form/cookbook-form';
import ErrorMessenger from '../../components/error-messenger/error-messenger';
import Recipe from '../../recipes/recipe/recipe';
import RecipePreview, {RecipePreviewFiller} from '../../recipes/recipe-preview/recipe-preview';
import ContentCard from '../../components/content-card/content-card';

import './cookbook.css';
import Dropdown from "../../../../components/dropdown/dropdown";
import DescendingSort from "../../../../images/react-icons/descending-sort";

const SORT_TYPES = {
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt'
};
const SORT_DISPLAY = {
    [SORT_TYPES.UPDATED_AT]: 'Updated At',
    [SORT_TYPES.CREATED_AT]: 'Created At'
};

export default function Cookbook({match, history, toggleSidebar}) {
    let {cookbookId} = match.params;
    let [data, setData] = useState();
    let [errorMessage, setErrorMessage] = useState();
    let [subPageTitle, setSubPageTitle] = useState();
    let [subPageIsPrivate, setSubPageIsPrivate] = useState(false);
    let [enableTitleBarSearch, setEnableTitleBarSearch] = useState(true);
    let [sortBy, setSortBy] = useState('updatedAt');
    let [sortDecreasing, setSortDecreasing] = useState(true);

    let loadData = async () => {
        try {
            let [cookbook, recipes] = (await Promise.all([
                axios.get(createUrl(`/cookbooks/${cookbookId}`)),
                axios.get(createUrl(`/cookbooks/${cookbookId}/recipes`))
            ])).map(response => response.data);

            let newData = {cookbook, recipes};
            setData(newData);
            return newData;
        } catch (error) {
            console.error(error);
            if (error.response?.status === 404) {
                setErrorMessage('Sorry, we couldn\'t find that cookbook.');
            } else if (error.response?.status === 403) {
                setErrorMessage('Sorry, you don\'t have access to this cookbook. You can ask an author to either make it public or to add you as an additional author.');
            } else {
                setErrorMessage('Oops, something went wrong with loading the cookbook... Try again later.');
            }
        }
    };
    useEffect(() => {loadData()}, [cookbookId]);

    let updateTitleBar = (title, options = {}) => {
        let {enableSearch, isPrivate = false} = options;
        setSubPageTitle(title);
        setSubPageIsPrivate(isPrivate);
        setEnableTitleBarSearch(enableSearch !== false);
    };

    let CookbookActions = () => {
        let isAuthor = data?.cookbook?.isAuthor || false;
        return (
            <div className="cookbook-cookbookActions">
                <div className="cookbook-cookbookPrimaryActions">
                    {isAuthor && (
                        <button
                            className="cookbook-addRecipeButton"
                            onClick={() => history.push(`/sous-chef/cookbooks/${cookbookId}/recipes/new`)}
                        >Add a Recipe</button>
                    )}
                    {isAuthor && (
                        <button
                            className="cookbook-editCookbookButton"
                            onClick={() => history.push(`/sous-chef/cookbooks/${cookbookId}/edit?referrer=cookbook`)}
                        >Edit Cookbook</button>
                    )}
                    <button
                        className="cookbook-viewAllCookbookButton"
                        onClick={() => history.push('/sous-chef/cookbooks')}
                    >View Cookbooks</button>
                </div>
                <div className="cookbook-cookbookSecondaryActions">
                    <span>Sort By:</span>
                    <Dropdown
                        className="cookbook-sortDropdown"
                        buttonClassName="cookbook-sortDropdownButton"
                        menuClassName="cookbook-sortDropdownMenu"
                        value={SORT_DISPLAY[sortBy]}
                    >
                        <button onClick={() => setSortBy('updatedAt')}>Updated At</button>
                        <button onClick={() => setSortBy('createdAt')}>Created At</button>
                    </Dropdown>
                    <button className="cookbook-sortDirectionButton" onClick={() => setSortDecreasing(!sortDecreasing)}>
                        <DescendingSort className={classnames('cookbook-sortDirectionIcon', {'cookbook-sortDirectionIcon--flip': !sortDecreasing})}/>
                    </button>
                </div>
            </div>
        )
    };

    let PendingDeletionWarning = ({reloadCookbook, cookbook}) => {
        let {pendingDeletionAsOf} = cookbook;
        if (!pendingDeletionAsOf) return null;
        let daysUntilDeletion = Math.max(moment(pendingDeletionAsOf).add(30, 'days').diff(moment(), 'days'), 0);
        let s = daysUntilDeletion !== 1 && 's';
        let onRestore = async () => {
            await axios.put(createUrl(`/cookbooks/${cookbook.id}/restore`));
            await reloadCookbook();
        };
        return (
            <ContentCard className="cookbook-pendingDeletionWarning">
                <div>
                    <span>Warning:</span> This cookbook will be permanently deleted in <span>{daysUntilDeletion} day{s}</span>.
                    Restore the cookbook to prevent it from being deleted.
                </div>
                <button onClick={onRestore} type="button">Restore&nbsp;Cookbook</button>
            </ContentCard>
        )
    };

    let Content = () => {
        let {recipes, cookbook} = data;
        recipes.sort((a, b) => {
            if (a[sortBy] && b[sortBy]) return sortDecreasing ? moment(b[sortBy]).diff(a[sortBy]) : moment(a[sortBy]).diff(b[sortBy]);
            return 0;
        });
        let recipePreviews = recipes.map(recipe => (
            <RecipePreview recipe={recipe} history={history} cookbookPendingDeletionAsOf={cookbook.pendingDeletionAsOf} key={recipe.id} />
        ));
        for (let i = 0; i < 10; i++) recipePreviews.push(<RecipePreviewFiller key={`recipePreviewFiller-${i}`} />);
        return (
            <div>
                <CookbookActions/>
                <PendingDeletionWarning cookbook={cookbook} reloadCookbook={loadData} />
                {!!recipePreviews.length && <div className="cookbook-recipePreviews">{recipePreviews}</div>}
                {!recipePreviews.length && <span className="cookbook-noRecipesMessage">This cookbook doesn't have any recipes yet</span>}
            </div>
        )
    };

    let content;
    if (errorMessage) {
        content = (
            <>
                <TitleBar title="Cookbook" enableSearch={true} toggleSidebar={toggleSidebar} />
                <ErrorMessenger>{errorMessage}</ErrorMessenger>
                <CookbookActions />
            </>
        )
    } else if (data) {
        let {cookbook} = data;
        let {icon, theme, isPrivate: cookbookIsPrivate} = cookbook;
        let CookbookIcon = allBookIcons[icon];
        let colors = COOKBOOK_COLORS[theme];
        let openCookbook = () => history.push(`/sous-chef/cookbooks/${cookbookId}`);
        let cookbookIcon = <CookbookIcon colors={colors} className="cookbook-icon" />;
        if (!match.isExact) cookbookIcon = <button onClick={openCookbook} className="cookbook-iconButton">{cookbookIcon}</button>;
        let additionalRouteProps = {updateTitleBar, reloadCookbook: loadData, cookbook};
        let title;
        if (subPageTitle) title = subPageTitle;
        else if (match.isExact) title = cookbook.name;
        content = (
            <>
                <TitleBar
                    title={title}
                    subtitle={title !== cookbook.name && cookbook.name}
                    subtitleOnClick={openCookbook}
                    isPrivate={cookbookIsPrivate || subPageIsPrivate}
                    enableSearch={enableTitleBarSearch}
                    toggleSidebar={toggleSidebar}
                    icon={cookbookIcon}
                />
                <Switch>
                    <Route path="/sous-chef/cookbooks/:cookbookId/recipes/new" render={RenderRoute(RecipeForm, additionalRouteProps)} />
                    <Route path="/sous-chef/cookbooks/:cookbookId/recipes/:recipeId/edit" render={RenderRoute(RecipeForm, additionalRouteProps)} />
                    <Route path="/sous-chef/cookbooks/:cookbookId/recipes/:recipeId" render={RenderRoute(Recipe, additionalRouteProps)} />
                    <Route path="/sous-chef/cookbooks/:cookbookId/edit" render={RenderRoute(CookbookForm, additionalRouteProps)} />
                    <Route path="/sous-chef/cookbooks/:cookbookId" render={Content} />
                </Switch>
            </>
        )
    }
    return <div className="cookbook">{content}</div>;
}
