import React from 'react';

export default function Blender({className}) {
    let color1 = '#E3EFFC';
    let color2 = '#546C94';
    let color3 = '#95C7EE';
    let color4 = '#DD5113';
    let color5 = '#86ED7F';
    let color6 = '#C9DAFC';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className}>
            <g id="_x30_8_x2C__Electric_Mixer_x2C__Mixer_Blender_x2C__Food_and_Restaurant_x2C__Household_Appliance_x2C__Mixing">
                <g>
                    <path fill={color1} d="M66,431c0,38.66,31.34,70,70,70V331C97.34,331,66,392.34,66,431z"/>
                    <path
                        fill={color1} d="M136,331v170c38.66,0,70-31.34,70-70S174.66,331,136,331z"/>
                    <path fill={color2}
                          d="M211,251v2.6c0,20.66-16.74,37.4-37.4,37.4c-20.195,0-62.958,0-75.2,0    C77.74,291,61,274.26,61,253.6V251l72.667-17.667L211,251z"/>
                    <path
                        fill={color2} d="M401,251v10c0,16.57-13.43,30-30,30s-30-13.43-30-30v-10l30-17.667L401,251z"/>
                    <path fill={color3}
                          d="M291,11H41c-16.57,0-30,13.43-30,30v140v40c0,16.57,13.43,30,30,30c33.931,0,130.136,0,170,0    c63.883,0,219.598,0,260,0c16.56,0,30-13.439,30-30C501,105.02,406.98,11,291,11z"/>
                    <circle
                        fill={color4} cx="71" cy="91" r="20"/>
                    <circle fill={color5} cx="151" cy="91" r="20"/>
                    <path fill={color6}
                          d="M111,431c0-38.66,11.19-100,25-100s25,61.34,25,100s-11.19,70-25,70S111,469.66,111,431z"/>
                    <path
                        fill={color1}
                        d="M391,151c0,16.56-13.44,30-30,30H241c-16.57,0-30-13.43-30-30v-50c0-16.57,13.43-30,30-30h70    C355.18,71,391,106.82,391,151z"/>
                    <g>
                        <g>
                            <path
                                d="M291,1H41C18.944,1,1,18.944,1,41v180c0,22.056,17.944,40,40,40h10.582c3.564,22.634,23.199,40,46.819,40H126v21.081      C85.428,330.818,56,390.953,56,431c0,44.112,35.888,80,80,80s80-35.888,80-80c0-40.047-29.428-100.182-70-108.919V301h27.601      c23.618,0,43.254-17.366,46.818-40H331c0,22.056,17.944,40,40,40s40-17.944,40-40h60c22.056,0,40-17.944,40-40      C511,99.397,412.588,1,291,1z M136,490.957c-4.307-1.752-15-22.056-15-59.957c0-16.777,2.262-38.523,5.903-56.752      c3.267-16.351,6.721-25.569,9.097-30.197c2.377,4.628,5.83,13.847,9.097,30.197C148.738,392.476,151,414.222,151,431      C151,468.903,140.307,489.205,136,490.957z M76,431c0-24.93,15.161-61.854,35.891-79.657C105.533,372.868,101,405.377,101,431      c0,18.932,2.646,38.57,8.189,53.653C89.531,474.789,76,454.451,76,431z M196,431c0,23.451-13.531,43.789-33.189,53.653      C168.354,469.57,171,449.932,171,431c0-25.688-4.551-58.194-10.89-79.656C180.84,369.148,196,406.07,196,431z M173.601,281H98.4      c-12.544,0-23.139-8.477-26.377-20h127.953C196.739,272.523,186.145,281,173.601,281z M371,281c-11.028,0-20-8.972-20-20h40      C391,272.028,382.028,281,371,281z M471,241H41c-11.028,0-20-8.972-20-20v-30h140c5.522,0,10-4.478,10-10s-4.478-10-10-10H21V41      c0-11.028,8.972-20,20-20h250c110.28,0,200,89.72,200,200C491,232.028,482.028,241,471,241z"/>
                            <path
                                d="M311,61h-70c-22.056,0-40,17.944-40,40v50c0,22.056,17.944,40,40,40h120c22.056,0,40-17.944,40-40      C401,101.374,360.626,61,311,61z M361,171H241c-11.028,0-20-8.972-20-20v-50c0-11.028,8.972-20,20-20h70      c38.599,0,70,31.401,70,70C381,162.028,372.028,171,361,171z"/>
                            <path
                                d="M71,61c-16.542,0-30,13.458-30,30s13.458,30,30,30s30-13.458,30-30S87.542,61,71,61z M71,101c-5.514,0-10-4.486-10-10      s4.486-10,10-10s10,4.486,10,10S76.514,101,71,101z"/>
                            <path
                                d="M151,61c-16.542,0-30,13.458-30,30s13.458,30,30,30s30-13.458,30-30S167.542,61,151,61z M151,101      c-5.514,0-10-4.486-10-10s4.486-10,10-10s10,4.486,10,10S156.514,101,151,101z"/>
                        </g>
                    </g>
                </g>
            </g>
            <g id="Layer_1"/>
        </svg>
    );
}
