import React from 'react';

export default function Grill({className}) {
    let color1 = '#000000';
    return (
        <svg viewBox="0 0 41.39 51.69" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g data-name="Layer 2" id="Layer_2">
                <g data-name="Layer 1" id="Layer_1-2">
                    <g id="bbq">
                        <path fill={color1}
                              d="M40.07,20.82H37.6c.11-1.24.7-3.58-1.18-3.67H5c-1.88.09-1.28,2.42-1.18,3.67H1.32a1.34,1.34,0,0,0,0,2.68H40.07A1.34,1.34,0,0,0,40.07,20.82Z"/>
                        <path fill={color1}
                              d="M28.83,33.49a17.22,17.22,0,0,0,7.09-7.3H5.47a17.22,17.22,0,0,0,7.09,7.3L4.05,49.72A1.34,1.34,0,0,0,6.43,51l4.87-9.29H30.09L35,51a1.34,1.34,0,0,0,2.38-1.25ZM12.71,39,15,34.58a17.13,17.13,0,0,0,11.35,0L28.69,39Z"/>
                        <path fill={color1}
                              d="M10.34,10.62a3.34,3.34,0,0,0,1.07,2.46c.32.34.36.4.36.64a1.34,1.34,0,0,0,1.34,1.34c2.28-.19,1.14-3.15.27-3.8-.32-.35-.36-.41-.36-.64s0-.29.36-.64a3.36,3.36,0,0,0,0-4.92c-.32-.35-.36-.4-.36-.64s0-.29.36-.64a3.34,3.34,0,0,0,1.07-2.46,1.34,1.34,0,0,0-2.68,0c0,.24,0,.3-.36.64a3.36,3.36,0,0,0,0,4.92c.32.35.36.4.36.64s0,.29-.36.64A3.34,3.34,0,0,0,10.34,10.62Z"/>
                        <path fill={color1}
                              d="M18.64,10.62a3.34,3.34,0,0,0,1.07,2.46c.32.34.36.4.36.64a1.34,1.34,0,0,0,1.34,1.34c2.28-.21,1.14-3.14.27-3.8-.32-.35-.36-.41-.36-.64s0-.29.36-.64a3.36,3.36,0,0,0,0-4.92c-.32-.35-.36-.4-.36-.64s0-.29.36-.64a3.34,3.34,0,0,0,1.07-2.46,1.34,1.34,0,0,0-2.68,0c0,.24,0,.3-.36.64a3.36,3.36,0,0,0,0,4.92c.32.35.36.4.36.64s0,.29-.36.64A3.34,3.34,0,0,0,18.64,10.62Z"/>
                        <path fill={color1}
                              d="M26.94,10.62A3.34,3.34,0,0,0,28,13.08c.32.34.36.4.36.64a1.34,1.34,0,0,0,1.34,1.34c2.29-.21,1.14-3.14.27-3.8-.32-.35-.36-.41-.36-.64s0-.29.36-.64a3.34,3.34,0,0,0,0-4.92c-.32-.35-.36-.4-.36-.64s0-.29.36-.64a3.34,3.34,0,0,0,1.08-2.46,1.35,1.35,0,0,0-2.69,0c0,.24,0,.3-.36.64a3.36,3.36,0,0,0,0,4.92c.32.35.36.4.36.64s0,.29-.36.64A3.34,3.34,0,0,0,26.94,10.62Z"/>
                    </g>
                </g>
            </g>
        </svg>
    );
}
