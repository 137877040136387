import React from 'react';
import classnames from 'classnames';

import './tooltip.css';

export default ({children, tooltipContent, className, tooltipClassName}) => (
    <span className={classnames('tooltip', className)}>
        {children}
        <span className={classnames('tooltip-bubble', tooltipClassName)}>{tooltipContent}</span>
    </span>
)