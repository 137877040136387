import React, {useEffect} from 'react';
import {reduxForm, Field, formValueSelector} from 'redux-form'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';

import {createUrl} from '../../util/formatters';
import Input from '../input/input';
import {openModal as _openModal} from '../../redux/modal/modal-actions';

import './message.css';

const FORM_NAME = 'message';

export class Message extends React.Component {
    static propTypes = {
        clearFields: PropTypes.func.isRequired,
        deleteMessage: PropTypes.func.isRequired,
        displayMessageOptions: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideHeader: PropTypes.bool,
        isAuthor: PropTypes.bool.isRequired,
        message: PropTypes.shape({
            author: PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.shape({
                    fullName: PropTypes.string.isRequired
                }).isRequired
            }).isRequired,
            body: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            updatedAt: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        }).isRequired,
        messageFormValue: PropTypes.string,
        openModal: PropTypes.func.isRequired,
        setDisplayMessageOptions: PropTypes.func.isRequired,
        unread: PropTypes.bool
    };

    EditableMessage = ({message}) => {
        let {handleSubmit, reset} = this.props;
        useEffect(() => document.getElementById('message-edit').focus(), []);
        return (
            <form onSubmit={handleSubmit(this.onSubmit)} className="message-form">
                <Field
                    name={message.id}
                    component={Input}
                    type="text"
                    className="message-edit"
                    id="message-edit"
                    fieldClassName="message-editField"
                    autoComplete="off"
                />
                <div className="message-editableMessageButtons">
                    <button type="button" className="message-cancelEditButton" onClick={() => {
                        this.setState({editing: false});
                        reset();
                    }}>Cancel</button>
                    <button type="submit" className="message-saveEditButton">Save</button>
                </div>
            </form>
        )
    };

    MessageOptions = ({message, isAuthor}) => {
        let {openModal, displayMessageOptions, deleteMessage, setDisplayMessageOptions, change} = this.props;
        let {updatedBody} = this.state;
        if (!isAuthor || !displayMessageOptions) return null;
        return (
            <div className="message-options">
                <button
                    type="button"
                    className="message-optionsButton"
                    onClick={() => {
                        change(message.id, updatedBody || message.body);
                        this.setState({editing: true});
                        setDisplayMessageOptions(false);
                    }}
                >Edit</button>
                <button
                    type="button"
                    className="message-optionsButton message-optionsButton--destructive"
                    onClick={() => openModal(
                        'CONFIRMATION_MODAL',
                        {
                            message: <div>Are you sure you want to <b>delete this message</b>?</div>,
                            onConfirm: deleteMessage,
                            destructive: true
                        },
                        {title: 'Delete Message', width: 400}
                    )}
                >Delete</button>
            </div>
        );
    };

    toggleMessageOptions = () => {
        let {displayMessageOptions, setDisplayMessageOptions} = this.props;
        setDisplayMessageOptions(!displayMessageOptions);
    };

    onSubmit = async formValues => {
        let {message} = this.props;
        let updatedBody = formValues[message.id];
        await axios.put(createUrl(`/threads/${message.thread}/messages/${message.id}`), {body: updatedBody});
        this.setState({editing: false, updatedBody});
    };

    mouseDownListener = event => {
        if (this.messageRef && !this.messageRef.contains(event.target)) {
            this.props.setDisplayMessageOptions(false);
        }
    };

    addEventListeners = () => {
        let {displayMessageOptions} = this.props;
        if (this.addedListeners || !displayMessageOptions) return;
        document.addEventListener('mousedown', this.mouseDownListener, false);
        document.addEventListener('touchstart', this.mouseDownListener, false);
        this.addedListeners = true;
    };

    removeEventListeners = () => {
        if (!this.addedListeners) return;
        document.removeEventListener('mousedown', this.mouseDownListener, false);
        document.removeEventListener('touchstart', this.mouseDownListener, false);
        this.addedListeners = false;
    };

    state = {
        editing: false
    };

    componentDidMount = () => {
        this.addEventListeners();
    };

    componentDidUpdate = previousProps => {
        let {displayMessageOptions} = this.props;
        if (previousProps.displayMessageOptions !== displayMessageOptions) {
            if (displayMessageOptions) this.addEventListeners();
            else this.removeEventListeners();
        }
    };

    componentWillUnmount = () => {
        let {clearFields, message, messageFormValue} = this.props;
        this.removeEventListeners();
        if (messageFormValue) clearFields(false, false, message.id);
    };

    render() {
        let {MessageOptions, EditableMessage} = this;
        let {isAuthor, hideHeader = false, message, unread} = this.props;
        let {editing, updatedBody} = this.state;
        let time = moment(message.createdAt).format('h:mma');
        let edited = updatedBody || message.createdAt !== message.updatedAt;
        let classNames = classnames(
            'message',
            {'message--headerHidden': hideHeader},
            {'message--unread': unread},
            {'message--isAuthor': isAuthor}
        );
        let body;
        if (editing) {
            body = <EditableMessage message={message} />;
        } else {
            let BodyType = isAuthor ? 'button' : 'div';
            let bodyTypeProps = isAuthor ? {onClick: this.toggleMessageOptions} : {};
            body = (
                <div className="message-bodyContainer" ref={element => this.messageRef = element}>
                    <BodyType className="message-body" {...bodyTypeProps} >
                        <div>{updatedBody || message.body}</div>
                        {isAuthor && <div className="message-optionsIcon" />}
                    </BodyType>
                    <MessageOptions message={message} isAuthor={isAuthor} />
                </div>
            );
        }
        return (
            <div className={classNames}>
                {unread && <div className="message-unreadText">NEW</div>}
                <div className="message-header">
                    {message.author.name.fullName}
                    <span className="message-time">{time}</span>
                </div>
                {body}
                {edited && <div className="message-editedLabel">Edited</div>}
            </div>
        );
    }
}

let reduxConfig = {
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
};

let mapStateToProps = (state, ownProps) => ({
    messageFormValue: formValueSelector(FORM_NAME)(state, ownProps.message.id)
});

let ReduxForm = reduxForm(reduxConfig)(Message);
export default connect(mapStateToProps, {openModal: _openModal})(ReduxForm);
