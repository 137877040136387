import React from 'react';

export default function DinnerPlate({className}) {
    let color1 = '#000000';
    return (
        <svg height="512px" viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="food__x2C__plate__x2C__knife__x2C_">
                <g>
                    <g>
                        <path
                            d="M272.234,418.521c-89.492,0-162.3-72.804-162.3-162.291c0-43.462,16.897-84.263,47.578-114.887     c30.631-30.574,71.374-47.412,114.722-47.412c43.347,0,84.089,16.838,114.72,47.412c30.681,30.624,47.577,71.424,47.577,114.887     C434.531,345.717,361.726,418.521,272.234,418.521z M272.234,102.932c-84.53,0-153.3,68.77-153.3,153.298     c0,84.525,68.77,153.291,153.3,153.291c84.528,0,153.297-68.766,153.297-153.291     C425.531,171.701,356.763,102.932,272.234,102.932z M273.946,368.782c-20.687,0-37.516-16.638-37.516-37.088V180.758     c0-20.687,16.83-37.516,37.516-37.516c20.451,0,37.089,16.83,37.089,37.516v150.936     C311.035,352.145,294.397,368.782,273.946,368.782z M273.946,152.242c-15.724,0-28.516,12.792-28.516,28.516v150.936     c0,15.487,12.792,28.088,28.516,28.088c15.488,0,28.089-12.601,28.089-28.088V180.758     C302.035,165.035,289.435,152.242,273.946,152.242z"
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="272.234,139.167 272.662,138.738 273.09,138.738 273.519,138.738 273.519,138.738      274.376,138.31 274.804,137.881 275.232,137.453 275.66,136.596 275.66,136.596 275.66,136.167 276.089,135.739 276.089,135.311      276.089,134.882 275.66,134.454 275.66,134.454 275.66,134.026 275.232,133.162 274.804,132.732 274.376,132.305      273.519,131.876 273.519,131.876 273.09,131.876 272.662,131.876 272.234,131.876    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="265.372,139.167 266.228,139.167 269.234,139.167 272.234,139.167 272.234,131.876      269.234,131.876 265.8,131.876 264.943,131.876    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="264.943,131.876 264.515,131.876 264.086,131.876 263.658,132.305 263.658,132.305      262.801,132.732 262.373,133.162 261.944,133.597 261.516,134.454 261.516,134.454 261.516,134.882 261.516,135.311      261.516,135.739 261.516,136.167 261.516,136.596 261.516,136.596 261.944,137.024 261.944,137.881 262.801,138.31      263.229,138.738 263.658,139.167 264.086,139.167 264.515,139.167 264.943,139.167 265.372,139.167 264.943,131.876    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="231.07,146.457 231.07,146.457 231.499,146.028 231.927,146.028 231.927,145.6      232.784,145.172 232.784,144.743 233.212,143.886 233.212,143.458 233.212,143.029 233.212,142.601 233.212,142.172      233.212,141.737 232.784,141.308 232.784,141.308 232.355,140.88 232.355,140.451 231.927,140.023 231.07,139.595      230.642,139.595 229.785,139.595 229.356,139.595 228.928,139.595 228.928,139.595 228.492,139.595 231.07,146.457    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="224.208,149.027 226.778,148.17 229.356,146.885 231.07,146.457 228.492,139.595      226.778,140.451 223.78,141.308 221.639,142.601    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="221.639,142.601 221.209,142.601 220.781,143.029 220.781,143.029 220.346,143.458      219.917,143.886 219.489,144.315 219.489,145.172 219.489,146.028 219.489,146.028 219.489,146.457 219.489,146.885      219.489,147.313 219.917,147.742 219.917,147.742 220.346,148.17 220.346,148.599 220.781,149.027 221.639,149.027      222.066,149.463 222.923,149.463 223.351,149.463 223.78,149.463 224.208,149.463 224.208,149.027 221.639,142.601    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="195.048,168.327 195.048,167.899 195.476,167.47 195.476,167.47 195.905,167.042      195.905,166.185 196.333,165.756 196.333,164.892 195.905,164.464 195.905,164.035 195.905,163.607 195.476,163.179      195.476,163.179 195.048,162.75 194.62,162.322 194.62,162.322 194.191,162.322 193.334,161.894 192.906,161.894      192.048,161.894 191.621,161.894 191.192,161.894 190.764,162.322 190.335,162.322 190.335,162.75 195.048,168.327    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="189.907,173.039 191.621,171.326 193.334,169.184 195.048,168.327 190.335,162.75      188.621,164.035 186.472,166.185 184.759,167.899    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="184.759,167.899 184.331,167.899 184.331,168.327 183.902,168.756 183.902,168.756      183.902,169.612 183.474,170.469 183.474,170.897 183.902,171.754 183.902,171.754 184.331,172.182 184.331,172.611      184.759,173.039 184.759,173.039 185.188,173.468 185.616,173.468 185.616,173.468 186.472,173.896 187.329,173.896      187.758,173.896 188.621,173.896 189.05,173.468 189.05,173.468 189.479,173.039 189.907,173.039 184.759,167.899    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="168.894,200.915 168.894,200.915 169.322,200.486 169.322,200.058 169.322,199.629      169.322,199.202 169.322,198.344 168.894,197.488 168.464,197.052 168.037,196.623 168.037,196.623 167.608,196.195      167.18,196.195 167.18,196.195 166.751,195.767 166.323,195.767 165.895,195.767 165.466,195.767 164.602,195.767      164.174,196.195 163.316,196.623 163.316,196.623 162.888,197.052 162.46,197.488 162.46,197.488 168.894,200.915    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="165.895,207.348 166.751,205.198 168.037,202.629 168.894,200.915 162.46,197.488      161.603,199.629 160.318,202.2 159.033,204.342    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="159.033,204.342 159.033,204.771 159.033,204.771 159.033,205.198 158.604,205.627      159.033,206.492 159.033,206.919 159.461,207.776 159.89,208.205 159.89,208.633 160.318,208.633 160.318,209.062      160.747,209.062 161.175,209.49 161.603,209.49 162.031,209.49 162.031,209.49 162.888,209.49 163.745,209.49 164.174,209.062      165.037,208.633 165.037,208.205 165.466,208.205 165.466,207.776 165.895,207.348 159.033,204.342    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="156.026,241.222 156.026,240.792 156.026,240.364 156.026,239.936 156.026,239.936      155.599,239.079 155.599,238.65 154.742,237.787 154.313,237.358 153.885,237.358 153.885,237.358 153.456,236.929      153.028,236.929 152.599,236.929 152.171,236.929 151.742,236.929 151.742,236.929 150.885,237.358 150.458,237.787      150.029,238.222 149.601,238.65 149.173,239.079 149.173,239.508 149.173,239.936 148.744,239.936 156.026,241.222    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="155.599,248.083 155.599,247.226 155.599,244.22 156.026,241.222 156.026,241.222      148.744,239.936 148.744,240.364 148.308,243.363 148.308,246.369 148.308,247.654    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="148.308,247.654 148.308,248.083 148.308,248.511 148.308,248.511 148.308,248.939      148.744,249.796 149.173,250.225 149.601,250.653 150.029,251.082 150.458,251.082 150.885,251.51 151.314,251.51      151.314,251.51 151.742,251.51 152.171,251.51 152.599,251.51 153.028,251.51 153.456,251.082 154.313,250.653 154.742,250.225      155.169,249.368 155.169,249.368 155.169,248.939 155.169,248.511 155.599,248.083 148.308,247.654    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="158.176,283.241 158.176,282.813 158.176,282.384 157.748,281.956 157.748,281.956      157.319,281.099 156.462,280.67 156.026,280.67 155.169,280.242 155.169,280.242 154.742,280.242 154.313,280.242      153.885,280.242 153.456,280.67 153.028,280.67 153.028,280.67 152.599,281.099 152.171,281.527 151.742,281.956      151.314,282.813 151.314,283.241 151.314,283.669 151.314,284.098 151.314,284.526 151.314,284.526 158.176,283.241    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="159.89,290.103 159.461,287.961 159.033,285.383 158.176,283.241 151.314,284.526      151.742,287.104 152.599,290.103 153.028,291.816    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="153.028,291.816 153.028,292.245 153.456,292.673 153.456,293.102 153.885,293.102      154.313,293.537 154.742,293.966 155.599,294.394 156.026,294.394 156.462,294.394 156.891,294.394 157.319,294.394      157.748,294.394 157.748,294.394 158.176,293.966 158.604,293.966 159.033,293.537 159.461,293.102 159.89,292.673      159.89,292.245 160.318,291.388 160.318,290.959 160.318,290.531 160.318,290.103 159.89,290.103 153.028,291.816    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="175.326,321.834 174.898,321.405 174.898,320.977 174.47,320.977 174.042,320.548      173.613,320.12 172.757,320.12 172.321,320.12 171.464,320.12 171.036,320.12 170.607,320.12 170.607,320.548 170.178,320.548      169.75,320.548 169.75,320.977 169.322,321.405 169.322,321.405 168.894,322.262 168.464,322.689 168.464,323.548      168.464,324.403 168.464,324.403 168.894,324.832 168.894,325.262 168.894,325.696 175.326,321.834    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="179.183,327.41 178.326,326.124 176.612,323.976 175.326,321.405 175.326,321.834      168.894,325.696 169.322,325.696 171.036,328.267 172.757,330.408 173.613,331.694    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="173.613,331.694 173.613,332.122 174.042,332.122 174.042,332.551 174.47,332.551      175.326,332.979 175.755,332.979 176.612,332.979 177.04,332.979 177.469,332.979 177.897,332.551 178.326,332.551      178.326,332.551 178.753,332.122 179.183,331.694 179.183,331.694 179.61,331.266 179.61,330.838 180.039,329.98      180.039,329.124 180.039,328.695 179.61,328.267 179.61,327.838 179.61,327.41 179.183,327.41 173.613,331.694    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="204.487,351.422 204.051,351.422 204.051,351.422 203.624,350.994 203.194,350.994      202.338,350.994 201.91,350.994 201.053,350.994 200.624,351.422 200.196,351.851 199.767,351.851 199.767,352.279      199.339,352.279 199.339,352.708 198.91,353.136 198.91,353.564 198.91,353.992 198.91,354.422 198.91,355.278 198.91,355.706      199.339,356.563 199.339,356.992 199.767,356.992 200.196,357.42 200.196,357.42 204.487,351.422    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="210.484,355.706 209.2,354.85 206.629,353.136 204.487,351.422 200.196,357.42      202.767,359.141 205.344,360.854 206.629,361.711    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="206.629,361.711 206.629,361.711 207.058,362.141 207.486,362.141 207.915,362.141      208.343,362.141 209.2,362.141 210.056,362.141 210.484,361.711 210.913,361.282 210.913,361.282 211.342,360.854      211.342,360.427 211.77,360.427 211.77,359.998 211.77,359.568 212.198,359.141 212.198,358.713 211.77,357.856 211.77,356.992      211.342,356.563 211.342,356.135 210.913,356.135 210.484,355.706 210.484,355.706 206.629,361.711    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="242.644,369.43 242.216,369.43 241.788,369.43 241.359,369.43 241.359,369.43      240.502,369.43 240.074,369.858 239.217,370.287 238.789,370.715 238.789,371.144 238.361,371.144 238.361,371.571      237.932,372.001 237.932,372.429 237.932,372.857 237.932,372.857 237.932,373.285 238.361,374.149 238.361,374.578      238.789,375.436 239.217,375.863 239.646,375.863 240.074,376.292 240.074,376.292 240.502,376.292 242.644,369.43    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="249.506,371.144 248.649,370.715 245.65,370.287 243.073,369.43 242.644,369.43      240.502,376.292 241.359,376.72 244.357,377.148 247.364,378.006 247.792,378.006    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="247.792,378.006 248.221,378.006 248.649,378.006 249.078,378.006 249.506,378.006      249.935,378.006 250.792,377.577 251.219,377.148 251.648,376.72 252.076,376.292 252.076,375.863 252.076,375.436      252.076,375.436 252.512,375.006 252.512,374.578 252.512,374.149 252.076,373.722 252.076,373.285 251.648,372.429      251.219,372.001 250.792,371.571 250.363,371.571 249.935,371.144 249.935,371.144 249.506,371.144 247.792,378.006    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="284.672,372.429 284.236,372.857 283.808,372.857 283.379,372.857 282.951,372.857      282.522,373.285 282.094,373.722 281.665,374.578 281.237,375.006 281.237,375.436 281.237,375.863 281.237,376.292      281.237,376.72 281.237,377.148 281.237,377.148 281.665,377.577 281.665,378.006 282.094,378.434 282.522,378.862      282.951,379.29 283.808,379.719 284.236,379.719 284.672,379.719 284.672,379.719 285.1,379.719 284.672,372.429    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="291.525,371.571 290.241,372.001 287.242,372.429 284.672,372.429 285.1,379.719      288.099,379.29 291.098,378.862 292.384,378.862    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="292.384,378.862 292.818,378.862 293.247,378.434 293.676,378.434 294.104,378.434      294.532,378.006 294.96,377.577 295.389,376.72 295.389,376.292 295.818,375.863 295.818,375.436 295.818,375.006      295.818,374.578 295.389,374.149 295.389,374.149 295.389,373.722 294.96,373.285 294.532,372.857 294.104,372.429      293.676,372.001 292.818,371.571 292.384,371.571 292.384,371.571 291.954,371.571 291.525,371.571 292.384,378.862    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="324.979,360.854 324.542,360.854 324.114,361.282 324.114,361.282 323.686,361.711      323.257,362.141 322.828,362.996 322.828,363.425 322.828,364.282 322.828,364.71 322.828,365.139 322.828,365.139      323.257,365.567 323.257,365.995 323.686,366.431 323.686,366.431 324.114,366.859 324.542,367.288 325.406,367.288      325.835,367.717 326.691,367.717 327.12,367.717 327.12,367.717 327.549,367.288 327.977,367.288 324.979,360.854    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="330.976,357.42 330.547,357.856 327.977,359.141 325.406,360.427 324.979,360.854      327.977,367.288 328.834,366.859 331.404,365.567 333.981,363.854 334.41,363.854    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="334.41,363.854 334.839,363.425 335.267,363.425 335.267,362.996 335.695,362.996      336.124,362.141 336.124,361.711 336.552,360.854 336.552,359.998 336.124,359.998 336.124,359.568 336.124,359.141      336.124,358.713 335.695,358.284 335.695,358.284 335.267,357.856 334.839,357.856 334.41,357.42 333.554,356.992      333.125,356.992 332.261,356.992 331.833,356.992 331.833,356.992 331.404,357.42 330.976,357.42 334.41,363.854    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="358.423,335.557 357.995,335.557 357.995,335.985 357.565,336.414 357.565,336.843      357.565,337.271 357.565,338.127 357.565,338.557 357.565,339.412 357.995,339.841 357.995,340.27 358.423,340.27      358.423,340.697 358.852,340.697 359.279,341.126 359.279,341.126 359.708,341.562 360.564,341.562 360.993,341.562      361.85,341.562 362.277,341.126 362.707,341.126 363.136,341.126 363.563,340.697 363.563,340.697 358.423,335.557    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="363.136,330.408 362.707,330.408 360.993,332.551 358.852,334.7 358.423,335.557      363.563,340.697 364.42,339.841 366.142,337.698 368.284,335.129 368.712,334.7    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="368.712,334.7 368.712,334.7 369.14,334.272 369.14,333.836 369.14,333.408      369.568,332.979 369.568,332.122 369.14,331.694 369.14,330.838 368.712,330.408 368.712,330.408 368.284,329.98      368.284,329.552 367.854,329.552 367.426,329.124 366.998,329.124 366.998,329.124 366.142,328.695 365.284,328.695      364.856,329.124 363.991,329.124 363.991,329.552 363.563,329.552 363.136,329.98 363.136,330.408 368.712,334.7    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="380.722,299.964 380.722,300.392 380.722,300.819 380.722,301.248 380.722,301.248      380.722,302.112 380.722,302.97 381.149,303.398 381.579,303.826 382.007,304.254 382.007,304.254 382.436,304.683      382.863,304.683 383.292,304.683 383.721,305.111 383.721,305.111 384.149,305.111 385.006,305.111 385.434,304.683      386.291,304.254 386.72,303.826 387.147,303.826 387.147,303.398 387.575,302.97 387.575,302.97 380.722,299.964    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="383.292,293.537 383.292,293.537 382.436,296.536 381.149,299.105 380.722,299.964      387.575,302.97 388.004,301.677 389.297,298.678 390.154,296.107 390.154,295.68    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="390.154,295.68 390.154,295.251 390.154,295.251 390.582,294.822 390.582,294.394      390.154,293.537 390.154,293.102 389.726,292.245 389.297,291.816 388.861,291.816 388.434,291.388 388.434,291.388      388.004,291.388 387.575,290.959 387.147,290.959 386.72,290.959 386.291,290.959 385.862,290.959 385.006,291.388      384.577,291.816 384.149,292.245 383.721,292.673 383.721,292.673 383.292,293.102 383.292,293.537 390.154,295.68    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="389.297,258.8 389.297,259.229 389.297,259.657 389.297,260.085 389.726,260.514      389.726,260.942 390.154,261.371 391.01,261.807 391.439,262.234 391.868,262.234 392.296,262.663 392.724,262.663      392.724,262.663 393.152,262.663 393.581,262.663 394.01,262.663 394.438,262.234 394.866,262.234 395.295,261.807      396.151,260.942 396.151,260.514 396.58,260.085 396.58,259.657 396.58,259.657 396.58,259.229 389.297,258.8    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon points="389.297,256.23 389.297,256.23 389.297,258.8 396.58,259.229 396.58,256.23      396.58,256.23    "
                                 fill={color1}/>
                    </g>
                    <g>
                        <polygon points="389.297,251.938 389.297,253.224 389.297,256.23 396.58,256.23 396.58,252.795      396.58,251.51    "
                                 fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="396.58,251.51 396.58,251.082 396.58,251.082 396.58,250.653 396.151,250.225      395.723,249.796 395.295,248.939 394.866,248.511 394.438,248.511 394.01,248.083 393.581,248.083 393.152,248.083      392.724,248.083 392.296,248.083 392.296,248.083 391.868,248.511 391.439,248.511 391.01,248.939 390.154,249.368      389.726,249.796 389.726,250.225 389.297,250.653 389.297,251.082 389.297,251.51 389.297,251.938 396.58,251.51    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="382.863,217.208 382.863,217.638 383.292,218.065 383.292,218.493 383.721,218.493      384.149,218.922 384.577,219.351 385.434,219.779 385.862,219.779 386.291,219.779 386.72,219.779 387.147,219.779      387.575,219.779 387.575,219.351 388.004,219.351 388.434,218.922 388.861,218.922 389.297,218.493 389.726,217.638      389.726,217.208 389.726,216.352 389.726,215.924 389.726,215.924 389.726,215.495 389.726,215.066 382.863,217.208    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="380.286,210.775 381.149,213.345 382.436,215.924 382.863,217.208 389.726,215.066      389.297,213.345 388.004,210.347 387.147,208.205    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="387.147,208.205 386.72,207.776 386.72,207.348 386.72,207.348 386.291,206.919      385.862,206.492 385.006,206.063 384.577,206.063 383.721,205.627 383.292,205.627 382.863,206.063 382.863,206.063      382.436,206.063 382.007,206.063 381.579,206.492 381.579,206.492 381.149,206.919 380.722,207.348 380.286,207.776      380.286,208.633 379.857,209.49 379.857,209.49 380.286,209.919 380.286,210.347 380.286,210.775 387.147,208.205    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="361.85,180.758 362.277,181.187 362.707,181.187 362.707,181.622 363.136,181.622      363.991,182.051 364.42,182.051 365.284,182.051 365.713,182.051 366.142,182.051 366.57,181.622 366.998,181.622      366.998,181.187 367.426,181.187 367.854,180.758 367.854,180.33 367.854,180.33 368.284,179.473 368.284,179.044      368.284,178.187 368.284,177.33 368.284,177.33 367.854,176.902 367.854,176.474 367.854,176.045 361.85,180.758    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="357.138,175.617 358.852,177.33 360.993,179.473 361.85,180.758 367.854,176.045      366.142,174.76 364.42,172.611 362.707,170.897    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="362.707,170.897 362.277,170.469 362.277,170.041 361.85,170.041 361.422,170.041      360.993,169.612 360.137,169.612 359.708,169.612 358.852,169.612 358.423,170.041 357.995,170.041 357.995,170.041      357.565,170.469 357.138,170.897 357.138,170.897 356.701,171.326 356.701,171.754 356.701,172.182 356.273,173.039      356.273,173.468 356.701,174.332 356.701,174.76 356.701,175.188 357.138,175.188 357.138,175.617 362.707,170.897    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="329.69,154.175 330.119,154.175 330.547,154.175 330.547,154.603 330.976,154.603      331.833,154.603 332.697,154.175 333.125,154.175 333.554,153.746 333.981,153.318 334.41,153.318 334.41,152.89      334.839,152.461 334.839,152.461 334.839,152.034 335.267,151.604 335.267,151.177 335.267,150.748 334.839,149.891      334.839,149.463 334.41,148.599 334.41,148.599 333.981,148.17 333.554,147.742 333.554,147.742 329.69,154.175    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="323.257,150.748 325.406,151.604 327.977,152.89 329.69,154.175 333.554,147.742      331.404,146.885 328.834,145.172 326.691,144.315    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="326.691,144.315 326.263,143.886 326.263,143.886 325.835,143.886 325.406,143.886      324.542,143.886 324.114,143.886 323.257,144.315 322.828,144.743 322.4,144.743 322.4,145.172 321.972,145.6 321.972,146.028      321.544,146.028 321.544,146.457 321.544,146.885 321.544,147.313 321.544,147.742 321.544,148.599 321.972,149.027      322.4,149.891 322.4,149.891 322.828,150.32 323.257,150.32 323.257,150.748 326.691,144.315    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="290.241,140.451 290.241,140.451 290.67,140.451 291.098,140.451 291.525,140.023      291.954,140.023 292.818,139.595 293.247,139.167 293.676,138.738 293.676,138.31 294.104,137.881 294.104,137.453      294.104,137.453 294.104,137.024 294.104,136.596 294.104,136.167 294.104,135.739 293.676,135.311 293.676,134.454      293.247,134.026 292.384,133.597 292.384,133.597 291.954,133.162 291.525,133.162 291.098,133.162 290.241,140.451    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="282.951,139.595 284.236,139.595 287.242,140.023 290.241,140.451 291.098,133.162      288.099,132.732 285.1,132.305 283.808,132.305    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="283.808,132.305 283.379,132.305 282.951,132.305 282.522,132.305 282.522,132.305      281.665,132.732 281.237,133.162 280.809,133.597 280.381,134.026 279.952,134.454 279.952,134.882 279.952,135.311      279.952,135.311 279.952,135.739 279.952,136.167 279.952,136.596 279.952,137.024 280.381,137.453 280.381,138.31      281.237,138.738 281.665,139.167 282.094,139.167 282.094,139.167 282.522,139.595 282.951,139.595 283.808,132.305    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="273.946,144.315 273.519,144.315 273.09,144.315 272.662,144.315 272.234,144.315      271.805,144.743 271.376,145.172 270.948,145.6 270.521,146.457 270.092,146.457 270.092,146.885 270.092,147.313      270.092,147.742 270.092,148.17 270.092,148.599 270.092,149.027 270.521,149.027 270.948,149.891 271.376,150.32      271.805,150.748 272.234,151.177 272.662,151.177 273.09,151.177 273.519,151.177 273.946,151.177    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon points="273.946,147.742 273.946,147.742 273.946,147.742 273.946,147.742 273.946,144.315    " fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="281.665,145.172 281.237,144.743 279.095,144.743 277.381,144.315 275.66,144.315      273.946,144.315 273.946,151.177 275.232,151.604 276.518,151.604 278.238,151.604 279.523,152.034 280.381,152.034    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="280.381,152.034 280.381,152.034 280.809,152.034 281.237,152.034 281.665,152.034      282.522,152.034 282.951,151.604 283.379,151.177 283.808,150.748 284.236,150.32 284.236,149.891 284.236,149.463      284.672,149.463 284.672,149.027 284.672,148.599 284.672,148.17 284.672,147.742 284.236,147.313 283.808,146.457      283.379,146.028 282.951,145.6 282.522,145.172 282.522,145.172 282.094,145.172 281.665,145.172 280.381,152.034    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="309.113,171.754 309.113,171.326 309.113,170.897 308.677,170.897 308.677,170.469      308.249,170.041 307.392,169.612 306.963,169.184 306.106,169.184 305.679,169.184 305.679,169.184 305.249,169.184      304.821,169.184 304.393,169.184 303.965,169.184 303.536,169.612 303.536,169.612 303.107,170.041 302.679,170.897      302.251,171.326 302.251,172.182 301.822,172.611 301.822,172.611 302.251,173.039 302.251,173.468 309.113,171.754    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="310.397,179.473 310.397,178.616 309.97,176.902 309.97,175.188 309.541,173.468      309.113,171.754 302.251,173.468 302.251,174.76 302.679,176.045 302.679,177.759 303.107,179.044 303.107,179.901    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="303.107,179.901 303.107,180.33 303.107,180.758 303.107,181.187 303.536,181.187      303.536,182.051 304.393,182.479 304.821,182.907 305.249,183.335 305.679,183.335 306.106,183.335 306.535,183.335      306.963,183.335 307.392,183.335 307.392,183.335 307.82,183.335 308.249,182.907 308.677,182.907 309.113,182.051      309.541,181.622 309.97,181.187 309.97,180.758 310.397,180.33 310.397,179.901 310.397,179.473 303.107,179.901    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="310.397,215.924 310.397,215.924 310.397,215.495 309.97,215.066 309.97,214.638      309.541,214.21 309.113,213.345 308.677,212.917 308.249,212.917 307.82,212.489 307.392,212.489 306.963,212.489      306.535,212.489 306.106,212.489 306.106,212.489 305.679,212.489 305.249,212.917 304.821,212.917 303.965,213.345      303.536,214.21 303.107,214.638 303.107,215.066 303.107,215.495 303.107,215.924 303.107,215.924    "
                            fill={color1}/>
                    </g>
                    <g>
                        <rect height="7.29" fill={color1} width="7.29" x="303.107" y="215.924"/>
                    </g>
                    <g>
                        <polygon
                            points="303.107,223.214 303.107,223.642 303.107,224.07 303.107,224.499 303.107,224.927      303.536,225.355 303.965,225.784 304.821,226.212 305.249,226.641 305.679,226.641 306.106,227.069 306.106,227.069      306.535,227.069 306.963,227.069 307.392,227.069 307.82,226.641 308.249,226.641 308.677,226.212 309.113,225.784      309.541,225.355 309.97,224.927 309.97,224.499 310.397,224.07 310.397,223.642 310.397,223.214    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="310.397,259.657 310.397,259.229 310.397,258.8 309.97,258.372 309.97,258.372      309.541,257.515 309.113,257.086 308.677,256.658 308.249,256.23 307.82,256.23 307.392,256.23 306.963,256.23 306.535,256.23      306.106,256.23 306.106,256.23 305.679,256.23 305.249,256.23 304.821,256.658 303.965,257.086 303.536,257.515 303.107,258.372      303.107,258.372 303.107,258.8 303.107,259.229 303.107,259.657    "
                            fill={color1}/>
                    </g>
                    <g>
                        <rect height="7.29" fill={color1} width="7.29" x="303.107" y="259.657"/>
                    </g>
                    <g>
                        <polygon
                            points="303.107,266.947 303.107,267.375 303.107,267.804 303.107,267.804 303.107,268.232      303.536,269.089 303.965,269.518 304.821,269.945 305.249,270.382 305.679,270.382 306.106,270.382 306.106,270.382      306.535,270.382 306.963,270.382 307.392,270.382 307.82,270.382 308.249,270.382 308.677,269.945 309.113,269.518      309.541,269.089 309.97,268.232 309.97,267.804 310.397,267.804 310.397,267.375 310.397,266.947    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="310.397,303.398 310.397,302.97 310.397,302.54 309.97,302.112 309.97,301.677      309.541,301.248 309.113,300.819 308.677,300.392 308.249,299.964 307.82,299.535 307.392,299.535 306.963,299.535      306.535,299.535 306.106,299.535 306.106,299.535 305.679,299.535 305.249,299.964 304.821,300.392 303.965,300.819      303.536,301.248 303.107,301.677 303.107,302.112 303.107,302.54 303.107,302.97 303.107,303.398    "
                            fill={color1}/>
                    </g>
                    <g>
                        <rect height="7.289" fill={color1} width="7.29" x="303.107" y="303.398"/>
                    </g>
                    <g>
                        <polygon
                            points="303.107,310.688 303.107,310.688 303.107,311.116 303.107,311.545 303.107,311.973      303.536,312.401 303.965,312.83 304.821,313.687 305.249,313.687 305.679,314.115 306.106,314.115 306.106,314.115      306.535,314.115 306.963,314.115 307.392,314.115 307.82,314.115 308.249,313.687 308.677,313.687 309.113,312.83      309.541,312.401 309.97,311.973 309.97,311.545 310.397,311.116 310.397,310.688 310.397,310.688    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="306.535,347.987 306.535,347.56 306.535,347.132 306.535,346.703 306.963,346.273      306.963,345.846 306.535,344.989 306.106,344.561 305.679,343.704 305.679,343.704 305.249,343.275 305.249,343.275      304.821,342.847 304.393,342.847 303.965,342.847 303.536,342.847 303.536,342.419 302.679,342.847 301.822,342.847      301.395,343.275 300.965,343.704 300.537,343.704 300.102,344.133 300.102,344.133 300.102,344.561 306.535,347.987    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="302.251,354.422 303.107,353.564 303.965,351.851 304.821,350.565 305.679,348.845      306.535,347.987 300.102,344.561 299.673,345.418 298.816,346.703 297.96,347.987 297.103,348.845 296.246,350.13    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="296.246,350.13 296.246,350.565 295.818,350.565 295.818,350.994 295.818,351.422      295.389,352.279 295.818,352.708 295.818,353.564 296.246,353.992 296.246,354.422 296.674,354.85 296.674,354.85      297.103,355.278 297.53,355.278 297.53,355.706 297.96,355.706 298.388,355.706 299.244,356.135 299.673,355.706      300.537,355.706 300.965,355.278 301.395,355.278 301.822,354.85 301.822,354.85 302.251,354.422 296.246,350.13    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="265.8,367.288 266.228,367.288 266.657,367.288 267.086,367.288 267.514,367.288      267.941,366.859 268.807,366.859 269.234,366.431 269.662,365.567 269.662,365.567 270.092,365.139 270.092,364.71      270.092,364.282 270.092,363.854 270.092,363.854 270.092,363.425 270.092,362.996 270.092,362.141 269.662,361.711      269.234,361.282 268.807,360.854 268.37,360.427 267.941,360.427 267.941,359.998 267.514,359.998 265.8,367.288    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="258.51,364.71 259.366,365.139 261.088,365.995 262.801,366.431 264.515,366.859      265.8,367.288 267.514,359.998 266.657,359.998 264.943,359.568 263.658,359.141 262.373,358.713 261.088,357.856    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="261.088,357.856 261.088,357.856 260.651,357.856 260.224,357.856 259.795,357.856      258.938,357.856 258.51,357.856 257.653,358.284 257.225,358.713 257.225,359.141 256.796,359.141 256.796,359.568      256.368,359.998 256.368,360.427 256.368,360.427 256.368,360.854 256.368,361.282 256.368,362.141 256.368,362.568      256.796,363.425 257.225,363.854 257.653,364.282 257.653,364.282 258.081,364.282 258.51,364.71 261.088,357.856    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="237.075,332.551 237.503,332.979 237.503,333.408 237.503,333.836 237.503,334.272      237.932,334.7 238.361,335.129 238.789,335.557 239.646,335.985 240.074,335.985 240.502,335.985 240.502,335.985      240.93,335.985 241.359,335.985 241.788,335.985 242.216,335.985 242.644,335.557 243.073,335.557 243.501,335.129      243.93,334.272 244.357,333.836 244.357,333.408 244.357,332.979 244.357,332.551 244.357,332.122 237.075,332.551    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon points="237.075,331.694 237.075,331.694 237.075,332.551 244.357,332.122 244.357,331.694      244.357,331.694    "
                                 fill={color1}/>
                    </g>
                    <g>
                        <rect height="6.433" fill={color1} width="7.282" x="237.075" y="325.262"/>
                    </g>
                    <g>
                        <polygon
                            points="244.357,325.262 244.357,324.832 244.357,324.403 244.357,323.976 244.357,323.976      243.93,323.119 243.501,322.689 243.073,322.262 242.216,321.834 241.788,321.834 241.788,321.834 241.359,321.405      240.93,321.405 240.502,321.405 240.074,321.834 239.646,321.834 239.646,321.834 238.789,322.262 238.361,322.689      237.932,323.119 237.503,323.976 237.503,323.976 237.503,324.403 237.075,324.832 237.075,325.262    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="237.075,288.817 237.075,289.245 237.503,289.675 237.503,290.103 237.503,290.531      237.932,290.959 238.361,291.388 238.789,291.816 239.646,292.245 239.646,292.245 240.074,292.673 240.502,292.673      240.93,292.673 241.359,292.673 241.788,292.673 241.788,292.245 242.216,292.245 243.073,291.816 243.501,291.388      243.93,290.959 244.357,290.531 244.357,290.103 244.357,289.675 244.357,289.245 244.357,288.817    "
                            fill={color1}/>
                    </g>
                    <g>
                        <rect height="7.29" fill={color1} width="7.282" x="237.075" y="281.527"/>
                    </g>
                    <g>
                        <polygon
                            points="244.357,281.527 244.357,281.099 244.357,281.099 244.357,280.67 244.357,280.242      243.93,279.814 243.501,278.956 243.073,278.528 242.216,278.528 241.788,278.101 241.788,278.101 241.359,278.101      240.93,278.101 240.502,278.101 240.074,278.101 239.646,278.101 239.646,278.528 238.789,278.528 238.361,278.956      237.932,279.814 237.503,280.242 237.503,280.67 237.503,281.099 237.075,281.099 237.075,281.527    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="237.075,245.512 237.075,245.941 237.503,245.941 237.503,246.369 237.503,246.798      237.932,247.226 238.361,248.083 238.789,248.511 239.646,248.511 239.646,248.939 240.074,248.939 240.502,248.939      240.93,248.939 241.359,248.939 241.788,248.939 241.788,248.939 242.216,248.511 243.073,248.511 243.501,248.083      243.93,247.226 244.357,246.798 244.357,246.369 244.357,245.941 244.357,245.941 244.357,245.512    "
                            fill={color1}/>
                    </g>
                    <g>
                        <rect height="7.29" fill={color1} width="7.282" x="237.075" y="238.222"/>
                    </g>
                    <g>
                        <polygon
                            points="244.357,238.222 244.357,237.787 244.357,237.358 244.357,236.929 244.357,236.501      243.93,236.073 243.501,235.645 243.073,235.215 242.216,234.788 241.788,234.788 241.788,234.359 241.359,234.359      240.93,234.359 240.502,234.359 240.074,234.359 239.646,234.788 239.646,234.788 238.789,235.215 238.361,235.645      237.932,236.073 237.503,236.501 237.503,236.929 237.503,237.358 237.075,237.787 237.075,238.222    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="237.075,201.771 237.075,202.2 237.503,202.629 237.503,203.057 237.503,203.057      237.932,203.914 238.361,204.342 238.789,204.771 239.646,205.198 239.646,205.198 240.074,205.198 240.502,205.627      240.93,205.627 241.359,205.627 241.788,205.198 241.788,205.198 242.216,205.198 243.073,204.771 243.501,204.342      243.93,203.914 244.357,203.057 244.357,203.057 244.357,202.629 244.357,202.2 244.357,201.771    "
                            fill={color1}/>
                    </g>
                    <g>
                        <rect height="7.29" fill={color1} width="7.282" x="237.075" y="194.481"/>
                    </g>
                    <g>
                        <polygon
                            points="244.357,194.481 244.357,194.053 244.357,193.625 244.357,193.625 244.357,193.197      243.93,192.34 243.501,191.911 243.073,191.483 242.216,191.054 241.788,191.054 241.788,191.054 241.359,191.054      240.93,191.054 240.502,191.054 240.074,191.054 239.646,191.054 239.646,191.054 238.789,191.483 238.361,191.911      237.932,192.34 237.503,193.197 237.503,193.625 237.503,193.625 237.075,194.053 237.075,194.481    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="245.223,157.603 245.223,158.038 244.794,158.466 244.794,158.895 244.794,158.895      244.794,159.751 244.794,160.608 244.794,161.037 245.223,161.894 245.223,161.894 245.65,162.322 245.65,162.75 246.079,162.75      246.507,163.179 246.507,163.179 246.936,163.179 247.364,163.607 248.221,163.607 248.649,163.607 249.506,163.179      249.935,163.179 250.363,162.75 250.792,162.75 250.792,162.322 251.219,162.322 245.223,157.603    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="251.219,152.034 250.363,152.461 249.078,153.746 247.792,155.032 246.936,155.889      245.65,157.603 245.223,157.603 251.219,162.322 251.219,161.894 252.076,161.037 252.94,160.18 254.226,158.895      255.083,158.038 255.511,157.603    "
                            fill={color1}/>
                    </g>
                    <g>
                        <polygon
                            points="255.511,157.603 255.94,157.603 256.368,157.173 256.368,156.746 256.368,156.746      256.796,155.889 256.796,155.032 256.796,154.603 256.796,153.746 256.796,153.318 256.368,153.318 256.368,152.89      256.368,152.461 255.94,152.461 255.511,152.034 255.511,152.034 255.083,151.604 254.226,151.604 253.797,151.177      252.94,151.177 252.512,151.604 252.076,151.604 251.648,151.604 251.219,152.034 251.219,152.034 255.511,157.603    "
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M240.93,335.766c-0.572,0-1.146-0.108-1.691-0.33c-32.181-13.055-52.975-44.145-52.975-79.206     c0-34.852,20.773-66.105,52.922-79.62c1.39-0.584,2.979-0.434,4.234,0.4c1.255,0.834,2.01,2.241,2.01,3.748v150.507     c0,1.497-0.745,2.896-1.986,3.732C242.69,335.506,241.813,335.766,240.93,335.766z M236.43,187.861     c-25.217,13.329-41.167,39.468-41.167,68.369c0,29.065,15.952,55.075,41.167,68.027V187.861z M306.535,334.48     c-0.847,0-1.689-0.239-2.425-0.709c-1.293-0.827-2.075-2.257-2.075-3.791v-147.93c0-1.535,0.782-2.963,2.074-3.79     c1.292-0.829,2.917-0.939,4.311-0.296c30.242,13.951,49.783,44.671,49.783,78.266c0,33.324-19.541,63.876-49.782,77.836     C307.82,334.344,307.177,334.48,306.535,334.48z M311.035,189.46v133.112c23.4-13.618,38.168-38.932,38.168-66.342     C349.203,228.572,334.435,203.096,311.035,189.46z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M64.268,241.858c-28.962,0-52.525-31.064-52.525-69.247s23.563-69.247,52.525-69.247     c28.726,0,52.097,31.064,52.097,69.247S92.994,241.858,64.268,241.858z M64.268,112.364c-24,0-43.525,27.027-43.525,60.247     s19.525,60.247,43.525,60.247c11.183,0,21.83-6.105,29.98-17.191c8.458-11.503,13.116-26.794,13.116-43.056     c0-16.259-4.658-31.548-13.116-43.053C86.097,118.47,75.45,112.364,64.268,112.364z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M64.268,393.223c-11.229,0-20.366-9.136-20.366-20.365V233.503c0-1.588,0.837-3.058,2.202-3.869     c1.365-0.81,3.056-0.842,4.451-0.083c3.973,2.164,8.714,3.307,13.713,3.307c4.832,0,9.235-1.074,13.855-3.381     c1.396-0.695,3.051-0.621,4.377,0.199c1.326,0.82,2.133,2.268,2.133,3.827v139.354C84.633,383.896,75.307,393.223,64.268,393.223     z M52.902,240.218v132.639c0,6.267,5.099,11.365,11.366,11.365c6.054,0,11.365-5.311,11.365-11.365V240.155     c-3.707,1.142-7.467,1.703-11.365,1.703C60.331,241.858,56.493,241.299,52.902,240.218z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M463.905,396.229c-11.467,0-20.795-9.328-20.795-20.793v-103.34c0-2.485,2.015-4.5,4.5-4.5     s4.5,2.015,4.5,4.5v103.34c0,6.503,5.291,11.793,11.795,11.793s11.854-5.222,12.222-11.907V273.38c0-2.485,2.015-4.5,4.5-4.5     s4.5,2.015,4.5,4.5v102.056c0,0.071-0.002,0.143-0.005,0.214C484.573,387.188,475.254,396.229,463.905,396.229z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M494.779,276.596H447.61c-2.471,0-4.479-1.992-4.5-4.462l-1.284-153.079     c-0.013-1.477,0.701-2.866,1.909-3.717c1.207-0.851,2.756-1.054,4.143-0.545c55.813,20.503,54.121,68.678,52.162,124.461     c-0.374,10.649-0.761,21.661-0.761,32.842C499.279,274.581,497.265,276.596,494.779,276.596z M452.073,267.596h38.226     c0.084-9.775,0.42-19.354,0.747-28.658c1.83-52.108,3.29-93.737-40.163-113.216L452.073,267.596z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M273.946,368.782c-20.687,0-37.516-16.638-37.516-37.088V180.758     c0-20.687,16.83-37.516,37.516-37.516c20.451,0,37.089,16.83,37.089,37.516v150.936     C311.035,352.145,294.397,368.782,273.946,368.782z M273.946,152.242c-15.724,0-28.516,12.792-28.516,28.516v150.936     c0,15.487,12.792,28.088,28.516,28.088c15.488,0,28.089-12.601,28.089-28.088V180.758     C302.035,165.035,289.435,152.242,273.946,152.242z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M306.535,260.73h-20.578c-2.485,0-4.5-2.016-4.5-4.5c0-2.485,2.015-4.5,4.5-4.5h20.578     c2.485,0,4.5,2.015,4.5,4.5C311.035,258.715,309.021,260.73,306.535,260.73z M262.373,260.73H240.93c-2.485,0-4.5-2.016-4.5-4.5     c0-2.485,2.015-4.5,4.5-4.5h21.443c2.485,0,4.5,2.015,4.5,4.5C266.873,258.715,264.858,260.73,262.373,260.73z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M239.964,284.19c-3.834,0-6.483-0.293-6.849-0.337c-2.468-0.29-4.234-2.525-3.944-4.994     c0.29-2.468,2.529-4.229,4.993-3.944c7.232,0.835,27.114,0.602,32.29-10.323c1.063-2.247,3.746-3.206,5.993-2.14     c2.246,1.063,3.204,3.747,2.14,5.993C268.079,282.183,249.982,284.19,239.964,284.19z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M305.619,284.124c-9.714,0-28.233-1.832-35.223-15.076c-1.16-2.198-0.318-4.921,1.879-6.08     c2.2-1.16,4.92-0.318,6.08,1.879c5.518,10.454,25.28,10.811,32.433,10.063c2.459-0.257,4.685,1.537,4.942,4.009     s-1.536,4.685-4.009,4.942C311.384,283.896,309.05,284.124,305.619,284.124z"
                            fill={color1}/>
                    </g>
                    <g>
                        <path
                            d="M273.946,271.447c-8.945,0-16.502-7.558-16.502-16.503c0-8.949,7.557-16.509,16.502-16.509     c8.95,0,16.511,7.561,16.511,16.509C290.457,263.89,282.896,271.447,273.946,271.447z M273.946,247.435     c-3.926,0-7.502,3.579-7.502,7.509c0,3.927,3.576,7.503,7.502,7.503c3.931,0,7.511-3.576,7.511-7.503     C281.457,251.014,277.877,247.435,273.946,247.435z"
                            fill={color1}/>
                    </g>
                </g>
            </g>
            <g id="Layer_1"/>
        </svg>
    );
}
