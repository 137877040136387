import CopyModal from './modals/copy-modal/copy-modal';
import DeletionModal from './modals/deletion-modal/deletion-modal';
import UnclaimModal from './modals/unclaim-modal/unclaim-modal';
import EditModal from './modals/edit-modal/edit-modal';
import InviteLinkModal from './modals/invite-link-modal/invite-link-modal';
import InviteModal from './modals/invite-modal/invite-modal';
import SettingsModal from './modals/settings-modal/settings-modal';
import ManageListsModal from './modals/manage-lists-modal/manage-lists-modal';
import CreateHubModal from './modals/create-hub-modal/create-hub-modal';
import ConfirmationModal from './modals/confirmation-modal/confirmation-modal';
import JoinModal from './modals/join-modal/join-modal';
import ContributorModal from './modals/contributor-modal/contributor-modal';
import GiftRectificationModal from './modals/gift-rectification-modal/gift-rectification-modal';

export default {
    COPY_MODAL: CopyModal,
    DELETION_MODAL: DeletionModal,
    UNCLAIM_MODAL: UnclaimModal,
    EDIT_MODAL: EditModal,
    INVITE_LINK_MODAL: InviteLinkModal,
    INVITE_MODAL: InviteModal,
    SETTINGS_MODAL: SettingsModal,
    MANAGE_LISTS: ManageListsModal,
    CREATE_HUB_MODAL: CreateHubModal,
    CONFIRMATION_MODAL: ConfirmationModal,
    JOIN_HUB_MODAL: JoinModal,
    CONTRIBUTOR_MODAL: ContributorModal,
    GIFT_RECTIFICATION_MODAL: GiftRectificationModal
};