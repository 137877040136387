import React from 'react';
import classnames from 'classnames';
import moment from 'moment';

import Tooltip from '../../../../../../components/tooltip/tooltip';

import './alert.css';

export default ({alert, toggleNotificationRead, onClick: customOnClick, children, link, push, closeMenu}) => {
    let createdAt = moment(alert.createdAt);
    let onClick = customOnClick;
    if (!onClick) {
        if (link && push && closeMenu) {
            onClick = () => {
                closeMenu();
                if (alert.unread) toggleNotificationRead();
                if (window.location.pathname === link) document.location.reload();
                else push(link);
            };
        } else if (alert.unread) {
            onClick = () => {
                toggleNotificationRead();
            };
        }
    } else {
        onClick = () => {
            closeMenu();
            if (alert.unread) toggleNotificationRead();
            customOnClick();
        }
    }
    return (
        <div
            className={classnames('alert', {'alert--unread': alert.unread}, {'alert--clickable': onClick})}
            onClick={onClick}
            key={alert.id}
        >
            {children}
            <div className="alert-subRow">
                <Tooltip tooltipContent={createdAt.format('M/D/YYYY h:mm a')} className="alert-createdAt">
                    {createdAt.from(moment())}
                </Tooltip>
                <button className="alert-markReadButton" onClick={toggleNotificationRead}>
                    {alert.unread ? 'Mark Read' : 'Mark Unread'}
                </button>
            </div>
        </div>
    );
};