import React from 'react';

export default function Cake({className}) {
    let color1 = '#FBAE3A';
    let color2 = '#F8E6C4';
    let color3 = '#CB9963';
    let color4 = '#EE3634';
    let color5 = '#000000';
    return (
        <svg height="512px" version="1.1" viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g fill="none" fillRule="evenodd" id="SVG" stroke="none" strokeWidth="1">
                <g id="Cake">
                    <g id="4" transform="translate(0.000000, 39.000000)">
                        <polygon fill={color1} id="Fill-1" points="11.7908406 272.958607 499.571014 272.958607 499.571014 185.601162 11.7908406 185.601162"/>
                        <polygon fill={color2} id="Fill-2" points="11.7908406 326.770675 499.571014 326.770675 499.571014 272.966026 11.7908406 272.966026"/>
                        <polygon fill={color3} id="Fill-3" points="11.1304348 418.519731 499.571014 418.519731 499.571014 326.770675 11.1304348 326.770675"/>
                        <polygon fill={color2} id="Fill-4" points="11.1319188 185.151615 352.984672 51.9344779 498.912093 185.151615"/>
                        <path
                            d="M386.647559,101.716204 C386.647559,131.493105 362.501936,140.795605 332.716893,140.795605 C302.924429,140.795605 278.778806,131.493105 278.778806,101.716204 C278.778806,71.9393032 302.924429,14.4181048 332.716893,14.4181048 C362.501936,14.4181048 386.647559,71.9393032 386.647559,101.716204 L386.647559,101.716204 Z"
                            fill={color4} id="Fill-5"/>
                        <path
                            d="M332.713925,14.4151375 C331.06662,14.4151375 329.448997,14.6525218 327.846214,14.9937618 C340.994968,34.385097 349.543142,61.7807361 349.543142,79.4584529 C349.543142,109.242772 325.397519,118.545272 295.612475,118.545272 C290.841229,118.545272 286.233229,118.270796 281.825577,117.751518 C289.164243,134.924793 309.147084,140.800056 332.713925,140.800056 C362.498968,140.800056 386.644591,131.497556 386.644591,101.713237 C386.644591,71.9363359 362.498968,14.4151375 332.713925,14.4151375"
                            fill={color5} id="Fill-7" opacity="0.2"/>
                        <path
                            d="M499.56953,185.607096 L498.723617,185.607096 C494.019154,196.467431 394.283038,415.847674 11.7893565,418.51083 L11.7893565,418.518248 L499.56953,418.518248 L499.56953,185.607096 Z"
                            fill={color5} id="Fill-9" opacity="0.3"/>
                        <path
                            d="M22.2682899,407.392339 L488.44058,407.392339 L488.44058,337.898067 L22.2682899,337.898067 L22.2682899,407.392339 Z M267.857623,97.0493761 C267.761159,98.6294657 267.649855,100.23181 267.649855,101.715462 C267.649855,120.468827 276.101565,151.922255 332.710957,151.922255 C383.74771,151.922255 395.612754,126.381181 397.460406,107.605562 L470.223768,174.028674 L70.3369275,174.028674 L267.857623,97.0493761 Z M332.710957,25.5447549 C347.685101,25.5447549 375.518609,64.6983383 375.518609,101.715462 C375.518609,109.36369 375.518609,129.667471 332.710957,129.667471 C289.910725,129.667471 289.910725,109.36369 289.910725,101.715462 C289.910725,64.6983383 317.744232,25.5447549 332.710957,25.5447549 L332.710957,25.5447549 Z M22.9138551,315.635865 L488.433159,315.635865 L488.433159,284.085999 L22.9138551,284.085999 L22.9138551,315.635865 Z M22.9212754,261.831215 L488.44058,261.831215 L488.44058,196.735972 L22.9212754,196.735972 L22.9212754,261.831215 Z M506.078609,176.617647 L393.535072,73.8821459 C393.535072,73.8895641 393.535072,73.8969824 393.542493,73.9044007 C384.586203,40.8041185 362.028522,3.28997091 332.710957,3.28997091 C304.157681,3.28997091 281.978435,38.8976254 272.569507,71.330264 L272.569507,71.330264 L7.08637681,174.785337 C2.10736232,176.728921 -0.793971014,181.943959 0.192927536,187.210924 C0.274550725,187.65602 0.51942029,188.012097 0.652985507,188.434938 L0.652985507,272.958607 L0.652985507,323.157982 C0.259710145,324.292976 0,325.494734 0,326.770675 L0,418.519731 C0,424.66947 4.98643478,429.647123 11.1304348,429.647123 L499.571014,429.647123 C505.715014,429.647123 510.701449,424.66947 510.701449,418.519731 L510.701449,326.770675 L510.701449,272.958607 L510.701449,185.60858 C510.701449,181.899449 508.868638,178.642833 506.078609,176.617647 L506.078609,176.617647 Z"
                            fill={color5} id="Fill-11"/>
                    </g>
                </g>
            </g>
        </svg>
    );
}
