import React from 'react';
import {reduxForm, Field} from 'redux-form'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';

import {createUrl} from '../../../../../util/formatters';
import Input from '../../../../../components/input/input';
import Message from '../../../../../components/message/message';
import Interstitial from '../../../../../components/interstitial/interstitial';
import {INTERSTITIAL_KEYS} from '../../../../../util/constants';

import './messages-view.css';

const MESSAGE_LOAD_LIMIT = 25;

export class MessagesView extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string.isRequired,
        hub: PropTypes.shape({
            directory: PropTypes.array.isRequired
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                hubId: PropTypes.string.isRequired
            }).isRequired,
            url: PropTypes.string.isRequired
        }).isRequired,
        reloadHub: PropTypes.func.isRequired,
        unreadMessagesCount: PropTypes.number.isRequired
    };

    static groupMessages = (messages, previousGroupedMessages = [], prepend = false) => {
        let modifiedMessages = [...messages];
        modifiedMessages = prepend ? modifiedMessages : modifiedMessages.reverse();
        return modifiedMessages
            .reduce((aggregate, message) => {
                let date = moment(message.createdAt).format('YYYY-MM-DD');
                let dateKey = `${date}|${message.id}`;
                let previousIndex = prepend ? 0 : aggregate.length - 1;
                let previousDateDetails = (aggregate.length && aggregate[previousIndex]) || {};
                if (previousDateDetails.date === date) {
                    aggregate[previousIndex].dateKey = dateKey;
                    if (prepend) aggregate[previousIndex].messages.unshift(message);
                    else aggregate[previousIndex].messages.push(message);
                } else {
                    let group = {date, dateKey, messages: [message]};
                    if (prepend) aggregate.unshift(group);
                    else aggregate.push(group);
                }
                return aggregate;
            }, previousGroupedMessages);
    };

    Date = ({date}) => {
        let dateMoment = moment(date);
        let daysBeforeToday = moment().diff(dateMoment, 'days');
        let label = dateMoment.format('MMMM D, YYYY');
        if (daysBeforeToday === 0) {
            label = 'Today';
        } else if (daysBeforeToday === 1) {
            label = 'Yesterday';
        }
        return (
            <div className="messagesView-date">
                {label}
            </div>
        );
    };

    deleteMessage = messageId => async () => {
        let {generalThread} = this.state.data;
        await axios.delete(createUrl(`/threads/${generalThread.id}/messages/${messageId}`));
        this.setState(({data}) => {
            let messageIndex = data.generalMessages.findIndex(message => message.id === messageId);
            if (messageIndex < 0) return {};
            data.generalMessages.splice(messageIndex, 1);
            data.groupedGeneralMessages = MessagesView.groupMessages(data.generalMessages);
            return {data};
        });
    };

    setDisplayMessageOptions = messageId => display => {
        this.setState({displayMessageOptions: display ? messageId : null});
    };

    scrollToBottom = () => {
        if (this.scrollWindow) this.scrollWindow.scrollTop = this.scrollWindow.scrollHeight;
    };

    onSubmit = async formValues => {
        let {generalThread} = this.state.data;
        let message = (await axios.post(createUrl(`/threads/${generalThread.id}/messages`), {body: formValues.newMessage})).data;
        this.setState(({data}) => {
            data.generalMessages.unshift(message);
            data.groupedGeneralMessages = MessagesView.groupMessages([message], data.groupedGeneralMessages);
            return {data};
        });
        this.scrollToBottom();
        await this.recordLastReadMessage(message);
    };

    loadOlderMessages = async () => {
        let {generalMessages, generalThread} = this.state.data;
        let oldestMessage = generalMessages[generalMessages.length - 1] || {};
        let olderMessages = (await axios.get(createUrl(`/threads/${generalThread.id}/messages`), {
            params: {createdBefore: oldestMessage.createdAt, limit: MESSAGE_LOAD_LIMIT}
        })).data;
        let previousScrollHeight = this.scrollWindow.scrollHeight;
        this.setState(({data}) => {
            data.generalMessages = [...data.generalMessages, ...olderMessages];
            data.groupedGeneralMessages = MessagesView.groupMessages(olderMessages, data.groupedGeneralMessages, true);
            data.unreadMessage = this.findOldestUnreadMessage(data);
            return {data, showLoadMore: olderMessages.length === MESSAGE_LOAD_LIMIT};
        });
        let scrollHeightChange = this.scrollWindow.scrollHeight - previousScrollHeight;
        this.scrollWindow.scrollTop = scrollHeightChange + this.scrollWindow.scrollTop;
        document.activeElement.blur();
    };

    checkForNewMessages = async () => {
        let {unreadMessagesCount, reloadHub} = this.props;
        let {generalMessages, generalThread} = this.state.data;
        let newestMessage = generalMessages[0] || {};
        let incomingMessages = (await axios.get(createUrl(`/threads/${generalThread.id}/messages`), {
            params: {createdAfter: newestMessage.createdAt}
        })).data;
        let distanceFromBottom;
        if (this.scrollWindow) distanceFromBottom = Math.abs(this.scrollWindow.scrollHeight - this.scrollWindow.scrollTop - this.scrollWindow.offsetHeight);
        this.setState(({data}) => {
            data.generalMessages = [...incomingMessages, ...data.generalMessages];
            data.groupedGeneralMessages = MessagesView.groupMessages(incomingMessages, data.groupedGeneralMessages);
            return {data};
        });
        if (this.scrollWindow && distanceFromBottom < 20) this.scrollToBottom();
        this.refreshTimeout = setTimeout(this.checkForNewMessages, 5000);
        await this.recordLastReadMessage(incomingMessages[0]);
        if (unreadMessagesCount && !incomingMessages[0]) await reloadHub();
    };

    recordLastReadMessage = async message => {
        let {reloadHub} = this.props;
        if (!message) return;
        await axios.put(createUrl(`/threads/${message.thread}/messages/${message.id}/read`));
        await reloadHub();
    };

    findOldestUnreadMessage = data => {
        let {lastReadMessage} = data.generalThread;
        if (lastReadMessage) {
            let generalMessageLength = data.generalMessages.length;
            let previousMessageId = null;
            for (let i = 0; i < generalMessageLength; i++) {
                let currentMessageId = data.generalMessages[i].id;
                if (currentMessageId === lastReadMessage) return previousMessageId;
                previousMessageId = currentMessageId;
            }
        }
        return null;
    };

    loadData = async () => {
        let {hub, reloadHub} = this.props;
        let {data = {}} = this.state;
        let generalThreadId = hub.threads.general;
        if (!generalThreadId) {
            try {
                let generalThread = (await axios.post(createUrl(`/threads`), {
                    topicType: 'Hub',
                    topic: hub.id,
                    topicScope: 'general'
                })).data;
                generalThreadId = generalThread.id;
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    generalThreadId = error.response.data.id;
                } else {
                    throw error;
                }
            }
            await reloadHub();
        }
        data.generalThread = (await axios.get(createUrl('/threads/' + generalThreadId))).data;
        data.generalMessages = (await axios.get(createUrl(`/threads/${generalThreadId}/messages`), {
            params: {limit: MESSAGE_LOAD_LIMIT}
        })).data;
        data.unreadMessage = this.findOldestUnreadMessage(data);
        data.groupedGeneralMessages = MessagesView.groupMessages(data.generalMessages);
        this.setState({data, showLoadMore: data.generalMessages.length === MESSAGE_LOAD_LIMIT});
        this.scrollToBottom();
        if (data.generalMessages.length) {
            let lastMessage = data.generalMessages[0];
            let {lastReadMessage} = data.generalThread;
            if (lastReadMessage !== lastMessage.id) await this.recordLastReadMessage(lastMessage);
        }
        return data;
    };

    setScrollWindow = element => {this.scrollWindow = element};

    state = {
        offset: 0,
        showLoadMore: true
    };

    componentDidMount = async () => {
        let {match, hub, history} = this.props;
        let {hubId} = match.params;
        if (!hub.hasJoined) {
            history.replace(`/gift-tags/hubs/${hubId}/join`);
            return;
        }
        await Interstitial.interstitialController(INTERSTITIAL_KEYS.HUB_MESSAGES_VIEW, this.loadData());
        this.refreshTimeout = setTimeout(this.checkForNewMessages, 5000);
        let newMessageElement = document.getElementById('messagesView-newMessage');
        if (newMessageElement) {
            newMessageElement.scrollIntoView();
            newMessageElement.focus();
        }
    };

    componentWillUnmount = () => {
        clearTimeout(this.refreshTimeout);
    };

    render() {
        let {Date} = this;
        let {hub, handleSubmit, submitting, valid, activeUserId} = this.props;
        let {data, showLoadMore, displayMessageOptions} = this.state;
        let content;
        if (hub.hasJoined && data) {
            let {groupedGeneralMessages, unreadMessage} = data;
            let previousAuthorId, previousCreatedAt;
            let elements = groupedGeneralMessages.reduce((aggregate, {date, dateKey, messages}) => {
                let messagesElements = messages.map(message => {
                    let authorId = message.author.id;
                    let elapsedMinutes = Math.abs(moment(message.createdAt).diff(previousCreatedAt, 'minutes'));
                    let hideHeader = previousAuthorId === authorId && previousCreatedAt && elapsedMinutes < 5;
                    let unread = message.id === unreadMessage && activeUserId !== message.author.id;
                    previousAuthorId = authorId;
                    previousCreatedAt = message.createdAt;
                    return (
                        <Message
                            key={message.id}
                            isAuthor={activeUserId === authorId}
                            hideHeader={hideHeader}
                            message={message}
                            unread={unread}
                            deleteMessage={this.deleteMessage(message.id)}
                            displayMessageOptions={displayMessageOptions === message.id}
                            setDisplayMessageOptions={this.setDisplayMessageOptions(message.id)}
                        />
                    );
                });
                return [
                    ...aggregate,
                    <Date date={date} key={dateKey}/>,
                    ...messagesElements
                ]
            }, []);
            if (!elements.length) {
                elements = (
                    <div>
                        <div className="messagesView-noMessagesHeader">No Messages Yet</div>
                        <div className="messagesView-noMessagesSubtext">You can send messages to other members of the hub using the textbox below. (eg. Reminders about updating their lists, questions, general discussion, or anything else!)</div>
                    </div>
                );
            }
            content = (
                <>
                    <div className="messagesView-scroll" ref={this.setScrollWindow}>
                        {showLoadMore && <button type="button" className="messagesView-showMoreButton" onClick={this.loadOlderMessages}>Load More</button>}
                        {elements}
                    </div>
                    <form className="messagesView-form" onSubmit={handleSubmit(this.onSubmit)}>
                        <Field
                            name="newMessage"
                            component={Input}
                            type="text"
                            className="messagesView-newMessage"
                            id="messagesView-newMessage"
                            fieldClassName="messagesView-newMessageField"
                            autoComplete="off"
                            placeholder="Type something..."
                        />
                        <button
                            type="submit"
                            disabled={submitting || !valid}
                            className={classnames('messagesView-submitButton', {'messagesView-submitButton--active': valid})}
                        >
                            Send
                        </button>
                    </form>
                </>
            )
        }
        return (
            <Interstitial className="messagesView" interstitialKey={INTERSTITIAL_KEYS.HUB_MESSAGES_VIEW} interstitialSize={Interstitial.INTERSTITIAL_SIZES.LARGE}>
                {content}
            </Interstitial>
        );
    }
}

let reduxConfig = {
    form: 'hubMessages',
    onSubmitSuccess: (result, dispatch, props) => props.reset(),
    validate: values => {
        let errors = {};
        if (!values.newMessage) errors._error = 'Required';
        return errors;
    }
};

let mapStateToProps = state => ({
    activeUserId: state.users.getIn(['user', 'id'])
});

let ReduxForm = reduxForm(reduxConfig)(MessagesView);
export default connect(mapStateToProps)(ReduxForm);
