import React from 'react';

export default function ForkAndKnife({className}) {
    return (
        <svg height="512px" viewBox="0 0 262.011 512" width="262.011px" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="meanicons_x5F_41">
                <g>
                    <path
                        d="M261.798,475.664h0.024c-0.024-0.045-0.047-0.097-0.047-0.141c-0.438-1.758-1.085-3.479-1.979-5.156    c-14.269-37.994-25.787-92.076-32.828-155.521c-4.53-44.008-5.301-94.53-5.979-148.26c-0.771-59.217,1.209-114.508,5.33-161.606    c-3.258-1.834-6.535-2.748-9.908-2.748c-34.423,0.213-60.98,100.215-59.391,223.412c0.19,15.44,0.848,30.533,1.856,45.1    c17.11,4.871,28.799,14.643,28.972,25.977l0.117,11.812c0.074,0.051,0.146,0.051,0.17,0.051    c-5.207,66.325-15.543,122.972-29.283,162.451c-0.869,1.642-1.47,3.399-1.857,5.164c0,0.037-0.021,0.097-0.021,0.097h0.021    c-0.268,1.348-0.479,2.693-0.457,4.051c0.189,17.107,24.105,30.851,53.314,30.653c29.213-0.19,52.713-14.2,52.5-31.28    C262.354,478.323,262.108,476.959,261.798,475.664z"/>
                    <path
                        d="M115.999,118.697l-6.365-85.953V7.579c0-4.724-3.95-8.58-8.868-8.58c-4.872,0-8.847,3.856-8.847,8.58v108.924    c-1.471,2.392-3.928,4.074-6.941,4.121c-4.752,0.146-8.652-3.612-8.748-8.36l-1.373-58.066V7.58c0-4.723-3.979-8.58-8.85-8.58    c-4.865,0-8.822,3.857-8.822,8.58v109.861c-1.516,2.289-3.953,3.959-6.893,4.002c-4.723,0.096-8.678-3.613-8.773-8.39    l-2.674-62.765V7.578c0-4.723-3.979-8.58-8.846-8.58c-4.891,0-8.87,3.857-8.87,8.58v13.347L9.174,118.718    c-0.438,18.304,14.391,33.724,37.501,38.205C41.812,282.987,25.203,393.061,3.051,460.622c-0.94,2.022-1.592,4.052-2.072,6.167    c0,0.032-0.049,0.083-0.049,0.146c-0.34,1.547-0.553,3.187-0.578,4.82c-0.482,20.293,22.803,36.777,52.012,36.757    c29.213,0,53.319-16.506,53.8-36.853c0-1.61-0.146-3.229-0.41-4.789c0-0.051-0.022-0.084-0.022-0.178    c-0.361-2.072-0.963-4.146-1.787-6.123C85.025,393.03,73.67,282.985,74.783,156.877    C98.116,152.396,115.587,137.021,115.999,118.697z"/>
                </g>
            </g>
        </svg>
    );
}
