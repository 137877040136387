import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FieldArray, reduxForm, formValueSelector} from 'redux-form';
import axios from 'axios';
import classnames from 'classnames';

import {createUrl} from '../../../../util/formatters';
import {INTERSTITIAL_KEYS} from '../../../../util/constants';
import Interstitial from '../../../../components/interstitial/interstitial';

import './gift-rectification-modal.css';

export class GiftRectificationModal extends React.Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        lists: PropTypes.array,
        submitting: PropTypes.bool.isRequired
    };

    responseButtonOnClick = ({fieldName, type, value}) => async () => {
        let {change, lists} = this.props;
        let {activeGift: {listIndex, giftIndex}} = this.state;
        let list = lists[listIndex];
        let gift = list.gifts[giftIndex];
        if (gift[type] === value) return;
        change(`${fieldName}.${type}`, value);
        change(`${fieldName}.confirmed`, false);
        await axios.put(createUrl(`/lists/${list.listId}/gifts/${gift.giftId}/claimRectification`), {[type]: value, confirmed: false});
    };

    ResponseField = ({type, fieldName, value, received}) => {
        let question;
        if (type === 'received') {
            question = 'Have you received this gift?';
        } else if (type === 'renew') {
            if (!received) return null;
            question = 'Would you like to keep this idea on your list?';
        } else return null;

        return (
            <div className="giftRectificationModal-responseField">
                <div className="giftRectificationModal-responseQuestion">{question}</div>
                <div className="giftRectificationModal-responseRadio">
                    <button
                        className={classnames({'giftRectificationModal-responseRadioButton--active': value === 'yes'})}
                        type="button"
                        onClick={this.responseButtonOnClick({fieldName, type, value: 'yes'})}
                    >Yes</button>
                    <button
                        className={classnames({'giftRectificationModal-responseRadioButton--active': value === 'maybe'})}
                        type="button"
                        onClick={this.responseButtonOnClick({fieldName, type, value: 'maybe'})}
                    >Maybe</button>
                    <button
                        className={classnames({'giftRectificationModal-responseRadioButton--active': value === 'no'})}
                        type="button"
                        onClick={this.responseButtonOnClick({fieldName, type, value: 'no'})}
                    >No</button>
                </div>
            </div>
        );
    };

    ResponseConfirmation = ({received, renew}) => {
        if (!received || !renew) return null;
        let resolution;
        if (['yes', 'maybe'].includes(received)) {
            resolution = ['yes', 'maybe'].includes(renew)
                ? 'We will check off and renew this idea for someone to claim.'
                : 'We will check this idea off your list.';
        } else {
            resolution = ['yes', 'maybe'].includes(renew)
                ? 'We will follow up with anyone who claimed this idea and have them either clear or re-confirm their claim.'
                : 'We will delete this idea so that anyone who claimed it is notified that you no longer want it.';
        }
        return (
            <div className="giftRectificationModal-responseConfirmation">
                <div>{resolution}</div>
                <button className="giftRectificationModal-resolutionButton" onClick={this.confirmResponses} type="button">OK</button>
            </div>
        );
    };

    GiftsFieldArray = ({fields, listIndex, gifts, giftsById}) => {
        let {ResponseField, ResponseConfirmation} = this;
        let {activeGift} = this.state;
        let giftFields = fields.map((fieldName, giftIndex) => {
            let active = activeGift.listIndex === listIndex && activeGift.giftIndex === giftIndex;
            let {giftId, received, renew, confirmed} = gifts[giftIndex];
            let gift = giftsById[giftId];
            let responseFields;
            if (active) {
                responseFields = (
                    <>
                        <ResponseField type="received" fieldName={fieldName} value={received} />
                        <ResponseField type="renew" fieldName={fieldName} value={renew} received={received} />
                        <ResponseConfirmation received={received} renew={renew} />
                    </>
                );
            }
            let status;
            let statusClassName = 'giftRectificationModal-status--yellow';
            if (confirmed && received && renew) {
                if (['yes', 'maybe'].includes(received)) {
                    status = ['yes', 'maybe'].includes(renew) ? 'Renew' : 'Check Off';
                    statusClassName = 'giftRectificationModal-status--green';
                } else {
                    status = ['yes', 'maybe'].includes(renew) ? 'Follow Up' : 'Delete';
                    statusClassName = ['yes', 'maybe'].includes(renew)
                        ? 'giftRectificationModal-status--yellow'
                        : 'giftRectificationModal-status--red';
                }
            }
            return (
                <div className={classnames('giftRectificationModal-giftField', {'giftRectificationModal-giftField--active': active})} key={giftId}>
                    <button
                        onClick={() => this.setActiveGift(listIndex, giftIndex)}
                        className={classnames('giftRectificationModal-giftHeaderButton', {'giftRectificationModal-giftHeaderButton--clickable': !active})}
                        type="button"
                    >
                        <div>{gift.label}</div>
                        <div className={classnames(statusClassName)}>{status}</div>
                    </button>
                    {responseFields}
                </div>
            );
        });
        return <div className="giftRectificationModal-giftsFieldArray">{giftFields}</div>
    };

    ListsFieldArray = ({fields, lists, giftsById, listNamesById}) => {
        let listFields = fields.map((fieldName, listIndex) => {
            let {listId, gifts} = lists[listIndex];
            return (
                <div className="giftRectificationModal-listField" key={listId}>
                    <div className="giftRectificationModal-listName">{listNamesById[listId]}:</div>
                    <FieldArray name={`${fieldName}.gifts`} component={this.GiftsFieldArray} props={{listIndex, gifts, giftsById}}/>
                </div>
            );
        });
        return <div className="giftRectificationModal-listsFieldArray">{listFields}</div>
    };

    onSubmit = async ({lists}) => {
        let gifts = lists.reduce((aggregate, list) => {
            let {listId} = list;
            list.gifts.forEach(gift => aggregate.push({listId, ...gift}));
            return aggregate;
        }, []);
        await Interstitial.interstitialController(INTERSTITIAL_KEYS.GIFT_RECTIFICATION_MODAL_SUBMIT,
            Promise.all(
                gifts.map(async gift => {
                    try {
                        let {listId, giftId, received, renew, confirmed} = gift;
                        await axios.put(
                            createUrl(`/lists/${listId}/gifts/${giftId}/claimRectification`),
                            {received, renew, confirmed, processRectification: true}
                        );
                    } catch (error) {
                        console.error(error);
                    }
                })
            )
        );
        this.props.closeModal();
        window.location.reload();
    };

    setActiveGift = (listIndex, giftIndex) => this.setState({activeGift: {listIndex, giftIndex}});

    proceedToNextUnconfirmedGift = () => {
        let {activeGift} = this.state;
        let {lists = []} = this.props;
        let listIndex = 0;
        let giftIndex = 0;
        while(true) {
            let newActiveGift = activeGift.listIndex !== listIndex || activeGift.giftIndex !== giftIndex;
            if (!lists[listIndex] || (!lists[listIndex].gifts[giftIndex].confirmed && newActiveGift)) break;
            if (giftIndex + 1 < lists[listIndex].gifts.length) {
                giftIndex++;
            } else {
                listIndex++;
                giftIndex = 0;
            }
        }
        this.setActiveGift(listIndex, giftIndex);
    };

    confirmResponses = async () => {
        let {activeGift: {listIndex, giftIndex}} = this.state;
        let {change, lists} = this.props;
        let list = lists[listIndex];
        let gift = list.gifts[giftIndex];
        this.proceedToNextUnconfirmedGift();
        if (!gift.confirmed) {
            change(`lists[${listIndex}].gifts[${giftIndex}].confirmed`, true);
            await axios.put(createUrl(`/lists/${list.listId}/gifts/${gift.giftId}/claimRectification`), {confirmed: true});
        }
    };

    state = {activeGift: {}};

    loadData = async () => {
        let {change} = this.props;
        let giftCollection = (await axios.get(createUrl('/lists/gift-rectifications'))).data;
        let gifts = await Promise.all(giftCollection.map(async gift => {
            let giftInstance = (await axios.get(createUrl(gift.links.self))).data;
            giftInstance.originList = gift.originList;
            return giftInstance;
        }));
        let giftsById = gifts.reduce((aggregate, gift) => ({...aggregate, [gift.id]: gift}), {});
        let listNamesById = gifts.reduce((aggregate, gift) => ({...aggregate, [gift.originList.id]: gift.originList.name}), {});
        let giftsByList = gifts.reduce(
            (aggregate, gift) => {
                if (aggregate[gift.originList.id]) {
                    aggregate[gift.originList.id].push(gift);
                } else {
                    aggregate[gift.originList.id] = [gift];
                }
                return aggregate;
            },
            {}
        );
        let lists = Object.keys(giftsByList).map(listId => {
            return {
                listId,
                gifts: giftsByList[listId].map(gift => ({
                    giftId: gift.id,
                    received: gift.claimRectification.received,
                    renew: gift.claimRectification.renew,
                    confirmed: gift.claimRectification.confirmed
                }))
            }
        });
        change('lists', lists);
        this.setState({giftsById, listNamesById});
        this.proceedToNextUnconfirmedGift();
    };

    componentDidMount = async () => {
        try {
            await Interstitial.interstitialController(INTERSTITIAL_KEYS.GIFT_RECTIFICATION_MODAL_LOAD, this.loadData());
        } catch (error) {
            console.error(error);
            this.setState({error});
        }
    };

    render() {
        let {closeModal, submitting, handleSubmit, lists} = this.props;
        let {giftsById, listNamesById, error} = this.state;

        if (error) return <div className="giftRectificationModal-mainBody">Sorry, we're experiencing technical difficulties. Please check back later.</div>;
        else if (!giftsById) return null;
        else if (!Object.keys(giftsById).length) {
            return (
                <div className="giftRectificationModal-mainBody">
                    Hey there! It looks like all of your gift ideas have been updated already,
                    but we'll be sure to let you know once you have more gifts to review. Thanks for stopping by!
                </div>
            );
        }

        let allGiftsConfirmed = lists.every(list => list.gifts.every(gift => gift.confirmed));
        return (
            <form onSubmit={handleSubmit(this.onSubmit)} className="giftRectificationModal">
                <Interstitial interstitialKey={INTERSTITIAL_KEYS.GIFT_RECTIFICATION_MODAL_LOAD}>
                    <div className="giftRectificationModal-mainBody">
                        <Interstitial interstitialKey={INTERSTITIAL_KEYS.GIFT_RECTIFICATION_MODAL_SUBMIT} interstitialText="Submitting...">
                            <div className="giftRectificationModal-instructions">
                                For each gift below, we have two questions for you to answer which will determine how we update the idea.
                                We will wait to make any changes until you've clicked "Submit" at the bottom of the page.
                            </div>
                            <FieldArray name="lists" component={this.ListsFieldArray} props={{lists, giftsById, listNamesById}} />
                        </Interstitial>
                    </div>
                    <div className="giftRectificationModal-buttonContainer">
                        <button
                            type="button"
                            disabled={submitting}
                            className="giftRectificationModal-cancelButton"
                            onClick={closeModal}
                        >
                            Save for Later
                        </button>
                        <button
                            type="submit"
                            disabled={!allGiftsConfirmed || submitting}
                            className="giftRectificationModal-submitButton"
                        >
                            Submit
                        </button>
                    </div>
                </Interstitial>
            </form>
        );
    }
}

let mapStateToProps = state => ({
    lists: formValueSelector('giftRectification')(state, 'lists')
});

let reduxFormConfig = {
    form: 'giftRectification'
};

let ReduxForm = reduxForm(reduxFormConfig)(GiftRectificationModal);
export default connect(mapStateToProps)(ReduxForm);
