import React, {useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';

import TitleBar from '../components/title-bar/title-bar';
import {createUrl} from '../../../util/formatters';
import RecipePreview from '../recipes/recipe-preview/recipe-preview';
import CookbookPreview from '../cookbooks/cookbook-preview/cookbook-preview';

import './recently-deleted.css'

export default function RecentlyDeleted ({history, toggleSidebar}) {
    let [data, setData] = useState();

    let loadData = async () => {
        try {
            let [deletedRecipes, deletedCookbooks] = (await Promise.all([
                axios.get(createUrl('/recipes'), {params: {pendingDeletion: 'true'}}),
                axios.get(createUrl('/cookbooks'), {params: {pendingDeletion: 'true'}})
            ])).map(response => response.data);
            setData({deletedRecipes, deletedCookbooks});
        } catch (error) {
            console.error(error.response);
        }
    };
    useEffect(() => {loadData();}, []);

    if (!data) return null;
    let {deletedRecipes, deletedCookbooks} = data;
    let recipePreviews = deletedRecipes.map(recipe => [
        recipe.pendingDeletionAsOf,
        <RecipePreview recipe={recipe} history={history} key={recipe.id} />
    ]);
    let cookbookPreviews = deletedCookbooks.map(cookbook => [
        cookbook.pendingDeletionAsOf,
        <CookbookPreview cookbook={cookbook} history={history} displayRecipes={false} key={cookbook.id} />
    ]);
    let deletedItemPreviews = [...recipePreviews, ...cookbookPreviews]
        .sort(([pendingDeletionAsOfA], [pendingDeletionAsOfB]) => moment(pendingDeletionAsOfB).diff(pendingDeletionAsOfA))
        .map(([, deletedItem]) => deletedItem);
    return (
        <div className="recentlyDeleted">
            <TitleBar title="Recently Deleted" enableSearch={true} toggleSidebar={toggleSidebar}/>
            {!!deletedItemPreviews.length && <div className="recentlyDeleted-deletedItemPreviews">{deletedItemPreviews}</div>}
            {!deletedItemPreviews.length && <span className="recentlyDeleted-nothingRecentlyDeletedMessage">Nothing was deleted recently.</span>}
        </div>
    );
}
