import BookIcon1 from './book_icon_1';
import BookIcon2 from './book_icon_2';
import BookIcon3 from './book_icon_3';
import BookIcon4 from './book_icon_4';
import BookIcon5 from './book_icon_5';
import BookIcon6 from './book_icon_6';
import BookIcon7 from './book_icon_7';
import BookIcon8 from './book_icon_8';
import BookIcon9 from './book_icon_9';
import BookIcon10 from './book_icon_10';
import BookIcon11 from './book_icon_11';
import BookIcon12 from './book_icon_12';
import BookIcon13 from './book_icon_13';
import BookIcon14 from './book_icon_14';

export default [
    BookIcon1,
    BookIcon2,
    BookIcon3,
    BookIcon4,
    BookIcon5,
    BookIcon6,
    BookIcon7,
    BookIcon8,
    BookIcon9,
    BookIcon10,
    BookIcon11,
    BookIcon12,
    BookIcon13,
    BookIcon14
];
