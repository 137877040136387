import React, {useState, useEffect} from 'react';
import moment from 'moment';

import Alert from '../../alert/alert';
import {loadUser, loadGift} from '../alert-template-services';

import '../alert-template-styles.css';

export default ({alert, toggleNotificationRead, closeMenu, push, activeUserId}) => {
    let {giftId, originList, ownedBy, label, referenceList, confirmedContributors} = alert.event.data;
    let claimedIdeasLink = '/gift-tags/lists/';
    let contributorDetails = confirmedContributors.find(
        contributorDetails => contributorDetails.contributor.toString() === activeUserId
    );
    claimedIdeasLink += contributorDetails ?  contributorDetails.referenceList : referenceList;

    let [ownedByUser, setOwnedByUser] = useState();
    let [gift, setGift] = useState();
    useEffect(() => {
        loadUser(ownedBy, setOwnedByUser);
        loadGift(originList, giftId, setGift);
    }, []);
    if (gift === null) setGift({}); // if loadGift failed then set a default value

    let message;
    if (ownedByUser && gift) {
        let ownerFullName = ownedByUser.name.fullName;
        let ownerFirstName = ownedByUser.name.first;
        let {expiresAt: claimExpiresAt} = gift.claimDetails || {};
        let currentStatusMessage;
        let expiringSoon = true;
        if (gift.holdsClaim) {
            if (claimExpiresAt) {
                let formattedExpirationDate = moment(claimExpiresAt).format('M/D/YYYY');
                currentStatusMessage = <b>If you do not respond in time, your claim on this idea will expire and be automatically removed after {formattedExpirationDate}.</b>;
            } else {
                expiringSoon = false;
                currentStatusMessage = (
                    <span>
                        <b>[Update]</b>: Your claim was renewed. No further action is required.
                    </span>
                );
            }
        } else {
            expiringSoon = false;
            currentStatusMessage = (
                <span>
                    <b>[Update]</b>: Your claim was removed.
                </span>
            );
        }
        message = (
            <span>
                {expiringSoon && <span><b>Your Claim Will Expire Soon:</b><br/></span>}
                Have you already gotten <b>"{label}"</b> for <b>{ownerFullName}</b>?
                If you have not given this gift to {ownerFirstName} and are still planning to give it to them, you will need to renew your claim on it.
                The reason your claim is expiring is because {ownerFirstName} let us know that they have either not gotten the gift or that they would like to get another one,
                because of this we are giving you the option to either renew your claim or remove it for other people to claim.
                You can click this message to view the gift on your Claimed Tags list.<br/><br/>
                {currentStatusMessage}
            </span>
        );
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={claimedIdeasLink}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}