import React from 'react';

export default function BookIcon1({className, colors = {}}) {
    let {
        primaryColor = '#323232',
        primaryShade = '#161616',
        grey = '#959b9f',
        white = '#ecf0f1'
    } = colors;
    return (
        <svg version="1.1" height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g transform="translate(0 -1028.4)">
                <path d="m3 8v2 1 3 1 5 1c0 1.105 0.8954 2 2 2h14c1.105 0 2-0.895 2-2v-1-5-4-3h-18z" fill={primaryShade} transform="translate(0 1028.4)"/>
                <path d="m3 1035.4v2 1 3 1 5 1c0 1.1 0.8954 2 2 2h14c1.105 0 2-0.9 2-2v-1-5-4-3h-18z" fill={white} />
                <path d="m3 1034.4v2 1 3 1 5 1c0 1.1 0.8954 2 2 2h14c1.105 0 2-0.9 2-2v-1-5-4-3h-18z" fill={grey} />
                <path d="m3 1033.4v2 1 3 1 5 1c0 1.1 0.8954 2 2 2h14c1.105 0 2-0.9 2-2v-1-5-4-3h-18z" fill={white} />
                <path
                    d="m5 1c-1.1046 0-2 0.8954-2 2v1 4 2 1 3 1 5 1c0 1.105 0.8954 2 2 2h2v-1h-1.5c-0.8284 0-1.5-0.672-1.5-1.5s0.6716-1.5 1.5-1.5h12.5 1c1.105 0 2-0.895 2-2v-1-5-4-3-1c0-1.1046-0.895-2-2-2h-4-10z"
                    fill={primaryShade} transform="translate(0 1028.4)"/>
                <path d="m8 1v18h1 9 1c1.105 0 2-0.895 2-2v-1-5-4-3-1c0-1.1046-0.895-2-2-2h-4-6-1z" fill={primaryColor} transform="translate(0 1028.4)"/>
            </g>
        </svg>
    );
}
