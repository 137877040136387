import React from 'react';

export default function Steak({className}) {
    let color1 = '#F69B77';
    let color2 = '#000000';
    let color3 = '#B08C7E';
    return (
        <svg height="91px" viewBox="0 0 91 91" width="91px" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g>
                <g>
                    <g>
                        <g>
                            <path d="M4.468,49.624c-1.553,2.082-2.527,4.79-2.055,8.26c2.714,20.01,49.147,27.769,49.147,27.769      S91.071,94.3,87.639,65.567"
                                  fill={color1}/>
                            <path
                                d="M63.869,89.108c-6.575,0-11.988-1.109-12.729-1.269c-2.615-0.44-48.057-8.431-50.933-29.657      c-0.503-3.685,0.33-7.012,2.475-9.891c0.735-0.988,2.132-1.191,3.119-0.455c0.988,0.735,1.191,2.13,0.455,3.119      c-1.443,1.936-1.977,4.103-1.634,6.628C6.664,72.65,39.462,81.367,51.93,83.453c0.034,0.007,0.07,0.013,0.106,0.021      c0.202,0.044,20.444,4.335,29.389-3.793c3.433-3.121,4.742-7.65,4.001-13.849c-0.146-1.222,0.728-2.331,1.95-2.478      c1.216-0.141,2.329,0.728,2.476,1.95c0.911,7.628-0.918,13.577-5.438,17.68C79.029,87.875,70.805,89.108,63.869,89.108z"
                                fill={color2}/>
                        </g>
                        <g>
                            <path
                                d="M30.214,36.404c0,0-27.339-2.182-25.675,15.145C6.203,68.87,43.721,75.348,43.721,75.348      s41.252,9.732,44.559-12.495c3.307-22.225-21.267-28.195-21.267-28.195s-7.675-2.872-22.117-0.113      C35.66,36.312,31.84,36.477,30.214,36.404z"
                                fill={color3}/>
                            <path
                                d="M62.637,79.697c-9.726,0-18.44-1.954-19.359-2.167c-2.353-0.412-39.17-7.175-40.957-25.769      c-0.413-4.299,0.701-7.933,3.311-10.804c5.431-5.978,15.939-6.87,21.722-6.87c1.821,0,2.99,0.09,3.038,0.094      c0.081,0.002,0.265,0.008,0.476,0.008c1.841,0,5.696-0.318,13.61-1.833c4.3-0.821,8.44-1.237,12.31-1.237      c6.427,0,10.155,1.159,10.881,1.407c1.997,0.536,26.253,7.561,22.816,30.654C89.362,70.714,83.621,79.697,62.637,79.697z       M27.353,38.543c-4.061,0-13.986,0.527-18.423,5.412c-1.754,1.928-2.464,4.343-2.172,7.38      C7.988,64.142,34.131,71.424,44.1,73.151c0.044,0.006,0.088,0.016,0.133,0.025c0.086,0.021,8.875,2.063,18.404,2.063      c14.297,0,22.183-4.277,23.438-12.716c2.965-19.914-18.669-25.475-19.59-25.701c-0.084-0.021-0.17-0.046-0.251-0.077      c-0.023-0.007-3.295-1.168-9.447-1.168c-3.591,0-7.45,0.39-11.472,1.157c-6.629,1.269-11.49,1.912-14.447,1.912      c-0.288,0-0.537-0.006-0.752-0.016C30.028,38.624,28.98,38.543,27.353,38.543z"
                                fill={color2}/>
                        </g>
                        <path
                            d="M28.23,60.845c-1.601,0-3.33-0.339-5-0.982c-5.358-2.064-8.543-6.425-7.411-10.143     c0.766-2.516,3.364-4.08,6.782-4.08c1.602,0,3.331,0.339,5.001,0.981c5.364,2.068,8.549,6.431,7.412,10.146     C34.244,59.28,31.644,60.845,28.23,60.845z M22.602,50.096c-1.468,0-2.378,0.465-2.518,0.924     c-0.309,1.017,1.426,3.404,4.75,4.685c1.164,0.447,2.338,0.683,3.397,0.683c1.47,0,2.381-0.464,2.523-0.926     c0.309-1.015-1.428-3.397-4.751-4.68C24.837,50.332,23.661,50.096,22.602,50.096z"
                            fill={color2}/>
                        <path
                            d="M39.689,49.529c-0.898,0-1.743-0.546-2.083-1.433l-2.479-6.495c-0.439-1.149,0.136-2.437,1.286-2.876     c1.15-0.438,2.439,0.138,2.877,1.288l2.479,6.491c0.439,1.149-0.136,2.438-1.286,2.878     C40.222,49.481,39.954,49.529,39.689,49.529z"
                            fill={color2}/>
                        <path
                            d="M46.179,63.025c-0.838,0-1.643-0.479-2.021-1.29l-3.165-6.796c-0.52-1.115-0.037-2.44,1.079-2.961     c1.118-0.52,2.443-0.036,2.961,1.081l3.165,6.795c0.52,1.115,0.036,2.44-1.079,2.961C46.814,62.957,46.494,63.025,46.179,63.025z     "
                            fill={color2}/>
                        <path
                            d="M50.578,73.503c-0.895,0-1.739-0.544-2.081-1.429l-1.776-4.612c-0.443-1.15,0.128-2.439,1.277-2.881     c1.147-0.442,2.438,0.129,2.881,1.278l1.777,4.613c0.443,1.147-0.129,2.438-1.278,2.881     C51.115,73.454,50.845,73.503,50.578,73.503z"
                            fill={color2}/>
                        <path
                            d="M54.002,53.393c-0.874,0-1.705-0.518-2.06-1.376l-4.955-11.971c-0.47-1.138,0.069-2.441,1.207-2.911     c1.136-0.47,2.441,0.069,2.911,1.207l4.955,11.972c0.471,1.136-0.069,2.438-1.207,2.911     C54.575,53.339,54.285,53.393,54.002,53.393z"
                            fill={color2}/>
                        <path
                            d="M62.192,72.598c-0.881,0-1.716-0.528-2.067-1.396L54.69,57.756c-0.462-1.143,0.09-2.441,1.229-2.902     c1.143-0.461,2.441,0.09,2.902,1.231l5.435,13.447c0.463,1.142-0.089,2.441-1.23,2.901     C62.752,72.543,62.471,72.598,62.192,72.598z"
                            fill={color2}/>
                        <path
                            d="M66.116,48.97c-0.859,0-1.68-0.501-2.043-1.34l-3.19-7.338c-0.488-1.128,0.027-2.441,1.155-2.932     c1.129-0.489,2.443,0.026,2.933,1.155l3.189,7.338c0.489,1.128-0.026,2.441-1.157,2.932C66.715,48.91,66.414,48.97,66.116,48.97z     "
                            fill={color2}/>
                        <path
                            d="M71.702,61.033c-0.861,0-1.681-0.501-2.046-1.34L67.03,53.65c-0.491-1.128,0.026-2.441,1.155-2.933     c1.128-0.489,2.44,0.027,2.933,1.155l2.626,6.043c0.491,1.128-0.026,2.441-1.155,2.933C72.3,60.974,71.999,61.033,71.702,61.033z     "
                            fill={color2}/>
                        <path
                            d="M76.039,71.626c-0.881,0-1.715-0.526-2.066-1.394l-1.41-3.484c-0.461-1.143,0.088-2.441,1.229-2.902     c1.142-0.459,2.441,0.089,2.902,1.23l1.41,3.485c0.462,1.14-0.088,2.439-1.229,2.901C76.603,71.572,76.317,71.626,76.039,71.626z     "
                            fill={color2}/>
                        <path
                            d="M79.86,56.184c-0.896,0-1.741-0.544-2.082-1.431l-1.695-4.413c-0.441-1.149,0.133-2.439,1.281-2.881     c1.147-0.441,2.438,0.133,2.881,1.282l1.693,4.413c0.441,1.149-0.132,2.439-1.28,2.881C80.396,56.135,80.125,56.184,79.86,56.184     z"
                            fill={color2}/>
                        <path
                            d="M37.827,68.916c-0.894,0-1.739-0.543-2.081-1.43l-1.773-4.609c-0.443-1.149,0.131-2.439,1.278-2.881     c1.149-0.443,2.437,0.131,2.88,1.28l1.775,4.609c0.441,1.149-0.13,2.439-1.281,2.881C38.364,68.868,38.094,68.916,37.827,68.916z     "
                            fill={color2}/>
                        <path
                            d="M29.189,46.463c-0.973,0-1.865-0.641-2.144-1.623l-0.706-2.493c-0.334-1.184,0.353-2.414,1.537-2.75     c1.186-0.336,2.416,0.353,2.751,1.537l0.707,2.493c0.334,1.185-0.353,2.416-1.537,2.75     C29.594,46.435,29.389,46.463,29.189,46.463z"
                            fill={color2}/>
                        <path
                            d="M37.177,29.57c-0.655,0-1.301-0.288-1.743-0.836c-0.768-0.962-0.612-2.364,0.349-3.132     c2.754-2.203,2.111-3.65,0.259-6.995c-1.738-3.14-4.364-7.883,1.539-12.226c0.99-0.729,2.385-0.517,3.115,0.476     c0.729,0.991,0.516,2.386-0.475,3.115c-2.623,1.929-2.154,3.091-0.28,6.477c1.762,3.184,4.425,7.995-1.376,12.633     C38.155,29.409,37.664,29.57,37.177,29.57z"
                            fill={color2}/>
                        <path
                            d="M49.016,25.133c-0.604,0-1.207-0.244-1.646-0.727c-0.829-0.908-0.764-2.318,0.144-3.147     c2.896-2.64,2.24-3.637,0.483-6.298c-1.85-2.804-4.644-7.042,0.96-12.773c0.861-0.881,2.271-0.896,3.151-0.036     c0.881,0.861,0.896,2.271,0.036,3.151c-3.093,3.163-2.201,4.514-0.426,7.204c1.796,2.724,4.51,6.838-1.202,12.046     C50.09,24.941,49.553,25.133,49.016,25.133z"
                            fill={color2}/>
                    </g>
                </g>
            </g>
        </svg>
    );
}
