import React, {useState} from 'react';
import {useDrag, useDrop} from 'react-dnd'
import classnames from 'classnames';
import {Field} from 'react-final-form';
import uuid from 'uuid/v4';

import {DRAGGABLE_ITEMS} from '../../../../../util/constants';
import DragHandle from '../../../../../images/react-icons/drag-handle';
import {FIELD_NAMES} from '../recipe-form';
import PhotoPreview from '../../../components/photo-preview/photo-preview';
import {required, validateNumber} from '../../../../../util/validation';
import FieldLabel from '../../../components/field-label/field-label';

import './ingredient-fields.css';

let IngredientButtons = ({photo, instructionToAddIngredients, updateInstructionIngredients, form, index, ingredientId, setShowDeleteNag}) => {
    let content = (
        <>
            <button
                type="button"
                className="ingredientFields-addPhotoButton"
                onClick={() => document.getElementById(ingredientId).click()}
            >{photo ? 'Change Photo' : 'Add Photo'}</button>
            <button
                type="button"
                className="ingredientFields-deleteButton"
                onClick={() => setShowDeleteNag(true)}
            >Delete</button>
        </>
    );
    if (instructionToAddIngredients) {
        let ingredientAddedToInstruction = !!instructionToAddIngredients.ingredients.find(selectedIngredientId => selectedIngredientId === ingredientId);
        content = (
            <>
                <button
                    type="button"
                    className={classnames(
                        {'ingredientFields-selectButton--select': !ingredientAddedToInstruction},
                        {'ingredientFields-selectButton--unselect': ingredientAddedToInstruction}
                    )}
                    onClick={() => updateInstructionIngredients(ingredientId)}
                >{ingredientAddedToInstruction ? 'Unselect' : 'Select'}</button>
            </>
        );
    }
    return (
        <div className="ingredientFields-photoAndButtons">
            {photo && !instructionToAddIngredients && (
                <div className="ingredientFields-photo">
                    <PhotoPreview
                        photo={photo}
                        deletePhoto={() => form.change(FIELD_NAMES.getIngredientPhotoName(index))}
                        imageClassName="ingredientFields-photoPreview"
                    />
                </div>
            )}
            <div className={classnames('ingredientFields-buttonContainer', {'ingredientFields-buttonContainer--stretch': !photo})}>{content}</div>
        </div>
    );
};

export default function IngredientFields ({index, ingredient, moveIngredient, removeIngredient, instructionToAddIngredients, updateInstructionIngredients, formProps}) {
    let {form} = formProps;
    let {quantity, description, id: ingredientId, photo} = ingredient;
    let [showDeleteNag, setShowDeleteNag] = useState();
    let [{isDragging}, drag, dragPreview] = useDrag(() => ({
        type: DRAGGABLE_ITEMS.INGREDIENT,
        item: {
            id: ingredientId,
            index,
            description,
            quantity
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    }), [index, ingredientId, description, quantity]);
    let [{isOver}, drop] = useDrop(() => ({
        accept: DRAGGABLE_ITEMS.INGREDIENT,
        drop: item => moveIngredient(item.id, index),
        collect: monitor => ({
            isOver: !!monitor.isOver()
        })
    }), [index, ingredientId, moveIngredient]);

    return (
        <div className={classnames('ingredientFields', {'ingredientFields-droppable': isOver})} ref={drop}>
            <div className="ingredientFields-ingredient" ref={dragPreview} style={{opacity: isDragging ? 0.3 : 1}}>
                <div className="ingredientFields-grabIconContainer" ref={drag}>
                    <DragHandle colors={{primaryColor: '#7a7a7a'}} className="ingredientFields-grabIcon" />
                </div>
                <div className="ingredientFields-content">
                    <div className="ingredientFields-fieldsAndButtons">
                        <div className="ingredientFields-formFields">
                            <div className="ingredientFields-quantitySection">
                                <FieldLabel
                                    fieldName={FIELD_NAMES.getIngredientQuantityName(index)}
                                    className="ingredientFields-quantityLabel"
                                    {...formProps}
                                >Amount</FieldLabel>
                                <Field
                                    name={FIELD_NAMES.getIngredientQuantityName(index)}
                                    component="input"
                                    autoComplete="off"
                                    className="ingredientFields-quantityInput"
                                    parse={value => (value || '').toString().replace(/[^0-9.]/g, '')}
                                    inputMode="decimal"
                                    pattern="^\d*\.?\d*$"
                                    validate={validateNumber}
                                />
                            </div>
                            <div className="ingredientFields-descriptionSection">
                                <FieldLabel
                                    fieldName={FIELD_NAMES.getIngredientDescriptionName(index)}
                                    className="ingredientFields-descriptionLabel"
                                    {...formProps}
                                >Description</FieldLabel>
                                <Field
                                    name={FIELD_NAMES.getIngredientDescriptionName(index)}
                                    component="input"
                                    autoComplete="off"
                                    className="ingredientFields-descriptionInput"
                                    validate={required}
                                />
                            </div>
                        </div>
                        <IngredientButtons
                            photo={photo}
                            instructionToAddIngredients={instructionToAddIngredients}
                            updateInstructionIngredients={updateInstructionIngredients}
                            form={form}
                            index={index}
                            ingredientId={ingredientId}
                            setShowDeleteNag={setShowDeleteNag}
                        />
                    </div>
                </div>
                {showDeleteNag && (
                    <div className="ingredientFields-deleteNag">
                        <div className="ingredientFields-deleteNagText">Are you sure you want to delete this ingredient?</div>
                        <div className="ingredientFields-deleteNagButtonContainer">
                            <button onClick={() => removeIngredient(ingredientId)} type="button" className="ingredientFields-yesButton">Yes</button>
                            <button onClick={() => setShowDeleteNag(false)} type="button" className="ingredientFields-noButton">No</button>
                        </div>
                    </div>
                )}
                <input
                    type="file"
                    accept="image/*"
                    className="ingredientFields-photoFileInput"
                    id={ingredientId}
                    onChange={event => {
                        let file = event.target.files?.[0];
                        if (!file) return;
                        file.id = uuid();
                        form.change(FIELD_NAMES.getIngredientPhotoName(index), file)
                    }}
                />
            </div>
        </div>
    )
}
