import React from 'react';

export default function BookIcon5({className, colors = {}}) {
    let {
        primaryColor = '#323232',
        primaryShade = '#161616',
        bookmarkColor = '#db4342'
    } = colors;
    return (
        <svg enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g>
                <g>
                    <path
                        d="M128.3,28.7l0.1,340c-0.1,31.9,19.9,29.4,27.1,29.4h248.1c38.5,0,37.1-26.8,37.1-33.8V34    c2.1-34.1-25.1-34.1-32.3-34.1H155.5C148.3-0.1,128.2,4.8,128.3,28.7z M374,169.7H189.5c-7.2,0-13.5-5.3-13.5-12.3V73.1    c0-7,6.8-15.1,14-15.1h184.5c7.2,0,13.5,6.7,13.5,13.7V156C388,163,381.2,169.7,374,169.7z"
                        fill={primaryColor}/>
                </g>
                <polygon
                    fill={bookmarkColor} points="224.8,512.8 176.6,498.7 128.4,512.8 128.4,424.1 224.8,424.1  "/>
                <g>
                    <path d="M437.5,450.3c-10-16-8.6-50.7,0.2-69.5h-15.9c-8.5,17.5-8.9,45.8,0,63.1H238.4V458l199.4,0    c2.9-1.3,1.5-4.9,0-7.7L437.5,450.3z" fill={primaryColor}/>
                    <path
                        d="M71.7,60.3l-0.2,382.6c-0.3,16.2,15.2,16.3,28.4,15.1h6.1l0.1-12.2c-19.3,4.6-35.1-40.2-0.4-47.9l0-395.5    C82.9,5.8,68.1,27,71.7,60.3z"
                        fill={primaryShade}/>
                </g>
            </g>
        </svg>
    );
}



