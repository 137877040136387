import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import {createUrl} from '../../../../util/formatters';
import HubRow from './hub-row/hub-row';
import RequestRow from './request-row/request-row';
import {openModal as _openModal} from '../../../../redux/modal/modal-actions';

import './hubs.css';

export class Hubs extends React.Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        openModal: PropTypes.func.isRequired,
        pendingRequestStats: PropTypes.object.isRequired
    };

    loadData = async () => {
        let [pendingRequests, joinedHubs] = (await Promise.all([
            axios.get(createUrl('/requests?type=hubInvite&type=hubApplication')),
            axios.get(createUrl('/hubs?myRole=member'))
        ])).map(response => response.data);
        joinedHubs = await Promise.all(joinedHubs.map(async hub => {
            return (await axios.get(createUrl(hub.links.self))).data;
        }));
        this.setState({pendingRequests, joinedHubs});
    };

    launchCreateHubModal = () => {
        let {openModal, history} = this.props;
        openModal('CREATE_HUB_MODAL', {history}, {title: 'Create a New Hub', width: 500});
    };

    launchJoinHubModal = () => {
        let {openModal, history} = this.props;
        openModal('JOIN_HUB_MODAL', {history}, {title: 'Join a Hub', width: 500});
    };

    state = {};

    componentDidMount = async () => {
        await this.loadData();
    };

    render() {
        let {history, pendingRequestStats} = this.props;
        let {pendingRequests, joinedHubs} = this.state;
        let rows = [];
        if (pendingRequests) {
            rows = [
                ...rows,
                ...pendingRequests.map((request, index) => <RequestRow request={request} reloadRows={this.loadData} history={history} key={'request:' + index}/>)
            ];
        }
        if (joinedHubs) {
            rows = [
                ...rows,
                ...joinedHubs.map((joinedHub, index) => {
                    let hubApplicationCount = pendingRequestStats.getIn(['hubApplication', 'Hub|' + joinedHub.id], 0);
                    return <HubRow hub={joinedHub} hubApplicationCount={hubApplicationCount} history={history} key={'hub:' + index}/>
                })
            ];
        }
        if (!rows.length && pendingRequests && joinedHubs) {
            rows = (
                <div className="hubs-noHubsMessage">
                    No Hubs Found. Try creating one, or you can join an existing one.
                </div>
            );
        }
        return (
            <div className="hubs">
                <div className="hubs-header">
                    <div className="hubs-title">
                        <div className="hubs-tagIcon" />
                        Tag Hubs
                    </div>
                    <div className="hubs-headerButtons">
                        <button className="hubs-headerButton" onClick={this.launchCreateHubModal}>Create</button>
                        <button className="hubs-headerButton" onClick={this.launchJoinHubModal}>Join</button>
                    </div>
                </div>
                {rows}
            </div>
        );
    }
}

export default connect(null, {openModal: _openModal})(Hubs);
