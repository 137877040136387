import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import Toggle from "react-toggle";

import {createUrl} from '../../../../util/formatters';

import './invite-link-modal.css';

export class InviteLinkModal extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string.isRequired,
        closeModal: PropTypes.func.isRequired,
        hub: PropTypes.object.isRequired,
        reloadHub: PropTypes.func.isRequired
    };

    InviteLink = () => {
        let {hub, activeUserId} = this.props;
        let {linkEnabled, updatedHub} = this.state;
        if (!linkEnabled) return null;

        let {inviteToken} = updatedHub ? updatedHub.inviteAuth : hub.inviteAuth;
        let link = `https://aggielacroix.com/gift-tags/register?inviteCode=${activeUserId}&redirectTo=/gift-tags/hubs/${hub.id}/join?inviteToken=${inviteToken}`;
        return (
            <div className="inviteLinkModal-inviteLink">
                <input type="text" readOnly id="inviteLinkModal-link" className="inviteLinkModal-link" value={link} />
                <button className="inviteLinkModal-copyButton" type="button" onClick={this.copyLink}>Copy</button>
            </div>
        )
    };

    copyLink = () => {
        let inviteLinkInput = document.getElementById('inviteLinkModal-link');
        inviteLinkInput.select();
        inviteLinkInput.setSelectionRange(0, 99999);
        document.execCommand('copy');
    };

    toggleInviteLink = async event => {
        let {hub, reloadHub} = this.props;
        let {checked} = event.target;
        this.setState({linkEnabled: checked});
        await axios.put(createUrl('/hubs/' + hub.id), {inviteAuthEnabled: checked});
        let updatedHub = await reloadHub();
        this.setState({updatedHub})
    };

    constructor(props) {
        super(props);
        this.state = {
            linkEnabled: props.hub.inviteAuth.enabled
        };
    }

    render() {
        let {InviteLink} = this;
        let {linkEnabled} = this.state;
        let endOfSubText = linkEnabled ? 'shown below' : 'which will appear below';
        return (
            <div className="inviteLinkModal">
                <div className="inviteLinkModal-enableSection">
                    <div className="inviteLinkModal-enableSectionText">
                        <div>Enable Invite Link</div>
                        <div className="inviteLinkModal-subText">While this is enabled, anyone can automatically join your hub by following the link {endOfSubText}.</div>
                    </div>
                    <Toggle checked={linkEnabled} icons={false} onChange={this.toggleInviteLink} />
                </div>
                <InviteLink />
            </div>
        );
    }
}

let mapStateToProps = state => ({
    activeUserId: state.users.getIn(['user', 'id'])
});

export default connect(mapStateToProps)(InviteLinkModal);