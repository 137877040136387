import React from 'react';
import classnames from 'classnames';

import FieldWrapper from '../field-wrapper/field-wrapper';

import './input.css';

export default class Input extends React.Component {
    render() {
        let {input, className, fieldClassName, ...otherProps} = this.props;
        return (
            <FieldWrapper {...otherProps} className={fieldClassName}>
                <input {...otherProps} {...input} className={classnames('input', className)} />
            </FieldWrapper>
        );
    }
}
