import React from 'react';

export default function BookIcon14({className, colors = {}}) {
    let {
        primaryColor = '#323232',
        primaryShade = '#161616',
        bookmarkColor = '#db4342',
        offWhite = '#c9c9c9',
        white = '#fff'
    } = colors;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className={className}>
            <g id="_x32_5_x2C__Cook_Book_x2C__Recipe_Book_x2C__Food_and_Restaurant_x2C__Ingredients_x2C__Chef">
                <g>
                    <polygon fill={bookmarkColor} points="501,71 481,101 501,131 421,131 421,71   "/>
                    <path fill={primaryColor} d="M401,11H81L70,430l361-29c0-17.907,0-343.877,0-360C431,24.43,417.57,11,401,11z"/>
                    <path fill={white}
                          d="M371,139.06c0.3,25.1-18.17,45.96-42.24,49.4V271L256,288.5L183.24,271v-82.54    c-24.07-3.44-42.54-24.3-42.24-49.4c0.33-27.04,22.72-48.92,49.76-48.67c10.09,0.09,19.46,3.22,27.23,8.52    c19.74-23.88,56.27-23.88,76.02,0c7.77-5.3,17.14-8.43,27.23-8.52C348.28,90.14,370.67,112.02,371,139.06z"/>
                    <path fill={white} d="M328.76,271v35.92c0,7.78-6.31,14.08-14.09,14.08H197.33c-7.78,0-14.09-6.3-14.09-14.08V271H328.76    z"/>
                    <path fill={primaryShade} d="M77.27,11C40.67,11,11,40.67,11,77.27V451l70-50V11H77.27z"/>
                    <path fill={white}
                          d="M400.667,451L381,501H61c-27.61,0-50-22.39-50-50c0-27.599,22.399-50,50-50    c6.368,0,303.113,0,320,0L400.667,451z"/>
                    <path fill={offWhite} d="M431,401v70c0,16.57-13.43,30-30,30h-20c0-27.101,0-84.039,0-100H431z"/>
                    <g>
                        <g>
                            <path
                                d="M493.019,101l16.302-24.453c2.046-3.068,2.236-7.014,0.496-10.266C508.076,63.03,504.688,61,501,61h-60V41      c0-22.056-17.944-40-40-40H77.267C35.213,1,1,35.213,1,77.267V451c0,33.084,26.916,60,60,60h340c22.056,0,40-17.944,40-40      c0-5.769,0-319.671,0-330h60c3.688,0,7.076-2.03,8.816-5.281c1.74-3.252,1.55-7.197-0.496-10.266L493.019,101z M421,41      c0,88.413,0,315.521,0,350H91V21h310C412.028,21,421,29.972,421,41z M71,21.357V391H61c-15.355,0-29.376,5.805-40,15.327V77.267      C21,48.36,42.914,24.485,71,21.357z M21,451c0-22.056,17.944-40,40-40h310v30H91c-5.522,0-10,4.478-10,10s4.478,10,10,10h280v30      H61C38.944,491,21,473.056,21,451z M421,471c0,11.028-8.972,20-20,20h-10v-80h30V471z M472.68,106.547L482.315,121H441V81      h41.314l-9.635,14.453C470.441,98.812,470.441,103.188,472.68,106.547z"/>
                            <path
                                d="M341,351H171c-5.522,0-10,4.478-10,10s4.478,10,10,10h170c5.522,0,10-4.478,10-10S346.523,351,341,351z"/>
                            <path
                                d="M173.245,196.469v110.449c0,13.279,10.803,24.082,24.081,24.082h117.348c13.278,0,24.081-10.803,24.081-24.082V196.469      c24.911-7.469,42.565-30.825,42.24-57.531c-0.395-32.402-26.934-58.55-59.283-58.55c-9.04,0-17.866,2.032-25.913,5.95      c-22.57-20.469-57.071-20.432-79.598,0c-7.858-3.827-16.483-5.864-25.349-5.948c-33.537-0.174-59.46,26.736-59.848,58.548      C130.68,165.644,148.334,189,173.245,196.469z M314.674,311H197.326c-2.25,0-4.081-1.831-4.081-4.082V281h125.51v25.918      C318.755,309.169,316.924,311,314.674,311z M190.665,100.39c7.787,0.072,15.289,2.418,21.693,6.782      c4.269,2.904,10.045,2.088,13.337-1.892c15.731-19.021,44.848-19.059,60.609,0c3.29,3.979,9.07,4.796,13.337,1.892      c6.404-4.364,13.906-6.71,21.693-6.782c21.231-0.332,39.396,16.959,39.662,38.792c0.239,19.672-14.229,36.601-33.656,39.376      c-4.927,0.704-8.586,4.923-8.586,9.899V261h-125.51v-72.543c0-4.977-3.659-9.195-8.586-9.899      c-19.427-2.775-33.896-19.704-33.656-39.376C151.269,117.34,169.443,100.055,190.665,100.39z"/>
                        </g>
                    </g>
                </g>
            </g>
            <g id="Layer_1"/>
        </svg>
    );
}
