import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import axios from 'axios';
import {Redirect, Route, Switch, matchPath} from 'react-router-dom';
import classnames from 'classnames';

import {createUrl} from '../../../../../util/formatters';
import List from '../../lists/list/list';
import ListsView from '../lists-view/lists-view';
import MessagesView from '../messages-view/messages-view';
import SettingsView from '../settings-view/settings-view';
import JoinView from '../join-view/join-view';
import Input from '../../../../../components/input/input';

import './hub.css';

export class Hub extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        loadPendingRequestStats: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                hubId: PropTypes.string.isRequired
            }).isRequired,
            url: PropTypes.string.isRequired
        }).isRequired,
        pendingRequestStats: PropTypes.object.isRequired
    };

    Title = () => {
        let {handleSubmit} = this.props;
        let {hub, editing} = this.state;
        let title, description;
        useEffect(() => {
            if (editing === 'title') document.getElementById('hub-title').select();
            else if (editing === 'description') document.getElementById('hub-description').select();
        }, [editing]);
        if (hub.hasJoined && hub.memberDetails.role === 'admin') {
            title = (
                <button type="button" onClick={this.setEditing('title')} className="hub-title">
                    {hub.name}
                    <div className="hub-editIcon" />
                </button>
            );
            description = (
                <button type="button" onClick={this.setEditing('description')} className="hub-description">
                    {hub.description}
                    <div className="hub-editIcon" />
                </button>
            );
            if (editing === 'title') {
                title = (
                    <Field
                        name="title"
                        component={Input}
                        type="text"
                        className="hub-title"
                        id="hub-title"
                        autoComplete="off"
                        onBlur={this.setEditing(null, 'title')}
                    />
                );
            } else if (editing === 'description') {
                description = (
                    <Field
                        name="description"
                        component={Input}
                        type="text"
                        className="hub-description"
                        id="hub-description"
                        autoComplete="off"
                        onBlur={this.setEditing(null, 'description')}
                    />
                );
            }
        } else {
            title = <div className="hub-title">{hub.name}</div>;
            description = <div className="hub-description">{hub.description}</div>;
        }
        return (
            <form className="hub-headerLeft" onSubmit={handleSubmit(this.onSubmit)}>
                {title}
                {hub.description && description}
            </form>
        );
    };

    Tabs = () => {
        let {pendingRequestStats} = this.props;
        let {hub, generalThread} = this.state;
        let hubApplicationCount = pendingRequestStats.getIn(['hubApplication', 'Hub|' + hub.id], 0);
        let unreadMessagesCount = generalThread ? generalThread.unreadMessagesCount : 0;
        let currentView = this.getCurrentView();

        if (!['lists', 'messages', 'settings'].includes(currentView)) return <hr className="hub-hr" />;
        return (
            <React.Fragment>
                <div className="hub-tabs">
                    <button
                        className={classnames('hub-tab', {'hub-tab--selected': currentView === 'lists'})}
                        onClick={this.onTabChange('lists')}
                    >
                        <div className="hub-icon hub-listIcon" />
                        <span className="hub-tabText">{this.tabTitles.lists}</span>
                    </button>
                    <button
                        className={classnames('hub-tab', {'hub-tab--selected': currentView === 'messages'})}
                        onClick={this.onTabChange('messages')}
                    >
                        <div className="hub-icon hub-messageIcon" />
                        <span className="hub-tabText">{this.tabTitles.messages}</span>
                        {!!unreadMessagesCount && <div className="hub-badge">{unreadMessagesCount}</div>}
                    </button>
                    <button
                        className={classnames('hub-tab', {'hub-tab--selected': currentView === 'settings'})}
                        onClick={this.onTabChange('settings')}
                    >
                        <div className="hub-icon hub-settingsIcon" />
                        <span className="hub-tabText">
                            {this.tabTitles.settings}
                        </span>
                        {!!hubApplicationCount && <div className="hub-badge">{hubApplicationCount}</div>}
                    </button>
                </div>
                <div className="hub-tabSubtitle">{this.tabTitles[currentView]}</div>
            </React.Fragment>
        )
    };

    onBack = () => {
        this.props.history.push('/gift-tags/hubs');
    };

    onTabChange = view => () => {
        let {history, match} = this.props;
        let {listsUrl} = this.state;
        if (this.getCurrentView() !== view) {
            if (view === 'lists' && listsUrl) {
                history.push(listsUrl);
            } else {
                history.push(`${match.url}/${view}`);
            }
        }
    };

    getCurrentView = () => {
        let {location} = this.props;
        let match = matchPath(location.pathname, {path: '/gift-tags/hubs/:hubId/:view'});
        return match ? match.params.view : null;
    };

    setListsUrl = url => this.setState({listsUrl: url});

    tabTitles = {
        lists: 'Lists',
        messages: 'Messages',
        settings: 'Settings'
    };

    setEditing = (value, currentValue) => () => {
        let {handleSubmit} = this.props;
        let {editing} = this.state;
        if (currentValue && editing !== currentValue) return;
        if (!value && editing) {
            handleSubmit(this.onSubmit)();
        } else if (!value || (value && !editing)) {
            this.setState({editing: value})
        }
    };

    onSubmit = async values => {
        let {hub} = this.state;
        await axios.put(createUrl('/hubs/' + hub.id), {name: values.title, description: values.description});
        await this.loadData();
        this.setState({editing: null});
    };

    loadData = async () => {
        let {match, change} = this.props;
        let {hubId} = match.params;
        let hub = (await axios.get(createUrl('/hubs/' + hubId))).data;
        let generalThread;
        if (hub.threads && hub.threads.general) {
            generalThread = (await axios.get(createUrl(`/threads/${hub.threads.general}`))).data;
        }
        change('title', hub.name);
        change('description', hub.description);
        this.setState({hub, generalThread});
        return hub;
    };

    state = {
        editing: null
    };

    componentDidMount = async () => {
        await this.loadData();
    };

    componentDidUpdate = async prevProps => {
        if (prevProps.match.params.hubId !== this.props.match.params.hubId) {
            this.setState({hub: undefined});
            await this.loadData();
        }
    };

    render() {
        let {loadPendingRequestStats} = this.props;
        let {hub, generalThread} = this.state;
        let {Title, Tabs} = this;
        if (!hub) return null;
        let unreadMessagesCount = generalThread ? generalThread.unreadMessagesCount : 0;
        return (
            <div className="hub">
                <div className="hub-header">
                    <Title />
                    <button className="hub-headerButton" onClick={this.onBack}>Close Hub</button>
                </div>
                <Tabs />
                <Switch>
                    <Route path="/gift-tags/hubs/:hubId/lists/:listId" render={props => <List {...props} hub={hub} setListsUrl={this.setListsUrl} />} />
                    <Route path="/gift-tags/hubs/:hubId/lists" render={props => <ListsView {...props} hub={hub} setListsUrl={this.setListsUrl} />} />
                    <Route path="/gift-tags/hubs/:hubId/messages" render={props => <MessagesView {...props} hub={hub} unreadMessagesCount={unreadMessagesCount} reloadHub={this.loadData} />} />
                    <Route path="/gift-tags/hubs/:hubId/settings" render={props => <SettingsView {...props} hub={hub} reloadHub={this.loadData} loadPendingRequestStats={loadPendingRequestStats} />} />
                    <Route path="/gift-tags/hubs/:hubId/join/:requestId" render={props => <JoinView {...props} hub={hub} reloadHub={this.loadData} loadPendingRequestStats={loadPendingRequestStats} />} />
                    <Route path="/gift-tags/hubs/:hubId/join" render={props => <JoinView {...props} hub={hub} reloadHub={this.loadData} loadPendingRequestStats={loadPendingRequestStats} />} />
                    <Redirect to="/gift-tags/hubs/:hubId/lists" />
                </Switch>
            </div>
        );
    }
}

let reduxFormConfig = {
    form: 'editHub'
};

export default reduxForm(reduxFormConfig)(Hub);
