import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fromJS} from 'immutable';
import axios from 'axios';
import classnames from 'classnames';

import Dropdown from '../../../../../components/dropdown/dropdown';
import Gifts from '../gifts/gifts';
import {SORT_DISPLAY} from '../../../../../util/constants';
import {createUrl} from "../../../../../util/formatters";
import {openModal as _openModal} from '../../../../../redux/modal/modal-actions';
import Interstitial from '../../../../../components/interstitial/interstitial';
import {INTERSTITIAL_KEYS} from '../../../../../util/constants';

import './list.css';

export class List extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                listId: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        onMount: PropTypes.func,
        openModal: PropTypes.func.isRequired,
        setListsUrl: PropTypes.func
    };

    OwnedBy = () => {
        let {activeUserId} = this.props;
        let {list, owner} = this.state;
        let selectedUserId = list.getIn(['ownedBy', 'id']);
        let {first, last} = list.getIn(['ownedBy', 'name']).toObject();
        let label = list.get('type') === 'reference' ? 'Ideas Claimed By' : 'Ideas For';
        let following = null;
        if (activeUserId !== selectedUserId && owner) {
            let isFollowing = owner.following;
            if (isFollowing) {
                following = (
                    <button className="list-followButton--unfollow" onClick={this.setFollowing(isFollowing)}>
                        Unfollow
                    </button>
                );
            } else {
                following = (
                    <button className="list-followButton--follow" onClick={this.setFollowing(isFollowing)}>
                        Follow
                    </button>
                );
            }
        }
        return (
            <div className="list-ownedBy">
                <div className="list-ownedByTitle">{label}:</div>
                <div className="list-ownedByValueWrapper">
                    <div className="list-ownedByValue">{first} {last}</div>
                    {following}
                </div>
            </div>
        );
    };

    sortLists = (listA, listB) => {
        let listTypeValues = {
            origin: 0,
            reference: 1
        };
        let typeDifference = listTypeValues[listA.get('type')] - listTypeValues[listB.get('type')];

        if (typeDifference === 0) {
            let createdAtA = new Date(listA.get('createdAt')).getTime();
            let createdAtB = new Date(listB.get('createdAt')).getTime();
            return createdAtA - createdAtB;
        }

        return typeDifference;
    };

    SelectList = () => {
        let {activeUserId, openModal} = this.props;
        let {list, lists} = this.state;
        let selectedUserId = list.getIn(['ownedBy', 'id']);
        let listComponent = <div className="list-name">{list.get('name')}</div>;
        if (lists.size > 1) {
            let options = lists.sort(this.sortLists).reduce((collection, list) => {
                let label;
                let listId = list.get('id');
                if (list.get('visibility') === 'private') label = '(Private)';
                else if (list.get('defaultList') && lists.size > 2) label = '(Primary)';
                return [
                    ...collection,
                    <button onClick={this.selectList(listId)} className="list-selectListButton" key={listId}>
                        {list.get('name')}
                        <span className="list-privateLabel">{label}</span>
                    </button>
                ]
            }, []);

            listComponent = (
                <Dropdown value={listComponent} buttonClassName="list-dropdownButton" menuClassName="list-selectableListsMenu">
                    {options}
                </Dropdown>
            )
        }
        let referenceListMessage = (
            <div className="list-referenceListMessage">
                This is a private list where your claimed gifts will appear.
            </div>
        );
        let editListsLink = (
            <button onClick={() => openModal('MANAGE_LISTS', {}, {title: 'Manage Your Lists'})} className="list-editListsLink">
                Manage Lists
            </button>
        );
        return (
            <div className="list-selectList">
                {listComponent}
                {activeUserId === selectedUserId && editListsLink}
                {list.get('type') === 'reference' && referenceListMessage}
            </div>
        );
    };

    SortedBy = () => {
        let {activeUserId} = this.props;
        let {list} = this.state;
        let selectedUserId = list.getIn(['ownedBy', 'id']);
        let type = list.get('type');
        if (activeUserId === selectedUserId) {
            let options = Object.keys(SORT_DISPLAY[type]).reduce((collection, value) => [
                ...collection,
                <button onClick={this.changeSort(value)} className="list-selectListButton" key={value}>
                    {SORT_DISPLAY[type][value]}
                </button>
            ], []);
            return (
                <div className="list-sortedBy">
                    <div className="list-sortedByTitle">Sorted By:</div>
                    <Dropdown value={SORT_DISPLAY[type][list.get('sortedBy')]} className="list-sortedByValue">
                        {options}
                    </Dropdown>
                </div>
            )
        } else {
            return (
                <div className="list-sortedBy">
                    <div className="list-sortedByTitle">Sorted By:</div>
                    <div className="list-sortedByValue">{SORT_DISPLAY[type][list.get('sortedBy')]}</div>
                </div>
            )
        }
    };

    changeSort = value => async () => {
        let {match} = this.props;
        let {listId} = match.params;
        let list = fromJS((await axios.put(createUrl(`/lists/${listId}`), {sortedBy: value})).data);
        this.setState({list});
    };

    selectList = selectedListId => () => {
        let {history, match} = this.props;
        let {hubId} = match.params;
        if (hubId) {
            history.push(`/gift-tags/hubs/${hubId}/lists/${selectedListId}`);
        } else {
            history.push('/gift-tags/lists/' + selectedListId);
        }
    };

    closeList = () => {
        let {history, match} = this.props;
        let {hubId} = match.params;
        if (hubId) {
            history.push(`/gift-tags/hubs/${hubId}/lists`);
        } else {
            history.goBack();
        }
    };

    setFollowing = isFollowing => async () => {
        let {list} = this.state;
        let selectedUserId = list.getIn(['ownedBy', 'id']);
        let owner;
        if (isFollowing) {
            owner = (await axios.post(createUrl(`/users/${selectedUserId}/unfollow`))).data;
        } else {
            owner = (await axios.post(createUrl(`/users/${selectedUserId}/follow`))).data;
        }
        this.setState({owner})
    };

    loadList = async () => {
        let {match} = this.props;
        let {list: previousList} = this.state;
        let {listId} = match.params;
        try {
            let list = fromJS((await axios.get(createUrl(`/lists/${listId}`))).data);
            let ownedBy = list.getIn(['ownedBy', 'id']);
            let owner = (await axios.get(createUrl(`/users/${ownedBy}`))).data;
            let newState = {list, owner};
            if (!previousList || previousList.getIn(['ownedBy', 'id']) !== ownedBy) {
                let lists = (await axios.get(createUrl('/lists'), {params: {ownedBy}})).data;
                newState.lists = fromJS(
                    (await Promise.all(lists.map(list => axios.get(createUrl(list.links.self)))))
                    .map(response => response.data)
                );
            }
            this.setState(newState);
        } catch (error) {
            let message = 'Unable to view this list.';
            if (error.response && error.response.status === 403) {
                message = 'You don\'t have permission to view this list.'
            }
            this.setState({error: message});
        }
    };

    state = {};

    async componentDidMount() {
        let {setListsUrl, match, onMount} = this.props;
        if (setListsUrl) setListsUrl(match.url);
        if (onMount) onMount();
        await Interstitial.interstitialController(INTERSTITIAL_KEYS.LIST, this.loadList(), 500);
    }

    componentDidUpdate = async (prevProps) => {
        let {match} = this.props;
        let {listId} = match.params;
        if (listId !== prevProps.match.params.listId) {
            await Interstitial.interstitialController(INTERSTITIAL_KEYS.LIST, this.loadList(), 500);
        }
    };

    render() {
        let {match, activeUserId} = this.props;
        let {list, lists, error} = this.state;
        let {OwnedBy, SelectList, SortedBy} = this;
        let {hubId, listId} = match.params;

        let content;
        if (error) return <div>{error}</div>;
        else if (list && list.get('id') === listId) {
            let closeButton = (hubId || activeUserId !== list.getIn(['ownedBy', 'id'])) && (
                <button className="list-closeButton" onClick={this.closeList}>
                    <div className="list-closeIcon" />
                </button>
            );
            content = (
                <>
                    <div className="list-header">
                        <OwnedBy />
                        <SelectList />
                        <SortedBy />
                        {closeButton}
                    </div>
                    <Gifts list={list} lists={lists} />
                </>
            )
        }

        return (
            <Interstitial className={classnames('list', {'list--spacing': !hubId})} interstitialKey={INTERSTITIAL_KEYS.LIST} interstitialSize={Interstitial.INTERSTITIAL_SIZES.LARGE}>
                {content}
            </Interstitial>
        );
    }
}

let mapStateToProps = (state) => {
    let activeUserId = state.users.getIn(['user', 'id']);

    return {
        activeUserId
    };
};

export default connect(mapStateToProps, {
    openModal: _openModal
})(List);
