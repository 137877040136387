import React from 'react';

export default function Oven({className}) {
    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M23.91,4A1.5,1.5,0,0,0,22.5,3h-4a1.5,1.5,0,0,0-1.41,1H12.91A1.5,1.5,0,0,0,11.5,3h-2A1.5,1.5,0,0,0,8.09,4H6V29H26V4H23.91ZM25,5V9H7V5H25ZM7,28V10H25V28H7Z"/>
            <circle cx="9" cy="7" r="1"/>
            <circle cx="17" cy="7" r="1"/>
            <circle cx="14" cy="7" r="1"/>
            <circle cx="20" cy="7" r="1"/>
            <circle cx="23" cy="7" r="1"/>
            <rect height="1" width="8" x="12" y="11"/>
            <path
                d="M20.5,13h-9A2.5,2.5,0,0,0,9,15.5v6A2.5,2.5,0,0,0,11.5,24h9A2.5,2.5,0,0,0,23,21.5v-6A2.5,2.5,0,0,0,20.5,13ZM22,21.5A1.5,1.5,0,0,1,20.5,23h-9A1.5,1.5,0,0,1,10,21.5v-6A1.5,1.5,0,0,1,11.5,14h9A1.5,1.5,0,0,1,22,15.5v6Z"/>
        </svg>
    );
}
