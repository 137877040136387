import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './checkbox.css';

export default class Checkbox extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        checked: PropTypes.bool,
        checkmarkClassName: PropTypes.string,
        disabled: PropTypes.bool,
        initialValue: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        disabled: false,
        initialValue: false,
        onChange: () => {}
    };

    onClick = async () => {
        let {disabled, onChange} = this.props;
        let {checked, externalControl} = this.state;
        if (disabled) return;
        let newValue = !checked;
        await onChange(newValue);
        if (!externalControl) this.setState({checked: newValue});
    };

    constructor(props) {
        super(props);
        let externalControl = props.hasOwnProperty('checked');
        this.state = {
            checked: externalControl ? props.checked : props.initialValue,
            externalControl
        }
    }

    componentDidUpdate = prevProps => {
        let {checked} = this.props;
        if (checked !== prevProps.checked) {
            this.setState({checked});
        }
    };

    render() {
        let {className, checkmarkClassName, disabled} = this.props;
        let {checked} = this.state;
        let checkmark = checked && (
            <div className={classnames('checkbox-checkmark', checkmarkClassName)} onClick={event => event.preventDefault()} />
        );
        return (
            <button className={classnames('checkbox', className)} disabled={disabled} onClick={this.onClick} type="button">
                {checkmark}
            </button>
        );
    }
}
