import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu}) => {
    let {data: {hubName}, generatedBy} = alert.event;
    let [deletor, setDeletor] = useState();
    useEffect(() => {
        loadUser(generatedBy, setDeletor);
    }, []);

    let message;
    if (deletor) {
        message = <span><b>{deletor.name.fullName}</b> deleted the tag hub <b>{hubName}</b></span>;
    }

    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            closeMenu={closeMenu}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}