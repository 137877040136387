import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import axios from 'axios';
import {Map} from 'immutable';

import {createUrl} from '../../../../util/formatters';
import Input from '../../../input/input';
import {saveUser as _saveUser} from '../../../../redux/users/users-actions';
import AlertPreferences from './alert-preferences/alert-preferences';
import AdminUtils from './admin-utils/admin-utils';

import './settings-modal.css';

export class SettingsModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        saveUser: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        user: PropTypes.instanceOf(Map).isRequired
    };

    static FIELD_NAMES = {
        FIRST_NAME: 'first',
        LAST_NAME: 'last',
        EMAIL: 'email',
        PASSWORD: 'password',
        PREFERENCES: 'preferences',
        ADMIN_UTILS: 'adminUtils'
    };

    static FIELD_LABELS = {
        first: 'First Name',
        last: 'Last Name',
        email: 'Email Address',
        password: 'Password',
        preferences: 'Notifications / Email',
        adminUtils: 'Admin Utilities'
    };

    FieldInfo = ({fieldName}) => {
        let fieldLabel = SettingsModal.FIELD_LABELS[fieldName];
        let fieldValue = this.props[fieldName];
        return (
            <div className="settingsModal-fieldInfo" onClick={this.setCurrentlyEditing(fieldName)}>
                <div className="searchModal-fieldInfoLeft">
                    <div className="settingsModal-fieldLabel">{fieldLabel}</div>
                    <div className="settingsModal-fieldValue">{fieldValue}</div>
                </div>
                <div className="settingsModal-editButton">Edit</div>
            </div>
        );
    };

    EditField = ({fieldName}) => {
        let fieldLabel = SettingsModal.FIELD_LABELS[fieldName];
        useEffect(() => {
            document.getElementById(fieldName).focus();
        }, []);
        return (
            <div className="settingsModal-editField">
                <div className="settingsModal-editFieldInner">
                    <label htmlFor={fieldName}>{fieldLabel}</label>
                    <Field
                        name={fieldName}
                        id={fieldName}
                        component={Input}
                        type="text"
                        className="settingsModal-input"
                        fieldClassName="settingsModal-inputField"
                    />
                </div>
                <div className="settingsModal-saveButtonContainer">
                    <button
                        type="button"
                        onClick={this.setCurrentlyEditing(null)}
                        className="settingsModal-cancelButton"
                    >
                        Cancel
                    </button>
                    <button type="submit" className="settingsModal-submitButton">
                        Save
                    </button>
                </div>
            </div>
        );
    };

    FirstName = () => {
        let {FieldInfo, EditField} = this;
        let FIRST_NAME = SettingsModal.FIELD_NAMES.FIRST_NAME;
        if (this.state.currentlyEditing === FIRST_NAME) {
            return <EditField fieldName={FIRST_NAME} />;
        } else {
            return <FieldInfo fieldName={FIRST_NAME} />;
        }
    };

    LastName = () => {
        let {FieldInfo, EditField} = this;
        let LAST_NAME = SettingsModal.FIELD_NAMES.LAST_NAME;
        if (this.state.currentlyEditing === LAST_NAME) {
            return <EditField fieldName={LAST_NAME} />;
        } else {
            return <FieldInfo fieldName={LAST_NAME} />;
        }
    };

    Email = () => {
        let {FieldInfo, EditField} = this;
        let EMAIL = SettingsModal.FIELD_NAMES.EMAIL;
        if (this.state.currentlyEditing === EMAIL) {
            return <EditField fieldName={EMAIL} />;
        } else {
            return <FieldInfo fieldName={EMAIL} />;
        }
    };

    Password = () => {
        let {FieldInfo, EditField} = this;
        let PASSWORD = SettingsModal.FIELD_NAMES.PASSWORD;
        if (this.state.currentlyEditing === PASSWORD) {
            return <EditField fieldName={PASSWORD} />;
        } else {
            return <FieldInfo fieldName={PASSWORD} />;
        }
    };

    Preferences = () => {
        let {FieldInfo} = this;
        let {saveUser, user} = this.props;
        let {PREFERENCES} = SettingsModal.FIELD_NAMES;
        if (this.state.currentlyEditing === PREFERENCES) {
            return <AlertPreferences saveUser={saveUser} user={user} setCurrentlyEditing={this.setCurrentlyEditing} />;
        } else {
            return <FieldInfo fieldName={PREFERENCES} />;
        }
    };

    AdminUtilsRow = () => {
        let {FieldInfo} = this;
        let {user} = this.props;
        if (!user.get('isAdmin')) return null;
        let {ADMIN_UTILS} = SettingsModal.FIELD_NAMES;
        return <FieldInfo fieldName={ADMIN_UTILS} />;
    };

    onSubmit = async formValues => {
        let {saveUser, reset} = this.props;
        let fieldName = this.state.currentlyEditing;
        let {FIRST_NAME, LAST_NAME} = SettingsModal.FIELD_NAMES;
        let data = {};
        if ([FIRST_NAME, LAST_NAME].includes(fieldName)) {
            data.name = {[fieldName]: formValues[fieldName]}
        } else {
            data[fieldName] = formValues[fieldName];
        }
        let updatedUser = (await axios.put(createUrl('/users/current'), data)).data;
        saveUser(updatedUser);
        this.setCurrentlyEditing(null)();
        reset();
    };

    setCurrentlyEditing = fieldName => () => {
        this.setState({currentlyEditing: fieldName});
        this.props.reset();
    };

    state = {
        currentlyEditing: null
    };

    render() {
        let {closeModal, handleSubmit} = this.props;
        let {FirstName, LastName, Email, Password, Preferences, AdminUtilsRow} = this;
        if (this.state.currentlyEditing === SettingsModal.FIELD_NAMES.ADMIN_UTILS) {
            return <AdminUtils setCurrentlyEditing={this.setCurrentlyEditing} />;
        }
        return (
            <form onSubmit={handleSubmit(this.onSubmit)} className="settingsModal">
                <FirstName />
                <LastName />
                <Email />
                <Password />
                <Preferences />
                <AdminUtilsRow />
                <div className="settingsModal-buttonContainer">
                    <button
                        type="button"
                        className="settingsModal-closeButton"
                        onClick={closeModal}
                    >
                        Close
                    </button>
                </div>
            </form>
        );
    }
}
let mapStateToProps = state => {
    let {FIELD_NAMES} = SettingsModal;
    let user = state.users.get('user');
    let first = user.getIn(['name', 'first']);
    let last = user.getIn(['name', 'last']);
    let email = user.get('email');
    return {
        initialValues: {
            [FIELD_NAMES.FIRST_NAME]: first,
            [FIELD_NAMES.LAST_NAME]: last,
            [FIELD_NAMES.EMAIL]: email
        },
        first,
        last,
        email,
        user
    }
};

let reduxFormConfig = {
    form: 'updateSettings',
    enableReinitialize: true
};

let ReduxForm = reduxForm(reduxFormConfig)(SettingsModal);
export default connect(mapStateToProps, {saveUser: _saveUser})(ReduxForm);
