import React from 'react';
import {reduxForm, Field, SubmissionError} from 'redux-form';
import PropTypes from 'prop-types';
import axios from 'axios';

import {createUrl} from '../../../../util/formatters';
import Input from '../../../input/input';
import Checkbox from '../../../checkbox/checkbox';

import './create-hub-modal.css';

export class CreateHubModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        submitting: PropTypes.bool.isRequired
    };

    onSubmit = async formValues => {
        let {closeModal, history} = this.props;
        let {defaultRoleToAdmin} = this.state;
        let data = {
            ...formValues,
            defaultRole: defaultRoleToAdmin ? 'admin' : 'member'
        };
        try {
            let hub = (await axios.post(createUrl('/hubs'), data)).data;
            closeModal();
            history.push('/gift-tags/hubs/' + hub.id);
        } catch (error) {
            console.error(error);
            let errorResponse = (error.response && error.response.data) || {_error: 'Something went wrong...'};
            throw new SubmissionError(errorResponse);
        }
    };

    setDefaultRoleToAdmin = defaultRoleToAdmin => this.setState({defaultRoleToAdmin});

    state = {
        defaultRoleToAdmin: false
    };

    render() {
        let {handleSubmit, closeModal, submitting} = this.props;
        return (
            <form className="createHubModal" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="createHubModal-mainContent">
                    <label htmlFor="createHubModal-label1">Name</label>
                    <Field
                        name="name"
                        component={Input}
                        type="text"
                        id="createHubModal-label1"
                        className="createHubModal-label"
                        autoComplete="off"
                    />
                    <label htmlFor="createHubModal-description1">Description</label>
                    <Field
                        name="description"
                        component="textarea"
                        id="createHubModal-description1"
                        className="createHubModal-description"
                    />
                    <label className="createHubModal-defaultRole">
                        <Checkbox
                            onChange={this.setDefaultRoleToAdmin}
                            className="createHubModal-checkbox"
                            checkmarkClassName="createHubModal-checkmark"
                        />
                        <div className="createHubModal-defaultRoleText">
                            <div className="createHubModal-defaultRoleMainText">Automatically Promote New Members to Admin</div>
                            <div className="createHubModal-defaultRoleSubText">Admins can edit the hub's members, name, and description</div>
                        </div>
                    </label>
                </div>
                <div className="createHubModal-buttonContainer">
                    <button
                        type="button"
                        className="createHubModal-cancelButton"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="createHubModal-submitButton"
                    >
                        Create
                    </button>
                </div>
            </form>
        );
    }
}
let reduxFormConfig = {
    form: 'createHub',
    validate: values => {
        let errors = {};
        if (!values.name) errors.name = 'Required';
        return errors;
    }
};

export default reduxForm(reduxFormConfig)(CreateHubModal);
