import React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import axios from 'axios';
import Toggle from 'react-toggle';

import {createUrl} from '../../../../../util/formatters';

import './alert-preferences.css';

export default class AlertPreferences extends React.Component {
    static propTypes = {
        saveUser: PropTypes.func.isRequired,
        setCurrentlyEditing: PropTypes.func.isRequired,
        user: PropTypes.instanceOf(Map).isRequired
    };

    static followerEventTypes = [
        'giftAdded',
        'giftEdited',
        'giftDeleted',
        'giftClaimed',
        'giftUnclaimed',
        'giftCompleted',
        'giftRestored'
    ];

    static urgentEventTypes = [
        'giftEditedWasClaimed',
        'giftDeletedWasClaimed',
    ];

    static userPreferenceEventLabels = {
        giftAdded: 'New Gift Ideas',
        giftEdited: 'Gift Idea Edited',
        giftEditedWasClaimed: 'Claimed Gift Idea Edited',
        giftDeleted: 'Gift Idea Deleted',
        giftDeletedWasClaimed: 'Claimed Gift Idea Deleted',
        giftClaimed: 'Gift Idea Claimed',
        giftUnclaimed: 'Gift Idea Claim Removed',
        giftCompleted: 'Gift Idea Marked Received',
        giftRestored: 'Gift Idea Un-Marked Received'
    };

    Row = ({label, subtext, leftChecked, leftDisabled, leftOnChange, rightChecked, rightDisabled, rightOnChange}) => (
        <div className="alertPreferences-row alertPreferences-rowWrapper">
            <div className="alertPreferences-rowLabel">
                {label}
                {subtext && <div className="alertPreferences-rowSubtext">{subtext}</div>}
            </div>
            <div className="alertPreferences-rowValue">
                {(leftOnChange || leftDisabled) && <Toggle checked={leftChecked} onChange={leftOnChange} disabled={leftDisabled} />}
            </div>
            <div className="alertPreferences-rowValue">
                {(rightOnChange || rightDisabled) && <Toggle checked={rightChecked} onChange={rightOnChange} disabled={rightDisabled} />}
            </div>
        </div>
    );

    updateGeneral = preferenceType => async event => {
        let {saveUser, user} = this.props;
        let {checked} = event.target;
        let updatedUser = user.setIn(['preferences', 'general', preferenceType], checked);
        saveUser(updatedUser);
        await axios.put(createUrl('/users/current/preferences/general'), {[preferenceType]: checked});
    };

    updateEvent = (eventType, channel) => async event => {
        let {saveUser, user} = this.props;
        let {checked} = event.target;
        let updatedUser = user.setIn(['preferences', 'events', eventType, channel], checked);
        saveUser(updatedUser);
        await axios.put(createUrl(`/users/current/preferences/events/${eventType}`), {[channel]: checked});
    };

    render() {
        let {Row, updateGeneral, updateEvent} = this;
        let {user, setCurrentlyEditing} = this.props;
        let {general, events} = user.get('preferences').toObject();
        let {enableDigestEmails, enableDigestWeb, enableHubRequests, liveEmailUpdates} = general.toObject();
        let followerEventRows = AlertPreferences.followerEventTypes.map((eventType) => {
            let leftChecked = enableDigestWeb && events.getIn([eventType, 'web']);
            let leftDisabled = !enableDigestWeb;
            let rightChecked = enableDigestEmails && events.getIn([eventType, 'email']);
            let rightDisabled = !enableDigestEmails;
            return (
                <Row
                    label={AlertPreferences.userPreferenceEventLabels[eventType]}
                    leftChecked={leftChecked}
                    leftOnChange={updateEvent(eventType, 'web')}
                    leftDisabled={leftDisabled}
                    rightChecked={rightChecked}
                    rightOnChange={updateEvent(eventType, 'email')}
                    rightDisabled={rightDisabled}
                    key={eventType}
                />
            )
        });
        let urgentEventRows = AlertPreferences.urgentEventTypes.map((eventType) => (
            <Row
                label={AlertPreferences.userPreferenceEventLabels[eventType]}
                leftChecked={events.getIn([eventType, 'web'])}
                leftOnChange={updateEvent(eventType, 'web')}
                rightChecked={events.getIn([eventType, 'email'])}
                rightOnChange={updateEvent(eventType, 'email')}
                key={eventType}
            />
        ));
        return (
            <div className="alertPreferences">
                <div className="alertPreferences-mainHeader">
                    <div className="alertPreferences-title">Notifications / Email</div>
                    <button
                        type="button"
                        onClick={setCurrentlyEditing(null)}
                        className="alertPreferences-doneButton"
                    >
                        Done
                    </button>
                </div>
                <div className="alertPreferences-preferencesGroup">
                    <div className="alertPreferences-rowWrapper alertPreferences-header">
                        <div className="alertPreferences-rowLabel">General Settings</div>
                    </div>
                    <Row
                        label="Enable Live Email Updates"
                        subtext="Emails from following someone will still be limited to once a day"
                        rightChecked={liveEmailUpdates}
                        rightOnChange={updateGeneral('liveEmailUpdates')}
                    />
                </div>
                <div className="alertPreferences-preferencesGroup">
                    <div className="alertPreferences-rowWrapper alertPreferences-header">
                        <div className="alertPreferences-rowLabel">Category</div>
                        <div className="alertPreferences-rowValue">Notifications</div>
                        <div className="alertPreferences-rowValue">Emails</div>
                    </div>
                    <Row
                        label="Hub Requests"
                        leftChecked={true}
                        leftDisabled={true}
                        rightChecked={enableHubRequests}
                        rightOnChange={updateGeneral('enableHubRequests')}
                    />
                    {urgentEventRows}
                </div>
                <div className="alertPreferences-preferencesGroup">
                    <div className="alertPreferences-rowWrapper alertPreferences-header">
                        <div className="alertPreferences-rowLabel">When Following Someone</div>
                        <div className="alertPreferences-rowValue alertPreferences-checkboxHeader">
                            <input
                                type="checkbox"
                                id="enableDigestWeb"
                                onChange={updateGeneral('enableDigestWeb')}
                                checked={enableDigestWeb}
                                className="alertPreferences-clickable"
                            />
                            <label
                                htmlFor="enableDigestWeb"
                                className="alertPreferences-clickable"
                            >
                                Notifications
                            </label>
                        </div>
                        <div className="alertPreferences-rowValue alertPreferences-checkboxHeader">
                            <input
                                type="checkbox"
                                id="enableDigestEmails"
                                onChange={updateGeneral('enableDigestEmails')}
                                checked={enableDigestEmails}
                                className="alertPreferences-clickable"
                            />
                            <label
                                htmlFor="enableDigestEmails"
                                className="alertPreferences-clickable"
                            >
                                Emails
                            </label>
                        </div>
                    </div>
                    <div className="alertPreferences-note">Note: emails for these alerts are sent out once a day at 12pm Central Time.</div>
                    {followerEventRows}
                </div>
            </div>
        );
    }
}
