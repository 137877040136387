import React from 'react';
import classnames from 'classnames'

import './content-card.css';

export default function ContentCard({title, className, children}) {
    return (
        <div className={classnames('contentCard', className)}>
            {title && <h3>{title}</h3>}
            {children}
        </div>
    )
}
