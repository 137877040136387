import React from 'react';

export default function ChefHat2({className}) {
    let color1 = '#2F88FF';
    return (
        <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect fill={color1} height="8" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" width="24" x="12" y="34"/>
            <path d="M29 34V20" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
            <path d="M22 34V26" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
            <path d="M12 25V34H36V25C36 25 41 22 41 17C41 12 37 10 32 10C32 8 29 4 24 4C19 4 16 8 16 10C12 10 7 12 7 17C7 22 12 25 12 25Z" stroke="black"
                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
        </svg>
    );
}
