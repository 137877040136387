import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadHub} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push}) => {
    let {topic, topicType} = alert.event.data;

    let [hub, setHub] = useState();
    useEffect(() => {
        if (topicType === 'Hub') loadHub(topic, setHub);
    }, []);
    let message, link;
    if (topicType === 'Hub' && hub) {
        link = `/gift-tags/hubs/${hub.id}/messages`;
        message = <span><b>New Message(s)</b> in <b>{hub.name}</b></span>
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={link}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}