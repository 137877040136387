import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import {createUrl} from "../../../../util/formatters";

import './deletion-modal.css';

export class DeletionModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        completeGift: PropTypes.func.isRequired,
        giftId: PropTypes.string.isRequired,
        giftUrl: PropTypes.string.isRequired,
        isComplete: PropTypes.bool.isRequired,
        reloadGifts: PropTypes.func.isRequired
    };

    receivedGift = async () => {
        let {closeModal, completeGift} = this.props;
        await completeGift();
        closeModal();
    };

    deleteGift = async values => {
        let {closeModal, giftUrl, reloadGifts} = this.props;
        await axios.delete(createUrl(giftUrl), {data: values});
        await reloadGifts();
        closeModal();
    };

    render() {
        let {closeModal, handleSubmit, giftLabel, isComplete} = this.props;
        let buttons = [
            <button className="deletionModal-cancelButton" type="button" key="cancel" onClick={closeModal}>Cancel</button>,
            <button className="deletionModal-confirmButton" type="submit" key="delete">Delete</button>,
        ];
        let markReceivedMessage;
        if (!isComplete) {
            markReceivedMessage = (
                <div className="deletionModal-markReceivedMessage">
                    <div className="deletionModal-alertIcon" />
                    <span>
                        <b>Did you receive this gift?</b> If so, we recommend checking the idea off of your list using the checkbox by the idea or the "Received" button below.
                    </span>
                </div>
            );
            buttons.push(
                <button className="deletionModal-cancelButton" type="button" key="complete" onClick={this.receivedGift}>Received</button>
            );
        }
        return (
            <form onSubmit={handleSubmit(this.deleteGift)} className="deletionModal">
                <div className="deletionModal-targetedGiftMessage">
                    {markReceivedMessage}
                    If you're sure you'd like to delete <b>"{giftLabel}"</b>, you can leave a message in case someone already claimed it.
                </div>
                <label htmlFor="deletionModal-deletionMemo1" className="deletionModal-deletionMemoLabel">
                    Message to Potential Gift Giver:
                </label>
                <Field
                    name="deletionMemo"
                    component="input"
                    className="deletionModal-deletionMemo"
                    id="deletionModal-deletionMemo1"
                />
                <div className="deletionModal-buttonContainer">{buttons}</div>
            </form>
        );
    }
}

let mapStateToProps = (state, props) => {
    let {isComplete} = props;
    let firstName = state.users.getIn(['user', 'name', 'first']);
    return {
        initialValues: {
            deletionMemo: isComplete ? `${firstName} has removed this gift idea` : `${firstName} no longer wants this idea`
        }
    };
};

let reduxFormConfig = {
    form: 'deletionModal'
};

let ReduxForm = reduxForm(reduxFormConfig)(DeletionModal);
export default connect(mapStateToProps)(ReduxForm);
