import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import 'promise-polyfill/src/polyfill';
import 'regenerator-runtime/runtime';
import {DndProvider, Preview} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';

import App from './app';
import reportWebVitals from './reportWebVitals';
import getStore from './redux/create-store';
import {DRAGGABLE_ITEMS} from './util/constants';

import './index.css';

export let store = getStore();

let generatePreview = ({itemType, item, style}) => {
    let contents;
    if (itemType === DRAGGABLE_ITEMS.INSTRUCTION) {
        let {description = '', index} = item;
        let descriptionPreview = description.slice(0, 150);
        let dots = descriptionPreview.length < description.length && '...';
        contents = <>{index + 1}. {descriptionPreview}{dots}</>;
    } else if (itemType === DRAGGABLE_ITEMS.INGREDIENT) {
        let {description = '', quantity} = item;
        let descriptionPreview = description.slice(0, 150);
        let dots = descriptionPreview.length < description.length && '...';
        contents = <>{quantity} {descriptionPreview}{dots}</>;
    } else if (itemType === DRAGGABLE_ITEMS.TIME_ESTIMATE) {
        let {description = '', duration = 0, units} = item;
        let descriptionPreview = description.slice(0, 150);
        let dots = descriptionPreview.length < description.length && '...';
        contents = <>{descriptionPreview}{dots} {duration} {units}</>;
    } else return null;
    return <div style={style} className="index-reactDnDPreview">{contents}</div>;
};

render(
    <Provider store={store}>
        <DndProvider options={HTML5toTouch}>
            <App />
            <Preview>{generatePreview}</Preview>
        </DndProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
