import React from 'react';

export default function Shrimp({className}) {
    let color1 = '#d9387c';
    let color2 = '#dd5891';
    let color3 = '#94d8e5';
    let color4 = '#f7f8fa';
    return (
        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="Prawn">
                <path fill={color1}
                      d="M27.34,27.89a1,1,0,0,0-.71,1.23h0L27,30.45a3.11,3.11,0,0,0,3.79,2.19,1,1,0,0,0,.7-1.23,1,1,0,0,0-1.22-.7,1.09,1.09,0,0,1-1.34-.78l-.36-1.33A1,1,0,0,0,27.34,27.89Z"
                      id="path14274"/>
                <path fill={color1}
                      d="M32.05,26.77a1,1,0,0,0-.94,1.06l.09,1.38a3.11,3.11,0,0,0,3.26,2.91,1,1,0,0,0,.94-1.06h0a1,1,0,0,0-1-.94,1.09,1.09,0,0,1-1.16-1l-.08-1.38a1,1,0,0,0-1.06-.94Z"
                      id="path14767"/>
                <path fill={color1}
                      d="M37.37,25.56a1,1,0,0,0-1.18.78l-.28,1.36a3.1,3.1,0,0,0,2.41,3.65,1,1,0,0,0,.4-2h0a1.07,1.07,0,0,1-.85-1.29l.28-1.35a1,1,0,0,0-.78-1.19Z"
                      id="path15100"/>
                <path fill={color1}
                      d="M41.54,26a1,1,0,0,0-1.19.77h0l-.3,1.35a3.12,3.12,0,0,0,2.38,3.68,1,1,0,0,0,.42-2h0a1.07,1.07,0,0,1-.84-1.3h0l.29-1.35A1,1,0,0,0,41.54,26Z"
                      id="path15102"/>
                <path fill={color2}
                      d="M25.91,15.67a3.9,3.9,0,0,0-2.81,3l-5.62,1.51c-1.06.28-2.46,1.72-2.15,2.77,1.49,5.1,8.58,7,13.87,5.61l6.4-1.71a10.09,10.09,0,0,1,5.22,19.49L39,46.81a3.37,3.37,0,0,0-4-2.06l-1.56.42a1.57,1.57,0,0,0-1.12,1.93l.39,1.46a3.48,3.48,0,0,0,1.65,2.14A3.47,3.47,0,0,0,34,53.37l.39,1.46A1.59,1.59,0,0,0,36.32,56l1.57-.42a3.38,3.38,0,0,0,2.46-3.8l1.79-.47a18.2,18.2,0,0,0-9.42-35.17l-2.87.77A3.88,3.88,0,0,0,25.91,15.67Z"
                      id="path83386-4"/>
                <path fill={color3} d="M24.44,37a3.11,3.11,0,1,1-3.11-3.1A3.11,3.11,0,0,1,24.44,37Z" id="path71055-1-6-7-3-9"/>
                <path fill={color3} d="M18.43,48.48a5,5,0,1,1-5-5A5,5,0,0,1,18.43,48.48Z" id="path23378-5"/>
                <path fill={color1}
                      d="M36.09,14.52a1,1,0,0,0-.71.28,1,1,0,0,0,0,1.42A8.38,8.38,0,0,1,37.51,20h0a8.39,8.39,0,0,1-.69,6.1,1,1,0,0,0,1.77.94h0a10.37,10.37,0,0,0,.85-7.55h0a10.47,10.47,0,0,0-2.65-4.62A1,1,0,0,0,36.09,14.52Z"
                      id="path17716"/>
                <path fill={color1}
                      d="M49.77,19.46A1,1,0,0,0,49,20.64a8.38,8.38,0,0,1-3.57,8.7,1,1,0,0,0-.28,1.38h0a1,1,0,0,0,1.39.28A10.4,10.4,0,0,0,51,20.23,1,1,0,0,0,49.77,19.46Z"
                      id="path18374"/>
                <path fill={color1}
                      d="M55.74,35.3a1,1,0,0,0-.76.09,8.3,8.3,0,0,1-6.43.77,1,1,0,0,0-1.24.69h0A1,1,0,0,0,48,38.09h0a10.42,10.42,0,0,0,5.54,0,10.29,10.29,0,0,0,2.43-1,1,1,0,0,0-.23-1.83Z"
                      id="path18366"/>
                <path fill={color1}
                      d="M43.49,44.17a1,1,0,0,0-.7.31,1,1,0,0,0,0,1.41A10.32,10.32,0,0,0,49,48.69a1,1,0,1,0,.2-2,8.36,8.36,0,0,1-5-2.26A1,1,0,0,0,43.49,44.17Z"
                      id="path18358"/>
                <path fill={color4} d="M26.57,18a1,1,0,0,0-.71,1.22l.36,1.35a1,1,0,0,0,1.23.71,1,1,0,0,0,.7-1.22l-.36-1.35A1,1,0,0,0,26.57,18Z"
                      id="path18662"/>
                <path fill={color1} d="M36.65,49.05l-2.55.68A1,1,0,0,0,33.39,51a1,1,0,0,0,1.23.7h0L37.17,51a1,1,0,1,0-.52-1.93Z" id="path22457"/>
                <path fill={color2}
                      d="M27.55,7,19.28,9.25a7.39,7.39,0,0,0-5.21,9l1.21,4.51a1,1,0,0,0,1.22.71h0a1,1,0,0,0,.71-1.22L16,17.77a5.37,5.37,0,0,1,3.8-6.59L28.07,9a1,1,0,0,0,.71-1.23h0A1,1,0,0,0,27.55,7Z"
                      id="path29931"/>
                <path fill={color2}
                      d="M31.84,9.08,20.88,12a4.62,4.62,0,0,0-3.24,5.63L18.26,20a1,1,0,0,0,1.23.71,1,1,0,0,0,.7-1.23l-.62-2.32A2.57,2.57,0,0,1,21.4,14l11-2.94a1,1,0,0,0,.71-1.23A1,1,0,0,0,31.84,9.08Z"
                      id="path31680"/>
            </g>
        </svg>
    );
}
