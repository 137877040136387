import {actionsNamespace} from '../../util/constants';

let prefix = actionsNamespace + 'interstitials/';

export let actionTypes = {
    start: prefix + 'start',
    stop: prefix + 'stop',
};

export let startInterstitial = interstitialKey => ({
    type: actionTypes.start,
    interstitialKey
});

export let stopInterstitial = interstitialKey => ({
    type: actionTypes.stop,
    interstitialKey
});
