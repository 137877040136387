import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import classnames from 'classnames';

import {createUrl} from '../../../../../util/formatters';
import Tooltip from '../../../../../components/tooltip/tooltip';
import Interstitial from '../../../../../components/interstitial/interstitial';
import {INTERSTITIAL_KEYS} from '../../../../../util/constants';

import './lists-view.css';

export default class ListsView extends React.Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        hub: PropTypes.shape({
            directory: PropTypes.array.isRequired
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                hubId: PropTypes.string.isRequired
            }).isRequired,
            url: PropTypes.string.isRequired
        }).isRequired,
        setListsUrl: PropTypes.func.isRequired
    };

    StatusBar = ({giftsClaimedByUser, availableGifts, giftsClaimedByOthers, giftsAcceptingContributors, ownedBySelf, summaryBar, className}) => {
        let totalGifts = giftsClaimedByUser + availableGifts + giftsClaimedByOthers;
        if (ownedBySelf) {
            return (
                <div className="listsView-statusBarPlaceholder">
                    Gift Tags from your list are hidden.
                </div>
            );
        } else if (!totalGifts) {
            return (
                <div className="listsView-statusBarPlaceholder">
                    No gift ideas yet.
                </div>
            );
        }
        let greenWidth = giftsClaimedByUser / totalGifts * 100;
        let yellowWidth = giftsAcceptingContributors / totalGifts * 100;
        let blueWidth = availableGifts / totalGifts * 100;
        let grayWidth = giftsClaimedByOthers / totalGifts * 100;
        return (
            <div className={classnames('listsView-statusBarOuter', className)}>
                <div className="listsView-statusBar">
                    <div className="listsView-statusBarLegend">
                        <Tooltip
                            className="listsView-statusBarLegendGroup"
                            tooltipClassName="listsView-statusBarTooltip"
                            tooltipContent="Tags claimed by you"
                        >
                            <div className="listsView-statusBarGreenIcon" />
                            {giftsClaimedByUser}
                        </Tooltip>
                        <Tooltip
                            className="listsView-statusBarLegendGroup"
                            tooltipClassName="listsView-statusBarTooltip"
                            tooltipContent="Tags you've been invited to claim with others"
                        >
                            <div className="listsView-statusBarYellowIcon" />
                            {giftsAcceptingContributors}
                        </Tooltip>
                        <Tooltip
                            className="listsView-statusBarLegendGroup"
                            tooltipClassName="listsView-statusBarTooltip"
                            tooltipContent="Tags not claimed yet"
                        >
                            <div className="listsView-statusBarBlueIcon" />
                            {availableGifts}
                        </Tooltip>
                        <Tooltip
                            className="listsView-statusBarLegendGroup"
                            tooltipClassName="listsView-statusBarTooltip"
                            tooltipContent="Tags claimed by others"
                        >
                            <div className="listsView-statusBarGrayIcon" />
                            {giftsClaimedByOthers}
                        </Tooltip>
                    </div>
                    <div className="listsView-statusBarWrapper">
                        <div style={{width: greenWidth.toString() + '%'}} className="listsView-statusBarGreen" />
                        <div style={{width: yellowWidth.toString() + '%'}} className="listsView-statusBarYellow" />
                        <div style={{width: blueWidth.toString() + '%'}} className="listsView-statusBarBlue" />
                        <div style={{width: grayWidth.toString() + '%'}} className="listsView-statusBarGray" />
                    </div>
                </div>
                {summaryBar && <div className="listsView-summarySubTitle">Note: Gift Tags from your lists are excluded.</div>}
            </div>
        );
    };

    List = ({listId}) => {
        let {StatusBar} = this;
        let {listsById} = this.state;
        let {match} = this.props;
        let {self: list, giftsClaimedByUser, availableGifts, giftsClaimedByOthers, giftsAcceptingContributors, totalGifts, ownedBySelf} = listsById[listId];
        let {name, defaultList} = list;
        let defaultListComponent = defaultList && <span className="listsView-defaultLabel">(Primary)</span>;
        return (
            <div className="listsView-list">
                <div className="listsView-listContent">
                    <span className="listsView-listHeader">
                        <div className="listsView-listHeaderLeft">
                            <span className="listsView-listName">{name}</span>
                            {defaultListComponent}
                        </div>
                        <span className="listsView-giftCount">
                            {totalGifts}&nbsp;Gift&nbsp;Ideas
                        </span>
                    </span>
                    <StatusBar
                        giftsClaimedByUser={giftsClaimedByUser}
                        availableGifts={availableGifts}
                        giftsClaimedByOthers={giftsClaimedByOthers}
                        giftsAcceptingContributors={giftsAcceptingContributors}
                        ownedBySelf={ownedBySelf}
                    />
                </div>
                <Link to={`${match.url}/${listId}`} className="listsView-listLink">View List</Link>
            </div>
        )
    };

    Member = ({memberInfo}) => {
        let {List} = this;
        let {listsById} = this.state;
        let {hub} = this.props;
        let {first, last} = memberInfo.member.name;
        const memberIsActiveUser = hub.memberDetails.member.id === memberInfo.member.id;
        let listComponents = memberInfo.memberLists.sort((listIdA, listIdB) => {
            let nameA = listsById[listIdA].self.name;
            let nameB = listsById[listIdB].self.name;
            return nameA.localeCompare(nameB);
        }).map(
            (listId, index) => <List listId={listId} key={index} />
        );
        if (!listComponents.length) {
            if (memberIsActiveUser) {
                listComponents = 'You have not shared any lists with this hub.';
            } else {
                listComponents = `No lists have been shared with this hub.`;
            }
        }
        return (
            <div className="listsView-member">
                <span className="listsView-memberName">
                    {first} {last} {memberIsActiveUser && '(You)'}
                </span>
                <div className="listsView-lists">
                    {listComponents}
                </div>
            </div>
        )
    };

    loadData = async () => {
        let {match, setListsUrl, hub} = this.props;
        let {hubId} = match.params;
        setListsUrl(match.url);
        let {availableGifts, giftsClaimedByUser, giftsClaimedByOthers, giftsAcceptingContributors, totalGifts, lists} = (await axios.get(createUrl(`/hubs/${hubId}/stats`))).data;
        let {listsById, members} = lists.reduce((aggregator, listStats) => {
            aggregator.listsById[listStats.self.id] = listStats;
            let {memberLists} = aggregator.members[listStats.self.ownedBy.id] || {};
            if (memberLists) {
                aggregator.members[listStats.self.ownedBy.id].memberLists = [...memberLists, listStats.self.id];
            } else {
                aggregator.members[listStats.self.ownedBy.id] = {
                    memberLists: [listStats.self.id],
                    member: listStats.self.ownedBy
                }
            }
            return aggregator;
        }, {listsById: {}, members: {}});
        hub.directory.forEach(record => {
            if (!members[record.member.id]) {
                members[record.member.id] = {
                    memberLists: [],
                    member: record.member
                };
            }
        });
        let memberLists = Object.values(members).sort((a, b) => {
            let nameA = a.member.name;
            let nameB = b.member.name;
            let firstNameComparison = nameA.first.localeCompare(nameB.first);
            if (firstNameComparison === 0) {
                return nameA.last.localeCompare(nameB.last);
            }
            return firstNameComparison;
        });
        this.setState({giftsClaimedByUser, availableGifts, giftsClaimedByOthers, giftsAcceptingContributors, totalGifts, listsById, memberLists});
    };

    state = {};

    componentDidMount = async () => {
        let {match, hub, history} = this.props;
        let {hubId} = match.params;
        if (!hub.hasJoined) {
            history.replace(`/gift-tags/hubs/${hubId}/join`);
            return;
        }
        await Interstitial.interstitialController(INTERSTITIAL_KEYS.HUB_LIST_VIEW, this.loadData());
    };

    render() {
        let {Member, StatusBar} = this;
        let {hub} = this.props;
        let {memberLists, listsById, giftsClaimedByUser, availableGifts, giftsClaimedByOthers, giftsAcceptingContributors, totalGifts} = this.state;
        let content;
        if (memberLists && hub.hasJoined) {
            let memberComponents = memberLists.map((memberInfo, index) => <Member memberInfo={memberInfo} key={index} />);
            if (!memberComponents.length) memberComponents = <div className="listsView-noListsMessage">No Lists Yet</div>;
            let totalHubs = hub.directory.length;
            let totalLists = Object.keys(listsById).length;
            content = (
                <>
                    <div className="listsView-summary">
                        <div className="listsView-summaryTitle">Summary</div>
                        <div className="listsView-summaryStats">
                            <span>{totalHubs} Member{totalHubs !== 1 && 's'}</span>
                            <span>{totalLists} List{totalLists !== 1 && 's'}</span>
                            <span>{totalGifts} Gift Ideas</span>
                        </div>
                        <StatusBar
                            giftsClaimedByUser={giftsClaimedByUser}
                            availableGifts={availableGifts}
                            giftsClaimedByOthers={giftsClaimedByOthers}
                            giftsAcceptingContributors={giftsAcceptingContributors}
                            summaryBar={true}
                        />
                    </div>
                    {memberComponents}
                </>
            )
        }
        return (
            <Interstitial className="listsView" interstitialKey={INTERSTITIAL_KEYS.HUB_LIST_VIEW} interstitialSize={Interstitial.INTERSTITIAL_SIZES.LARGE}>
                {content}
            </Interstitial>
        );
    }
}
