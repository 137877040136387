import React from 'react';
import classnames from 'classnames';
import {Redirect, Route, Switch} from "react-router-dom";

import GiftTags from './gift-tags/gift-tags';
import SousChef from './sous-chef/sous-chef';
import Modal from '../components/modal/modal';
import RenderRoute from './sous-chef/components/render-route/render-route';
import {BREAKPOINT_WIDTHS} from '../util/breakpoints';

import './routes.css';

export default class Routes extends React.Component {
    breakpoints = Object.keys(BREAKPOINT_WIDTHS)
        .sort((a, b) => BREAKPOINT_WIDTHS[b] - BREAKPOINT_WIDTHS[a]) // descending order by width
        .map(name => ({name, width: BREAKPOINT_WIDTHS[name]}));

    mediaQueries = [];

    onBreakpointChange = () => {
        let reversedMediaQueries = [...this.mediaQueries].reverse();
        let activeQuery = reversedMediaQueries.find(query => query.matches);
        if (activeQuery) {
            let activeBreakpoint;
            let classNames = this.breakpoints.reduce(
                (matchingClasses, breakpoint) => {
                    if (matchingClasses.length) {
                        return [breakpoint.name, ...matchingClasses];
                    } else if (breakpoint.name === activeQuery.name) {
                        activeBreakpoint = breakpoint.name;
                        return [breakpoint.name, ...matchingClasses];
                    } else {
                        return matchingClasses;
                    }
                },
                []
            );
            this.setState({classNames, activeBreakpoint});
        }
    };

    state = {
        classNames: []
    };

    componentDidMount() {
        this.breakpoints.forEach(({name, width}) => {
            const query = window.matchMedia(`(max-width: ${width}px)`);
            query.width = width;
            query.name = name;
            query.addListener(this.onBreakpointChange);
            this.mediaQueries.push(query);
        });
        this.onBreakpointChange();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        let {location} = this.props;
        let {classNames, activeBreakpoint} = this.state;
        return (
            <div className={classnames('routes', ...classNames)}>
                <Modal pathname={location.pathname} />
                <Switch>
                    <Route path="/gift-tags" component={GiftTags} />
                    <Route path="/sous-chef" render={RenderRoute(SousChef, {activeBreakpoint})} />
                    <Redirect to="/gift-tags" />
                </Switch>
            </div>
        );
    }
}
