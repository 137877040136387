import React from 'react';

import TitleBar from '../components/title-bar/title-bar';
import CookbooksWidget from './widgets/cookbooks-widget/cookbooks-widget';

import './dashboard.css';

export default function Dashboard({history, toggleSidebar}) {
    return (
        <div className="scDashboard">
            <TitleBar title="Dashboard" enableSearch={true} toggleSidebar={toggleSidebar} />
            <CookbooksWidget history={history} />
        </div>
    )
}
