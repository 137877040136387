import React, {useState} from 'react';
import classnames from 'classnames';
import moment from 'moment';

import allRecipeIcons from '../../../../images/react-icons/recipes/all-recipe-icons';
import ForkAndSpoon from '../../../../images/react-icons/recipes/fork-and-spoon';
import {createUrl} from '../../../../util/formatters';
import ContentCard from '../../components/content-card/content-card';
import Spinner from '../../components/spinner/spinner';

import './recipe-preview.css'

export let RecipePreviewFiller = () => <div className="recipePreview-filler" />;

export default function RecipePreview ({recipe, history, className, cookbookPendingDeletionAsOf}) {
    let {id: recipeId, photos, icon, name, tags, cookbook: cookbookId, pendingDeletionAsOf: recipePendingDeletionAsOf} = recipe;
    let formattedTags = tags && tags.join(', ');
    let displayPhoto = photos?.length;
    let [displayPhotoSpinner, setDisplayPhotoSpinner] = useState(displayPhoto);
    let coverPhoto = <ForkAndSpoon className="recipePreview-icon" />;
    if (displayPhoto) {
        coverPhoto = (
            <img
                className="recipePreview-image"
                src={createUrl(`/photos/${photos[0]}`)}
                onLoad={() => setDisplayPhotoSpinner(false)}
                alt={`Photo of ${name}`}
            />
        );
    } else if (icon) {
        let Icon = allRecipeIcons[icon] || ForkAndSpoon;
        coverPhoto = <Icon className="recipePreview-icon" />
    }
    let deletionWarning;
    let pendingDeletionAsOf = cookbookPendingDeletionAsOf || recipePendingDeletionAsOf;
    if (pendingDeletionAsOf) {
        if (cookbookPendingDeletionAsOf && recipePendingDeletionAsOf) {
            pendingDeletionAsOf = moment(cookbookPendingDeletionAsOf).isBefore(recipePendingDeletionAsOf) ? cookbookPendingDeletionAsOf : recipePendingDeletionAsOf;
        }
        let daysUntilDeletion = moment(pendingDeletionAsOf).add(30, 'days').diff(moment(), 'days');
        deletionWarning = <div className="recipePreview-deletionWarning">{Math.max(daysUntilDeletion, 0)} days until permanently deleted</div>;
    }
    return (
        <button
            onClick={() => history.push(`/sous-chef/cookbooks/${cookbookId}/recipes/${recipeId}`)}
            className={classnames('recipePreview', className)}
            type="button"
        >
            <ContentCard className="recipePreview-contentCard">
                <div className="recipePreview-coverPhoto">
                    {coverPhoto}
                    <Spinner className="recipePreview-spinner" active={displayPhotoSpinner} fullPage={false} spinnerColor="#aeaeae" />
                </div>
                <div className="recipePreview-text">
                    <div className="recipePreview-name">{name}</div>
                    <div className="recipePreview-tags">{formattedTags}</div>
                    {deletionWarning}
                </div>
            </ContentCard>
        </button>
    );
}
