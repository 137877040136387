import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './confirmation-modal.css';

export default class ConfirmationModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        destructive: PropTypes.bool,
        message: PropTypes.node,
        onConfirm: PropTypes.func.isRequired
    };

    confirm = () => {
        let {closeModal, onConfirm} = this.props;
        closeModal();
        onConfirm();
    };

    render() {
        let {message, closeModal, destructive} = this.props;
        return (
            <div className="confirmationModal">
                <div className="confirmationModal-message">{message}</div>
                <div className="confirmationModal-buttonContainer">
                    <button className="confirmationModal-cancelButton" type="button" onClick={closeModal}>Cancel</button>
                    <button
                        className={classnames(
                            'confirmationModal-confirmButton',
                            {'confirmationModal-confirmButton--destructive': destructive}
                        )}
                        type="button"
                        onClick={this.confirm}
                    >Confirm</button>
                </div>
            </div>
        );
    }
}
