import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm, SubmissionError} from 'redux-form'
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import queryString from 'qs';

import {createUrl} from '../../../../util/formatters';
import {required} from '../../../../util/validation';
import Input from '../../../../components/input/input';

import './reset-link.css';

export class ResetLink extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        submitting: PropTypes.bool
    };

    onSubmit = async ({email}) => {
        try {
            this.setState({confirmation: false});
            await axios.post(createUrl('/users/reset-link'), {email});
            this.setState({confirmation: true});
        } catch (error) {
            if (error.response) {
                let errorResponse = error.response.data || {_error: 'Something went wrong...'};
                console.error(errorResponse);
                throw new SubmissionError(errorResponse);
            } else {
                console.error(error);
                throw new SubmissionError({_error: 'Something went wrong...'});
            }
        }
    };

    state = {
        confirmation: false
    };

    render() {
        let {handleSubmit, submitting, error, location} = this.props;
        let {confirmation} = this.state;
        let errorMessage = error ? <div className="resetLink-error">{error}</div> : null;
        let confirmationMessage = confirmation ? <div className="resetLink-confirmation">Email Sent!</div> : null;
        let loginLink = '/gift-tags/login';
        let {redirectTo} = queryString.parse(location.search.slice(1));
        if (redirectTo) loginLink += `?redirectTo=${redirectTo}`;
        return (
            <form className="resetLink" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="resetLink-title">Please enter your email address, so we can send you a reset link.</div>
                {confirmationMessage}
                {errorMessage}
                <Field
                    name="email"
                    component={Input}
                    type="text"
                    placeholder="Email"
                    className="resetLink-input"
                    tabIndex="1"
                    validate={[required]}
                />
                <div className="resetLink-actions">
                    <div>
                        <span>Back to </span>
                        <NavLink to={loginLink} tabIndex="4">
                            Login
                        </NavLink>
                    </div>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="resetLink-submitButton"
                        tabIndex="3"
                    >
                        SUBMIT
                    </button>
                </div>
            </form>
        );
    }
}

let reduxConfig = {
    form: 'resetLink'
};

export default reduxForm(reduxConfig)(ResetLink);
