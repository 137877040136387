import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Map, fromJS, OrderedMap, List, Set} from 'immutable';
import moment from 'moment';
import Toggle from 'react-toggle';

import Gift from '../gift/gift';
import AddGift from '../add-gift/add-gift';
import {createUrl} from '../../../../../util/formatters';
import {openModal as _openModal} from '../../../../../redux/modal/modal-actions';
import {INTERSTITIAL_KEYS} from '../../../../../util/constants';
import Interstitial from '../../../../../components/interstitial/interstitial';

import './gifts.css';
import 'react-toggle/style.css';

export class Gifts extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string.isRequired,
        isCollaborator: PropTypes.bool.isRequired,
        list: PropTypes.instanceOf(Map).isRequired,
        lists: PropTypes.instanceOf(List).isRequired,
        openModal: PropTypes.func.isRequired
    };

    CollaboratorToggle = ({noGifts}) => {
        let {activeUserId, selectedUserId, ownersFirstName} = this.props;
        if (activeUserId === selectedUserId || noGifts) return null;
        return (
            <label className="gifts-collaboratorToggle">
                <Toggle checked={this.state.disableCollaboratorView} icons={false} onChange={this.toggleCollaboratorView} />
                <div className="gifts-collaboratorToggleText">Show {ownersFirstName}'s View</div>
            </label>
        );
    };

    CompletedGifts = ({completeGifts, listType}) => {
        let {showCompletedGifts, hasCompletedGifts} = this.state;
        if (!completeGifts.length && !hasCompletedGifts) {
            if (showCompletedGifts) this.setState({showCompletedGifts: false});
            return null
        }
        let completionType = listType === 'reference' ? 'Completed' : 'Received';
        let buttonLabel = showCompletedGifts ? `Hide ${completionType} Gifts` : `Show ${completionType} Gifts`;
        let completedGiftsNotice = listType !== 'reference' && (
            <div className="gifts-completedGiftsNotice">
                Received gifts are only visible to you and the person who claimed the tag.
            </div>
        );
        let expandableContent = showCompletedGifts ? (
            <div className="gifts-completedGiftsContent">
                {completedGiftsNotice}
                {completeGifts}
            </div>
        ) : null;
        return (
            <Interstitial className="gifts-completeGifts" interstitialKey={INTERSTITIAL_KEYS.COMPLETE_GIFTS}>
                <hr className="gifts-completedHr"/>
                <span className="gifts-completedButtonContainer">
                    <button className="gifts-completedButton" onClick={this.toggleCompletedGifts}>
                        {buttonLabel}
                    </button>
                </span>
                {expandableContent}
            </Interstitial>
        )
    };

    saveGift = targetGift => {
        this.setState(state => {
            let {gifts} = state;
            let gift = fromJS(targetGift);
            let index = gifts.findIndex(currentGift => currentGift.get('id') === gift.get('id'));
            let updatedGifts = index === -1 ? gifts.push(gift) : gifts.set(index, gift);
            let hasCompletedGifts = updatedGifts.some(this.isComplete);
            return {gifts: updatedGifts, hasCompletedGifts};
        });
    };

    removeGift = giftId => {
        this.setState(state => {
            let {gifts} = state;
            let index = gifts.findIndex(currentGift => currentGift.get('id') === giftId);
            let updatedGifts = gifts.delete(index);
            let hasCompletedGifts = updatedGifts.some(this.isComplete);
            return {gifts: updatedGifts, hasCompletedGifts};
        });
    };

    toggleCollaboratorView = event => {
        this.setState({disableCollaboratorView: event.target.checked});
    };

    toggleCompletedGifts = () => this.setState(previousState => ({
        showCompletedGifts: !previousState.showCompletedGifts
    }));

    sortGifts = (gifts, sortedBy) => {
        let {list} = this.props;
        switch (sortedBy) {
            case 'createdAt':
                return gifts.sort((giftA, giftB) => {
                    let timestampA = giftA.get('createdAt');
                    let timestampB = giftB.get('createdAt');
                    return moment(timestampB).diff(moment(timestampA));
                });
            case 'completedAt':
                return gifts.sort((giftA, giftB) => {
                    let timestampLocation = list.get('type') === 'reference'
                        ? ['claimDetails', 'completedAt']
                        : ['completedAt'];
                    let timestampA = giftA.getIn(timestampLocation);
                    let timestampB = giftB.getIn(timestampLocation);
                    return moment(timestampB).diff(moment(timestampA));
                });
            case 'claimedAt':
                return gifts.sort((giftA, giftB) => {
                    let timestampA = giftA.getIn(['claimDetails', 'claimedAt']);
                    let timestampB = giftB.getIn(['claimDetails', 'claimedAt']);
                    return moment(timestampB).diff(moment(timestampA));
                });
            case 'owner':
                return gifts.sort((giftA, giftB) => {
                    let lastNameA = giftA.getIn(['ownedBy', 'name', 'last']);
                    let lastNameB = giftB.getIn(['ownedBy', 'name', 'last']);
                    let result = lastNameA.localeCompare(lastNameB);
                    if (result === 0) {
                        let firstNameA = giftA.getIn(['ownedBy', 'name', 'first']);
                        let firstNameB = giftB.getIn(['ownedBy', 'name', 'first']);
                        result = firstNameA.localeCompare(firstNameB);
                    }
                    return result;
                });
            case 'label':
                return gifts.sort((giftA, giftB) => {
                    let labelA = giftA.get('label');
                    let labelB = giftB.get('label');
                    return labelA.localeCompare(labelB);
                });
            case 'price':
                return gifts.sort((giftA, giftB) => {
                    let priceA = giftA.get('price', 0);
                    let priceB = giftB.get('price', 0);
                    return priceB - priceA;
                });
            case 'priority':
                return gifts.sort((giftA, giftB) => {
                    let priorityA = giftA.get('priority', 0);
                    let priorityB = giftB.get('priority', 0);
                    return priorityA - priorityB;
                });
            default: return gifts;
        }
    };

    getDisplayableGifts = gifts => {
        let {list, isCollaborator, activeUserId, openModal} = this.props;
        let {referenceListId, ownedOriginLists, claimedGiftOriginListsById} = this.state;
        let listType = list.get('type');
        let sortedBy = list.get('sortedBy');
        let isReference = listType === 'reference';
        return gifts.map((gift, giftId) => {
            let primaryClaimer = gift.getIn(['claimDetails', 'claimedBy', 'id']);
            let acceptedContributors = gift
                .get('contributors', new List())
                .filter(contributorDetails => contributorDetails.get('status') === 'accepted')
                .map(contributorDetails => contributorDetails.getIn(['contributor', 'id']));
            let claimers = [primaryClaimer, ...acceptedContributors.toArray()];
            let anonymizeClaimers = !gift.get('holdsClaim', false) && claimers.some(
                claimerId => list.get('contributorsToAnonymize').includes(claimerId)
            );
            return (
                <Gift
                    gift={gift}
                    isCollaborator={isCollaborator}
                    disableCollaboratorView={this.state.disableCollaboratorView}
                    referenceListId={referenceListId}
                    saveGift={this.saveGift}
                    activeUserId={activeUserId}
                    key={giftId}
                    isReference={isReference}
                    removeGift={this.removeGift}
                    openModal={openModal}
                    ownedOriginLists={ownedOriginLists}
                    listType={listType}
                    originList={isReference ? claimedGiftOriginListsById.get(gift.get('originList'), new Map()) : list}
                    sortedBy={sortedBy}
                    numGifts={gifts.size}
                    reloadGifts={this.loadData}
                    anonymizeClaimers={anonymizeClaimers}
                />
            );
        }).valueSeq().toArray();
    };

    isComplete = gift => {
        let {list, activeUserId} = this.props;
        let listType = list.get('type');
        if (listType === 'reference') {
            let holdsPrimaryClaim = gift.getIn(['claimDetails', 'claimedBy', 'id']) === activeUserId;
            if (holdsPrimaryClaim) {
                return gift.getIn(['claimDetails', 'status']) === 'complete';
            } else {
                let contributorDetails = gift.get('contributors', new List())
                    .find(details => details.getIn(['contributor', 'id']) === activeUserId, null, new Map());
                return contributorDetails.get('completed', false);
            }
        } else {
            return gift.get('completed');
        }
    };

    state = {
        showCompletedGifts: false,
        disableCollaboratorView: false
    };

    loadClaimedGiftOriginLists = async gifts => {
        try {
            let {list} = this.props;
            if (list.get('type') !== 'reference') return new Map();
            let originListIds = Array.from(
                gifts.reduce((ids, gift) => ids.add(gift.originList), new Set())
            );
            let originLists = (await Promise.all(
                originListIds.map(originListId => axios.get(createUrl('/lists/' + originListId)))
            )).map(response => response.data);
            return originLists.reduce((aggregate, originList) => aggregate.set(originList.id, fromJS(originList)), new Map());
        } catch (error) {
            return new Map();
        }
    };

    loadData = async () => {
        let {list, lists, selectedUserId, activeUserId} = this.props;
        let {showCompletedGifts} = this.state;
        let gifts = (await axios.get(createUrl(list.getIn(['links', 'gifts'])))).data;
        let claimedGiftOriginListsPromise = this.loadClaimedGiftOriginLists(gifts);
        let hasCompletedGifts = !!gifts.find(gift => gift.completed);
        if (!showCompletedGifts) gifts = gifts.filter(gift => !gift.completed);
        gifts = fromJS(
            (await Promise.all(
                gifts.map(gift => axios.get(createUrl(gift.links.self))))
            ).map(response => response.data)
        );
        let activeUserLists = lists;
        if (selectedUserId !== activeUserId) {
            activeUserLists = fromJS((await axios.get(createUrl('/lists'), {params: {ownedBy: activeUserId}})).data);
        }
        let {referenceListId, ownedOriginLists} = activeUserLists.reduce((aggregate, list) => {
            if (list.get('type') === 'reference' && list.get('defaultList')) {
                aggregate.referenceListId = list.get('id');
            } else if (list.get('type') === 'origin') {
                aggregate.ownedOriginLists = aggregate.ownedOriginLists.push(list);
            }
            return aggregate;
        }, {ownedOriginLists: new List()});
        let claimedGiftOriginListsById = await claimedGiftOriginListsPromise;
        this.setState({gifts, referenceListId, ownedOriginLists, claimedGiftOriginListsById, hasCompletedGifts});
    };

    async componentDidMount() {
        await Interstitial.interstitialController(INTERSTITIAL_KEYS.ALL_GIFTS, this.loadData());
    }

    async componentDidUpdate(prevProps, prevState) {
        let {showCompletedGifts} = this.state;
        if (prevProps.list.get('id') !== this.props.list.get('id')){
            this.setState({showCompletedGifts: false, disableCollaboratorView: false, hasCompletedGifts: false});
            await Interstitial.interstitialController(INTERSTITIAL_KEYS.ALL_GIFTS, this.loadData());
        } else if (showCompletedGifts && showCompletedGifts !== prevState.showCompletedGifts) {
            await Interstitial.interstitialController(INTERSTITIAL_KEYS.COMPLETE_GIFTS, this.loadData());
        }
    }

    render() {
        let {list, isCollaborator} = this.props;
        let {gifts} = this.state;
        let {CompletedGifts, CollaboratorToggle} = this;

        let content;
        if (gifts) {
            let listType = list.get('type');
            let {completeGifts, incompleteGifts} = gifts
                .reduce((collection, gift) => {
                    let giftId = gift.get('id');
                    let completedType = this.isComplete(gift) ? 'completeGifts' : 'incompleteGifts';
                    collection[completedType] = collection[completedType].set(giftId, gift);
                    return collection;
                }, {incompleteGifts: new OrderedMap(), completeGifts: new OrderedMap()});
            completeGifts = this.getDisplayableGifts(this.sortGifts(completeGifts, 'completedAt'));
            incompleteGifts = this.getDisplayableGifts(this.sortGifts(incompleteGifts, list.get('sortedBy')));

            let noGifts = !completeGifts.length && !incompleteGifts.length;

            if (noGifts && (isCollaborator || listType === 'reference')) {
                incompleteGifts = 'No Gift Ideas Yet';
            }
            content = (
                <>
                    <CollaboratorToggle noGifts={noGifts} />
                    <AddGift listId={list.get('id')} listType={listType} isCollaborator={isCollaborator} saveGift={this.saveGift} />
                    <div className="gifts-incompleteGifts">{incompleteGifts}</div>
                    <CompletedGifts completeGifts={completeGifts} listType={listType}/>
                </>
            )
        }

        return (
            <Interstitial className="gifts" interstitialKey={INTERSTITIAL_KEYS.ALL_GIFTS}>
                {content}
            </Interstitial>
        );
    }
}

let mapStateToProps = (state, ownProps) => {
    let {list} = ownProps;
    let selectedUserId = list.getIn(['ownedBy', 'id']);
    let activeUserId = state.users.getIn(['user', 'id']);
    let ownersFirstName = list.getIn(['ownedBy', 'name', 'first']);
    let isCollaborator = (selectedUserId !== activeUserId) || list.get('type') === 'reference';
    return {
        activeUserId,
        selectedUserId,
        ownersFirstName,
        isCollaborator
    };
};

let actions = {
    openModal: _openModal
};

export default connect(mapStateToProps, actions)(Gifts);
