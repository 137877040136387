import React from 'react';

export default function BookIcon13({className, colors = {}}) {
    let {
        primaryColor = '#323232',
        primaryShade = '#161616'
    } = colors;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className={className}>
            <g id="_x32_5_x2C__Cook_Book_x2C__Recipe_Book_x2C__Food_and_Restaurant_x2C__Ingredients_x2C__Chef">
                <g>
                    <path fill={primaryShade} d="M192.617,311.251c0,2.442,1.987,4.429,4.428,4.429H324.38c2.442,0,4.428-1.987,4.428-4.429v-28.124H192.617V311.251z"/>
                    <path fill={primaryShade}
                        d="M331.608,87.146c-8.45,0.078-16.59,2.624-23.54,7.359c-4.63,3.152-10.902,2.264-14.472-2.052    c-17.103-20.681-48.697-20.639-65.768,0c-3.572,4.318-9.84,5.204-14.472,2.052c-6.949-4.736-15.09-7.281-23.54-7.359    c-23.028-0.363-42.749,18.393-43.038,42.094c-0.26,21.346,15.441,39.715,36.521,42.727c5.346,0.764,9.317,5.342,9.317,10.742    v78.717h136.191v-78.717c0-5.4,3.971-9.978,9.317-10.742c21.08-3.012,36.78-21.381,36.521-42.727    C374.356,105.549,354.645,86.786,331.608,87.146z"/>
                    <path fill={primaryShade} d="M59.968,402.489V1.388C29.492,4.782,5.712,30.688,5.712,62.055v357.065c11.528-10.333,26.742-16.631,43.404-16.631H59.968    z"/>
                    <path fill={primaryColor}
                        d="M5.712,467.596C5.712,491.528,25.184,511,49.117,511H385.5v-32.553H81.67c-5.993,0-10.851-4.859-10.851-10.851    s4.858-10.851,10.851-10.851H385.5v-32.553H49.117C25.184,424.191,5.712,443.663,5.712,467.596z"/>
                    <path fill={primaryColor} d="M407.202,511h10.851c11.967,0,21.702-9.735,21.702-21.702v-65.106h-32.553V511z"/>
                    <path fill={primaryColor}
                        d="M418.053,1H81.67v401.489h358.085V120.362V55.255V22.702C439.755,10.735,430.02,1,418.053,1z M352.946,380.787H168.478    c-5.992,0-10.851-4.858-10.851-10.851c0-5.992,4.859-10.851,10.851-10.851h184.468c5.993,0,10.851,4.859,10.851,10.851    C363.797,375.929,358.939,380.787,352.946,380.787z M350.51,191.402v119.849c0,14.409-11.722,26.131-26.131,26.131H197.045    c-14.408,0-26.13-11.722-26.13-26.131V191.402c-27.031-8.104-46.188-33.449-45.835-62.428c0.42-34.519,28.55-63.719,64.941-63.531    c9.62,0.091,18.979,2.302,27.506,6.454c24.444-22.17,61.882-22.211,86.372,0c8.732-4.252,18.309-6.457,28.118-6.457    c35.103,0,63.9,28.373,64.328,63.533C396.698,157.954,377.542,183.298,350.51,191.402z"/>
                </g>
            </g>
            <g id="Layer_1"/>
        </svg>
    );
}
