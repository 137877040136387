import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, activeUserId, closeMenu, push}) => {
    let {giftLabel, ownedBy, originList, removedUserId} = alert.event.data;

    let [ownedByUser, setOwnedByUser] = useState();
    let [removedUser, setRemovedUser] = useState();
    let [initiatingUser, setInitiatingUser] = useState();
    useEffect(() => {
        loadUser(ownedBy, setOwnedByUser);
        if (activeUserId !== removedUserId) {
            loadUser(removedUserId, setRemovedUser);
        }
        if (alert.event.generatedBy !== removedUserId) {
            loadUser(alert.event.generatedBy, setInitiatingUser);
        }
    }, []);
    let originListLink = '/gift-tags/lists/' + originList;

    let message;
    if (ownedByUser) {
        let ownerName = ownedByUser.name.fullName;
        if (removedUser) {
            let removedUserName = removedUser.name.fullName;
            if (alert.event.generatedBy === removedUserId) {
                message = <span><b>{removedUserName}</b> removed themself from the contributors on the gift for <b>{ownerName}</b> - <b>"{giftLabel}"</b></span>
            } else if (initiatingUser) {
                let initiatingUserName = initiatingUser.name.fullName;
                message = <span><b>{removedUserName}</b> was removed from the contributors by <b>{initiatingUserName}</b> on the gift for <b>{ownerName}</b> - <b>"{giftLabel}"</b></span>
            }
        } else if (activeUserId === removedUserId && initiatingUser) {
            let initiatingUserName = initiatingUser.name.fullName;
            message = <span><b>You</b> were removed as a contributors by <b>{initiatingUserName}</b> on the gift for <b>{ownerName}</b> - <b>"{giftLabel}"</b></span>
        }
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={originListLink}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}