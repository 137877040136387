import React from 'react';
import PropTypes from 'prop-types';

import './unclaim-modal.css';

export default class UnclaimModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        unclaimGift: PropTypes.func.isRequired
    };

    unclaimGift = () => {
        let {unclaimGift, closeModal} = this.props;
        closeModal();
        unclaimGift();
    };

    render() {
        let {closeModal} = this.props;
        return (
            <div className="unclaimModal">
                <label className="unclaimModal-deletionMemoLabel">
                    If you return the tag, others will be able to claim this idea.
                </label>
                <div className="unclaimModal-buttonContainer">
                    <button className="unclaimModal-cancelButton" onClick={closeModal}>Cancel</button>
                    <button className="unclaimModal-confirmButton" onClick={this.unclaimGift}>Confirm</button>
                </div>
            </div>
        );
    }
}
