import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './dropdown.css';

export default class Dropdown extends React.Component {
    static propTypes = {
        buttonClassName: PropTypes.string,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        closeOnMenuClick: PropTypes.bool,
        hideIcon: PropTypes.bool,
        iconClassName: PropTypes.string,
        menuClassName: PropTypes.string,
        value: PropTypes.node
    };

    toggleMenu = () => {
        this.setState((previousState => ({
            menuVisible: !previousState.menuVisible
        })));
    };

    closeMenu = () => {
        this.setState({menuVisible: false});
    };

    setRef = reference => this.ref = reference;

    mouseDownListener = event => {
        if (!this.ref.contains(event.target)) {
            this.setState({menuVisible: false});
        }
    };

    state = {
        menuVisible: false
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.mouseDownListener, false);
        document.addEventListener('touchstart', this.mouseDownListener, false);
    }

    componentDidUpdate(previousProps) {
        if (!this.state.menuVisible && this.props.includeButton === false && this.props.children !== previousProps.children) this.setState({menuVisible: true})
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.mouseDownListener, false);
        document.removeEventListener('touchstart', this.mouseDownListener, false);
    }

    render() {
        let {value, className, buttonClassName, iconClassName = 'dropdown-expandIcon', menuClassName, hideIcon = false, closeOnMenuClick = true, includeButton = true} = this.props;
        let {menuVisible} = this.state;
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {closeMenu: this.closeMenu});
        });
        let menu = menuVisible ? (
            <div className={classnames('dropdown-menu', menuClassName)} onClick={closeOnMenuClick ? this.closeMenu : undefined}>
                {children}
            </div>
        ) : null;
        let valueContents = (
            <>
                <div className="dropdown-value">{value}</div>
                {!hideIcon && <div className={iconClassName}/>}
            </>
        );
        if (includeButton) {
            valueContents = (
                <button
                    className={classnames('dropdown-button', buttonClassName)}
                    onClick={this.toggleMenu}
                    type="button"
                >
                    {valueContents}
                </button>
            )
        }

        return (
            <div className={classnames("dropdown", className)} ref={this.setRef}>
                {valueContents}
                {menu}
            </div>
        );
    }
}
