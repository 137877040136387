import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {connect} from 'react-redux';

import {store} from '../../index';
import {startInterstitial, stopInterstitial} from '../../redux/interstitials/interstitial-actions';
import greenTag from '../../images/icons/tag-green.svg';
import yellowTag from '../../images/icons/tag-yellow.svg';
import blueTag from '../../images/icons/tag-blue.svg';

import './interstitial.css';

export class Interstitial extends React.Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        children: PropTypes.node,
        className: PropTypes.string,
        interstitialKey: PropTypes.string.isRequired,
        interstitialSize: PropTypes.string,
        interstitialText: PropTypes.string,
        interstitialType: PropTypes.string
    };

    static INTERSTITIAL_SIZES = {
        SMALL: 'SMALL',
        MEDIUM: 'MEDIUM',
        LARGE: 'LARGE'
    };

    static INTERSTITIAL_SIZE_CLASSNAMES = {
        [Interstitial.INTERSTITIAL_SIZES.SMALL]: 'interstitial--small',
        [Interstitial.INTERSTITIAL_SIZES.MEDIUM]: 'interstitial--medium',
        [Interstitial.INTERSTITIAL_SIZES.LARGE]: 'interstitial--large'
    };

    static INTERSTITIAL_TYPES = {
        SINGLE_TAG: 'SINGLE_TAG',   // Single Tag spinning in place
        LOADING: 'LOADING',         // "Loading" with three tags appearing after the text sequentially then repeating
        LOGO: 'LOGO'                // Gift Logo with tags circling
    };

    static defaultProps = {
        interstitialSize: Interstitial.INTERSTITIAL_SIZES.MEDIUM,
        interstitialType: Interstitial.INTERSTITIAL_TYPES.SINGLE_TAG,
        interstitialText: 'Loading...'
    };

    static interstitialController = async (interstitialKey, promise, minimumDuration = 1000) => {
        try {
            store.dispatch(startInterstitial(interstitialKey));
            let [result] = await Promise.all([
                promise,
                new Promise(resolve => setTimeout(resolve, minimumDuration))
            ]);
            store.dispatch(stopInterstitial(interstitialKey));
            return result;
        } catch (error) {
            store.dispatch(stopInterstitial(interstitialKey));
            throw error;
        }
    };

    SingleTagInterstitial = () => {
        let {interstitialText} = this.props;
        return (
            <div className="interstitial-block">
                <div className="interstitial-container">
                    <span className="interstitial-singleTagInterstitial" style={this.singleTagStyle} />
                </div>
                <div className="interstitial-text">{interstitialText}</div>
            </div>
        );
    };

    LoadingInterstitial = () => {
        return (
            <span>
                Loading Interstitial
            </span>
        );
    };

    LogoInterstitial = () => {
        return (
            <span>
                Logo Interstitial
            </span>
        );
    };

    constructor(props) {
        super(props);
        let tagStyles = [greenTag, yellowTag, blueTag];
        this.singleTagStyle = {backgroundImage: `url(${tagStyles[Math.floor(Math.random() * 3)]})`};
    }

    render() {
        let {active, children, interstitialType, interstitialSize, className} = this.props;
        let {SingleTagInterstitial, LoadingInterstitial, LogoInterstitial} = this;
        let {SINGLE_TAG, LOADING, LOGO} = Interstitial.INTERSTITIAL_TYPES;
        let spinner;
        if (active) {
            spinner = (
                <>
                    {interstitialType === SINGLE_TAG && <SingleTagInterstitial />}
                    {interstitialType === LOADING && <LoadingInterstitial />}
                    {interstitialType === LOGO && <LogoInterstitial />}
                </>
            )
        }
        return (
            <div className={classnames("interstitial", {[Interstitial.INTERSTITIAL_SIZE_CLASSNAMES[interstitialSize]]: active})}>
                {spinner}
                <div className={classnames('interstitial-content', className, {'interstitial-hidden': active})}>{children}</div>
            </div>
        );
    }
}

let mapStateToProps = (state, props) => ({
    active: state.interstitials.get(props.interstitialKey, 0) > 0
});

export default connect(mapStateToProps)(Interstitial);
