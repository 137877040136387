import React, {useState, useEffect} from 'react';
import axios from 'axios';
import moment from 'moment';

import ContentCard from '../../../components/content-card/content-card';
import CookbookPreview from '../../../cookbooks/cookbook-preview/cookbook-preview';
import {createUrl} from '../../../../../util/formatters';
import ErrorMessenger from '../../../components/error-messenger/error-messenger';

import './cookbooks-widget.css';

export default function CookbooksWidget({history}) {
    let [data, setData] = useState();
    let [errorMessage, setErrorMessage] = useState();

    let loadData = async () => {
        try {
            let cookbooks = (await axios.get(createUrl('/cookbooks'), {params: {author: 'self'}})).data.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
            setData({featuredCookbooks: cookbooks.slice(0, 2), cookbooks});
        } catch (error) {
            console.error(error);
            setErrorMessage('Oops, something went wrong with loading your cookbooks... Try again later.');
        }
    };
    useEffect(() => {loadData()}, []);

    let NoCookbooksPlaceholder = () => {
        return (
            <ContentCard className="cookbooksWidget-noCookbooksPlaceholder">
                <div className="cookbooksWidget-cookbookDetails">
                    <div className="cookbooksWidget-cookbookShadow" />
                </div>
                <div className="cookbooksWidget-line" />
                <div className="cookbooksWidget-recipePlaceholderText">Create a cookbook to begin adding recipes.</div>
            </ContentCard>
        );
    };

    let CookbookActions = () => {
        let {cookbooks, featuredCookbooks} = data;
        let cookbooksText = featuredCookbooks.length !== cookbooks.length ? 'View All Cookbooks' : 'Manage Cookbook(s)';
        return (
            <div className="cookbooksWidget-cookbookActions">
                {!!cookbooks.length && (
                    <button
                        className="cookbooksWidget-showMoreButton"
                        onClick={() => history.push('/sous-chef/cookbooks')}
                    >{cookbooksText}</button>
                )}
                {!cookbooks.length && (
                    <button
                        className="cookbooksWidget-createCookbookButton"
                        onClick={() => history.push('/sous-chef/cookbooks/new?referrer=dashboard')}
                    >Create a Cookbook</button>
                )}
            </div>
        )
    };

    if (errorMessage) return <ErrorMessenger>{errorMessage}</ErrorMessenger>;
    else if (!data) return null;
    let {featuredCookbooks} = data;
    let cookbookComponents = <NoCookbooksPlaceholder/>;
    if (featuredCookbooks.length) cookbookComponents = featuredCookbooks.map(
        cookbook => <CookbookPreview cookbook={cookbook} history={history} key={cookbook.id}/>
    );
    return (
        <div className="cookbooksWidget">
            <CookbookActions />
            {cookbookComponents}
        </div>
    )
}
