import React from 'react';

import Alert from '../../alert/alert';

export default ({alert, toggleNotificationRead}) => {
    return (
        <Alert alert={alert} toggleNotificationRead={toggleNotificationRead}>
            Welcome! Now that you have joined the Gift Tags Community, you will be able to create or join various "Hubs" where you can collaborate on gift ideas for you, your friends, and family.
        </Alert>
    );
}