import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Map} from 'immutable';
import classnames from 'classnames';

import {createUrl} from '../../../../util/formatters';
import Checkbox from '../../../../components/checkbox/checkbox';

import './contributor-modal.css';

export default class ContributorModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        gift: PropTypes.object.isRequired,
        reloadGifts: PropTypes.func.isRequired
    };

    Member = ({record, hubSelected}) => {
        let {gift} = this.props;
        let memberId = record.member.id;
        let contributorDetails = gift.get('contributors').find(
            contributorDetails => contributorDetails.getIn(['contributor', 'id']) === memberId,
            undefined,
            new Map()
        );
        let secondaryClaimPending = contributorDetails.get('status') === 'pending';
        useEffect(() => this.setMemberSelection(memberId)(secondaryClaimPending), []);
        let memberSelected = this.state.selectedMembers[memberId];
        let tagClassName = hubSelected || memberSelected ? 'contributorModal-yellowTagIcon' : 'contributorModal-greyTagIcon';
        return (
            <label className={classnames('contributorModal-member', {'contributorModal-member--disabled': hubSelected})}>
                <div className="contributorModal-memberName">
                    <Checkbox className="contributorModal-checkbox" checked={hubSelected || memberSelected} disabled={hubSelected} onChange={this.setMemberSelection(memberId)} />
                    {record.member.name.fullName}
                </div>
                <div className={tagClassName} />
            </label>
        );
    };

    Hub = ({hub}) => {
        let {Member} = this;
        let {gift} = this.props;
        let acceptingClaimsFromHub = gift.get('acceptContributorsFrom').some(acceptedHub => acceptedHub.get('id') === hub.id);
        let hubSelected = this.state.selectedHubs[hub.id];
        useEffect(() => this.setHubSelection(hub.id)(acceptingClaimsFromHub), []);
        let members = hub.directory
            .filter(record => {
                let memberId = record.member.id;
                if (gift.getIn(['ownedBy', 'id']) === memberId) return false;

                let holdsPrimaryClaim = memberId === gift.getIn(['claimDetails', 'claimedBy', 'id']);
                if (holdsPrimaryClaim) return false;

                let contributorDetails = gift.get('contributors').find(
                    contributorDetails => contributorDetails.getIn(['contributor', 'id']) === memberId,
                    undefined,
                    new Map()
                );
                let holdsSecondaryClaim = contributorDetails.get('status') === 'accepted';
                return !holdsSecondaryClaim;
            })
            .map(record => (
                <Member
                    record={record}
                    hubId={hub.id}
                    hubSelected={hubSelected}
                    key={record.member.id}
                />
            ));
        let membersComponent = !!members.length && (
            <div className="contributorModal-members">
                {members}
            </div>
        );
        return (
            <div className="contributorModal-hub">
                <label className="contributorModal-hubName">
                    <Checkbox className="contributorModal-checkbox" checked={hubSelected} onChange={this.setHubSelection(hub.id)} />
                    {hub.name}
                </label>
                {membersComponent}
            </div>
        );
    };

    setHubSelection = hubId => selected => this.setState(state => state.selectedHubs[hubId] = selected);

    setMemberSelection = memberId => selected => this.setState(state => state.selectedMembers[memberId] = selected);

    onSubmit = async () => {
        let {gift, closeModal, reloadGifts} = this.props;
        let {selectedMembers, selectedHubs} = this.state;
        let {status, links, contributors, acceptContributorsFrom} = gift.toObject();
        let allMemberIds = Object.keys(selectedMembers);
        let allHubIds = Object.keys(selectedHubs);
        let someMembersSelected = allMemberIds.some(memberId => selectedMembers[memberId]);
        let someHubsSelected = allHubIds.some(hubId => selectedHubs[hubId]);
        let acceptingContributors = someMembersSelected || someHubsSelected;
        if ((acceptingContributors && status !== 'acceptingContributors') || (!acceptingContributors && status !== 'claimed')) {
            await axios.put(createUrl(links.get('self') + '/contributors'), {acceptingContributors});
        }
        await Promise.all(allMemberIds.map(memberId => {
            let selected = selectedMembers[memberId];
            let existingContributor = !!contributors.find(contributorDetails => contributorDetails.getIn(['contributor', 'id']) === memberId);
            if (selected !== existingContributor) {
                if (selected) {
                    return axios.post(createUrl(links.get('self') + '/contributors/user'), {userId: memberId});
                } else {
                    return axios.delete(createUrl(links.get('self') + `/contributors/user/${memberId}`));
                }
            }
        }));
        await Promise.all(allHubIds.map(hubId => {
            let selected = selectedHubs[hubId];
            let existingHub = !!acceptContributorsFrom.find(acceptedHub => acceptedHub.get('id') === hubId);
            if (selected !== existingHub) {
                if (selected) {
                    return axios.post(createUrl(links.get('self') + '/contributors/hub'), {hubId});
                } else {
                    return axios.delete(createUrl(links.get('self') + `/contributors/hub/${hubId}`));
                }
            }
        }));
        await reloadGifts();
        closeModal();
    };

    state = {
        selectedHubs: {},
        selectedMembers: {}
    };

    componentDidMount = async () => {
        let {gift} = this.props;
        let originList = gift.get('originList');
        let sharedHubs = (await axios.get(createUrl(`/hubs?myRole=member&sharedList=${originList}`))).data;
        sharedHubs = (await Promise.all(sharedHubs.map(async hub => {
            return (await axios.get(createUrl(hub.links.self))).data;
        })));
        this.setState({sharedHubs});
    };

    render() {
        let {Hub} = this;
        let {closeModal} = this.props;
        let {sharedHubs} = this.state;
        if (!sharedHubs) return null;
        else if (!sharedHubs.length) {
            return (
                <div className="contributorModal-content">
                    This gift is on a list that's not shared with your hubs anymore, so you cannot invite additional contributors.
                </div>
            );
        }

        let hubs = sharedHubs.map(hub => <Hub hub={hub} key={hub.id} />);
        return (
            <div className="contributorModal">
                <div className="contributorModal-content">
                    <div className="contributorModal-contentTitle">Select entire hubs or specific members to invite them to contribute.</div>
                    <div className="contributorModal-contentSubtitle">Those who are invited will have a Yellow Tag by this gift and see a button to "pitch in" which will confirm them as a contributor.</div>
                    {hubs}
                </div>
                <div className="contributorModal-buttonContainer">
                    <button className="contributorModal-cancelButton" type="button" onClick={closeModal}>Cancel</button>
                    <button className="contributorModal-confirmButton" type="button" onClick={this.onSubmit}>Confirm</button>
                </div>
            </div>
        );
    }
}
