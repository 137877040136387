import React from 'react';

export default function DinnerPlateWithDome2({className}) {
    let color1 = '#25B7D3';
    let color2 = '#FBC8C8';
    let color3 = '#FFFFFF';
    let color5 = '#E2E4E5';
    let color6 = '#3E3E3F';
    let color7 = '#F7B4B4';
    return (
        <svg height="110px" viewBox="0 0 110 110" width="110px" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="Multicolor">
                <circle cx="55" cy="55" r="55" fill={color1}/>
                <g>
                    <g>
                        <path
                            d="M37,79c0,0,3.778-10.39-2.436-17.401c-0.339-0.383-0.336-0.96,0.01-1.337     C36.719,57.917,40.528,57.967,46,68c1.429,2.619,4.409,2.479,7,1c4.928-2.813,12.04-11.775,19.5-9.592     c0.339,0.099,0.601,0.37,0.688,0.712c0.087,0.342-0.013,0.705-0.263,0.955L47,87L37,79z"
                            fill={color2}/>
                    </g>
                    <g>
                        <path d="M87,58c0,0.552-0.448,1-1,1H24c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1h62     c0.552,0,1,0.448,1,1V58z"
                              fill={color3}/>
                    </g>
                    <g>
                        <path d="M55,27c1.042,0,2.034,0.046,3,0.115V24c0-0.552-0.448-1-1-1h-4c-0.552,0-1,0.448-1,1v3.115     C52.966,27.046,53.958,27,55,27z"
                              fill={color3}/>
                    </g>
                    <g>
                        <path d="M27,55c0,0-1-28,28-28s28,28,28,28H27z" fill={color5}/>
                    </g>
                    <g>
                        <polygon points="37,79 27,87 47,87    " fill={color6}/>
                    </g>
                    <g>
                        <path d="M55,27c-29,0-28,28-28,28h28V27z" fill={color3}/>
                    </g>
                    <g>
                        <path
                            d="M37,79c0,0,3.778-10.39-2.436-17.401c-0.339-0.383-0.336-0.96,0.01-1.337     C36.719,57.917,40.528,57.967,46,68c1.429,2.619,4.409,2.479,7,1c4.928-2.813,12.04-11.775,19.5-9.592     c0.339,0.099,0.601,0.37,0.688,0.712c0.087,0.342-0.013,0.705-0.263,0.955L47,87L37,79z"
                            fill={color2}/>
                    </g>
                    <g>
                        <path
                            d="M72.925,61.075c0.25-0.25,0.35-0.612,0.263-0.955s-0.349-0.613-0.688-0.712     C65.04,57.226,57.928,66.187,53,69c-2.591,1.479-5.571,1.619-7-1c-5.472-10.033-9.281-10.083-11.426-7.738     c-0.345,0.377-0.349,0.955-0.01,1.337C40.778,68.609,37,79,37,79l2.727,2.182l30.975-17.884L72.925,61.075z"
                            fill={color7}/>
                    </g>
                </g>
            </g>
        </svg>
    );
}
