import React from 'react';

export default function DinnerPlateWithDome({className}) {
    let color1 = '#000000';
    return (
        <svg height="48px" viewBox="0 0 48 48" width="48px" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="Layer_5">
                <path
                    d="M45.784,35.596c-1.111-8.562-7.126-15.581-15.15-18.122v-5.979v-0.998v-0.95h-0.951h-0.511H18.639h-0.354   h-0.949v0.95v1.234v5.742c-8.025,2.541-14.039,9.56-15.152,18.122H0v2.857h1.977H3.31h1.424h38.501h1.123h1.634h1.977v-2.857   H45.784z M19.235,13.191v-0.795v-0.95h0.95h0.565h6.153h0.88h0.952v0.95v1.229v3.349c-1.532-0.337-3.119-0.528-4.751-0.528   c-1.632,0-3.218,0.191-4.75,0.528V13.191z M34.96,33.046c-1.712-5.202-5.823-9.313-11.028-11.027c0.018,0,0.035-0.003,0.052-0.003   c7.344,0,13.603,4.578,16.121,11.03H34.96z"
                    fill={color1}/>
            </g>
        </svg>
    );
}
