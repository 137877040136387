import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, reduxForm, SubmissionError} from 'redux-form'
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import queryString from 'qs';

import {login as loginAction, logout as logoutAction} from '../../../../redux/users/users-actions'
import {createUrl} from '../../../../util/formatters';
import Input from '../../../../components/input/input';

import './login.css';

export class Login extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.shape({
            search: PropTypes.string
        }).isRequired,
        login: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        submitting: PropTypes.bool
    };

    onSubmit = async values => {
        try {
            let {login, history, location, logout} = this.props;
            logout();
            let response = await axios.post(createUrl('/users/login'), {
                user: values
            });
            login(response.data.user.token);
            let {redirectTo} = queryString.parse(location.search.slice(1));
            history.push(redirectTo || '/gift-tags');
        } catch (error) {
            if (error.response) {
                let errorResponse = error.response.data || {_error: 'Something went wrong...'};
                console.error(errorResponse);
                throw new SubmissionError(errorResponse);
            } else {
                console.error(error);
                throw new SubmissionError({_error: 'Something went wrong...'});
            }
        }
    };

    state = {};

    async componentDidMount() {
        let {history, location} = this.props;
        let jwtToken = sessionStorage.getItem('jwtToken');
        let expiredToken = queryString.parse(location.search.slice(1)).expiredToken === 'true';
        if (jwtToken && !expiredToken) {
            let {redirectTo} = queryString.parse(location.search.slice(1));
            history.push(redirectTo || '/gift-tags');
        }
    }

    render() {
        let {handleSubmit, submitting, error, location} = this.props;
        let errorMessage = error ? <div className="login-error">{error}</div> : null;
        let registerLink = '/gift-tags/register';
        let passwordResetLink = '/gift-tags/password-reset';
        let {redirectTo} = queryString.parse(location.search.slice(1));
        if (redirectTo) {
            registerLink += `?redirectTo=${redirectTo}`;
            passwordResetLink += `?redirectTo=${redirectTo}`;
        }
        return (
            <form className="login" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="login-title">Enter your credentials</div>
                {errorMessage}
                <Field
                    name="email"
                    component={Input}
                    type="text"
                    placeholder="Email"
                    className="login-input"
                    tabIndex="1"
                />
                <Field
                    name="password"
                    component={Input}
                    type="password"
                    placeholder="Password"
                    className="login-input"
                    tabIndex="2"
                />
                <NavLink to={passwordResetLink} className="login-passwordReset">Forgot password?</NavLink>
                <div className="login-actions">
                    <div>
                        <span>Or </span>
                        <NavLink to={registerLink} tabIndex="4">
                            Register
                        </NavLink>
                    </div>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="login-submitButton"
                        tabIndex="3"
                    >
                        LOGIN
                    </button>
                </div>
            </form>
        );
    }
}

let reduxConfig = {
    form: 'login'
};

let ReduxFormHOC = reduxForm(reduxConfig)(Login);
export default connect(null, {login: loginAction, logout: logoutAction})(ReduxFormHOC);
