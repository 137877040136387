import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadHub, loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push}) => {
    let {sender, senderType, recipient, requestType} = alert.event.data;

    let hubId = senderType === 'Hub' ? sender : recipient;
    let newMemberId = senderType === 'User' ? sender : recipient;
    let [hub, setHub] = useState();
    let [newMember, setNewMember] = useState();
    useEffect(() => {
        loadHub(hubId, setHub);
        loadUser(newMemberId, setNewMember);
    }, []);

    let link, message;
    if (hub && newMember) {
        if (requestType === 'hubInvite') {
            link = `/gift-tags/hubs/${hub.id}/join`;
            message = <span>You have been invited to join a Tag Hub - <b>{hub.name}</b></span>;
        } else {
            link = `/gift-tags/hubs/${hub.id}/settings`;
            message = <span><b>{newMember.name.fullName}</b> has requested to join <b>{hub.name}</b></span>;
        }
    } else if (hub === null && newMember) {
        if (requestType === 'hubInvite') {
            message = <span>You have been invited to join a Tag Hub that is not currently available</span>;
        } else {
            message = <span><b>{newMember.name.fullName}</b> has requested to join a Tag Hub that is not currently available</span>;
        }
    }

    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={link}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}