import {DOMAIN} from './constants';

export let createUrl = path => `${DOMAIN}${path}`;

export let formatAmount = (amount = 0) => {
    let prefix = amount < 0 ? '- $' : '$';
    let absoluteAmount = Math.abs(amount).toFixed(2);
    let formattedAmount = absoluteAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return prefix + formattedAmount;
};
