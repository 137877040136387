import axios from 'axios';
import {fromJS} from "immutable";

import {actionTypes} from './users-actions';

let INITIAL_STATE = fromJS({
    authenticated: false,
    user: null
});

let login = (state, action) => {
    let {token} = action;
    sessionStorage.setItem('jwtToken', token);
    axios.defaults.headers.common.authorization = `Token ${token}`;
    return state.set('authenticated', true);
};

let logout = () => {
    sessionStorage.removeItem('jwtToken');
    delete axios.defaults.headers.common.authorization;
    return INITIAL_STATE;
};

let saveUser = (state, action) => {
    let {user} = action;
    return state.set('user', fromJS(user));
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.login: return login(state, action);
        case actionTypes.logout: return logout(state, action);
        case actionTypes.saveUser: return saveUser(state, action);
        default: return state;
    }
};