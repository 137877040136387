import axios from 'axios';

import {createUrl} from '../../../../../../util/formatters';

export let loadUser = async (userId, setUser) => {
    if (!userId) return;
    try {
        let user = (await axios.get(createUrl('/users/' + userId))).data;
        setUser(user);
    } catch (error) {
        setUser(null);
    }
};

export let loadHub = async (hubId, setHub) => {
    if (!hubId) return;
    try {
        let hub = (await axios.get(createUrl('/hubs/' + hubId))).data;
        setHub(hub);
    } catch (error) {
        setHub(null);
    }
};

export let loadGift = async (listId, giftId, setGift) => {
    if (!giftId || !listId) return;
    try {
        let gift = (await axios.get(createUrl(`/lists/${listId}/gifts/${giftId}`))).data;
        setGift(gift);
    } catch (error) {
        setGift(null);
    }
};

export let loadList = async (listId, setList) => {
    if (!listId) return;
    try {
        let list = (await axios.get(createUrl(`/lists/${listId}`))).data;
        setList(list);
    } catch (error) {
        setList(null);
    }
};

