import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push}) => {
    let {data: {hubName, hubId}, generatedBy} = alert.event;
    let [modifier, setModifier] = useState();
    useEffect(() => {
        loadUser(generatedBy, setModifier);
    }, []);

    let message, link;
    if (modifier) {
        message = <span><b>{modifier.name.fullName}</b> removed you from the tag hub <b>{hubName}</b></span>;
        link = '/gift-tags/hubs/' + hubId;
    }

    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            closeMenu={closeMenu}
            push={push}
            link={link}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}