import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadHub, loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push, activeUserId}) => {
    let {sender, senderType, recipient} = alert.event.data;
    let hubId = senderType === 'Hub' ? sender : recipient;
    let newMemberId = senderType === 'User' ? sender : recipient;
    let [hub, setHub] = useState();
    let [newMember, setNewMember] = useState();
    useEffect(() => {
        loadHub(hubId, setHub);
        loadUser(newMemberId, setNewMember);
    }, []);

    let message, link;
    if (hub && newMember) {
        message = <span><b>{newMember.name.fullName}</b> has joined <b>{hub.name}</b></span>;
        link = `/gift-tags/hubs/${hub.id}`;
        if (activeUserId === newMemberId) {
            message = <span>Welcome to <b>{hub.name}</b>! You can now view and claim tags for the members of this hub.</span>;
        }
    } else if (hub === null && newMember) {
        message = <span><b>{newMember.name.fullName}</b> has joined a hub that is not currently available</span>;
        if (activeUserId === newMemberId) {
            message = <span>You were added to a hub that is not currently available</span>;
        }
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={link}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}