import React from 'react';

export default function ChickenDrum({className}) {
    let color1 = '#fddead';
    let color2 = '#aa7b50';
    let color3 = '#bb9573';
    let color4 = '#926945';
    return (
        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g data-name="Layer 25" id="Layer_25">
                <path fill={color1}
                      d="M62.865,3.831a3.838,3.838,0,0,0-1.708-.988,3.841,3.841,0,1,0-7.031,2.921L40.692,19.2a1,1,0,0,0,0,1.414l2.7,2.7a1,1,0,0,0,1.414,0L58.235,9.875a3.843,3.843,0,0,0,1.923.519h.014a3.8,3.8,0,0,0,2.708-1.12A3.853,3.853,0,0,0,62.865,3.831Z"/>
                <path fill={color2}
                      d="M45.515,22.611,41.39,18.487a1,1,0,0,0-1.045-.235L24.117,24.073a18.308,18.308,0,0,1-5.489,1.059,19.442,19.442,0,0,0-.215,38.838c.358.02.714.03,1.07.03A19.379,19.379,0,0,0,38.868,45.376a18.356,18.356,0,0,1,1.059-5.495l5.822-16.225A1,1,0,0,0,45.515,22.611Z"/>
                <path fill={color3}
                      d="M45.515,22.611,41.39,18.487a1,1,0,0,0-1.045-.235l-.813.292L43.6,22.611a1,1,0,0,1,.234,1.045L38.011,39.881a18.356,18.356,0,0,0-1.059,5.495A19.334,19.334,0,0,1,18.337,63.962c.026,0,.051.007.076.008.358.02.714.03,1.07.03A19.379,19.379,0,0,0,38.868,45.376a18.356,18.356,0,0,1,1.059-5.495l5.822-16.225A1,1,0,0,0,45.515,22.611Z"/>
                <path fill={color4} d="M18,58a1,1,0,0,1-1-1V55a1,1,0,0,1,2,0v2A1,1,0,0,1,18,58Z"/>
                <path fill={color4} d="M22,58a1,1,0,0,1-1-1V55a1,1,0,0,1,2,0v2A1,1,0,0,1,22,58Z"/>
                <path fill={color4} d="M26,58a1,1,0,0,1-1-1V55a1,1,0,0,1,2,0v2A1,1,0,0,1,26,58Z"/>
            </g>
        </svg>
    );
}
