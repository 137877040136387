import {Map} from "immutable";

import {actionTypes} from './interstitial-actions';

let INITIAL_STATE = new Map();

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.start: return state.set(action.interstitialKey, state.get(action.interstitialKey, 0) + 1);
        case actionTypes.stop: return state.set(action.interstitialKey, state.get(action.interstitialKey, 1) - 1);
        default: return state;
    }
};