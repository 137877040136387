import React, {useState} from 'react';
import classnames from 'classnames';

import {createUrl} from '../../../../util/formatters';

import './photo-preview.css';

export default function PhotoPreview ({photo, deletePhoto, className, imageClassName}) {
    let [photoIsSelected, setPhotoIsSelected] = useState(false);

    let onClose = event => {
        event.stopPropagation();
        photoIsSelected ? setPhotoIsSelected(false) : deletePhoto();
    };
    let expandPreview = () => setPhotoIsSelected(true);
    let stopPropagation = event => event.stopPropagation();
    return (
        <div onClick={onClose} className={classnames('photoPreview', className, {'photoPreview--selected': photoIsSelected}, {'photoPreview--unselected': !photoIsSelected})}>
            <div onClick={stopPropagation} className="photoPreview-buttonContainer">
                <button onClick={expandPreview} className="photoPreview-button" type="button">
                    <img
                        src={typeof photo === 'string' ? createUrl(`/photos/${photo}`) : URL.createObjectURL(photo)}
                        alt={photo.name}
                        className={classnames('photoPreview-image', imageClassName)}
                    />
                </button>
                {(photoIsSelected || deletePhoto) && (
                    <button onClick={onClose} className="photoPreview-closeButton" type="button">
                        <div className="photoPreview-closeIcon" />
                    </button>
                )}
            </div>
        </div>
    );
};

