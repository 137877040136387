import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {connect} from 'react-redux';
import qs from 'qs';

import {createUrl} from '../../../../../util/formatters';
import {openModal as _openModal} from '../../../../../redux/modal/modal-actions';

import './join-view.css';

export class JoinView extends React.Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        hub: PropTypes.object.isRequired,
        loadPendingRequestStats: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                hubId: PropTypes.string.isRequired
            }).isRequired,
            url: PropTypes.string.isRequired
        }).isRequired,
        openModal: PropTypes.func.isRequired,
        reloadHub: PropTypes.func.isRequired,
        requestId: PropTypes.string
    };

    ErrorNotice = () => {
        let {error} = this.state;
        if (!error) return null;
        return (
            <div className="joinView-errorNotice">
                <div className="joinView-errorNoticeIcon" />
                {error}
            </div>
        );
    };

    CreateApplication = () => {
        let {submitting} = this.state;
        return (
            <div className="joinView-application">
                <div className="joinView-mainText">Join this Hub?</div>
                <div className="joinView-subtext">
                    Once your request is accepted, you will be able to claim gift ideas for the members of this hub, and they will be able to claim ideas on your lists.
                </div>
                <div className="joinView-buttonContainer--right">
                    <button
                        disabled={submitting}
                        className="joinView-cancelButton"
                        onClick={this.closeHub}
                    >
                        Cancel
                    </button>
                    <button onClick={this.sendApplication} className="joinView-sendRequestButton" disabled={submitting}>
                        Join
                    </button>
                </div>
            </div>
        );
    };

    Application = () => {
        let {openModal} = this.props;
        let {submitting} = this.state;
        return (
            <div className="joinView-application">
                <div className="joinView-mainText">Join Requested and Awaiting Approval</div>
                <div className="joinView-subtext">
                    Once your request is accepted, you will be able to claim gift ideas for the members of this hub, and they will be able to claim ideas on your lists.
                </div>
                <div className="joinView-buttonContainer--right">
                    <button
                        onClick={() => openModal(
                            'CONFIRMATION_MODAL', {
                                message: 'Are you sure you want to cancel your join request?',
                                onConfirm: this.cancelApplication,
                                destructive: true
                            }, {title: 'Cancel Join Request', width: 400}
                        )}
                        className="joinView-declineButton"
                        disabled={submitting}
                    >
                        Cancel Request
                    </button>
                </div>
            </div>
        );
    };

    Invitation = () => {
        let {openModal} = this.props;
        let {submitting} = this.state;
        return (
            <div className="joinView-banner">
                <div className="joinView-mainText">You've been invited to join our Tag Hub!</div>
                <div className="joinView-subtext">
                    By accepting, you will be able to claim gift ideas for the members of this hub, and they will be able to claim ideas on your lists.
                </div>
                <div className="joinView-buttonContainer">
                    <button
                        onClick={() => openModal(
                            'CONFIRMATION_MODAL', {
                                message: 'Are you sure you want to decline this invitation?',
                                onConfirm: this.decline,
                                destructive: true
                            }, {title: 'Decline Hub Invite', width: 400}
                        )}
                        disabled={submitting}
                        className="joinView-declineButton"
                    >
                        <span className="joinView-buttonInner">
                            <div className="joinView-declineIcon" />
                            <span>Decline</span>
                        </span>
                    </button>
                    <button onClick={this.accept} className="joinView-acceptButton" disabled={submitting}>
                        <span className="joinView-buttonInner">
                            <div className="joinView-acceptIcon" />
                            <span>Accept</span>
                        </span>
                    </button>
                </div>
            </div>
        );
    };

    sendApplication = async () => {
        let {activeUserId, match} = this.props;
        let {hubId} = match.params;
        try {
            this.setState({submitting: true});
            let request = (await axios.post(createUrl('/requests'), {
                type: 'hubApplication',
                senderType: 'User',
                sender: activeUserId,
                recipientType: 'Hub',
                recipient: hubId
            })).data;
            this.setState({request, submitting: false});
        } catch (error) {
            this.setState({submitting: false, error: 'There was a problem with making the request.'});
        }
    };

    closeHub = () => {
        let {history} = this.props;
        history.push('/gift-tags/hubs');
    };

    cancelApplication = async () => {
        let {history} = this.props;
        let {request} = this.state;
        try {
            this.setState({submitting: true});
            await axios.put(createUrl(`/requests/${request.id}/rescind`));
            history.push('/gift-tags/hubs');
        } catch (error) {
            this.setState({submitting: false, error: 'There was a problem with canceling the request.'});
        }
    };

    accept = async () => {
        let {history, hub, reloadHub, loadPendingRequestStats} = this.props;
        let {request, inviteToken} = this.state;
        try {
            this.setState({submitting: true});
            if (inviteToken) {
                await axios.post(createUrl(`/hubs/${hub.id}/directory?inviteToken=${inviteToken}`));
            } else if (request) {
                await axios.put(createUrl(`/requests/${request.id}/respond`), {status: 'accepted'});
            }
            await Promise.all([
                reloadHub(),
                loadPendingRequestStats()
            ]);
            history.push('/gift-tags/hubs/' + hub.id);
        } catch (error) {
            this.setState({submitting: false, error: 'There was a problem with accepting the request.'});
            if (inviteToken && error.response && error.response.status === 403) {
                this.setState({
                    submitting: false,
                    error: 'There was a problem with accepting the request. ' +
                        'An admin from the hub may need to enable the Join Link, or you may need to have them resend you the link.'
                });
            }
        }
    };

    decline = async () => {
        let {history, loadPendingRequestStats} = this.props;
        let {request, inviteToken} = this.state;
        try {
            this.setState({submitting: true});
            if (request && !inviteToken) {
                await axios.put(createUrl(`/requests/${request.id}/respond`), {status: 'rejected'});
                await loadPendingRequestStats();
            }
            history.push('/gift-tags/hubs');
        } catch (error) {
            this.setState({submitting: false, error: 'There was a problem with declining the request.'});
        }
    };

    state = {submitting: false};

    componentDidMount = async () => {
        let {hub, history, match, location} = this.props;
        let {requestId} = match.params;
        let {token, inviteToken} = qs.parse(location.search.slice(1));
        let request = null;
        if (hub.hasJoined) {
            history.replace('/gift-tags/hubs/' + hub.id);
            return;
        } else if (requestId) {
            try {
                request = (await axios.get(createUrl(`/requests/${requestId}`), {params: {token}})).data;
                if ((request.sender.id !== hub.id && request.recipient.id !== hub.id) || request.status !== 'pending') {
                    request = null;
                    history.replace(`/gift-tags/hubs/${hub.id}/join`);
                }
                if (request.recipientType === 'Email') {
                    request = (await axios.put(createUrl(`/requests/${requestId}/update-recipient`), {token})).data;
                }
            } catch (error) {
                history.replace(`/gift-tags/hubs/${hub.id}/join`);
            }
        }
        if (!request && !inviteToken) {
            let pendingRequests = (await axios.get(createUrl('/requests?type=hubInvite&type=hubApplication')))
                .data
                .filter(request => request.sender.id === hub.id || request.recipient.id === hub.id);
            let pendingInvite = pendingRequests.find(request => request.type === 'hubInvite');
            let pendingApplication = pendingRequests.find(request => request.type === 'hubApplication');
            if (pendingInvite) {
                request = pendingInvite;
            } else if (pendingApplication) {
                request = pendingApplication;
            }
        }
        this.setState({request, inviteToken});
    };

    render() {
        let {CreateApplication, Application, Invitation, ErrorNotice} = this;
        let {request, inviteToken} = this.state;
        if (request === undefined && !inviteToken) return null;

        let content;
        if (inviteToken) {
            content = <Invitation />;
        } else if (request === null) {
            content = <CreateApplication />;
        } else if (request.type === 'hubInvite') {
            content = <Invitation />;
        } else if (request.type === 'hubApplication') {
            content = <Application />;
        }

        return (
            <div className="joinView">
                <ErrorNotice />
                {content}
            </div>
        );
    }
}

let mapStateToProps = state => ({
    activeUserId: state.users.getIn(['user', 'id'])
});

export default connect(mapStateToProps, {openModal: _openModal})(JoinView);
