import React from 'react';

export default function CookingPot2({className}) {
    let color1 = '#ccc';
    let color2 = '#d6d6d6';
    let color3 = '#3b5892';
    let color4 = '#f3a748';
    let color5 = '#b5b5b5';
    return (
        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g data-name="Layer 5" id="Layer_5">
                <path fill={color1} d="M9,14H55a0,0,0,0,1,0,0V44a4,4,0,0,1-4,4H13a4,4,0,0,1-4-4V14A0,0,0,0,1,9,14Z"/>
                <path fill={color2} d="M9,14V42.2a40.34,40.34,0,0,0,8,.8c18.74,0,34.14-12.73,35.84-29Z"/>
                <rect fill={color3} height="10" width="60" x="2" y="52"/>
                <path fill={color4} d="M35.44,46.4A3.67,3.67,0,0,1,32.52,52a4.52,4.52,0,0,1-1,0,3.67,3.67,0,0,1-2.92-5.57L32,41Z"/>
                <path fill={color4} d="M49.44,46.4A3.67,3.67,0,0,1,46.52,52a4.52,4.52,0,0,1-1,0,3.67,3.67,0,0,1-2.92-5.57L46,41Z"/>
                <path fill={color4} d="M21.44,46.4A3.67,3.67,0,0,1,18.52,52a4.52,4.52,0,0,1-1,0,3.67,3.67,0,0,1-2.92-5.57L18,41Z"/>
                <rect fill={color5} height="6" width="52" x="6" y="8"/>
                <rect fill={color2} height="6" width="10" x="27" y="2"/>
                <circle fill={color1} cx="56" cy="57" r="2"/>
                <circle fill={color1} cx="48" cy="57" r="2"/>
                <rect fill={color1} height="4" width="12" x="5" y="55"/>
                <rect fill={color5} height="4" width="7" x="55" y="18"/>
                <rect fill={color5} height="4" width="7" x="2" y="18"/>
                <path
                    d="M50.94,49H51a5,5,0,0,0,5-5V23h6a1,1,0,0,0,1-1V18a1,1,0,0,0-1-1H56V15h2a1,1,0,0,0,1-1V8a1,1,0,0,0-1-1H38V2a1,1,0,0,0-1-1H27a1,1,0,0,0-1,1V7H6A1,1,0,0,0,5,8v6a1,1,0,0,0,1,1H8v2H2a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H8V44a5,5,0,0,0,5,5h.06a4.53,4.53,0,0,0,.4,1.28,4.08,4.08,0,0,0,.44.72H2a1,1,0,0,0-1,1V62a1,1,0,0,0,1,1H62a1,1,0,0,0,1-1V52a1,1,0,0,0-1-1H50.1a4.08,4.08,0,0,0,.44-.72A4.53,4.53,0,0,0,50.94,49ZM61,19v2H56V19ZM28,3h8V7H28ZM7,9H57v4H7ZM3,21V19H8v2Zm7,23V15H54V44a3,3,0,0,1-3,3h-.2a4.67,4.67,0,0,0-.52-1.14l-3.44-5.4a1,1,0,0,0-1.68,0l-3.44,5.4A4.67,4.67,0,0,0,41.2,47H36.8a4.67,4.67,0,0,0-.52-1.14l-3.44-5.4a1,1,0,0,0-1.68,0l-3.44,5.4A4.67,4.67,0,0,0,27.2,47H22.8a4.67,4.67,0,0,0-.52-1.14l-3.44-5.4a1,1,0,0,0-1.68,0l-3.44,5.4A4.67,4.67,0,0,0,13.2,47H13A3,3,0,0,1,10,44Zm38.74,5.41A2.87,2.87,0,0,1,46.42,51a3.93,3.93,0,0,1-.84,0,2.87,2.87,0,0,1-2.32-1.57,2.5,2.5,0,0,1,.14-2.47L46,42.86l2.6,4.08A2.5,2.5,0,0,1,48.74,49.41ZM29.4,46.94,32,42.86l2.6,4.08a2.5,2.5,0,0,1,.14,2.47A2.87,2.87,0,0,1,32.42,51a3.93,3.93,0,0,1-.84,0,2.87,2.87,0,0,1-2.32-1.57A2.5,2.5,0,0,1,29.4,46.94Zm-8.66,2.47A2.87,2.87,0,0,1,18.42,51a3.93,3.93,0,0,1-.84,0,2.87,2.87,0,0,1-2.32-1.57,2.5,2.5,0,0,1,.14-2.47L18,42.86l2.6,4.08A2.5,2.5,0,0,1,20.74,49.41Zm1.8.87a4.53,4.53,0,0,0,.4-1.28h4.12a4.53,4.53,0,0,0,.4,1.28,4.08,4.08,0,0,0,.44.72H22.1A4.08,4.08,0,0,0,22.54,50.28ZM61,61H3V53H61ZM36.1,51a4.08,4.08,0,0,0,.44-.72,4.53,4.53,0,0,0,.4-1.28h4.12a4.53,4.53,0,0,0,.4,1.28,4.08,4.08,0,0,0,.44.72Z"/>
                <path d="M56,60a3,3,0,1,0-3-3A3,3,0,0,0,56,60Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,56,56Z"/>
                <path d="M45,57a3,3,0,1,0,3-3A3,3,0,0,0,45,57Zm3-1a1,1,0,1,1-1,1A1,1,0,0,1,48,56Z"/>
                <path d="M17,54H5a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V55A1,1,0,0,0,17,54Zm-1,4H6V56H16Z"/>
            </g>
        </svg>
    );
}
