import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, reduxForm, SubmissionError} from 'redux-form'
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import queryString from 'qs';

import {login as loginAction} from '../../../../redux/users/users-actions'
import {createUrl} from '../../../../util/formatters';
import {required} from '../../../../util/validation';
import Input from '../../../../components/input/input';

import './password-reset.css';

export class PasswordReset extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        submitting: PropTypes.bool
    };

    onSubmit = async values => {
        try {
            let {match, login, history, location} = this.props;
            let response = await axios.post(createUrl('/users/password-reset'), {
                resetToken: match.params.resetToken,
                newPassword: values.password
            });
            login(response.data.user.token);
            let {redirectTo} = queryString.parse(location.search.slice(1));
            history.push(redirectTo || '/gift-tags');
        } catch (error) {
            if (error.response) {
                let errorResponse = error.response.data || {_error: 'Something went wrong...'};
                console.error('errorResponse', errorResponse);
                throw new SubmissionError(errorResponse);
            } else {
                console.error('error', error);
                throw new SubmissionError({_error: 'Something went wrong...'});
            }
        }
    };

    state = {};

    render() {
        let {handleSubmit, submitting, error, location} = this.props;
        let errorMessage = error ? <div className="passwordReset-error">{error}</div> : null;
        let loginLink = '/gift-tags/login';
        let {redirectTo} = queryString.parse(location.search.slice(1));
        if (redirectTo) loginLink += `?redirectTo=${redirectTo}`;
        return (
            <form className="passwordReset" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="passwordReset-title">Please enter your new password</div>
                {errorMessage}
                <Field
                    name="password"
                    component={Input}
                    type="password"
                    placeholder="New Password"
                    className="passwordReset-input"
                    tabIndex="1"
                    validate={[required]}
                />
                <div className="passwordReset-actions">
                    <div>
                        <span>Back to </span>
                        <NavLink to={loginLink} tabIndex="3">
                            Login
                        </NavLink>
                    </div>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="passwordReset-submitButton"
                        tabIndex="2"
                    >
                        SUBMIT
                    </button>
                </div>
            </form>
        );
    }
}

let reduxConfig = {
    form: 'passwordReset'
};

let ReduxForm = reduxForm(reduxConfig)(PasswordReset);
export default connect(null, {login: loginAction})(ReduxForm);
