import {actionsNamespace} from '../../util/constants';

let prefix = actionsNamespace + 'modal/';

export let actionTypes = {
    openModal: prefix + 'openModal',
    closeModal: prefix + 'closeModal'
};

export let openModal = (modalKey, modalProps = {}, modalOptions = {}) => ({
    type: actionTypes.openModal,
    modalKey,
    modalProps,
    modalOptions
});

export let closeModal = () => ({
    type: actionTypes.closeModal
});