import React from 'react';

export default function BookIcon8({className, colors = {}}) {
    let {
        primaryColor = '#323232',
        primaryShade = '#161616',
        lines = '#000000',
        white = '#FFFFFF'
    } = colors;
    return (
        <svg version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="_x33_-Book">
				<g>
					<g><rect fill={primaryShade} height="61.8" width="8.8" x="9.9" y="1.1"/></g>
                    <g><rect fill={primaryColor} height="61.8" width="35.3" x="18.8" y="1.1"/></g>
                    <g><rect fill={white} height="11" width="22.1" x="25.4" y="7.7"/></g>
                    <g>
                        <g><path fill={lines} d="M54.1,0H18.8H9.9C9.3,0,8.8,0.5,8.8,1.1v61.8c0,0.6,0.5,1.1,1.1,1.1h8.8h35.3c0.6,0,1.1-0.5,1.1-1.1V1.1      C55.2,0.5,54.7,0,54.1,0z M11,2.2h6.6v59.6H11V2.2z M53,61.8H19.9V2.2H53V61.8z"/></g>
                        <g><path fill={lines} d="M25.4,19.9h22.1c0.6,0,1.1-0.5,1.1-1.1v-11c0-0.6-0.5-1.1-1.1-1.1H25.4c-0.6,0-1.1,0.5-1.1,1.1v11      C24.3,19.4,24.8,19.9,25.4,19.9z M26.5,8.8h19.9v8.8H26.5V8.8z"/></g>
                        <g><path fill={lines} d="M24.3,33.1h24.3c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1H24.3c-0.6,0-1.1,0.5-1.1,1.1S23.7,33.1,24.3,33.1z      "/></g>
                        <g><path fill={lines} d="M24.3,39.7h24.3c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1H24.3c-0.6,0-1.1,0.5-1.1,1.1S23.7,39.7,24.3,39.7z      "/></g>
                        <g><path fill={lines} d="M24.3,46.3h24.3c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1H24.3c-0.6,0-1.1,0.5-1.1,1.1S23.7,46.3,24.3,46.3z      "/></g>
                    </g>
				</g>
            </g>
		</svg>
    );
}
