import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import Routes from './routes/routes';

import './app.css';

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Route component={Routes} />
      </Router>
    );
  }
}
