import React from 'react';

export default function Turkey({className}) {
    let color1 = '#fd9a30';
    let color2 = '#e3e3e1';
    let color3 = '#fdb62f';
    let color4 = '#dd4a43';
    let color5 = '#7ea82d';
    let color6 = '#dbdbd9';
    return (
        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="flat">
                <path
                    d="M56,44H8V29.778A7.778,7.778,0,0,1,15.778,22h16a7.777,7.777,0,0,1,5.5,2.278l5.444,5.444A7.777,7.777,0,0,0,48.222,32h0A7.778,7.778,0,0,1,56,39.778Z"
                    fill={color1}/>
                <path d="M54,16a2,2,0,1,0-3.82.82L45,22l3,3,5.18-5.18A2,2,0,1,0,54,16Z" fill={color2}/>
                <path d="M48,25l-3-3-9.229,4.114a7,7,0,1,0,8.115,8.115Z" fill={color3}/>
                <path d="M18,33V32a5,5,0,0,0-5-5h0V37a2,2,0,0,0,2,2h8a3,3,0,0,0,3-3h0a3,3,0,0,0-3-3Z" fill={color3}/>
                <circle cx="9" cy="43" r="4" fill={color4}/>
                <circle cx="56" cy="43" r="4" fill={color5}/>
                <circle cx="50" cy="43" r="4" fill={color4}/>
                <path d="M3,44H61a0,0,0,0,1,0,0v0a6,6,0,0,1-6,6H9a6,6,0,0,1-6-6v0A0,0,0,0,1,3,44Z" fill={color2}/>
                <path d="M54,44a6,6,0,0,1-6,6h7a6,6,0,0,0,6-6Z" fill={color6}/>
            </g>
        </svg>
    );
}
