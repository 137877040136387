import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {reducer as form} from 'redux-form';

import users from './users/users-reducer';
import modal from './modal/modal-reducer';
import view from './view/view-reducer';
import interstitials from './interstitials/interstitial-reducer';

export default function getStore() {
    let rootReducer = combineReducers({
        form,
        users,
        modal,
        view,
        interstitials
    });

    let middleware = [
        thunk
    ];

    let composeEnhancer;
    if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    } else {
        composeEnhancer = compose;
    }

    return createStore(
        rootReducer,
        composeEnhancer(applyMiddleware(...middleware))
    );
}