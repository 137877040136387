import React from 'react';

export default function BookIcon4({className, colors = {}}) {
    let {
        primaryColor = '#323232',
        primaryShade = '#161616',
        bookmarkColor = '#db4342',
        bookmarkShade = '#b33939',
        white = '#f2f2f3'
    } = colors;
    return (
        <svg data-name="Livello 1" height="48" id="Livello_1" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g data-name="Libreria 4" id="Libreria_4">
                <rect fill={primaryColor} height="46" rx="8" ry="8" width="48" y="2"/>
                <path fill={primaryShade} d="M8,48a8,8,0,0,1-8-8V10A8,8,0,0,1,8,2H8V48Z"/>
                <path fill={primaryShade} d="M40,2H36a8,8,0,0,1,8,8V40a8,8,0,0,1-8,8h4a8,8,0,0,0,8-8V10A8,8,0,0,0,40,2Z"/>
                <path fill={white} d="M41.494,4.2A7.971,7.971,0,0,1,44,10V40a7.971,7.971,0,0,1-2.506,5.805A6,6,0,0,0,46,40V10A6,6,0,0,0,41.494,4.2Z"/>
                <path fill={bookmarkColor}
                      d="M24,.014V0H12a1.96,1.96,0,0,0-2,1.917V20.136a.992.992,0,0,0,.872.985l-.017,0v0H10.9a1,1,0,0,0,.219,0l1.743,0a.986.986,0,0,0,.7-.159L17,16.9h0l3.445,4.068a.987.987,0,0,0,.534.168h1.984a1,1,0,0,0,1.037-1V16.005h0V2h2A1.993,1.993,0,0,0,24,.014Z"/>
                <path fill={bookmarkShade}
                      d="M24,.014a2.01,2.01,0,0,0-.336.018A2.073,2.073,0,0,0,22,2.111V16.005h0v2.7h0v1.433a1,1,0,0,1-1.021,1h1.984a1,1,0,0,0,1.037-1V16.005h0V2h2A1.993,1.993,0,0,0,24,.014Z"/>
                <path fill={bookmarkShade} d="M10.854,21.119v0H10.9Z"/>
                <path fill={bookmarkShade}
                      d="M15.445,16.374,13.26,18.955h0l-1.7,2.013a.977.977,0,0,1-.441.155l1.743,0a.98.98,0,0,0,.7-.158L17,16.9l-.445-.526A1,1,0,0,0,15.445,16.374Z"/>
            </g>
        </svg>
    );
}
