import React from 'react';

export default function ChefHat({className}) {
    let color1 = '#000000';
    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g data-name="Chef Hat" id="Chef_Hat">
                <rect fill={color1} height="1" width="12" x="10" y="29"/>
                <path
                    fill={color1}
                    d="M23,6a3.85,3.85,0,0,0-1.27.21,6,6,0,0,0-11.46,0A3.85,3.85,0,0,0,9,6a4,4,0,0,0-.87,7.9L10,27v0h2V24a1,1,0,0,1,2,0v3h1V22a1,1,0,0,1,2,0v5h1V24a1,1,0,0,1,2,0v3h2v0l1.86-13.1A4,4,0,0,0,23,6Z"/>
            </g>
        </svg>
    );
}
