import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import modals from './modals';
import {closeModal as _closeModal} from '../../redux/modal/modal-actions';

import './modal.css';

export class Modal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        ModalContent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        modalOptions: PropTypes.object,
        modalProps: PropTypes.object,
        pathname: PropTypes.string.isRequired
    };

    Header = () => {
        let {modalOptions, closeModal} = this.props;
        let title = modalOptions.title;
        if (typeof title === 'string') {
            title = (
                <h3 className="modal-title">
                    {title}
                </h3>
            )
        }
        return (
            <div className="modal-header">
                <div className="modal-headerLeft" />
                <div className="modal-titleContainer">{title}</div>
                <div className="modal-headerRight">
                    <button onClick={closeModal} className="modal-closeButton">
                        <div className="modal-closeButtonIcon" />
                    </button>
                </div>
            </div>
        )
    };

    addEvent(element, eventName, callback) {
        if (element.addEventListener) {
            element.addEventListener(eventName, callback, false);
        } else if (element.attachEvent) {
            element.attachEvent("on" + eventName, callback);
        } else {
            element["on" + eventName] = callback;
        }
    }

    componentDidMount() {
        this.addEvent(document, 'keyup', e => {
            e = e || window.event;
            if (e.key === 'Escape') {
                this.props.closeModal();
            }
        });
    }

    componentDidUpdate(prevProps) {
        let {pathname, closeModal, ModalContent} = this.props;
        if (prevProps.pathname !== pathname) {
            closeModal();
        } else if (ModalContent && !prevProps.ModalContent) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        let {ModalContent, modalProps, modalOptions, closeModal} = this.props;
        let {Header} = this;
        if (!ModalContent) return null;
        let modalStyles = {
            width: modalOptions.width || 650
        };
        return (
            <div className="modal">
                <div className="modal-mask" onClick={closeModal} />
                <div className="modal-window" style={modalStyles}>
                    <Header/>
                    <ModalContent {...modalProps} closeModal={closeModal} />
                </div>
            </div>
        );
    }
}

let mapStateToProps = state => ({
    ModalContent: modals[state.modal.get('modalKey')],
    modalProps: state.modal.get('modalProps'),
    modalOptions: state.modal.get('modalOptions')
});

let actions = {
    closeModal: _closeModal
};

export default connect(mapStateToProps, actions)(Modal);
