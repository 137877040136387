import React from 'react';

export default function CookingPot({className}) {
    let color1 = '#F2DAAE';
    let color2 = '#E0C494';
    let color3 = '#3A4249';
    let color4 = '#D36767';
    let color5 = '#62C4C3';
    return (
        <svg id="_x31_-outline-expand" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M54,62H10c-2.209,0-4-1.791-4-4V17h52v41C58,60.209,56.209,62,54,62L54,62z" fill={color1} />
            <path d="M58,17H6v-2c0-2.209,1.791-4,4-4h44c2.209,0,4,1.791,4,4V17z" fill={color2}/>
            <path d="M6,22H4.5C3.119,22,2,20.881,2,19.5C2,18.119,3.119,17,4.5,17H6V22z" fill={color3}/>
            <path d="M59.5,22H58v-5h1.5c1.381,0,2.5,1.119,2.5,2.5C62,20.881,60.881,22,59.5,22L59.5,22z" fill={color3}/>
            <path d="M21,11V6c0-2.209,1.791-4,4-4h14c2.209,0,4,1.791,4,4v5h-4V6H25v5H21z" fill={color3}/>
            <path d="M21,57c0,3.866-2.239,5-5,5s-5-1.134-5-5c0-3.866,5-9,5-9S21,53.134,21,57L21,57z" fill={color4}/>
            <path d="M19,59.143C19,61.352,17.657,62,16,62s-3-0.648-3-2.857C13,56.934,16,54,16,54S19,56.934,19,59.143  L19,59.143z" fill={color5}/>
            <path d="M37,57c0,3.866-2.239,5-5,5c-2.761,0-5-1.134-5-5c0-3.866,5-9,5-9S37,53.134,37,57L37,57z" fill={color4}/>
            <path d="M35,59.143C35,61.352,33.657,62,32,62s-3-0.648-3-2.857C29,56.934,32,54,32,54S35,56.934,35,59.143  L35,59.143z" fill={color5}/>
            <path d="M53,57c0,3.866-2.239,5-5,5c-2.761,0-5-1.134-5-5c0-3.866,5-9,5-9S53,53.134,53,57L53,57z" fill={color4}/>
            <path d="M51,59.143C51,61.352,49.657,62,48,62c-1.657,0-3-0.648-3-2.857C45,56.934,48,54,48,54  S51,56.934,51,59.143L51,59.143z"
                  fill={color5}/>
            <rect height="2" fill={color3} width="2" x="53" y="20"/>
            <rect height="2" fill={color3} width="2" x="49" y="20"/>
            <rect height="2" fill={color3} width="2" x="45" y="20"/>
        </svg>
    );
}
