import {actionsNamespace} from '../../util/constants';

let prefix = actionsNamespace + 'view/';

export let actionTypes = {
    toggleLeftNav: prefix + 'toggleLeftNav',
};

export let toggleLeftNav = () => ({
    type: actionTypes.toggleLeftNav
});
