import React from 'react';

import Alert from '../../alert/alert';

export default ({alert, toggleNotificationRead}) => {
    let {headline, message} = alert.event.data;

    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
        >
            <span><b>{headline}</b>: {message}</span>
        </Alert>
    );
}