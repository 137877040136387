import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Field, Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import FormData from 'form-data';
import Toggle from 'react-toggle';
import classnames from 'classnames';
import {OnChange} from 'react-final-form-listeners';
import uuid from 'uuid/v4';

import ContentCard from '../../components/content-card/content-card';
import {compose, required, validateNonInternalUrl, validateOptionalUrl} from '../../../../util/validation';
import FieldLabel from '../../components/field-label/field-label';
import allRecipeIcons from '../../../../images/react-icons/recipes/all-recipe-icons';
import Dropdown from '../../../../components/dropdown/dropdown';
import InstructionFields from './instruction-fields/instruction-fields';
import IngredientFields from './ingredient-fields/ingredient-fields';
import TimeEstimateFields from './time-estimate-fields/time-estimate-fields';
import PhotoPreview from '../../components/photo-preview/photo-preview';
import {createUrl} from '../../../../util/formatters';
import Spinner from '../../components/spinner/spinner';

import './recipe-form.css';

export const FIELD_NAMES = {
    NAME: 'name',
    USE_PHOTO: 'usePhoto',
    PHOTO: 'photo',
    ICON: 'icon',
    ORIGINAL_RECIPE_LINK: 'originalRecipeLink',
    ORIGINAL_RECIPE_PHOTOS: 'originalRecipePhotos',
    INSTRUCTIONS: 'instructions',
    getInstructionDescriptionName: index => `instructions[${index}].description`,
    getInstructionPhotoName: index => `instructions[${index}].photo`,
    INGREDIENTS: 'ingredients',
    getIngredientDescriptionName: index => `ingredients[${index}].description`,
    getIngredientQuantityName: index => `ingredients[${index}].quantity`,
    getIngredientPhotoName: index => `ingredients[${index}].photo`,
    TIME_ESTIMATES: 'timeEstimates',
    getEstimateDescriptionName: index => `timeEstimates[${index}].description`,
    getEstimateDurationName: index => `timeEstimates[${index}].duration`,
    getEstimateUnitsName: index => `timeEstimates[${index}].units`,
    NOTES: 'notes',
    IS_PRIVATE: 'isPrivate',
    TAGS: 'tags',
    TAGS_SEARCH: 'tagsSearch'
};
let primaryFields = Object.values(FIELD_NAMES).filter(fieldName => typeof fieldName === 'string');

export default function RecipeForm({match, history, updateTitleBar, cookbook, reloadCookbook}) {
    let {recipeId} = match.params;
    let editingExistingRecipe = !!recipeId;
    let [data, setData] = useState();
    let [showDeleteSection, setShowDeleteSection] = useState(false);
    let [instructionToAddIngredients, setInstructionToAddIngredients] = useState();

    let getRandomIcon = () => {
        let iconNames = Object.keys(allRecipeIcons);
        // randomly selecting any icon except the last 10 which are dish specific
        return iconNames[Math.floor(Math.random() * (iconNames.length - 10))];
    };

    let loadData = async () => {
        let initialValues, recipe;
        if (editingExistingRecipe) {
            recipe = (await axios.get(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}`))).data;
            let {name, photos = [], icon, originalRecipe = {}, instructions = [], ingredients = [], timeEstimates = [], notes, isPrivate, tags = []} = recipe;
            initialValues = {
                [FIELD_NAMES.NAME]: name,
                [FIELD_NAMES.USE_PHOTO]: !!photos[0],
                [FIELD_NAMES.PHOTO]: photos[0],
                [FIELD_NAMES.ICON]: icon || getRandomIcon(),
                [FIELD_NAMES.ORIGINAL_RECIPE_LINK]: originalRecipe.link,
                [FIELD_NAMES.ORIGINAL_RECIPE_PHOTOS]: originalRecipe.photos || [],
                [FIELD_NAMES.INSTRUCTIONS]: instructions.map(
                    ({id, description, photos = [], ingredients: instructionIngredients = []}) => ({id, description, photo: photos[0], ingredients: instructionIngredients})
                ),
                [FIELD_NAMES.INGREDIENTS]: ingredients.map(({id, description, quantity, photos = []}) => ({id, description, quantity, photo: photos[0]})),
                [FIELD_NAMES.TIME_ESTIMATES]: timeEstimates,
                [FIELD_NAMES.NOTES]: notes,
                [FIELD_NAMES.IS_PRIVATE]: isPrivate,
                [FIELD_NAMES.TAGS]: tags
            };
        } else {
            initialValues = {
                [FIELD_NAMES.IS_PRIVATE]: cookbook.isPrivate,
                [FIELD_NAMES.ICON]: getRandomIcon(),
                [FIELD_NAMES.ORIGINAL_RECIPE_PHOTOS]: [],
                [FIELD_NAMES.INSTRUCTIONS]: [],
                [FIELD_NAMES.INGREDIENTS]: [],
                [FIELD_NAMES.TIME_ESTIMATES]: [],
                [FIELD_NAMES.TAGS]: []
            };
        }
        setData({initialValues, recipe});
    };
    useEffect(() => {
        loadData();
        updateTitleBar(editingExistingRecipe ? 'Edit Recipe' : 'Add a Recipe', {enableSearch: false});
        return () => updateTitleBar();
    }, []);

    let uploadPhoto = (photo, parentRecipeId, photoType, associatePhotoWith) => {
        if (!photo || typeof photo === 'string') return null;
        const photoData = new FormData();
        photoData.append('image', photo);
        let headers = {'content-type': 'multipart/form-data', 'photo-type': photoType};
        if (associatePhotoWith) headers['associate-photo-with'] = associatePhotoWith;
        return axios.post(createUrl(`/cookbooks/${cookbook.id}/recipes/${parentRecipeId}/photos`), photoData, {headers});
    };

    let validate = values => {
        let {usePhoto, photo, icon, tagsSearch, tags} = values;
        let errors = {};
        if (typeof usePhoto !== 'boolean' || (usePhoto && !photo) || (!usePhoto && !icon)) errors[FIELD_NAMES.PHOTO] = 'Required';
        if (tagsSearch) errors[FIELD_NAMES.TAGS] = 'Be sure to select or create a label after filling in the search field.';
        else if (!tags.length) errors[FIELD_NAMES.TAGS] = 'Please add at least 1 label';
        return Object.keys(errors).length ? errors : undefined;
    };

    let generateFieldArrayChanges = (oldFieldArray, newFieldArray, fieldNames, numericFieldNames = []) => {
        let changes = [];

        // Deletions
        let deletedFieldGroupIds = oldFieldArray
            .filter(oldFieldGroup => !newFieldArray.find(newFieldGroup => newFieldGroup.id === oldFieldGroup.id))
            .map(oldFieldGroup => oldFieldGroup.id);
        deletedFieldGroupIds.forEach(deletedFieldGroupId => changes.push({type: 'delete', id: deletedFieldGroupId}));

        // Edits
        let remainingOldFieldGroups = oldFieldArray.filter(oldFieldGroup => !deletedFieldGroupIds.includes(oldFieldGroup.id));
        let preexistingNewFieldGroups = newFieldArray.filter(newFieldGroup => oldFieldArray.find(oldFieldGroup => oldFieldGroup.id === newFieldGroup.id));
        let orderChanged = false;
        preexistingNewFieldGroups.forEach((newFieldGroup, currentIndex) => {
            let edits = {};
            let originalIndex = remainingOldFieldGroups.findIndex(oldFieldGroup => oldFieldGroup.id === newFieldGroup.id);
            let oldFieldGroup = remainingOldFieldGroups[originalIndex];
            fieldNames.forEach(fieldName => {
                if (Array.isArray(newFieldGroup[fieldName])) {
                    if (newFieldGroup[fieldName].join() !== oldFieldGroup[fieldName].join()) {
                        edits[fieldName] = newFieldGroup[fieldName];
                    }
                } else if (newFieldGroup[fieldName] !== oldFieldGroup[fieldName]) {
                    edits[fieldName] = newFieldGroup[fieldName] && numericFieldNames.includes(fieldName) ? Number(newFieldGroup[fieldName]) : newFieldGroup[fieldName];
                }
            });
            orderChanged = orderChanged || currentIndex !== originalIndex;
            if (orderChanged) edits.newIndex = currentIndex;
            if (Object.keys(edits).length) {
                edits.type = 'edit';
                edits.id = newFieldGroup.id;
                changes.push(edits);
            }
        });

        // Additions
        newFieldArray.forEach((newFieldGroup, newIndex) => {
            let isAddition = !oldFieldArray.find(oldFieldGroup => oldFieldGroup.id === newFieldGroup.id);
            if (isAddition) {
                let change = {type: 'add', newIndex, tempId: newFieldGroup.id};
                fieldNames.forEach(
                    fieldName => change[fieldName] = newFieldGroup[fieldName] && numericFieldNames.includes(fieldName) ? Number(newFieldGroup[fieldName]) : newFieldGroup[fieldName]
                );
                changes.push(change);
            }
        });

        return changes;
    };

    let onSubmit = async (values, form) => {
        try {
            let {name, usePhoto, photo, icon, tags, originalRecipeLink, originalRecipePhotos, ingredients, instructions, timeEstimates, isPrivate, notes} = values;
            let {recipe} = data;
            let newRecipeId, errorMessage;
            if (editingExistingRecipe) {
                let formState = form.getState();
                let {NAME, ORIGINAL_RECIPE_LINK, NOTES, IS_PRIVATE, TAGS, INSTRUCTIONS, INGREDIENTS, TIME_ESTIMATES, PHOTO, USE_PHOTO, ICON, ORIGINAL_RECIPE_PHOTOS} = FIELD_NAMES;
                let requestBody = primaryFields
                    .filter(fieldName => formState.dirtyFields[fieldName])
                    .reduce((aggregate, fieldName) => {
                        if ([NAME, ORIGINAL_RECIPE_LINK, NOTES, TAGS].includes(fieldName)) aggregate[fieldName] = values[fieldName] || null;
                        else if (fieldName === IS_PRIVATE) aggregate[fieldName] = values[fieldName];
                        else if (fieldName === INSTRUCTIONS) {
                            let originalInstructions = recipe.instructions || [];
                            originalInstructions = originalInstructions.map(
                                ({id, description, photos = [], ingredients: instructionIngredients = []}) => ({id, description, photo: photos[0], ingredients: instructionIngredients})
                            );
                            let instructionUpdates = generateFieldArrayChanges(originalInstructions, instructions, ['description', 'ingredients']);
                            if (instructionUpdates.length) aggregate.instructionUpdates = instructionUpdates;
                        }
                        else if (fieldName === INGREDIENTS) {
                            let originalIngredients = formState.initialValues[INGREDIENTS];
                            let ingredientUpdates = generateFieldArrayChanges(originalIngredients, ingredients, ['description', 'quantity'], ['quantity']);
                            if (ingredientUpdates.length) aggregate.ingredientUpdates = ingredientUpdates;
                        }
                        else if (fieldName === TIME_ESTIMATES) {
                            let originalTimeEstimates = formState.initialValues[TIME_ESTIMATES];
                            let timeEstimateUpdates = generateFieldArrayChanges(originalTimeEstimates, timeEstimates, ['description', 'duration', 'units'], ['duration']);
                            if (timeEstimateUpdates.length) aggregate.timeEstimateUpdates = timeEstimateUpdates;
                        } else if (fieldName === USE_PHOTO || fieldName === ICON) {
                            aggregate.icon = usePhoto ? null : icon;
                        }
                        return aggregate;
                    }, {});
                let updatedRecipe = data.recipe;
                if (Object.keys(requestBody).length) updatedRecipe = (await axios.put(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}`), requestBody)).data;
                try {
                    let photoServiceCalls = [];
                    if (formState.dirtyFields[USE_PHOTO]) {
                        if (usePhoto) {
                            photoServiceCalls.push(() => uploadPhoto(photo, recipeId, 'photo'));
                        } else if (formState.initialValues[PHOTO]) {
                            let photoId = formState.initialValues[PHOTO];
                            photoServiceCalls.push(() => axios.delete(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}/photos/${photoId}`)))
                        }
                    } else if (usePhoto && formState.dirtyFields[PHOTO]) {
                        let updatePhoto = async () => {
                            let photoId = formState.initialValues[PHOTO];
                            if (photoId) await axios.delete(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}/photos/${photoId}`));
                            return uploadPhoto(photo, recipeId, 'photo')
                        };
                        photoServiceCalls.push(updatePhoto);
                    }
                    if (formState.dirtyFields[ORIGINAL_RECIPE_PHOTOS]) {
                        let currentPhotos = formState.initialValues[ORIGINAL_RECIPE_PHOTOS];
                        let deletionServiceCalls = currentPhotos
                            .filter(photoId => !originalRecipePhotos.includes(photoId))
                            .map(photoIdToDelete => () => axios.delete(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}/photos/${photoIdToDelete}`)));
                        let uploadServiceCalls = originalRecipePhotos
                            .filter(originalRecipePhoto => typeof originalRecipePhoto !== 'string')
                            .map(newPhoto => () => uploadPhoto(newPhoto, recipeId, 'originalRecipePhotos'));
                        photoServiceCalls = photoServiceCalls.concat(deletionServiceCalls).concat(uploadServiceCalls);
                    }
                    if (formState.dirtyFields[INGREDIENTS]) {
                        let deletePhotoServiceCalls = formState.initialValues[INGREDIENTS]
                            .filter(({photo: currentPhoto, id: rowId}) => {
                                let updatedRow = ingredients.find(row => row.id === rowId);
                                return currentPhoto && updatedRow && currentPhoto !== updatedRow.photo
                            })
                            .map(({photo: photoIdToDelete}) => () => axios.delete(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}/photos/${photoIdToDelete}`)));
                        let photoUploadServiceCalls = ingredients.map(({photo}, index) => () => uploadPhoto(photo, recipeId, 'ingredients', updatedRecipe.ingredients[index]?.id));
                        photoServiceCalls = photoServiceCalls.concat(deletePhotoServiceCalls).concat(photoUploadServiceCalls)
                    }
                    if (formState.dirtyFields[INSTRUCTIONS]) {
                        let deletePhotoServiceCalls = formState.initialValues[INSTRUCTIONS]
                            .filter(({photo: currentPhoto, id: rowId}) => {
                                let updatedRow = instructions.find(row => row.id === rowId);
                                return currentPhoto && updatedRow && currentPhoto !== updatedRow.photo
                            })
                            .map(({photo: photoIdToDelete}) => () => axios.delete(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}/photos/${photoIdToDelete}`)));
                        let photoUploadServiceCalls = instructions.map(({photo}, index) => () => uploadPhoto(photo, recipeId, 'instructions', updatedRecipe.instructions[index]?.id));
                        photoServiceCalls = photoServiceCalls.concat(deletePhotoServiceCalls).concat(photoUploadServiceCalls)
                    }
                    let executeServiceCallsSequentially = async () => {
                        for (const serviceCall of photoServiceCalls) await serviceCall();
                    };
                    await executeServiceCallsSequentially();
                } catch (error) {
                    console.error(error);
                    errorMessage = 'We encountered issues while making updates to one or more of the recipe\'s photos. Please edit the recipe and try again.';
                }
            } else {
                let requestBody = {
                    name,
                    tags,
                    originalRecipeLink,
                    ingredients: ingredients.map(({description, quantity, id}) => ({description, quantity: quantity ? Number(quantity) : undefined, tempId: id})),
                    instructions: instructions.map(({description, ingredients: instructionIngredients}) => ({description, ingredients: instructionIngredients})),
                    timeEstimates: timeEstimates.map(({description, duration, units}) => ({description, duration: Number(duration), units})),
                    isPrivate,
                    notes
                };
                if (!usePhoto) requestBody.icon = icon;
                let recipe = (await axios.post(createUrl(`/cookbooks/${cookbook.id}/recipes`), requestBody)).data;
                newRecipeId = recipe.id;
                try {
                    let photoServiceCalls = [];
                    if (usePhoto) photoServiceCalls.push(() => uploadPhoto(photo, recipe.id, 'photo'));
                    photoServiceCalls = photoServiceCalls
                        .concat(originalRecipePhotos.map(originalRecipePhoto => () => uploadPhoto(originalRecipePhoto, recipe.id, 'originalRecipePhotos')))
                        .concat(ingredients.map(({photo}, index) => () => uploadPhoto(photo, recipe.id, 'ingredients', recipe.ingredients[index]?.id)))
                        .concat(instructions.map(({photo}, index) => () => uploadPhoto(photo, recipe.id, 'instructions', recipe.instructions[index]?.id)));
                    let executeServiceCallsSequentially = async () => {
                        for (const serviceCall of photoServiceCalls) await serviceCall();
                    };
                    await executeServiceCallsSequentially();
                } catch (error) {
                    console.error(error);
                    errorMessage = 'We encountered issues while uploading one or more photos to the recipe. Please edit the recipe and try again.';
                }
            }
            await reloadCookbook();
            let targetUrl = `/sous-chef/cookbooks/${cookbook.id}/recipes/${newRecipeId || recipeId}`;
            if (errorMessage) targetUrl += `?errorMessage=${errorMessage}`;
            history.push(targetUrl);
        } catch (error) {
            console.error(error);
            console.error('Server Response:', error.response?.data);
            window.scrollTo(0, 0);
            if (error.response?.data) return error.response.data;
            else return {[FORM_ERROR]: 'Oops, something went wrong...'};
        }
    };

    let onDelete = async () => {
        try {
            await axios.delete(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}`));
            await reloadCookbook();
        } catch (error) {
            console.error(error);
            console.error('Server Response:', error.response?.data);
        }
        history.push(`/sous-chef/cookbooks/${cookbook.id}`);
    };

    let onChangeOfTags = formProps => value => {
        // todo: search for any similar tags from other recipes to suggest
    };

    let CoverPhotoPicker = ({formProps}) => {
        let {values, form} = formProps;
        let selectedIcon = values[FIELD_NAMES.ICON];
        let icons = Object.keys(allRecipeIcons).map((iconName, index) => {
            let Icon = allRecipeIcons[iconName];
            return (
                <button
                    type="button"
                    onClick={() => form.change(FIELD_NAMES.ICON, iconName)}
                    className={classnames('recipeForm-iconPickerButton', {'recipeForm-iconPickerButton--selected': iconName === selectedIcon})}
                    key={iconName}
                >
                    <Icon className="recipeForm-iconPreview" key={index} />
                </button>
            )
        });
        let RecipeIcon = allRecipeIcons[selectedIcon];
        let usePhoto = values[FIELD_NAMES.USE_PHOTO];
        let photo = values[FIELD_NAMES.PHOTO];
        let uploadButtonText = 'Change\xa0Photo';
        if (!photo) uploadButtonText = 'Upload\xa0Photo';
        else if (usePhoto === false) uploadButtonText = 'Use\xa0Photo';
        let uploadPhotoButton = (
            <button
                type="button"
                className="recipeForm-greenButton recipeForm-uploadPhotoButton"
                onClick={() => {
                    form.change(FIELD_NAMES.USE_PHOTO, true);
                    if (!photo || usePhoto !== false) document.getElementById('recipeForm-coverPhotoInput').click()
                }}
            >{uploadButtonText}</button>
        );
        let contents;
        if (typeof usePhoto === 'boolean') {
            if (usePhoto) {
                contents = (
                    <div className="recipeForm-photoPicker">
                        {photo && <PhotoPreview photo={photo} deletePhoto={() => form.change(FIELD_NAMES.PHOTO)} className="recipeForm-photoPreview" />}
                    </div>
                )
            } else {
                contents = (
                    <div className="recipeForm-iconPicker">
                        <div className="recipeForm-iconContainer">
                            <RecipeIcon className="recipeForm-icon" />
                            <div className="recipeForm-iconPreviews">{icons}</div>
                        </div>
                        <div className="recipeForm-photoIconButtonContainer">Or {uploadPhotoButton}</div>
                    </div>
                )
            }
        }
        return (
            <>
                <div className="recipeForm-fieldGroup">
                    <FieldLabel
                        fieldName={FIELD_NAMES.PHOTO}
                        infoMessage="Upload a photo of the end product, or you can select an icon instead."
                        {...formProps}
                    >Cover Photo</FieldLabel>
                    {usePhoto !== false && (
                        <div className="recipeForm-photoIconORContainer">
                            {uploadPhotoButton}
                            <div className="recipeForm-orSeparator">OR</div>
                            <button
                                type="button"
                                className="recipeForm-selectIconButton"
                                onClick={() => {
                                    form.resetFieldState(FIELD_NAMES.PHOTO);
                                    form.change(FIELD_NAMES.USE_PHOTO, false)
                                }}
                            >Select&nbsp;Icon</button>
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        className="recipeForm-photoFileInput"
                        id="recipeForm-coverPhotoInput"
                        onChange={event => {
                            let file = event.target.files?.[0];
                            if (!file) return;
                            file.id = uuid();
                            form.resetFieldState(FIELD_NAMES.PHOTO);
                            form.change(FIELD_NAMES.PHOTO, file);
                        }}
                    />
                </div>
                <div>{contents}</div>
            </>
        )
    };

    let ErrorMessage = ({formProps}) => {
        let {error, submitError} = formProps;
        let errorMessage = submitError || error;
        if (!errorMessage) return null;
        return <ContentCard className="recipeForm-errorMessage"><span>Error:</span> {errorMessage}</ContentCard>;
    };

    let OriginalRecipePhotos = ({formProps}) => {
        let {form, values} = formProps;
        let photos = values[FIELD_NAMES.ORIGINAL_RECIPE_PHOTOS];
        let photoPreviews = photos.map((photo, index) => {
            let deletePhoto = () => {
                let updatedPhotos = [...photos];
                updatedPhotos.splice(index, 1);
                form.change(FIELD_NAMES.ORIGINAL_RECIPE_PHOTOS, updatedPhotos);
            };
            return <PhotoPreview photo={photo} deletePhoto={deletePhoto} key={photo.id || photo} className="recipeForm-photoPreview" />
        });
        return (
            <>
                <div className="recipeForm-fieldGroup">
                    <FieldLabel fieldName={FIELD_NAMES.ORIGINAL_RECIPE_PHOTOS} {...formProps}>Hardcopy Recipe</FieldLabel>
                    <button
                        type="button"
                        className="recipeForm-greenButton recipeForm-originalRecipePhotosInput"
                        onClick={() => document.getElementById('recipeForm-originalRecipePhotosInput').click()}
                    >Upload Photos</button>
                    <input
                        type="file"
                        multiple
                        accept="image/*"
                        className="recipeForm-photoFileInput"
                        id="recipeForm-originalRecipePhotosInput"
                        onChange={event => {
                            let newPhotos = Array.from(event.target.files).map(file => {
                                file.id = uuid();
                                return file;
                            });
                            form.change(FIELD_NAMES.ORIGINAL_RECIPE_PHOTOS, [...photos, ...newPhotos]);
                        }}
                    />
                </div>
                <div>{photoPreviews}</div>
            </>
        );
    };

    let Instructions = ({formProps}) => {
        let {form, values} = formProps;
        let instructions = values[FIELD_NAMES.INSTRUCTIONS];
        let ingredients = values[FIELD_NAMES.INGREDIENTS];
        let createNewInstruction = () => form.change(FIELD_NAMES.INSTRUCTIONS, [...instructions, {id: uuid(), ingredients: []}]);
        let removeInstruction = instructionId => {
            let currentIndex = instructions.findIndex(instruction => instruction.id === instructionId);
            if (currentIndex < 0) return;
            let newInstructions = [...instructions];
            newInstructions.splice(currentIndex, 1);
            form.change(FIELD_NAMES.INSTRUCTIONS, newInstructions);
        };
        let moveInstruction = (instructionId, newIndex) => {
            let currentIndex = instructions.findIndex(instruction => instruction.id === instructionId);
            if (currentIndex < 0) return;
            let instruction = instructions[currentIndex];
            let newInstructions = [...instructions];
            newInstructions.splice(currentIndex, 1);
            newInstructions.splice(newIndex, 0, instruction);
            form.change(FIELD_NAMES.INSTRUCTIONS, newInstructions)
        };
        let content;
        if (instructions.length) {
            let instructionComponents = instructions.map(
                (instruction, index) => (
                    <InstructionFields
                        index={index}
                        moveInstruction={moveInstruction}
                        removeInstruction={removeInstruction}
                        instructionToAddIngredients={instructionToAddIngredients}
                        setInstructionToAddIngredients={setInstructionToAddIngredients}
                        instruction={instruction}
                        ingredients={ingredients}
                        formProps={formProps}
                        key={instruction.id}
                    />
                )
            );
            content = <div className="recipeForm-instructions">{instructionComponents}</div>;
        }
        let addInstructionButton = (
            <button onClick={createNewInstruction} className="recipeForm-greenButton recipeForm-addInstructionsButton" type="button">
                Add Instruction{!instructions.length && 's'}
            </button>
        );
        return (
            <>
                <div className="recipeForm-fieldGroup recipeForm-instructionsFieldGroup">
                    <FieldLabel fieldName={FIELD_NAMES.INSTRUCTIONS} {...formProps}>Instructions</FieldLabel>
                    {!instructions.length && addInstructionButton}
                </div>
                {content}
                <div className="recipeForm-instructionButtons">{!!instructions.length && addInstructionButton}</div>
            </>
        )
    };

    let Ingredients = ({formProps}) => {
        let {form, values} = formProps;
        let ingredients = values[FIELD_NAMES.INGREDIENTS];
        let instructions = values[FIELD_NAMES.INSTRUCTIONS];
        let instructionToAddIngredientsIndex = instructions.findIndex(instruction => instruction.id === instructionToAddIngredients);
        let createNewIngredient = () => form.change(FIELD_NAMES.INGREDIENTS, [...ingredients, {id: uuid()}]);
        let removeIngredient = ingredientId => {
            // first, remove the ingredient from any related instructions
            let instructionsUpdated = false;
            let newInstructions = instructions.map(instruction => {
                let ingredientIndex = instruction.ingredients.findIndex(selectedIngredientId => selectedIngredientId === ingredientId);
                if (ingredientIndex < 0) return instruction;
                instructionsUpdated = true;
                instruction.ingredients = [...instruction.ingredients];
                instruction.ingredients.splice(ingredientIndex, 1);
                return instruction;
            });
            if (instructionsUpdated) form.change(FIELD_NAMES.INSTRUCTIONS, newInstructions);
            let currentIndex = ingredients.findIndex(ingredient => ingredient.id === ingredientId);
            if (currentIndex < 0) return;
            let newIngredients = [...ingredients];
            newIngredients.splice(currentIndex, 1);
            form.change(FIELD_NAMES.INGREDIENTS, newIngredients);
        };
        let moveIngredient = (ingredientId, newIndex) => {
            let currentIndex = ingredients.findIndex(ingredient => ingredient.id === ingredientId);
            if (currentIndex < 0) return;
            let ingredient = ingredients[currentIndex];
            let newIngredients = [...ingredients];
            newIngredients.splice(currentIndex, 1);
            newIngredients.splice(newIndex, 0, ingredient);
            form.change(FIELD_NAMES.INGREDIENTS, newIngredients)
        };
        let updateInstructionIngredients = ingredientId => {
            if (instructionToAddIngredientsIndex < 0) return;
            let ingredientIndex = instructions[instructionToAddIngredientsIndex].ingredients.findIndex(selectedIngredientId => selectedIngredientId === ingredientId);
            let newInstructions = [...instructions];
            let instructionIngredients = [...newInstructions[instructionToAddIngredientsIndex].ingredients];
            if (ingredientIndex < 0) newInstructions[instructionToAddIngredientsIndex].ingredients = [...instructionIngredients, ingredientId];
            else {
                instructionIngredients.splice(ingredientIndex, 1);
                newInstructions[instructionToAddIngredientsIndex].ingredients = instructionIngredients;
            }
            form.change(FIELD_NAMES.INSTRUCTIONS, newInstructions);
        };
        let content;
        if (ingredients.length) {
            let ingredientComponents = ingredients.map(
                (ingredient, index) => (
                    <IngredientFields
                        index={index}
                        moveIngredient={moveIngredient}
                        removeIngredient={removeIngredient}
                        instructionToAddIngredients={instructions[instructionToAddIngredientsIndex]}
                        updateInstructionIngredients={updateInstructionIngredients}
                        ingredient={ingredient}
                        formProps={formProps}
                        key={ingredient.id}
                    />
                )
            );
            content = <div className="recipeForm-ingredients">{ingredientComponents}</div>;
        }
        let addIngredientButton = (
            <button onClick={createNewIngredient} className="recipeForm-greenButton recipeForm-addIngredientsButton" type="button">
                Add Ingredient{!ingredients.length && 's'}
            </button>
        );
        return (
            <>
                <div className="recipeForm-fieldGroup recipeForm-ingredientsFieldGroup">
                    <FieldLabel fieldName={FIELD_NAMES.INGREDIENTS} {...formProps}>Ingredients</FieldLabel>
                    {!ingredients.length && addIngredientButton}
                </div>
                {content}
                <div className="recipeForm-ingredientButtons">{!!ingredients.length && addIngredientButton}</div>
            </>
        );
    };

    let TimeEstimates = ({formProps}) => {
        let {form, values} = formProps;
        let timeEstimates = values[FIELD_NAMES.TIME_ESTIMATES];
        let createNewEstimate = () => {
            let description;
            if (!timeEstimates.length) description = 'Prep-Time';
            else if (timeEstimates.length === 1 && timeEstimates[0].description === 'Prep-Time') description = 'Cook-Time';
            form.change(FIELD_NAMES.TIME_ESTIMATES, [...timeEstimates, {id: uuid(), description, units: 'minutes'}]);
        };
        let removeEstimate = estimateId => {
            let currentIndex = timeEstimates.findIndex(estimate => estimate.id === estimateId);
            if (currentIndex < 0) return;
            let newTimeEstimates = [...timeEstimates];
            newTimeEstimates.splice(currentIndex, 1);
            form.change(FIELD_NAMES.TIME_ESTIMATES, newTimeEstimates);
        };
        let moveEstimate = (estimateId, newIndex) => {
            let currentIndex = timeEstimates.findIndex(estimate => estimate.id === estimateId);
            if (currentIndex < 0) return;
            let estimate = timeEstimates[currentIndex];
            let newTimeEstimates = [...timeEstimates];
            newTimeEstimates.splice(currentIndex, 1);
            newTimeEstimates.splice(newIndex, 0, estimate);
            form.change(FIELD_NAMES.TIME_ESTIMATES, newTimeEstimates)
        };
        let content;
        if (timeEstimates.length) {
            let timeEstimateComponents = timeEstimates.map(
                (estimate, index) => (
                    <TimeEstimateFields
                        index={index}
                        moveEstimate={moveEstimate}
                        removeEstimate={removeEstimate}
                        estimate={estimate}
                        formProps={formProps}
                        key={estimate.id}
                    />
                )
            );
            content = <div className="recipeForm-timeEstimates">{timeEstimateComponents}</div>;
        }
        let addEstimateButton = (
            <button onClick={createNewEstimate} className="recipeForm-greenButton recipeForm-addEstimatesButton" type="button">
                Add Estimate{!timeEstimates.length && 's'}
            </button>
        );
        return (
            <>
                <div className="recipeForm-fieldGroup recipeForm-timeEstimatesFieldGroup">
                    <FieldLabel fieldName={FIELD_NAMES.TIME_ESTIMATES} {...formProps}>Time Estimates</FieldLabel>
                    {!timeEstimates.length && addEstimateButton}
                </div>
                {content}
                <div className="recipeForm-timeEstimateButtons">{!!timeEstimates.length && addEstimateButton}</div>
            </>
        )
    };

    let TagOptions = ({formProps}) => {
        let {form, values} = formProps;
        let tagsSearchValue = values[FIELD_NAMES.TAGS_SEARCH];
        let tagOptions = [];
        if (tagsSearchValue) {
            let onClick = () => {
                form.change(FIELD_NAMES.TAGS, [...values[FIELD_NAMES.TAGS], tagsSearchValue]);
                form.change(FIELD_NAMES.TAGS_SEARCH, '');
            };
            tagOptions.push(
                <button onClick={onClick} className="recipeForm-tagOption" type="button" key={`createTagOption:${tagsSearchValue}`}>
                    New Label: "{tagsSearchValue}"
                </button>
            );
        }
        return <>{tagOptions}</>
    };

    let SelectedTags = ({values, form}) => {
        let tags = values[FIELD_NAMES.TAGS];
        let selectedTagComponents = tags.map((tag, index) => {
            let removeTag = () => {
                tags.splice(index, 1);
                form.change(FIELD_NAMES.TAGS, [...tags]);
                form.blur(FIELD_NAMES.TAGS_SEARCH);
            };
            return (
                <div className="recipeForm-tag" key={`${tag}-${index}`}>
                    <div className="recipeForm-tagName">{tag}</div>
                    <button className="recipeForm-removeTagButton" onClick={removeTag} type="button">
                        <div className="recipeForm-closeIcon" />
                    </button>
                </div>
            )
        });
        return <div className="recipeForm-selectedTags">{selectedTagComponents}</div>
    };

    let RecipeForm = formProps => {
        let {handleSubmit, form, initialValues, submitting} = formProps;
        useEffect(() => {
            form.registerField(FIELD_NAMES.USE_PHOTO, () => {}, {});
            form.registerField(FIELD_NAMES.PHOTO, () => {}, {});
            form.registerField(FIELD_NAMES.ICON, () => {}, {});
            form.registerField(FIELD_NAMES.ORIGINAL_RECIPE_PHOTOS, () => {}, {});
            form.registerField(FIELD_NAMES.INSTRUCTIONS, () => {}, {});
            form.registerField(FIELD_NAMES.INGREDIENTS, () => {}, {});
            form.registerField(FIELD_NAMES.TIME_ESTIMATES, () => {}, {});
            form.registerField(FIELD_NAMES.IS_PRIVATE, () => {}, {});
            form.registerField(FIELD_NAMES.TAGS, () => {}, {});
        }, [cookbook.id]);
        // todo: prevent ENTER key from submitting the form
        // todo: try converting file inputs into normal Field components to fix bug of clearing image and trying to reselect the same image
        return (
            <>
                <ErrorMessage formProps={formProps} />
                <ContentCard className="recipeForm-contentCard">
                    <form onSubmit={handleSubmit}>
                        <div className="recipeForm-fieldGroup">
                            <FieldLabel fieldName={FIELD_NAMES.NAME} {...formProps}>Name</FieldLabel>
                            <Field
                                name={FIELD_NAMES.NAME}
                                component="input"
                                autoComplete="off"
                                className="recipeForm-input"
                                validate={required}
                            />
                        </div>
                        <CoverPhotoPicker formProps={formProps} />
                        <div className="recipeForm-fieldGroup recipeForm-tagsFieldGroup">
                            <FieldLabel
                                fieldName={FIELD_NAMES.TAGS}
                                infoMessage="Add labels to help you search for specific types of recipes later (eg Dinner, Quick, Italian, etc.)"
                                {...formProps}
                            >Labels</FieldLabel>
                            <Dropdown
                                value={(
                                    <Field
                                        name={FIELD_NAMES.TAGS_SEARCH}
                                        component="input"
                                        className="recipeForm-input"
                                        autoComplete="off"
                                    />
                                )}
                                className="recipeForm-dropdown"
                                hideIcon={true}
                                includeButton={false}
                            >
                                <TagOptions formProps={formProps} />
                            </Dropdown>
                            <OnChange name={FIELD_NAMES.TAGS_SEARCH}>{onChangeOfTags(formProps)}</OnChange>
                        </div>
                        <SelectedTags {...formProps} />
                        <div className="recipeForm-fieldGroup">
                            <FieldLabel
                                fieldName={FIELD_NAMES.IS_PRIVATE}
                                infoMessage={cookbook.isPrivate ? 'This recipe will be private, since the cookbook is private. Only authors of this cookbook will be able to view it.' : 'If enabled, only authors of this cookbook will be able to view this recipe.'}
                                {...formProps}
                            >Keep Private</FieldLabel>
                            <Toggle
                                onChange={event => form.change(FIELD_NAMES.IS_PRIVATE, event.target.checked)}
                                defaultChecked={initialValues[FIELD_NAMES.IS_PRIVATE]}
                                className="recipeForm-toggle"
                                disabled={cookbook.isPrivate}
                            />
                        </div>
                        <TimeEstimates formProps={formProps} />
                        <div className="recipeForm-fieldGroup recipeForm-fieldGroupColumn">
                            <FieldLabel fieldName={FIELD_NAMES.NOTES} {...formProps}>General Notes</FieldLabel>
                            <Field
                                name={FIELD_NAMES.NOTES}
                                component="textarea"
                                className="recipeForm-notesTextArea"
                                rows={2}
                            />
                        </div>
                        <Ingredients formProps={formProps} />
                        <Instructions formProps={formProps} />
                        <div className="recipeForm-fieldGroup">
                            <FieldLabel fieldName={FIELD_NAMES.ORIGINAL_RECIPE_LINK} {...formProps}>Link to External Recipe</FieldLabel>
                            <Field
                                name={FIELD_NAMES.ORIGINAL_RECIPE_LINK}
                                component="input"
                                autoComplete="off"
                                className="recipeForm-input"
                                validate={compose(validateOptionalUrl, validateNonInternalUrl)}
                            />
                        </div>
                        <OriginalRecipePhotos formProps={formProps} />
                        {!showDeleteSection && (
                            <div className="recipeForm-buttonGroup">
                                <div className="recipeForm-secondaryButtons">
                                    <button
                                        className="recipeForm-cancelButton"
                                        type="button"
                                        onClick={() => {
                                            let path = `/sous-chef/cookbooks/${cookbook.id}`;
                                            if (editingExistingRecipe) path += `/recipes/${recipeId}`;
                                            history.push(path);
                                        }}
                                    >Cancel</button>
                                    {editingExistingRecipe && !data?.recipe?.pendingDeletionAsOf && (
                                        <button
                                            className="recipeForm-deleteRecipeButton"
                                            type="button"
                                            onClick={() => setShowDeleteSection(true)}
                                        >
                                            Delete&nbsp;Recipe
                                        </button>
                                    )}
                                </div>
                                <button className="recipeForm-submitButton" type="submit">{editingExistingRecipe ? 'Save' : 'Create'}</button>
                            </div>
                        )}
                        {showDeleteSection && (
                            <div className="recipeForm-deleteSection">
                                <div>
                                    <div className="recipeForm-deletionPrompt">Are you sure you want to delete this recipe?</div>
                                    <div className="recipeForm-deletionHelpText">Deleted recipes can be restored for up to 30 days.</div>
                                </div>
                                <div className="recipeForm-deletionButtonSection">
                                    <button
                                        className="recipeForm-deletionYesButton"
                                        type="button"
                                        onClick={onDelete}
                                    >Yes</button>
                                    <button
                                        className="recipeForm-deletionNoButton"
                                        type="button"
                                        onClick={() => setShowDeleteSection(false)}
                                    >No</button>
                                </div>
                            </div>
                        )}
                        <Spinner active={submitting} />
                    </form>
                </ContentCard>
            </>
        )
    };

    if (!data) return null;
    let {initialValues} = data;
    return (
        <div className="recipeForm">
            <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>{RecipeForm}</Form>
        </div>
    )
}