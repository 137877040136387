import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser} from '../alert-template-services';

import '../alert-template-styles.css';

export default ({alert, toggleNotificationRead, closeMenu, push}) => {
    let {ownedBy, label, referenceList, deletionMemo} = alert.event.data;
    let claimedIdeasLink = '/gift-tags/lists/' + referenceList;

    let [ownedByUser, setOwnedByUser] = useState();
    useEffect(() => {
        loadUser(ownedBy, setOwnedByUser);
    }, []);

    let message;
    if (ownedByUser) {
        let ownerName = ownedByUser.name.fullName;
        message = (
            <span>
                <b>{ownerName}</b> deleted an idea <span className="underline">that you have claimed</span> - <b>"{label}"</b>, and they left this message for you: <b>"{deletionMemo}"</b>.
                You will still be able to view this idea on your Claimed Tags list unless you choose to remove your claim.
            </span>
        );
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={claimedIdeasLink}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}