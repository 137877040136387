import {fromJS} from "immutable";

import {actionTypes} from './modal-actions';

let INITIAL_STATE = fromJS({
    modalKey: undefined,
    modalProps: {},
    modalOptions: {}
});

let openModal = (state, action) => {
    let {modalKey, modalProps, modalOptions} = action;
    return state.set('modalKey', modalKey)
        .set('modalProps', modalProps)
        .set('modalOptions', modalOptions);
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.openModal: return openModal(state, action);
        case actionTypes.closeModal: return INITIAL_STATE;
        default: return state;
    }
};