import React from 'react';

import Alert from '../../alert/alert';

export default ({alert, toggleNotificationRead}) => {
    return (
        <Alert alert={alert} toggleNotificationRead={toggleNotificationRead}>
            Your email and password have been updated
        </Alert>
    );
}