import React from 'react';

import Hamburger from '../../../../images/react-icons/hamburger';

import './title-bar.css';

export default function TitleBar({title, subtitle, subtitleOnClick, isPrivate, enableSearch, toggleSidebar, icon}) {
    let subTitleComponent;
    if (subtitle) {
        subTitleComponent = <div className="titleBar-subTitle">{subtitle}</div>;
        if (subtitleOnClick) {
            subTitleComponent = <button className="titleBar-subTitle" onClick={subtitleOnClick}>{subtitle}</button>;
        }
    }
    return (
        <div className="titleBar">
            <div className="titleBar-primary">
                {toggleSidebar && (
                    <button onClick={toggleSidebar} className="titleBar-hamburgerButton">
                        <Hamburger className="titleBar-hamburgerIcon"/>
                    </button>
                )}
                {icon}
                <div className="titleBar-titles">
                    <div className="titleBar-primaryTitleSection">
                        <h2>{title}</h2>
                        {isPrivate && <span>(Private)</span>}
                    </div>
                    {subTitleComponent}
                </div>
            </div>
            {enableSearch && <div className="titleBar-searchBar">Search</div>}
        </div>
    )
}
