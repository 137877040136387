import {actionsNamespace} from '../../util/constants';

let prefix = actionsNamespace + 'users/';

export let actionTypes = {
    login: prefix + 'login',
    logout: prefix + 'logout',
    saveUser: prefix + 'saveUser'
};

export let login = token => ({
    type: actionTypes.login,
    token
});

export let logout = () => ({
    type: actionTypes.logout
});

export let saveUser = user => ({
    type: actionTypes.saveUser,
    user
});