import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Authenticator from './authenticator/authenticator';
import Dashboard from './dashboard/dashboard';

import './gift-tags.css';

export default class GiftTags extends React.Component {
    render() {
        let {url} = this.props.match;
        return (
            <div className="giftTags">
                <Switch>
                    <Route path={url + '/register'} component={Authenticator} />
                    <Route path={url + '/login'} component={Authenticator} />
                    <Route path={url + '/logout'} component={Authenticator} />
                    <Route path={url + '/password-reset'} component={Authenticator} />
                    <Route path={url} component={Dashboard} />
                </Switch>
            </div>
        );
    }
}
