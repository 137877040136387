import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push}) => {
    let {ownedBy, label, claimedBy, originList} = alert.event.data;
    let originListLink = '/gift-tags/lists/' + originList;

    let [ownedByUser, setOwnedByUser] = useState();
    let [claimedByUser, setClaimedByUser] = useState();
    useEffect(() => {
        loadUser(ownedBy, setOwnedByUser);
        loadUser(claimedBy, setClaimedByUser);
    }, []);

    let message;
    if (ownedByUser && claimedByUser) {
        let ownerName = ownedByUser.name.fullName;
        let claimerName = claimedByUser.name.fullName;
        message = <span><b>{claimerName}</b> returned the tag of an idea for <b>{ownerName}</b> - <b>"{label}"</b></span>
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={originListLink}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}