import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Map, List} from 'immutable';
import classnames from 'classnames';
import moment from 'moment';

import Checkbox from '../../../../../components/checkbox/checkbox';
import Dropdown from '../../../../../components/dropdown/dropdown';
import Tooltip from '../../../../../components/tooltip/tooltip';
import {formatAmount, createUrl} from '../../../../../util/formatters';

import './gift.css';

export default class Gift extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string.isRequired,
        anonymizeClaimers: PropTypes.bool.isRequired,
        isCollaborator: PropTypes.bool.isRequired,
        isReference: PropTypes.bool.isRequired,
        gift: PropTypes.instanceOf(Map).isRequired,
        listType: PropTypes.string.isRequired,
        openModal: PropTypes.func.isRequired,
        originList: PropTypes.instanceOf(Map).isRequired,
        ownedOriginLists: PropTypes.instanceOf(List).isRequired,
        referenceListId: PropTypes.string.isRequired,
        reloadGifts: PropTypes.func.isRequired,
        removeGift: PropTypes.func.isRequired,
        saveGift: PropTypes.func.isRequired
    };

    Tag = () => {
        let {gift, disableCollaboratorView, isCollaborator, isReference} = this.props;
        if ((!isCollaborator || disableCollaboratorView) && !isReference) return null;

        let giftAvailable = gift.get('status') === 'available';
        let giftAcceptingContributors = gift.get('status') === 'acceptingContributors';
        let claimedByActiveUser = gift.get('holdsClaim', false);
        let claimedByFirstName = gift.getIn(['claimDetails', 'claimedBy', 'name', 'first']);
        let claimedByLastName = gift.getIn(['claimDetails', 'claimedBy', 'name', 'last']);

        let tooltipContent, iconClassName;
        if (giftAvailable) {
            tooltipContent = "Available to claim";
            iconClassName = 'gift-tagIconBlue';
        } else if (claimedByActiveUser) {
            tooltipContent = "You claimed this tag";
            iconClassName = 'gift-tagIconGreen';
        } else if (giftAcceptingContributors) {
            tooltipContent = 'You\'ve been invited you to pitch in on this gift';
            iconClassName = 'gift-tagIconYellow';
        } else {
            tooltipContent = `${claimedByFirstName} ${claimedByLastName} claimed the tag`;
            iconClassName = 'gift-darkTagIcon';
        }
        return (
            <Tooltip className="gift-tagToolTip" tooltipContent={tooltipContent}>
                <span className={classnames('gift-tagIcon', iconClassName)} />
            </Tooltip>
        );
    };

    Label = () => {
        let {gift} = this.props;
        let customLink = gift.get('customLink', '');
        let isDeleted = gift.get('status') === 'deleted';
        let deletedStatus = isDeleted ? (
            <div className="gift-deletedText">
                [<span className="gift-deletedStatus">DELETED</span>: {gift.get('deletionMemo')}]
            </div>
        ) : null;
        let label = gift.get('label');
        if (customLink) {
            if (!customLink.includes('://')) customLink = `//${customLink}`;
            label = <a href={customLink} target="_blank" rel="noopener noreferrer" className="gift-link">{label}</a>;
        }
        label = (
            <div className={classnames('gift-labelPrimary', {'gift-label--deleted': isDeleted})}>
                {label}
            </div>
        );

        return <div className="gift-label">{label}{deletedStatus}</div>;
    };

    Description = () => {
        let {gift} = this.props;
        let description = gift.get('description', '');
        let toggle = null;
        if (description.length > 100) {
            let toggleText = 'Less';
            if (this.state.shortDescription) {
                description = description.substring(0, 100);
                toggleText = 'More';
            }
            toggle = (
                <button className="gift-descriptionToggle" onClick={this.toggleShortDescription}>
                    {toggleText}
                </button>
            );
        }
        return <div className="gift-description">{description} {toggle}</div>;
    };

    Contributor = ({name, memberId, isPrimary}) => {
        let {activeUserId, openModal} = this.props;
        let enableRemoval = !isPrimary || memberId === activeUserId;
        let identifier = memberId === activeUserId ? 'yourself' : name;
        let removeContributorButton = (
            <button
                className="gift-removeAcceptedContributorButton"
                onClick={() => openModal(
                    'CONFIRMATION_MODAL',
                    {
                        message: <div>Are you sure you want to <b>remove {identifier}</b> as a contributor?</div>,
                        onConfirm: this.removeContributor({memberId, isPrimary}),
                        destructive: true
                    },
                    {title: 'Remove Contributor', width: 400}
                )}
            >
                <div className="gift-closeIcon" />
            </button>
        );
        return (
            <div className="gift-acceptedContributor">
                <div className="gift-acceptedContributorLabel">{name}</div>
                {enableRemoval && removeContributorButton}
            </div>
        );
    };

    Contributors = () => {
        let {Contributor} = this;
        let {gift, isCollaborator, isReference, disableCollaboratorView} = this.props;
        let {contributors, claimDetails, status} = gift.toObject();
        if (!this.state.showContributorPanel || !claimDetails || ((!isCollaborator || disableCollaboratorView) && !isReference)) return null;

        let primaryClaimedByName = claimDetails.getIn(['claimedBy', 'name', 'fullName']);
        let primaryClaimedById = claimDetails.getIn(['claimedBy', 'id']);
        let acceptedContributors = contributors
            .filter(contributor => contributor.get('status') === 'accepted')
            .map(contributor => {
                let name = contributor.getIn(['contributor', 'name', 'fullName']);
                let memberId = contributor.getIn(['contributor', 'id']);
                return <Contributor name={name} memberId={memberId} key={memberId} />
            })
            .insert(0, <Contributor name={primaryClaimedByName} memberId={primaryClaimedById} isPrimary={true} key={primaryClaimedById} />);
        let finalizeText = (
            <div className="gift-contributorsFinalizeText">
                If this is everyone who will be contributing,
                <button className="gift-finalizeContributorsButton" onClick={this.finalizeContributors}>click here to finalize</button>
                .
            </div>
        );

        return (
            <div className="gift-contributors">
                <div className="gift-acceptedContributors">
                    <div className="gift-acceptedContributorsTitle">Contributors:</div>
                    {acceptedContributors}
                </div>
                {status === 'acceptingContributors' && finalizeText}
            </div>
        )
    };

    CollaboratorInfo = () => {
        let {isCollaborator, gift, isReference, disableCollaboratorView, anonymizeClaimers} = this.props;
        let {showContributorPanel} = this.state;
        if ((!isCollaborator || disableCollaboratorView) && !isReference) return null;

        let {holdsClaim: claimedByActiveUser, holdsPrimaryClaim, contributors, status, claimDetails} = gift.toObject();

        let acceptedContributors = contributors.filter(contributor => contributor.get('status') === 'accepted');
        let sharedTag = !!acceptedContributors.size;
        let claimExpiresAt = claimDetails && claimDetails.get('expiresAt');

        let text, firstButton, secondButton;
        if (status === 'available') {
            firstButton = (
                <button key="claim" className="gift-greenButton" onClick={this.claimGift}>
                    Claim Tag
                </button>
            );
        } else if (status === 'acceptingContributors') {
            let claimedBy = holdsPrimaryClaim ? 'You' : claimDetails.getIn(['claimedBy', 'name', 'fullName']);
            if (claimedByActiveUser) {
                if (claimExpiresAt) {
                    firstButton = (
                        <button
                            key="renewClaim"
                            className="gift-greenButton"
                            onClick={this.claimGift}
                        >Renew Claim</button>
                    );
                    secondButton = (
                        <button
                            key="returnTag"
                            onClick={this.openUnclaimModal}
                            className="gift-redButton"
                        >Return&nbsp;Tag</button>
                    );
                } else {
                    firstButton = (
                        <button
                            key="viewInvites"
                            className="gift-yellowButton"
                            onClick={this.openContributorModal(gift)}
                        >View&nbsp;Invites</button>
                    );
                    secondButton = (
                        <button
                            key="toggleContributors"
                            className="gift-greenButton"
                            onClick={this.toggleContributorPanel()}
                        >{showContributorPanel ? 'Hide' : 'Manage'}&nbsp;Contributors ({acceptedContributors.size + 1})</button>
                    );
                }
            } else {
                firstButton = <button key="pitchIn" className="gift-greenButton" onClick={this.pitchIn}>Pitch&nbsp;In</button>;
            }
            if (sharedTag) {
                let s = acceptedContributors.size > 1 ? 's' : '';
                let verb = acceptedContributors.size > 1 || claimedByActiveUser ? 'are' : 'is';
                let contributorNames = acceptedContributors.map(contributor => contributor.getIn(['contributor', 'name', 'fullName'])).join(', ');
                let others = (
                    <Tooltip className="gift-contributorsToolTip" tooltipContent={contributorNames}>
                        {acceptedContributors.size} other{s}
                    </Tooltip>
                );
                let formattedExpiresAt = moment(claimExpiresAt).format('M/D/YYYY');
                text = claimExpiresAt
                    ? <span>The claim by {claimedBy} and {others} will expire after {formattedExpiresAt}</span>
                    : <span>{claimedBy} and {others} {verb} requesting additional contributors</span>;
            } else {
                let verb = claimedByActiveUser ? 'are' : 'is';
                let formattedExpiresAt = moment(claimExpiresAt).format('M/D/YYYY');
                text = claimExpiresAt
                    ? <span>The claim by {claimedBy} will expire after {formattedExpiresAt}</span>
                    : `${claimedBy} ${verb} requesting additional contributors`;
            }
        } else if (status === 'claimed' || (status === 'completed' && claimedByActiveUser)) {
            let claimedBy = holdsPrimaryClaim ? 'You' : claimDetails.getIn(['claimedBy', 'name', 'fullName']);
            if (anonymizeClaimers) claimedBy = 'Someone';
            if (claimedByActiveUser) {
                if (claimExpiresAt) {
                    firstButton = (
                        <button
                            key="renewClaim"
                            className="gift-greenButton"
                            onClick={this.claimGift}
                        >Renew Claim</button>
                    );
                } else if (status !== 'completed') {
                    firstButton = (
                        <button
                            key="inviteContributors"
                            className="gift-yellowButton"
                            onClick={this.openContributorModal(gift)}
                        >Invite&nbsp;Contributors</button>
                    );
                }
                if (sharedTag && !claimExpiresAt) {
                    secondButton = (
                        <button
                            key="toggleContributors"
                            className="gift-greenButton"
                            onClick={this.toggleContributorPanel()}
                        >{showContributorPanel ? 'Hide' : 'Manage'}&nbsp;Contributors ({acceptedContributors.size + 1})</button>
                    );
                } else {
                    secondButton = (
                        <button
                            key="returnTag"
                            onClick={this.openUnclaimModal}
                            className="gift-redButton"
                        >Return&nbsp;Tag</button>
                    );
                }
            }
            if (sharedTag && !anonymizeClaimers) {
                let s = acceptedContributors.size > 1 ? 's' : '';
                let contributorNames = acceptedContributors.map(contributor => contributor.getIn(['contributor', 'name', 'fullName'])).join(', ');
                let others = (
                    <Tooltip className="gift-contributorsToolTip" tooltipContent={contributorNames}>
                        {acceptedContributors.size} other{s}
                    </Tooltip>
                );
                let formattedExpiresAt = moment(claimExpiresAt).format('M/D/YYYY');
                text = claimExpiresAt
                    ? <span>The claim by {claimedBy} and {others} will expire after {formattedExpiresAt}</span>
                    : <span>{claimedBy} and {others} claimed the tag</span>;
            } else {
                let formattedExpiresAt = moment(claimExpiresAt).format('M/D/YYYY');
                text = claimExpiresAt
                    ? <span>The claim by {claimedBy} will expire after {formattedExpiresAt}</span>
                    : <span>{claimedBy} claimed the tag</span>;
            }
        } else if (status === 'deleted' && claimedByActiveUser) {
            firstButton = <button onClick={this.unclaimGift} className="gift-redButton">Acknowledge&nbsp;&&nbsp;Remove</button>;
        } else {
            return null;
        }

        return (
            <div className="gift-status">
                <div className={classnames('gift-collaboratorInfoText', {'gift-collaboratorInfoText--red': claimExpiresAt})}>{text}</div>
                <div className="gift-collaboratorButtons">
                    {firstButton}
                    {secondButton}
                </div>
            </div>
        );
    };

    ContextMenu = () => {
        let {gift, activeUserId, listType, removeGift, ownedOriginLists, reloadGifts, sortedBy, numGifts, saveGift} = this.props;
        let giftId = gift.get('id');
        let copyGiftUrl = gift.getIn(['links', 'copy']);
        let listId = gift.get('originList');
        let copyPossible = ownedOriginLists.size > 0;
        let otherOwnedOriginLists = ownedOriginLists.filter(list => list.get('id') !== listId);
        let transferPossible = otherOwnedOriginLists.size > 0;
        let options = [];
        let copyOption = (
            <button
                className="gift-contextMenuOption"
                key="Copy"
                onClick={this.openModal(
                    'COPY_MODAL',
                    {
                        ownedOriginLists,
                        copyGiftUrl,
                        giftId,
                        removeGift,
                        reloadGifts,
                        listId
                    },
                    {title: 'Copy Gift', width: 500}
                )}
            >Copy</button>
        );
        if (copyPossible) options.push(copyOption);
        let giftUrl = gift.getIn(['links', 'self']);
        let claimedByActiveUser = gift.get('holdsClaim', false);
        if (claimedByActiveUser && listType === 'reference') {
            options = [
                ...options,
                <button
                    onClick={this.openModal(
                        'UNCLAIM_MODAL',
                        {
                            unclaimGift: this.unclaimGift
                        },
                        {title: 'Delete Gift', width: 400}
                    )}
                    className="gift-contextMenuOption"
                    key="Delete"
                >Delete</button>
            ]
        }
        let isOwner = gift.getIn(['ownedBy', 'id']) === activeUserId;
        if (isOwner) {
            let transferGiftUrl = gift.getIn(['links', 'transfer']);
            options = [
                <button
                    onClick={this.openModal(
                        'EDIT_MODAL',
                        {
                            gift,
                            giftUrl,
                            saveGift
                        },
                        {title: 'Manage Gift', width: 600}
                    )}
                    className="gift-contextMenuOption"
                    key="Edit"
                >Edit</button>,
                <button
                    onClick={this.openModal(
                        'DELETION_MODAL',
                        {
                            giftId,
                            giftUrl,
                            giftLabel: gift.get('label'),
                            reloadGifts,
                            completeGift: () => this.onCheckBoxChange(true),
                            isComplete: gift.get('completed')
                        },
                        {title: 'Delete Gift', width: 400}
                    )}
                    className="gift-contextMenuOption"
                    key="Delete"
                >Delete</button>,
                ...options
            ];
            let transferOption = (
                <button
                    className="gift-contextMenuOption"
                    key="Move"
                    onClick={this.openModal(
                        'COPY_MODAL',
                        {
                            ownedOriginLists: otherOwnedOriginLists,
                            transferGiftUrl,
                            giftId,
                            reloadGifts,
                            removeGift,
                            listId
                        },
                        {title: 'Transfer Gift', width: 500}
                    )}
                >Transfer</button>
            );
            if (transferPossible) options.push(transferOption);
            if (sortedBy === 'priority' && !gift.get('completed') && listType === 'origin') {
                let currentPriority = gift.get('priority');
                if (currentPriority > 1) {
                    options.push(
                        <button
                            className="gift-contextMenuOption"
                            onClick={this.updatePriority(currentPriority - 1)}
                            key="moveUp"
                        >
                            Move Up
                        </button>
                    )
                }
                if (currentPriority < numGifts) {
                    options.push(
                        <button
                            className="gift-contextMenuOption"
                            onClick={this.updatePriority(currentPriority + 1)}
                            key="moveDown"
                        >
                            Move Down
                        </button>
                    )
                }
            }
        }
        return (
            <Dropdown buttonClassName="gift-dropdown" iconClassName="gift-contextMenu">
                {options}
            </Dropdown>
        );
    };

    updatePriority = newPriority => async () => {
        let {gift, saveGift, reloadGifts} = this.props;
        let giftUrl = gift.getIn(['links', 'self']);
        let updatedGift = (await axios.put(createUrl(giftUrl), {
            ...gift.toJS(),
            priority: newPriority
        })).data;
        await reloadGifts();
        saveGift(updatedGift);
    };

    openModal = (modalKey, modalProps, modalOptions) => () => this.props.openModal(modalKey, modalProps, modalOptions);

    claimGift = async () => {
        let {gift, referenceListId, saveGift} = this.props;
        await axios.post(
            createUrl(gift.getIn(['links', 'claim'])),
            {referenceListId}
        );
        let updatedGift = (await axios.get(createUrl(gift.getIn(['links', 'self'])))).data;
        saveGift(updatedGift);
    };

    unclaimGift = async () => {
        let {gift, saveGift, removeGift, isReference} = this.props;
        await axios.post(createUrl(gift.getIn(['links', 'unclaim'])));
        if (!isReference){
            let updatedGift = (await axios.get(createUrl(gift.getIn(['links', 'self'])))).data;
            saveGift(updatedGift);
        }
        if (isReference) {
            removeGift(gift.get('id'));
        }
    };

    finalizeContributors = async () => {
        let {gift, saveGift} = this.props;
        await axios.put(createUrl(gift.getIn(['links', 'self']) + '/contributors'), {acceptingContributors: false});
        let updatedGift = (await axios.get(createUrl(gift.getIn(['links', 'self'])))).data;
        saveGift(updatedGift);
        this.toggleContributorPanel(false)();
    };

    pitchIn = async () => {
        let {gift, saveGift, activeUserId} = this.props;
        let pendingContributor = gift.get('contributors').find(contributorDetails => contributorDetails.getIn(['contributor', 'id']) === activeUserId);
        if (pendingContributor) {
            await axios.put(createUrl(gift.getIn(['links', 'self']) + '/contributors/user/' + activeUserId), {status: 'accepted'});
        } else {
            await axios.post(createUrl(gift.getIn(['links', 'self']) + '/contributors/user/'), {userId: activeUserId});
        }
        let updatedGift = (await axios.get(createUrl(gift.getIn(['links', 'self'])))).data;
        saveGift(updatedGift);
    };

    removeContributor = ({memberId, isPrimary}) => async () => {
        let {gift, saveGift, activeUserId, isReference, removeGift} = this.props;
        if (isPrimary) {
            await axios.post(createUrl(gift.getIn(['links', 'self']) + '/unclaim'));
        } else {
            await axios.delete(createUrl(gift.getIn(['links', 'self']) + '/contributors/user/' + memberId));
        }
        if (isReference && memberId === activeUserId) {
            removeGift(gift.get('id'));
            return;
        }
        let updatedGift = (await axios.get(createUrl(gift.getIn(['links', 'self'])))).data;
        let remainingContributors = updatedGift.contributors.filter(contributorDetails => contributorDetails.status === 'accepted').length;
        let hideContributors = !remainingContributors || memberId === activeUserId;
        if (hideContributors) {
            this.toggleContributorPanel(false)();
        }
        saveGift(updatedGift);
    };

    onCheckBoxChange = async checked => {
        let {gift, saveGift, reloadGifts} = this.props;
        let changeType = checked ? 'complete' : 'restore';
        let changeLink = gift.getIn(['links', changeType]);
        await axios.post(createUrl(changeLink));
        let selfLink = gift.getIn(['links', 'self']);
        let updatedGift = (await axios.get(createUrl(selfLink))).data;
        if (checked) await reloadGifts();
        saveGift(updatedGift);
    };

    toggleShortDescription = () => this.setState(
        previousState => ({shortDescription: !previousState.shortDescription})
    );

    toggleContributorPanel = value => () => {
        if (typeof value === 'boolean') {
            return this.setState({showContributorPanel: value});
        }
        this.setState(state => ({showContributorPanel: !state.showContributorPanel}));
    };

    openUnclaimModal = this.openModal(
        'UNCLAIM_MODAL',
        {unclaimGift: this.unclaimGift},
        {title: 'Return Tag', width: 400}
    );

    openContributorModal = gift => () => {
        this.openModal(
            'CONTRIBUTOR_MODAL',
            {gift, reloadGifts: this.props.reloadGifts},
            {title: 'Invite Contributors', width: 600}
        )();
        this.setState({showContributorPanel: false});
    };

    state = {
        shortDescription: true,
        showContributorPanel: false
    };

    render() {
        let {gift, isReference, isCollaborator, sortedBy, disableCollaboratorView, activeUserId, originList} = this.props;
        let {Tag, Label, Description, CollaboratorInfo, ContextMenu, Contributors} = this;
        let formattedAmount = typeof gift.get('price') === 'number'? formatAmount(gift.get('price')) : null;
        let fullName = gift.getIn(['ownedBy', 'name', 'fullName']);
        let ownedBy, completed, date;
        if (isReference) {
            let originListName = originList.get('name');
            ownedBy = <div className="gift-ownedBy">{originListName && originListName + ' - '}{fullName}</div>;
            let holdsPrimaryClaim = gift.getIn(['claimDetails', 'claimedBy', 'id']) === activeUserId;
            if (holdsPrimaryClaim) {
                completed = gift.getIn(['claimDetails', 'status']) === 'complete';
            } else {
                let contributorDetails = gift.get('contributors', new List()).find(details => details.getIn(['contributor', 'id']) === activeUserId, null, new Map());
                completed = contributorDetails.get('completed', false);
            }
            date = <div className="gift-date">Claimed on {moment(gift.getIn(['claimDetails', 'claimedAt'])).format('MMM D, YYYY')}</div>;
        } else {
            completed = gift.get('completed');
            date = <div className="gift-date">Added on {moment(gift.get('createdAt')).format('MMM D, YYYY')}</div>;
        }
        let checkbox = !isCollaborator || isReference ? (
            <Checkbox className="gift-checkbox" onChange={this.onCheckBoxChange} initialValue={completed} />
        ) : null;
        let priority = sortedBy === 'priority' && !gift.get('completed') ? <div className="gift-priority">{gift.get('priority')}.</div>: null;
        let claimedByActiveUser = gift.get('holdsClaim', false);
        let claimedByOtherUser = gift.get('status') === 'claimed' && !claimedByActiveUser;
        return (
            <div className={classnames('gift', {'gift--claimedByOther': claimedByOtherUser && !disableCollaboratorView})}>
                <div className="gift-primary">
                    {checkbox}
                    <div className="gift-priorityAndTag">
                        {priority}
                        <Tag />
                    </div>
                    <div className="gift-mainContent">
                        <div className="gift-leftSection">
                            <div className="gift-info">
                                <Label />
                                <Description />
                                {ownedBy}
                                {date}
                            </div>
                        </div>
                        <div className="gift-rightSection">
                            {formattedAmount && <div className="gift-price">{formattedAmount}</div>}
                            <CollaboratorInfo/>
                        </div>
                    </div>
                    <ContextMenu />
                </div>
                <Contributors />
            </div>
        );
    }
}
