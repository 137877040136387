import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

import {logout as logoutAction} from '../../../../redux/users/users-actions';

import './logout.css';

export class Logout extends React.Component {
    static propTypes = {
        logout: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.props.logout()
    }

    render() {
        return (
            <div className="logout">
                <div className="logout-title">Logout successful</div>
                <div>
                    <span>Return to </span>
                    <NavLink to="/gift-tags/login">login</NavLink>
                </div>
            </div>
        );
    }
}

export default connect(null, {logout: logoutAction})(Logout);
