import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './hub-row.css';

export default class HubRow extends React.Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        hub: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            hasJoined: PropTypes.bool.isRequired,
            memberDetails: PropTypes.shape({
                isOwner: PropTypes.bool.isRequired,
                role: PropTypes.string.isRequired
            })
        }).isRequired,
        hubApplicationCount: PropTypes.number.isRequired
    };

    onClick = () => {
        let {hub, history} = this.props;
        if (hub.hasJoined) {
            history.push('/gift-tags/hubs/' + hub.id);
        }
    };

    render() {
        let {hub, hubApplicationCount} = this.props;
        let rightContent;
        if (hub.hasJoined) {
            let rank = hub.memberDetails.isOwner ? 'owner' : hub.memberDetails.role;
            rightContent = (
                <div className="hubRow-right">
                    {!!hubApplicationCount && <div className="hubRow-badge">{hubApplicationCount}</div>}
                    <div className="hubRow-role">
                        {rank}
                    </div>
                </div>
            );
        }
        let HubRowType = hub.hasJoined ? 'button' : 'div';
        return (
            <HubRowType className={classnames('hubRow', {'hubRow--joined': hub.hasJoined})} onClick={this.onClick}>
                <div className="hubRow-left">
                    <div className="hubRow-name">{hub.name}</div>
                    {hub.description && <div className="hubRow-description">{hub.description}</div>}
                </div>
                {rightContent}
            </HubRowType>
        );
    }
}
