import React, {useEffect} from 'react';
import {reduxForm, Field, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import {withRouter, matchPath} from "react-router-dom";
import {fromJS} from 'immutable';

import {createUrl} from '../../../../util/formatters';
import Input from '../../../input/input';
import Checkbox from '../../../checkbox/checkbox';
import Dropdown from '../../../dropdown/dropdown';

import './manage-lists-modal.css';

export class ManageListsModal extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired
        }).isRequired,
        reset: PropTypes.func.isRequired
    };

    DeletionConfirmation = ({list}) => {
        return (
            <div className="manageListsModal-deletionConfirmation">
                <div className="manageListsModal-deleteConfirmationLeft">
                    <div className="manageListsModal-warningIcon" />
                    <div>
                        <div className="manageListsModal-deleteConfirmationMessage">
                            You are about to delete "{list.get('name')}"
                        </div>
                        <div className="manageListsModal-deleteConfirmationSubMessage">
                            This will delete all gift ideas from the list. This cannot be undone.
                        </div>
                    </div>
                </div>
                <div className="manageListsModal-deleteConfirmationRight">
                    <button type="button" className="manageListsModal-confirmDeleteButton" onClick={this.onDelete(list.get('id'))}>Delete</button>
                    <button type="button" className="manageListsModal-cancelButton" onClick={this.setDeleteRow(null)}>Cancel</button>
                </div>
            </div>
        );
    };

    DefaultListCheckBox = () => (
        <label className="manageListsModal-editableListSubMain">
            <Checkbox
                onChange={this.makeEditRowDefault}
                className="manageListsModal-checkbox"
                checkmarkClassName="manageListsModal-checkmark"
            />
            <span className="manageListsModal-editableListSubMainText">Make Primary List</span>
        </label>
    );

    EditableList = ({list}) => {
        let {DefaultListCheckBox} = this;
        useEffect(() => {
            this.props.change('name', list.get('name'));
            document.getElementById('name').focus();
        }, [this.state.editRow]);
        return (
            <div className="manageListsModal-editableList">
                <div className="manageListsModal-editableListMain">
                    <Field
                        name="name"
                        id="name"
                        component={Input}
                        type="text"
                        className="manageListsModal-input"
                        fieldClassName="manageListsModal-inputField"
                        autoComplete="off"
                    />
                    <button type="submit" className="manageListsModal-submitButton">Save</button>
                    <button type="button" className="manageListsModal-cancelButton" onClick={this.setEditRow(null)}>Cancel</button>
                </div>
                {!list.get('defaultList') && <DefaultListCheckBox />}
            </div>
        );
    };

    SharedWith = ({list}) => {
        let {visibility} = list.toObject();
        if (visibility !== 'custom') return null;

        let {hubs} = this.state;
        let hubComponents = hubs.map(hub => {
            let shared = !!list.get('sharedWith').find(hubId => hubId === hub.id);
            return (
                <label className="manageListsModal-hub">
                    <Checkbox
                        className="manageListsModal-hubCheckbox"
                        checkmarkClassName="manageListsModal-hubCheckboxIcon"
                        initialValue={shared}
                        onChange={this.setListSharedWithHub(list, hub.id)}
                    />
                    {hub.name}
                </label>
            )
        });
        if (!hubComponents.length) hubComponents = <span>You have not yet joined any hubs. <br/> <b>Warning</b>: This list will <b>NOT</b> share with new hubs automatically.</span>;
        return (
            <div className="manageListsModal-sharedWith">
                {hubComponents}
            </div>
        )
    };

    ListDetails = ({list, numberOfLists}) => {
        let {EditableList, DeletionConfirmation, SharedWith} = this;
        let {id: listId, name, visibility, defaultList} = list.toObject();
        if (this.state.editRow === listId) return <EditableList list={list} />;
        else if (this.state.deleteRow === listId) return <DeletionConfirmation list={list} />;

        let label;
        if (visibility === 'private') label = '(Private)';
        else if (defaultList && numberOfLists > 2) label = '(Primary)';

        let deleteButton;
        if (defaultList) {
            deleteButton = (
                <button type="button" className="manageListsModal-deleteButtonDisabled" disabled>
                    <div className="manageListsModal-disabledDeleteIcon" />
                </button>
            );
        } else {
            deleteButton = (
                <button type="button" onClick={this.setDeleteRow(listId)} className="manageListsModal-deleteButton">
                    <div className="manageListsModal-deleteIcon" />
                </button>
            );
        }

        let visibilityComponent;
        if (visibility !== 'private') {
            let sharedWithValue = visibility === 'hubs' ? 'Joined Hubs' : 'Specific Hubs';
            visibilityComponent = (
                <div className="manageListsModal-visibility">
                    <span>Shared with: </span>
                    <Dropdown
                        value={sharedWithValue}
                        className="manageListsModal-visibilityDropdown"
                        buttonClassName="manageListsModal-visibilityDropdownButton"
                    >
                        <button
                            onClick={this.setVisibility(list, 'hubs')}
                            type="button"
                            className="manageListsModal-dropdownOptionButton"
                        >Joined Hubs</button>
                        <button
                            onClick={this.setVisibility(list, 'custom')}
                            type="button"
                            className="manageListsModal-dropdownOptionButton"
                        >Specific Hubs</button>
                    </Dropdown>
                </div>
            );
        }

        return (
            <div className="manageListsModal-listDetails">
                <div className="manageListsModal-listDetailsUpper">
                    <div className="manageListsModal-listDetailsLeft">
                        <div>
                            <button type="button" className="manageListsModal-selectListButton" onClick={this.selectList(listId)}>
                                {name}
                            </button>
                            <span className="manageListsModal-privateLabel">{label}</span>
                        </div>
                        {visibilityComponent}
                    </div>
                    <div className="manageListsModal-listDetailsRight">
                        <button type="button" onClick={this.setEditRow(listId)} className="manageListsModal-editButton">Edit</button>
                        {deleteButton}
                    </div>
                </div>
                <SharedWith list={list} />
            </div>
        );
    };

    AddList = () => {
        let {DefaultListCheckBox} = this;
        useEffect(() => {
            if (this.state.editRow === 'addList') document.getElementById('name').focus();
        }, [this.state.editRow]);
        if (this.state.editRow === 'addList') {
            return (
                <div className="manageListsModal-addList--active">
                    <div className="manageListsModal-editableListMain">
                        <Field
                            name="name"
                            id="name"
                            component={Input}
                            type="text"
                            className="manageListsModal-input"
                            fieldClassName="manageListsModal-inputField"
                            autoComplete="off"
                        />
                        <button type="submit" className="manageListsModal-submitButton">Add</button>
                        <button type="button" className="manageListsModal-cancelButton" onClick={this.setEditRow(null)}>Cancel</button>
                    </div>
                    <DefaultListCheckBox />
                </div>
            )
        } else {
            return (
                <button onClick={this.setEditRow('addList')} className="manageListsModal-addList">
                    + Create New List
                </button>
            );
        }
    };

    setListSharedWithHub = (list, hubId) => async checked => {
        let key = checked ? 'addHubs' : 'removeHubs';
        let updatedList = fromJS((await axios.put(createUrl(`/lists/${list.get('id')}/permissions`), {
            visibility: 'custom',
            [key]: [hubId]
        })).data);
        this.updateList(updatedList);
    };

    setVisibility = (list, visibility) => async () => {
        let {id: listId, visibility: currentVisibility} = list.toObject();
        if (visibility === currentVisibility) return;
        let updatedList = fromJS((await axios.put(createUrl(`/lists/${listId}/permissions`), {visibility})).data);
        this.updateList(updatedList);
    };

    updateList = updatedList => {
        this.setState(state => {
            let {lists} = state;
            let index = lists.findIndex(list => list.get('id') === updatedList.get('id'));
            return {lists: lists.set(index, updatedList)};
        });
    };

    makeEditRowDefault = makeEditRowDefault => this.setState({makeEditRowDefault});

    setEditRow = row => () => {
        this.props.reset();
        this.setState({editRow: row, deleteRow: null});
    };

    setDeleteRow = row => () => {
        this.setState({deleteRow: row, editRow: null});
    };

    selectList = listId => () => {
        let {history, closeModal} = this.props;
        let currentListId = this.getCurrentListId();
        if (currentListId !== listId) history.push('/gift-tags/lists/' + listId);
        closeModal();
    };

    sortLists = (listA, listB) => {
        let listTypeValues = {
            origin: 0,
            reference: 1
        };
        let typeDifference = listTypeValues[listA.get('type')] - listTypeValues[listB.get('type')];

        if (typeDifference === 0) {
            let createdAtA = new Date(listA.get('createdAt')).getTime();
            let createdAtB = new Date(listB.get('createdAt')).getTime();
            return createdAtA - createdAtB;
        }

        return typeDifference;
    };

    getCurrentListId = () => {
        let {location} = this.props;
        let match = matchPath(location.pathname, {path: '/gift-tags/lists/:listId'});
        return match ? match.params.listId : null;
    };

    onSubmit = async values => {
        try {
            let {editRow, makeEditRowDefault, lists} = this.state;
            if (makeEditRowDefault) values.defaultList = true;
            let currentListId = this.getCurrentListId();
            let listIds = lists.map(list => list.get('id'));
            if (editRow === 'addList' && values.name) {
                await axios.post(createUrl('/lists'), values);
                if (listIds.includes(currentListId)) this.setState({reloadOnUnMount: true});
            } else if (editRow && values.name) {
                await axios.put(createUrl('/lists/' + editRow), values);
                if (listIds.includes(currentListId)) this.setState({reloadOnUnMount: true});
            }
            await this.loadLists();
            this.setEditRow(null)();
        } catch (error) {
            if (error.response) {
                let errorResponse = error.response.data || {_error: 'Something went wrong...'};
                console.error(errorResponse);
                throw new SubmissionError(errorResponse);
            } else {
                console.error(error);
                throw new SubmissionError({_error: 'Something went wrong...'});
            }
        }
    };

    onDelete = listId => async () => {
        await axios.delete(createUrl('/lists/' + listId));
        this.setState(state => {
            let index = state.lists.findIndex(list => list.get('id') === listId);
            let currentListId = this.getCurrentListId();
            let listIds = state.lists.map(list => list.get('id'));
            let reloadOnUnMount = listIds.includes(currentListId);
            let currentListDeleted = listId === currentListId;
            return {lists: state.lists.delete(index), deleteRow: null, currentListDeleted, reloadOnUnMount};
        })
    };

    loadLists = async () => {
        let {activeUserId} = this.props;
        let [lists, hubs] = (await Promise.all([
            axios.get(createUrl('/lists'), {params: {ownedBy: activeUserId}}),
            axios.get(createUrl('/hubs?myRole=member'))
        ])).map(response => response.data);
        lists = fromJS(
            (await Promise.all(lists.map(list => axios.get(createUrl(list.links.self)))))
                .map(response => response.data)
        );
        this.setState({lists, hubs});
    };

    state = {
        editRow: null,
        deleteRow: null,
        makeEditRowDefault: false,
        reloadOnUnMount: false,
        currentListDeleted: false
    };

    componentDidMount = async () => {
        await this.loadLists();
    };

    componentWillUnmount = () => {
        let {history} = this.props;
        let {currentListDeleted, reloadOnUnMount, lists} = this.state;
        if (currentListDeleted) {
            let defaultList = lists.find(list => list.get('type') === 'origin' && list.get('defaultList'));
            history.push('/gift-tags/lists/' + defaultList.get('id'));
        }
        if (reloadOnUnMount) {
            window.location.reload();
        }
    };

    render() {
        let {handleSubmit} = this.props;
        let {lists} = this.state;
        let {AddList, ListDetails} = this;
        if (!lists) return null;
        let displayableLists = lists.sort(this.sortLists).reduce((aggregate, list) => {
            return [
                ...aggregate,
                <ListDetails key={list.get('id')} list={list} numberOfLists={lists.size} />
            ]
        }, []);
        return (
            <form onSubmit={handleSubmit(this.onSubmit)} className="manageListsModal">
                {displayableLists}
                <AddList />
            </form>
        );
    }
}

let mapStateToProps = state => {
    let activeUserId = state.users.getIn(['user', 'id']);
    return {
        activeUserId
    };
};

let reduxFormConfig = {
    form: 'manageLists'
};

let ReduxForm = reduxForm(reduxFormConfig)(ManageListsModal);
export default withRouter(connect(mapStateToProps)(ReduxForm));
