import React from 'react';
import classnames from 'classnames';

import ContentCard from '../content-card/content-card';

import './error-messenger.css'

export default function ErrorMessenger({children, className}) {
    if (!children) return null;
    return (
        <ContentCard className={classnames('errorMessenger', className)}>
            {children}
        </ContentCard>
    )
}
