import React from 'react';

export default function Cauldron2({className}) {
    return (
        <svg data-name="Layer 1" height="24" id="Layer_1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M16.28759,21.168c-1.0435,1.0434-2.1367,1.0373-2.8148.3592s-.6842-1.7713.35921-2.8148,3.41939-.9638,3.41939-.9638S17.331,20.1245,16.28759,21.168Z"
                fill="#fa6d62"/>
            <path
                d="M14.80979,21.3566c-.4956.4956-1.0149.4927-1.337.1706s-.325-.8414.1706-1.337a2.64059,2.64059,0,0,1,1.6242-.4578A2.64059,2.64059,0,0,1,14.80979,21.3566Z"
                fill="#fff4ab" />
            <path
                d="M7.71239,21.168c1.0435,1.0434,2.1367,1.0373,2.8148.3592s.6842-1.7713-.35919-2.8148-3.41941-.9638-3.41941-.9638S6.669,20.1245,7.71239,21.168Z"
                fill="#fa6d62" />
            <path
                d="M9.19019,21.3566c.4956.4956,1.0149.4927,1.337.1706s.325-.8414-.1706-1.337a2.64059,2.64059,0,0,0-1.6242-.4578A2.64059,2.64059,0,0,0,9.19019,21.3566Z"
                fill="#fff4ab" />
            <path d="M5.079,8A8,8,0,1,0,18.921,8Z" fill="#4b5661" />
            <circle cx="7.5" cy="5.5" r="1.5" fill="#67c7a5" />
            <circle cx="11" cy="2" r="1" fill="#67c7a5" />
            <circle cx="12.5" cy="7.5" r="2.5" fill="#67c7a5" />
            <path d="M20,10H4A1,1,0,0,1,3,9H3A1,1,0,0,1,4,8H20a1,1,0,0,1,1,1h0A1,1,0,0,1,20,10Z" fill="#4b5661" />
            <circle cx="17.5" cy="3.5" r="1.5" fill="#67c7a5" />
            <path d="M13.73629,19.7556C13.73629,21.2313,12.95889,22,12,22s-1.73631-.7687-1.73631-2.2444S12,16.6563,12,16.6563,13.73629,18.28,13.73629,19.7556Z"
                  fill="#fa6d62" />
            <path
                d="M12.82479,20.9339C12.82479,21.6348,12.45549,22,12,22s-.82481-.3652-.82481-1.0661A2.64038,2.64038,0,0,1,12,19.4617,2.64039,2.64039,0,0,1,12.82479,20.9339Z"
                fill="#fff4ab" />
        </svg>
    );
}
