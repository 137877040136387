import React from 'react';

export default function BookIcon12({className, colors = {}}) {
    let {
        primaryColor = '#323232'
    } = colors;
    return (
        <svg id="Layer_2" version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g>
                <path fill={primaryColor} d="M27.42999,26.16998c0.82001,0,1.5-0.67999,1.5-1.5V2c0-0.83002-0.67999-1.5-1.5-1.5H7.57001   c-2.47998,0-4.5,2.01996-4.5,4.5c0,0,0.01001,22.53998,0.02997,22.64001C3.26001,29.78998,5.04999,31.5,7.23999,31.5h20.19   c0.82001,0,1.5-0.67004,1.5-1.5c0-0.83002-0.67999-1.5-1.5-1.5h-1.72998v-2.33002H27.42999z M6.07001,5   c0-0.83002,0.67999-1.5,1.5-1.5h0.08997v19.66998H7.23999c-0.40997,0-0.78998,0.07001-1.16998,0.17999V5z M22.70001,28.5H7.23999   c-0.64001,0-1.16998-0.52002-1.16998-1.17004c0-0.63995,0.52997-1.15997,1.16998-1.15997h15.46002V28.5z"/>
            </g>
        </svg>
    );
}
