import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import axios from 'axios';

import {openModal as _openModal} from '../../../../redux/modal/modal-actions';
import {toggleLeftNav as _toggleLeftNav} from '../../../../redux/view/view-actions';
import {createUrl} from '../../../../util/formatters';

import './left-nav.css';

export class LeftNav extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        openModal: PropTypes.func.isRequired,
        pendingRequestStats: PropTypes.object.isRequired,
        toggleLeftNav: PropTypes.func.isRequired,
        visible: PropTypes.bool.isRequired
    };

    AccountInfo = () => {
        let {user, toggleLeftNav} = this.props;
        let fullName = user.getIn(['name', 'fullName']);
        let onClick = () => {
            toggleLeftNav();
            this.viewMyLists();
        };
        return (
            <button className="leftNav-accountInfo" onClick={onClick}>
                <div className="leftNav-name">{fullName}</div>
            </button>
        )
    };

    CloseButton = () => {
        let {toggleLeftNav} = this.props;
        return (
            <button onClick={toggleLeftNav} className="leftNav-closeButton">
                <div className="leftNav-closeButtonIcon" />
            </button>
        )
    };

    MenuOptions = () => {
        let {openModal, toggleLeftNav, history, pendingRequestStats} = this.props;
        let {enableSousChef = false} = this.state?.data || {};
        let {totalCount} = pendingRequestStats.toObject();
        return (
            <div className="leftNav-menuOptions">
                <div onClick={toggleLeftNav} className="leftNav-topMenuOptions">
                    <button
                        onClick={() => this.viewMyLists()}
                    >
                        <div className="leftNav-listsIcon" />
                        <div className="leftNav-menuOptionText">Your Lists</div>
                    </button>
                    <button
                        onClick={() => history.push('/gift-tags/hubs')}
                    >
                        <div className="leftNav-tagIcon" />
                        <div className="leftNav-menuOptionText">
                            <span>Tag Hubs</span>
                            {!!totalCount && <span className="leftNav-badge">{totalCount}</span>}
                        </div>
                    </button>
                    <button
                        onClick={() => openModal('MANAGE_LISTS', {}, {title: 'Manage Your Lists'})}
                    >
                        <div className="leftNav-listPreferencesIcon" />
                        <div className="leftNav-menuOptionText">Manage Your Lists</div>
                    </button>
                    <button
                        onClick={this.copyInviteLink}
                    >
                        <div className="leftNav-linkIcon" />
                        <div className="leftNav-menuOptionText">Copy Invite Link</div>
                    </button>
                    {enableSousChef && (
                        <button onClick={() => history.push('/sous-chef')}>
                            <div className="leftNav-logoutIcon" />
                            <div className="leftNav-menuOptionText">Open Sous-Chef</div>
                        </button>
                    )}
                    <button
                        onClick={() => openModal('SETTINGS_MODAL', {}, {title: 'Update Profile Settings'})}
                    >
                        <div className="leftNav-settingsIcon" />
                        <div className="leftNav-menuOptionText">Settings</div>
                    </button>
                </div>
                <NavLink to="/gift-tags/logout" className="leftNav-logout" onClick={toggleLeftNav}>
                    <div className="leftNav-logoutIcon" />
                    <div className="leftNav-menuOptionText">Logout</div>
                </NavLink>
            </div>
        );
    };

    copyInviteLink = () => {
        let inviteLinkInput = document.getElementById('leftNav-hiddenInput');
        inviteLinkInput.select();
        inviteLinkInput.setSelectionRange(0, 99999);
        document.execCommand('copy');
    };

    viewMyLists = async () => {
        let {history} = this.props;
        history.push('/gift-tags/lists');
    };

    state = {};

    addEvent(element, eventName, callback) {
        if (element.addEventListener) {
            element.addEventListener(eventName, callback, false);
        } else if (element.attachEvent) {
            element.attachEvent("on" + eventName, callback);
        } else {
            element["on" + eventName] = callback;
        }
    }

    componentDidMount = async () => {
        this.addEvent(document, 'keyup', e => {
            let {visible, toggleLeftNav} = this.props;
            if (e.key === 'Escape' && visible) toggleLeftNav();
        });
        try {
            let enableSousChef = (await axios.get(createUrl('/accesses/name/SOUS_CHEF'))).data.hasAccess;
            this.setState({data: {enableSousChef}})
        } catch (error) {
            console.error(error);
        }
    };

    render() {
        let {AccountInfo, MenuOptions, CloseButton} = this;
        let {visible, toggleLeftNav, activeUserId} = this.props;
        let style = {
            display: visible ? 'block' : 'none'
        };
        let inviteLink = `https://aggielacroix.com/gift-tags/register?inviteCode=${activeUserId}`;
        return (
            <div style={style}>
                <div className="leftNav-mask" onClick={toggleLeftNav} />
                <div className="leftNav">
                    <div className="leftNav-header">
                        <AccountInfo />
                        <CloseButton />
                    </div>
                    <MenuOptions />
                </div>
                <input type="text" readOnly id="leftNav-hiddenInput" value={inviteLink} />
            </div>
        );
    }
}

let mapStateToProps = state => {
    let activeUserId = state.users.getIn(['user', 'id']);
    let visible = state.view.get('leftNavExpanded');
    let user = state.users.get('user');
    return {
        activeUserId,
        user,
        visible,
    }
};

let actions = {
    openModal: _openModal,
    toggleLeftNav: _toggleLeftNav
};

export default connect(mapStateToProps, actions)(LeftNav);
