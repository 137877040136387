import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import axios from 'axios';

import {createUrl} from "../../../../util/formatters";
import Input from "../../../input/input";

import './edit-modal.css';

export class EditModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        giftUrl: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        saveGift: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired
    };

    validatePrice = value => {
        try {
            if (!value) return;
            let numericValue = Number(value);
            if (isNaN(numericValue)) return 'Please enter a valid number';

            let includesDecimal = value.toString().includes('.');
            let decimals = value.toString().split('.')[1];
            if (includesDecimal && decimals.length > 2) return 'Only two decimal places are allowed';
        } catch (error) {
            return 'Please enter a valid number';
        }
    };

    onSubmit = async formValues => {
        let {giftUrl, saveGift} = this.props;
        if (formValues.price) formValues.price = Number(formValues.price);
        else formValues.price = undefined;
        formValues.description = formValues.description || '';
        formValues.customLink = formValues.customLink || '';
        let updatedGift = (await axios.put(createUrl(giftUrl), formValues)).data;
        saveGift(updatedGift);
    };

    render() {
        let {closeModal, handleSubmit, submitting} = this.props;
        return (
            <form onSubmit={handleSubmit(this.onSubmit)} className="editModal">
                <div className="editModal-mainContent">
                    <label htmlFor="editModal-label1">Name</label>
                    <Field
                        name="label"
                        component={Input}
                        type="text"
                        id="editModal-label1"
                        className="editModal-label"
                        autoComplete="off"
                    />
                    <label htmlFor="editModal-description1">Description</label>
                    <Field
                        name="description"
                        component="textarea"
                        id="editModal-description1"
                        className="editModal-description"
                    />
                    <label htmlFor="editModal-customLink1">Website</label>
                    <Field
                        name="customLink"
                        component={Input}
                        type="text"
                        autoComplete="off"
                        id="editModal-customLink1"
                        className="editModal-customLink"
                    />
                    <label htmlFor="editModal-price1">Price</label>
                    <div className="editModal-priceContainer">
                        <span className="editModal-priceDollarSign">$</span>
                        <Field
                            name="price"
                            component={Input}
                            type="text"
                            autoComplete="off"
                            id="editModal-price1"
                            className="editModal-price"
                            fieldClassName="editModal-priceField"
                            validate={this.validatePrice}
                            normalize={value => value.replace(/[^0-9.]/g, '')}
                            inputMode="decimal"
                            pattern="^\d*\.?\d{0,2}$"
                        />
                    </div>
                </div>
                <div className="editModal-buttonContainer">
                    <button
                        type="button"
                        className="editModal-cancelButton"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="editModal-submitButton"
                    >
                        Save
                    </button>
                </div>
            </form>
        );
    }
}
let mapStateToProps = (state, ownProps) => {
    let {gift} = ownProps;
    return {
        initialValues: {
            label: gift.get('label'),
            description: gift.get('description'),
            customLink: gift.get('customLink'),
            price: gift.get('price'),
        },
        onSubmitSuccess: ownProps.closeModal
    }
};

let reduxFormConfig = {
    form: 'editGift',
    validate: values => {
        let errors = {};
        if (!values.label) errors._error = 'Required';
        return errors;
    }
};

let ReduxFormComponent = reduxForm(reduxFormConfig)(EditModal);
export default connect(mapStateToProps)(ReduxFormComponent);
