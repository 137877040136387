import Blender from './blender'
import Burger from './burger'
import Cake from './cake'
import Cauldron from './cauldron'
import Cauldron2 from './cauldron-2'
import ChefHat from './chef-hat'
import ChefHat2 from './chef-hat-2'
import ChickenDrum from './chicken-drum';
import Cookie from './cookie';
import CookingPot from './cooking-pot';
import CookingPot2 from './cooking-pot-2';
import Crab from './crab';
import CrunchyTaco from './crunchy-taco';
import DinnerPlate from './dinner-plate';
import DinnerPlate2 from './dinner-plate-2';
import DinnerPlateWithDome from './dinner-plate-with-dome';
import DinnerPlateWithDome2 from './dinner-plate-with-dome-2';
import Fish from './fish';
import ForkAndKnife from './fork-and-knife';
import ForkAndSpoon from './fork-and-spoon';
import Grill from './grill';
import Microwave from './microwave';
import Oven from './oven';
import Shrimp from './shrimp';
import SoupBowl from './soup-bowl';
import SoupBowl2 from './soup-bowl-2';
import Steak from './steak';
import Turkey from './turkey';

export default {
    ForkAndSpoon,
    ForkAndKnife,
    DinnerPlate,
    DinnerPlate2,
    DinnerPlateWithDome,
    DinnerPlateWithDome2,
    SoupBowl,
    SoupBowl2,
    ChefHat,
    ChefHat2,
    CookingPot,
    CookingPot2,
    Cauldron,
    Cauldron2,
    Grill,
    Microwave,
    Oven,
    Blender,
    Burger,
    Steak,
    ChickenDrum,
    Turkey,
    Crab,
    Fish,
    Shrimp,
    CrunchyTaco,
    Cake,
    Cookie,
}
