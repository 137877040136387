import React from 'react';

export default function Fish({className}) {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="Fish">
                <path
                    d="M452.25,256l17.5877-75.4248c4.0246-17.2373-15.3125-30.45-29.9243-20.4746L380.5877,200.876a193.2729,193.2729,0,0,1,18.551,20.2119,55.57,55.57,0,0,1,0,69.8257,193.524,193.524,0,0,1-18.551,20.2119l59.3252,40.7754c14.6118,9.9741,33.9489-3.2383,29.9243-20.4756Z"/>
                <path
                    d="M180.9121,377.3628a87.4367,87.4367,0,0,0,156.451-33.6875C298.95,366.0747,253.1,378.5,205.4124,378.5,197.188,378.5,188.9634,378.1509,180.9121,377.3628Z"/>
                <path
                    d="M337.3631,168.3262a87.4362,87.4362,0,0,0-156.451-33.6885c8.0513-.7871,16.2759-1.1377,24.5-1.1377C253.1,133.5,298.95,145.9258,337.3631,168.3262Z"/>
                <path
                    d="M378.6651,237.4888c-37.5293-46.8594-101.063-77.731-173.2825-77.731-66.4412,0-125.5056,26.1441-163.7593,66.8125l34.0408,29.4375L41.6233,285.4463c38.2515,40.667,97.3181,66.8115,163.7593,66.8115,72.22,0,135.7532-30.8706,173.2825-77.729A29.5487,29.5487,0,0,0,378.6651,237.4888Zm-249.5418,9.769a17.5,17.5,0,1,1,17.5-17.5A17.4991,17.4991,0,0,1,129.1233,247.2578Zm64.42,55.4971a13.1276,13.1276,0,0,1-21.7212-14.7486,58.4244,58.4244,0,0,0,0-64.0058,13.1251,13.1251,0,1,1,21.7212-14.74,84.6753,84.6753,0,0,1,0,93.4942Z"/>
            </g>
        </svg>
    );
}
