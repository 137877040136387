import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form'
import {NavLink} from "react-router-dom";
import axios from 'axios';
import queryString from 'qs';
import Toggle from 'react-toggle';

import {login as loginAction} from '../../../../redux/users/users-actions'
import {createUrl} from '../../../../util/formatters';
import Input from '../../../../components/input/input';

import './register.css';

export class Register extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        submitting: PropTypes.bool
    };

    toggleLiveEmailUpdates = event => {
        let {change} = this.props;
        let {checked} = event.target;
        change('liveEmailUpdates', checked);
    };

    onSubmit = async values => {
        try {
            let {login, history, location} = this.props;
            let {email, password, firstName, lastName, inviteCode, liveEmailUpdates} = values;
            let response = await axios.post(createUrl('/users'), {
                user: {
                    inviteCode,
                    email,
                    password,
                    name: {
                        first: firstName,
                        last: lastName
                    },
                    preferences: {
                        general: {liveEmailUpdates}
                    }
                }
            });
            login(response.data.user.token);
            let {redirectTo} = queryString.parse(location.search.slice(1));
            history.push(redirectTo || '/gift-tags');
        } catch (error) {
            if (error.response) {
                let errorResponse = error.response.data || {_error: 'Something went wrong...'};
                console.error(errorResponse);
                throw new SubmissionError(errorResponse);
            } else {
                console.error(error);
                throw new SubmissionError({_error: 'Something went wrong...'});
            }
        }
    };

    state = {hideInviteCode: false};

    componentDidMount() {
        let {location, change} = this.props;
        let {inviteCode} = queryString.parse(location.search.slice(1));
        if (inviteCode) {
            change('inviteCode', inviteCode);
            this.setState({hideInviteCode: true})
        }
    }

    render() {
        let {handleSubmit, submitting, location} = this.props;
        let {hideInviteCode} = this.state;
        let loginLink = '/gift-tags/login';
        let {redirectTo} = queryString.parse(location.search.slice(1));
        if (redirectTo) loginLink += `?redirectTo=${redirectTo}`;
        return (
            <form className="register" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="register-title">Create a User Account</div>
                {!hideInviteCode && <div className="register-helpText">
                    Note: For the time being, we are only accepting users that are referred via word of mouth.
                    If you don't have an Invite Code, ask another user for an Invite Link which can be copied from their side menu.
                </div>}
                {!hideInviteCode && <Field
                    name="inviteCode"
                    component={Input}
                    type="text"
                    placeholder="Invite Code"
                    className="register-input register-maskedInput"
                    autoComplete="off"
                    tabIndex="1"
                />}
                <Field
                    name="firstName"
                    component={Input}
                    type="text"
                    placeholder="First Name"
                    className="register-input"
                    tabIndex="1"
                />
                <Field
                    name="lastName"
                    component={Input}
                    type="text"
                    placeholder="Last Name"
                    className="register-input"
                    tabIndex="2"
                />
                <Field
                    name="email"
                    component={Input}
                    type="text"
                    placeholder="Email"
                    className="register-input"
                    tabIndex="3"
                />
                <Field
                    name="password"
                    component={Input}
                    type="password"
                    placeholder="Password"
                    className="register-input"
                    tabIndex="4"
                />
                <div className="register-preferences">
                    <div><Toggle onChange={this.toggleLiveEmailUpdates}/></div>
                    <div className="register-preferenceDescription">
                        <div>Enable Live Email Updates</div>
                        <div className="register-preferenceSubtext">Leave this off if you'd prefer fewer emails per day. This can be changed later in settings from the side menu.</div>
                    </div>
                </div>
                <div className="register-actions">
                    <div>
                        <span>Or </span>
                        <NavLink to={loginLink} tabIndex="6">
                            Login
                        </NavLink>
                    </div>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="register-submitButton"
                        tabIndex="5"
                    >
                        REGISTER
                    </button>
                </div>
            </form>
        );
    }
}

let reduxConfig = {
    form: 'register',
    initialValues: {'liveEmailUpdates': false}
};

let ReduxFormHOC = reduxForm(reduxConfig)(Register);
export default connect(null, {login: loginAction})(ReduxFormHOC);
