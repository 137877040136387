import React, {useState, useEffect} from 'react';
import {Field, Form} from 'react-final-form';
import axios from 'axios';
import Toggle from 'react-toggle';
import qs from 'qs';
import {FORM_ERROR} from 'final-form';
import {OnChange} from 'react-final-form-listeners';
import classnames from 'classnames';

import TitleBar from '../../components/title-bar/title-bar';
import ContentCard from '../../components/content-card/content-card';
import FieldLabel from '../../components/field-label/field-label';
import {createUrl} from '../../../../util/formatters';
import {compose, required} from '../../../../util/validation';
import Dropdown from '../../../../components/dropdown/dropdown';
import Spinner from '../../components/spinner/spinner';
import allBookIcons from '../../../../images/react-icons/books/all-book-icons';
import {COOKBOOK_COLORS} from '../../../../util/constants';
import ErrorMessenger from '../../components/error-messenger/error-messenger';

import './cookbook-form.css';

const FIELD_NAMES = {
    NAME: 'name',
    AUTHORS_SEARCH: 'authorsSearch',
    AUTHORS: 'authors',
    IS_PRIVATE: 'isPrivate',
    ICON: 'icon',
    THEME: 'theme'
};

export default function CookbookForm({match, history, location, toggleSidebar, updateTitleBar, reloadCookbook, cookbook}) {
    let {cookbookId} = match.params;
    let editingCookbook = !!cookbookId;

    let [data, setData] = useState();
    let [errorMessage, setErrorMessage] = useState();
    let [authorResults, setAuthorResults] = useState([]);
    let [usersById, setUsersById] = useState({});
    let [showDeleteSection, setShowDeleteSection] = useState(false);

    let loadData = async () => {
        try {
            let [{user}, cookbooks] = (await Promise.all([
                axios.get(createUrl('/users/current')),
                axios.get(createUrl('/cookbooks'))
            ])).map(response => response.data);

            let initialValues;
            if (editingCookbook) {
                if (!cookbook) {
                    setErrorMessage('Cookbook not found');
                    return;
                }
                let {name, authors, isPrivate, icon, theme} = cookbook;
                let authorIds = authors.map(author => author.id);
                let authorsById = authors.reduce((aggregate, author) => ({...aggregate, [author.id]: author}), {});
                setUsersById(authorsById);
                initialValues = {
                    [FIELD_NAMES.NAME]: name,
                    [FIELD_NAMES.AUTHORS]: authorIds,
                    [FIELD_NAMES.IS_PRIVATE]: isPrivate,
                    [FIELD_NAMES.ICON]: icon,
                    [FIELD_NAMES.THEME]: theme
                }
            } else {
                let {first: firstName} = user.name;
                let existingNames = cookbooks.map(currentCookbook => currentCookbook.name);
                let defaultName = `${firstName}'s Cookbook`;
                let counter = 1;
                while (existingNames.includes(defaultName)) defaultName = `${firstName}'s Cookbook v${++counter}.0`;
                let themes = Object.keys(COOKBOOK_COLORS);
                initialValues = {
                    [FIELD_NAMES.NAME]: defaultName,
                    [FIELD_NAMES.AUTHORS]: [],
                    [FIELD_NAMES.IS_PRIVATE]: false,
                    [FIELD_NAMES.ICON]: Math.floor(Math.random() * allBookIcons.length),
                    [FIELD_NAMES.THEME]: themes[Math.floor(Math.random() * themes.length)]
                };
            }
            setData({initialValues, user, cookbooks});
        } catch (error) {
            console.error(error);
            setErrorMessage('Oops, something went wrong... Try again later.');
        }
    };
    useEffect(() => {
        if (editingCookbook) updateTitleBar('Edit Cookbook', {enableSearch: false});
        loadData();
        return () => {
            if (editingCookbook) updateTitleBar()
        };
    }, []);

    let REFERRERS = {
        dashboard: '/sous-chef',
        cookbooks: '/sous-chef/cookbooks',
        cookbook: `/sous-chef/cookbooks/${cookbookId}`
    };
    let exit = () => {
        let {referrer} = qs.parse(location.search.slice(1));
        let returnUrl = REFERRERS[referrer] || REFERRERS.dashboard;
        history.push(returnUrl);
    };

    let validateName = value => {
        let {cookbooks} = data;
        let otherCookbooks = cookbooks.filter(currentCookbook => currentCookbook.id !== cookbookId);
        let existingNames = otherCookbooks.map(currentCookbook => currentCookbook.name);
        // Note: this only validates duplicates among the user's own cookbooks and doesn't check the other authors'. The server will check for those.
        if (value && existingNames.includes(value.trim())) return 'One of your cookbooks already uses that name';
    };

    let onSubmit = async values => {
        try {
            let {name, authors, authorsSearch, isPrivate, icon, theme} = values;
            if (authorsSearch) return {[FIELD_NAMES.AUTHORS_SEARCH]: 'Please select a valid author to add or clear your search before submitting'};
            if (editingCookbook) {
                let updates = {};
                if (name !== cookbook.name) updates.name = name;
                let allAuthorsMatch = authors.every((newAuthorId, index) => cookbook.authors[index] === newAuthorId);
                if (authors.length !== cookbook.authors.length || !allAuthorsMatch) updates.authors = authors;
                if (isPrivate !== cookbook.isPrivate) updates.isPrivate = isPrivate;
                if (icon !== cookbook.icon) updates.icon = icon;
                if (theme !== cookbook.theme) updates.theme = theme;
                await axios.put(createUrl('/cookbooks/' + cookbookId), updates);
                await reloadCookbook();
            } else {
                await axios.post(createUrl('/cookbooks'), {name, additionalAuthors: authors, isPrivate, icon, theme});
            }
            exit();
        } catch (error) {
            console.error(error);
            console.error('Server Response:', error.response?.data);
            if (error.response?.data) return error.response.data;
            else return {[FORM_ERROR]: 'Oops, something went wrong...'};
        }
    };

    let onDelete = async () => {
        try {
            await axios.delete(createUrl(`/cookbooks/${cookbookId}`));
        } catch (error) {
            console.error(error);
            console.error('Server Response:', error.response?.data);
        }
        history.push('/sous-chef/cookbooks');
    };

    let AuthorOption = ({user, change, values}) => {
        let onClick = () => {
            change(FIELD_NAMES.AUTHORS, [...values[FIELD_NAMES.AUTHORS], user.id]);
            change(FIELD_NAMES.AUTHORS_SEARCH, '');
        };
        return <button className="cookbookForm-authorOption" onClick={onClick}>{user.name.fullName}</button>;
    };

    let SelectedAuthors = ({values, change, blur}) => {
        let {user} = data;
        let authors = values[FIELD_NAMES.AUTHORS];
        let removingSelf = editingCookbook && authors.every(userId => userId !== user.id);
        let selectedUsers = authors.map(userId => usersById[userId]);
        let selectedUserComponents = selectedUsers.map(user => {
            let removeAuthor = () => {
                let userIndex = authors.findIndex(authorId => authorId === user.id);
                if (userIndex >= 0) {
                    authors.splice(userIndex, 1);
                    change(FIELD_NAMES.AUTHORS, [...authors]);
                    blur(FIELD_NAMES.AUTHORS_SEARCH);
                }
            };
            return (
                <div className="cookbookForm-author" key={user.id}>
                    <div className="cookbookForm-authorName">{user.name.fullName}</div>
                    <button className="cookbookForm-removeAuthorButton" onClick={removeAuthor} type="button">
                        <div className="cookbookForm-closeIcon" />
                    </button>
                </div>
            )
        });
        return (
            <div className="cookbookForm-selectedAuthors">
                {selectedUserComponents}
                {removingSelf && (
                    <div className="cookbookForm-note">
                        <div><b>WARNING</b></div>
                        <div>
                            You are about to <u>remove yourself as an author</u> and will no longer be able to modify this cookbook or re-add yourself later.
                            <a
                                onClick={() => change(FIELD_NAMES.AUTHORS, [user.id, ...values[FIELD_NAMES.AUTHORS]])}
                                className="cookbookForm-undoLink"
                            >Undo</a>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    let IconSelector = ({change, formProps, values}) => {
        let iconIndex = values[FIELD_NAMES.ICON] || 0;
        let Icon = allBookIcons[iconIndex];
        let theme = values[FIELD_NAMES.THEME] || 'RED';
        let colors = COOKBOOK_COLORS[theme];
        let iconPreviews = allBookIcons.map((IconPreview, index) => (
            <button
                type="button"
                className={classnames('cookbookForm-iconButton', {'cookbookForm-iconButton--selected': index === iconIndex})}
                onClick={() => change(FIELD_NAMES.ICON, index)}
                key={index}
            >
                <IconPreview
                    colors={colors}
                    className="cookbookForm-iconPreview"
                    key={index}
                />
            </button>
        ));
        let themePreviews = Object.keys(COOKBOOK_COLORS).map(themeKey => (
            <button
                type="button"
                className={classnames('cookbookForm-themeButton', {'cookbookForm-themeButton--selected': themeKey === theme})}
                style={{backgroundColor: COOKBOOK_COLORS[themeKey].primaryColor}}
                onClick={() => change(FIELD_NAMES.THEME, themeKey)}
                key={themeKey}
            />
        ));
        return (
            <div className="cookbookForm-fieldGroup cookbookForm-iconSelector">
                <FieldLabel fieldName={FIELD_NAMES.ICON} {...formProps}>Icon</FieldLabel>
                <div className="cookbookForm-iconField">
                    <Icon colors={colors} className="cookbookForm-icon" />
                    <div className="cookbookForm-iconPreviews">{iconPreviews}</div>
                    <div className="cookbookForm-themePreviews">{themePreviews}</div>
                </div>
            </div>
        );
    };

    let CookbookForm = formProps => {
        let {handleSubmit, form, error, submitError, submitting, values, initialValues} = formProps;
        let onChangeOfAuthorSearch = async value => {
            let name = value && value.trim();
            let users = name ? (await axios.get(createUrl('/users'), {params: {name}})).data : [];
            users = users
                .filter(user => (user.id !== data.user.id || editingCookbook) && !values[FIELD_NAMES.AUTHORS].includes(user.id))
                .slice(0, 10);
            let newUsersById = users.reduce((aggregate, user) => ({...aggregate, [user.id]: user}), {});
            setUsersById({...usersById, ...newUsersById});
            setAuthorResults(users.map(user => <AuthorOption user={user} change={form.change} values={values} key={user.id} />));
        };
        return (
            <>
                <ErrorMessenger className="cookbookForm-errorMessenger">{submitError || error}</ErrorMessenger>
                <ContentCard className="cookbookForm-contentCard">
                    <form onSubmit={handleSubmit} className="cookbookForm-form">
                        <div className="cookbookForm-fieldGroup">
                            <FieldLabel fieldName={FIELD_NAMES.NAME} {...formProps}>Title</FieldLabel>
                            <Field
                                name={FIELD_NAMES.NAME}
                                component="input"
                                autoComplete="off"
                                className="cookbookForm-input"
                                validate={compose(required, validateName)}
                            />
                        </div>
                        <div className="cookbookForm-fieldGroup cookbookForm-authorsFieldGroup">
                            <FieldLabel fieldName={FIELD_NAMES.AUTHORS_SEARCH} {...formProps}>{editingCookbook ? 'Authors' : 'Additional Authors'}</FieldLabel>
                            <Dropdown
                                value={(
                                    <Field
                                        name={FIELD_NAMES.AUTHORS_SEARCH}
                                        component="input"
                                        className="cookbookForm-input"
                                        autoComplete="off"
                                    />
                                )}
                                className="cookbookForm-dropdown"
                                hideIcon={true}
                                includeButton={false}
                            >{authorResults}</Dropdown>
                            <OnChange name={FIELD_NAMES.AUTHORS_SEARCH}>{onChangeOfAuthorSearch}</OnChange>
                        </div>
                        <SelectedAuthors values={values} change={form.change} blur={form.blur} />
                        <div className="cookbookForm-fieldGroup cookbookForm-toggleFieldGroup">
                            <FieldLabel
                                fieldName={FIELD_NAMES.IS_PRIVATE}
                                infoMessage="If enabled, only authors of this cookbook will be able to view it."
                                {...formProps}
                            >Keep Private</FieldLabel>
                            <Toggle
                                onChange={event => form.change(FIELD_NAMES.IS_PRIVATE, event.target.checked)}
                                defaultChecked={initialValues[FIELD_NAMES.IS_PRIVATE]}
                                className="cookbookForm-toggle"
                            />
                        </div>
                        <IconSelector values={values} change={form.change} formProps={formProps} />
                        {!editingCookbook && <div className="cookbookForm-note">You can always come back later to modify any of these values.</div>}
                        {!showDeleteSection && (
                            <div className="cookbookForm-buttonGroup">
                                <div className="cookbookForm-secondaryButtons">
                                    <button className="cookbookForm-cancelButton" type="button" onClick={() => exit()}>Cancel</button>
                                    {editingCookbook && !cookbook?.pendingDeletionAsOf && (
                                        <button
                                            className="cookbookForm-deleteRecipeButton"
                                            type="button"
                                            onClick={() => setShowDeleteSection(true)}
                                        >
                                            Delete&nbsp;Cookbook
                                        </button>
                                    )}
                                </div>
                                <button className="cookbookForm-submitButton" type="submit">{editingCookbook ? 'Update' : 'Create'}</button>
                            </div>
                        )}
                        {showDeleteSection && (
                            <div className="cookbookForm-deleteSection">
                                <div>
                                    <div className="cookbookForm-deletionPrompt">Are you sure you want to delete this cookbook?</div>
                                    <div className="cookbookForm-deletionHelpText">Deleted cookbooks can be restored for up to 30 days.</div>
                                </div>
                                <div className="cookbookForm-deletionButtonSection">
                                    <button
                                        className="cookbookForm-deletionYesButton"
                                        type="button"
                                        onClick={onDelete}
                                    >Yes</button>
                                    <button
                                        className="cookbookForm-deletionNoButton"
                                        type="button"
                                        onClick={() => setShowDeleteSection(false)}
                                    >No</button>
                                </div>
                            </div>
                        )}
                    </form>
                </ContentCard>
                <Spinner active={submitting} />
            </>
        )
    };

    let content;
    if (data) {
        let {initialValues} = data;
        let validate = values => {
            let authors = values[FIELD_NAMES.AUTHORS];
            if (editingCookbook && (!authors || !authors.length)) return {[FIELD_NAMES.AUTHORS_SEARCH]: 'At least 1 author is required'}
        };
        content = <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>{CookbookForm}</Form>;
    } else if (errorMessage) {
        content = <ErrorMessenger className="cookbookForm-errorMessenger">{errorMessage}</ErrorMessenger>
    }
    return (
        <div className="cookbookForm">
            {!editingCookbook && <TitleBar title="Create a Cookbook" toggleSidebar={toggleSidebar} />}
            {content}
        </div>
    )
}
