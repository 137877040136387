import React from 'react';
import {reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Map} from 'immutable';

import {createUrl} from "../../../../util/formatters";

import './copy-modal.css';

export class CopyModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        copyGiftUrl: PropTypes.string,
        giftId: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        listId: PropTypes.string.isRequired,
        ownedOriginLists: PropTypes.instanceOf(Map).isRequired,
        removeGift: PropTypes.func.isRequired,
        reloadGifts: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        transferGiftUrl: PropTypes.string
    };

    onSubmit = async formValues => {
        let {copyGiftUrl, transferGiftUrl, closeModal, removeGift, giftId, listId, reloadGifts} = this.props;
        await axios.post(createUrl(copyGiftUrl || transferGiftUrl), formValues);
        if (transferGiftUrl) removeGift(giftId);
        if (transferGiftUrl || listId === formValues.toListId) await reloadGifts();
        closeModal();
    };

    render() {
        let {handleSubmit, ownedOriginLists, closeModal, submitting, transferGiftUrl} = this.props;
        let options = ownedOriginLists.reduce((collection, list) => {
            return [
                ...collection,
                <option value={list.get('id')} key={list.get('id')}>
                    {list.get('name')}
                </option>
            ]
        }, []);
        let label = transferGiftUrl ? 'Transfer' : 'Copy';
        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="copyModal-mainContent">
                    <label htmlFor="copyModal-toList1">{label} To List</label>
                    <Field
                        name="toListId"
                        component="select"
                        id="copyModal-toList1"
                        className="copyModal-toList"
                    >
                        {options}
                    </Field>
                </div>
                <div className="copyModal-buttonContainer">
                    <button
                        type="button"
                        className="copyModal-cancelButton"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="copyModal-submitButton"
                    >
                        {label}
                    </button>
                </div>
            </form>
        );
    }
}

let mapStateToProps = (state, ownProps) => {
    let {ownedOriginLists} = ownProps;
    return {
        initialValues: {
            toListId: ownedOriginLists.first().get('id')
        }
    }
};

let reduxFormConfig = {
    form: 'copyGift'
};

let ReduxFormComponent = reduxForm(reduxFormConfig)(CopyModal);
export default connect(mapStateToProps)(ReduxFormComponent);
