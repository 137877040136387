import React from 'react';

export default function Microwave({className}) {
    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M0,6V27H32V6H0ZM1,26V7H24V26H1Zm30,0H25V7h6V26Z"/>
            <path d="M28,12a2,2,0,1,0-2-2A2,2,0,0,0,28,12Zm0-3a1,1,0,1,1-1,1A1,1,0,0,1,28,9Z"/>
            <circle cx="28" cy="17" r="1"/>
            <circle cx="28" cy="21" r="1"/>
            <path
                d="M19.5,9H5.5A2.5,2.5,0,0,0,3,11.5v10A2.5,2.5,0,0,0,5.5,24h14A2.5,2.5,0,0,0,22,21.5v-10A2.5,2.5,0,0,0,19.5,9ZM21,21.5A1.5,1.5,0,0,1,19.5,23H5.5A1.5,1.5,0,0,1,4,21.5v-10A1.5,1.5,0,0,1,5.5,10h14A1.5,1.5,0,0,1,21,11.5v10Z"/>
        </svg>
    );
}
