import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import axios from 'axios';

import Input from '../../../input/input';
import {createUrl} from '../../../../util/formatters';
import {emailRegex} from '../../../../util/constants';

import './invite-modal.css';

export class InviteModal extends React.Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hub: PropTypes.object.isRequired,
        loadRequests: PropTypes.func.isRequired,
        pendingRequests: PropTypes.array.isRequired,
        recipient: PropTypes.string,
        recipientCount: PropTypes.number,
        submitting: PropTypes.bool
    };

    Errors = () => {
        let {errors} = this.state;
        if (!errors || !errors.length) return null;
        let messages = errors.map((error, index) => <li key={index}>{error}</li>);
        return <ul className="inviteModal-errors">{messages}</ul>;
    };

    Recipients = () => {
        let {recipients, users = {}} = this.state;
        if (!recipients.length) return null;
        let recipientComponents = recipients.map((recipientConfig, index) => {
            let label = recipientConfig.recipient;
            if (recipientConfig.recipientType === 'User') {
                label = users[recipientConfig.recipient].name.fullName;
            }
            return (
                <div key={index} className="inviteModal-recipient">
                    <div className="inviteModal-recipientLabel">{label}</div>
                    <button
                        className="inviteModal-removeRecipientButton"
                        type="button"
                        onClick={this.removeRecipient(recipientConfig.recipient)}
                    >
                        <div className="inviteModal-closeWhiteIcon" />
                    </button>
                </div>
            )
        });
        return <div className="inviteModal-recipients">{recipientComponents}</div>;
    };

    Users = () => {
        let {recipient, hub, pendingRequests} = this.props;
        let {users, selectedUserIds, recipients} = this.state;
        if (!users || !selectedUserIds) return null;

        let userRows = selectedUserIds.sort(this.sortUsers).map(userId => {
            let user = users[userId];
            let userAlreadyJoined = hub.directory.find(record => record.member.id === userId);
            let foundPendingRequest = pendingRequests.find(request => request.recipientType === 'User' && request.recipient.id === userId);
            let userAlreadySelected = recipients.find(recipientConfig => recipientConfig.recipient === userId);
            let onClick = this.selectRecipient('User', userId);
            let label;
            if (userAlreadyJoined) {
                label = userId === hub.ownedBy ? 'owner' : userAlreadyJoined.role;
                label = <div className="inviteModal-rank">{label}</div>;
            } else if (foundPendingRequest) {
                label = <div className="inviteModal-pendingLabel">Invite Pending</div>;
            } else if (userAlreadySelected) {
                label = <div className="inviteModal-selectedLabel">Selected</div>;
                onClick = this.removeRecipient(userId);
            }
            return (
                <button
                    className="inviteModal-userButton"
                    type="button"
                    onClick={onClick}
                    disabled={userAlreadyJoined || foundPendingRequest}
                    key={userId}
                >
                    <div>{user.name.fullName}</div>
                    {label}
                </button>
            );
        });
        if (emailRegex.test(recipient)) {
            let foundPendingRequest = pendingRequests.find(request => request.recipientType === 'Email' && request.recipient === recipient);
            let emailAlreadySelected = recipients.find(recipientConfig => recipientConfig.recipient === recipient);
            let onClick = this.selectRecipient('Email', recipient);
            let label;
            if (foundPendingRequest) {
                label = <div className="inviteModal-pendingLabel">Invite Pending</div>;
            } else if (emailAlreadySelected) {
                label = <div className="inviteModal-selectedLabel">Selected</div>;
                onClick = this.removeRecipient(recipient);
            }
            userRows.push(
                <button
                    className="inviteModal-userButton"
                    type="button"
                    onClick={onClick}
                    disabled={foundPendingRequest}
                    key="email"
                >
                    <div>Send Invite to "{recipient}"...</div>
                    {label}
                </button>
            );
        }
        if (recipient && !userRows.length) {
            userRows = <div className="inviteModal-noUsers">No users found</div>
        }
        return (
            <div className="inviteModal-users">
                {userRows}
            </div>
        );
    };

    onSubmit = async () => {
        let {hub, closeModal, loadRequests} = this.props;
        let {recipients, users = {}} = this.state;
        let requestData = {
            type: 'hubInvite',
            senderType: 'Hub',
            sender: hub.id
        };
        let statuses = await Promise.all(
            recipients.map(async ({recipient, recipientType}) => {
                try {
                    let response = await axios.post(createUrl('/requests'), {
                        ...requestData,
                        recipient,
                        recipientType
                    });
                    return response.status;
                } catch (error) {
                    return error.response ? error.response.status : 500;
                }
            })
        );
        await loadRequests();
        let errors = statuses.reduce((aggregate, status, index) => {
            if (status >= 200 && status <= 299) {
                return aggregate;
            } else {
                let {recipient, recipientType} = recipients[index];
                if (recipientType === 'User') recipient = users[recipient].name.fullName;
                let message = status === 409
                    ? `There is already a pending request for ${recipient}`
                    : `There was a problem with sending an invite to ${recipient}`;
                return [...aggregate, message];
            }
        }, []);
        if (errors.length) {
            statuses.forEach((status, index) => {
                if (status >= 200 && status <= 299) this.removeRecipient(recipients[index].recipient)();
            });
            this.setState({errors});
        } else {
            closeModal();
        }
    };

    sortUsers = (userIdA, userIdB) => {
        let {users} = this.state;
        let {first: firstA, last: lastA} = users[userIdA].name;
        let {first: firstB, last: lastB} = users[userIdB].name;

        let firstDifference = firstA.localeCompare(firstB);
        if (firstDifference) return firstDifference;

        return lastA.localeCompare(lastB);
    };

    loadUsers = async params => {
        let users = (await axios.get(createUrl('/users'), {params}))
            .data
            .reduce((aggregate, user) => ({
                ...aggregate,
                [user.id]: user
            }), {});
        this.setState(({users: previousUsers = {}}) => ({
            users: {...previousUsers, ...users},
            selectedUserIds: Object.keys(users)
        }));
    };

    onRecipientChange = async () => {
        let {recipient} = this.props;
        let params = {name: recipient, limit: 10};
        if (recipient) {
            await this.loadUsers(params);
        } else {
            this.setState({selectedUserIds: []});
        }
    };

    selectRecipient = (recipientType, recipient) => () => {
        let {change} = this.props;
        this.setState(state => {
            let recipients = [...state.recipients, {recipientType, recipient}];
            change('recipientCount', recipients.length);
            return {recipients}
        });
        document.getElementById('inviteModal-userInput').focus();
    };

    removeRecipient = recipient => () => {
        let {change} = this.props;
        this.setState(state => {
            let recipients = state.recipients.filter(recipientConfig => recipientConfig.recipient !== recipient);
            change('recipientCount', recipients.length);
            return {recipients};
        });
    };

    clearRecipientInput = () => {
        this.props.change('recipient', '');
        document.getElementById('inviteModal-userInput').focus();
    };

    onKeyPress = event => {
        if (event.key === 'Enter') event.preventDefault();
    };

    state = {
        recipients: []
    };

    componentDidMount = async () => {
        let {change} = this.props;
        change('recipientCount', 0);
        document.getElementById('inviteModal-userInput').focus();
    };

    componentDidUpdate = async prevProps => {
        if (prevProps.recipient !== this.props.recipient) await this.onRecipientChange();
    };

    render() {
        let {Errors, Recipients, Users} = this;
        let {closeModal, handleSubmit, submitting, valid, recipient, recipientCount} = this.props;
        let disabled = submitting || !valid;
        let clearButton = recipient && (
            <button className="inviteModal-searchInputCloseButton" onClick={this.clearRecipientInput} type="button">
                <div className="inviteModal-closeBlackIcon" />
            </button>
        );
        return (
            <form className="inviteModal" onSubmit={handleSubmit(this.onSubmit)} onKeyPress={this.onKeyPress}>
                <div className="inviteModal-mainContent">
                    <label htmlFor="inviteModal-userInput">Enter a Name or Email Address</label>
                    <div className="inviteModal-searchInputWrapper">
                        <Field
                            name="recipient"
                            component={Input}
                            type="text"
                            autoComplete="off"
                            placeholder="Recipient"
                            className="inviteModal-searchInput"
                            id="inviteModal-userInput"
                        />
                        {clearButton}
                    </div>
                    <Errors />
                    <Recipients />
                    <Users />
                </div>
                <div className="inviteModal-buttonContainer">
                    <button
                        type="button"
                        className="inviteModal-cancelButton"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={disabled}
                        className="inviteModal-submitButton"
                    >
                        Invite {!!recipientCount && `(${recipientCount})`}
                    </button>
                </div>
            </form>
        );
    }
}

let mapStateToProps = state => ({
    recipient: formValueSelector('inviteMembers')(state, 'recipient'),
    recipientCount: formValueSelector('inviteMembers')(state, 'recipientCount') || 0
});

let reduxFormConfig = {
    form: 'inviteMembers',
    validate: values => {
        let errors = {};
        if (!values.recipientCount) errors._error = 'Must select at least one recipient';
        return errors;
    }
};

let ReduxForm = reduxForm(reduxFormConfig)(InviteModal);
export default connect(mapStateToProps)(ReduxForm);