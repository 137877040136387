import React from 'react';

export default function BookIcon2({className, colors = {}}) {
    let {
        primaryColor = '#323232',
        primaryShade = '#161616',
        white = '#FFFFFF'
    } = colors;
    return (
        <svg id="Layer_1" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g>
                <path fill={white}
                      d="M407.5,144.1l-2.5-1.3v-72H148.1c-20.5,0-37.1,16.6-37.1,37.1l0,0c0,1.2,0.1,2.3,0.2,3.4H111v328.1   c0,16.3,13.2,29.5,29.5,29.5h239c16.3,0,29.5-13.2,29.5-29.5V144.2L407.5,144.1z"/>
                <path fill={primaryShade}
                      d="M407.7,144.1l-2.1-1.3v-72H183.4c-17.7,0-32.1,16.6-32.1,37.1l0,0c0,1.2,0.1,2.3,0.1,3.4h-0.1v328.1   c0,16.3,11.4,29.5,25.5,29.5h206.7c14.1,0,25.5-13.2,25.5-29.5V144.2L407.7,144.1z"/>
                <path fill={primaryColor}
                      d="M382.2,144.1l-2.1-1.3v-72H162.6c-17.3,0-31.4,16.6-31.4,37.1l0,0c0,1.2,0.1,2.3,0.1,3.4h-0.1v328.1   c0,16.3,11.2,29.5,25,29.5h202.4c13.8,0,25-13.2,25-29.5V144.2L382.2,144.1z"/>
                <path fill={white}
                      d="M405,144.7v-44c0-7.2-5.9-13.1-13.2-13.1H149.3c-16.7,0-30.2,13.5-30.2,30.2v12.9c0,0,10.4,13.1,33.4,13.9   C175.5,145.5,405,144.7,405,144.7z"/>
                <path fill={white}
                      d="M141.2,407c-1.9,0-3.5-1.6-3.5-3.5v-32.8c0-1.9,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5v32.8   C144.7,405.4,143.1,407,141.2,407z"/>
                <path d="M111,234c-3.3,0-6-2.7-6-6v-21.1c0-3.3,2.7-6,6-6s6,2.7,6,6V228C117,231.3,114.3,234,111,234z"/>
                <g><path d="M409,316c-3.3,0-6,2.7-6,6v118c0,13-10.5,23.5-23.5,23.5h-239c-13,0-23.5-10.5-23.5-23.5V242c0-3.3-2.7-6-6-6s-6,2.7-6,6    v198c0,19.6,15.9,35.5,35.5,35.5h239c19.6,0,35.5-15.9,35.5-35.5V322C415,318.7,412.3,316,409,316z"/>
                    <path
                        d="M414.9,144c0-0.1,0-0.1,0-0.2c0-0.3-0.1-0.5-0.2-0.8c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.5-0.3-0.7c0,0,0-0.1-0.1-0.1    c-0.1-0.2-0.3-0.5-0.4-0.7c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.3-0.4-0.5-0.6c0,0,0-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.5    c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.3-0.7-0.4c0,0,0,0,0,0c-0.2-0.1-0.5-0.2-0.7-0.3c0,0-0.1,0-0.1,0v-38.4V70.7c0-3.3-2.7-6-6-6    H148.1c-23.8,0-43.1,19.3-43.1,43.1c0,1,0,2,0.1,3c-0.1,0.4-0.1,0.8-0.1,1.1v80.6c0,3.3,2.7,6,6,6s6-2.7,6-6v-54.9    c7.9,8.2,18.9,13.3,31.1,13.3H403v156.3c0,3.3,2.7,6,6,6s6-2.7,6-6V144.9C415,144.6,415,144.3,414.9,144z M399,138.9H148.1    c-9.1,0-17.3-3.9-23-10.2v-10.8c0-13.3,10.9-24.2,24.2-24.2h242.5c4,0,7.2,3.2,7.2,7.1V138.9z M399,83.1c-2.2-0.9-4.7-1.4-7.2-1.4    H149.3c-13.3,0-24.9,7.2-31.2,17.8c3.6-13.1,15.7-22.8,30-22.8H399V83.1z"/></g>
                <path fill={white}
                      d="M141.2,191.1c-1.9,0-3.5-1.6-3.5-3.5c0-15.5,12.6-28.1,28.1-28.1c1.9,0,3.5,1.6,3.5,3.5s-1.6,3.5-3.5,3.5   c-11.6,0-21.1,9.5-21.1,21.1C144.7,189.5,143.1,191.1,141.2,191.1z"/>
                <path fill={white}
                      d="M141.2,209.5c-1.9,0-3.5-1.6-3.5-3.5v-5.5c0-1.9,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5v5.5   C144.7,207.9,143.1,209.5,141.2,209.5z"/>
                <path fill={white}
                      d="M302.2,269.8h-89.7c-6.3,0-11.5-5.1-11.5-11.5v-43.6c0-6.3,5.1-11.5,11.5-11.5h89.7c6.3,0,11.5,5.1,11.5,11.5   v43.6C313.7,264.6,308.6,269.8,302.2,269.8z"/>
                <path
                    d="M302.2,275.8h-89.7c-9.6,0-17.5-7.9-17.5-17.5v-43.6c0-9.6,7.9-17.5,17.5-17.5h89.7c9.6,0,17.5,7.9,17.5,17.5v43.6   C319.7,267.9,311.8,275.8,302.2,275.8z M212.5,209.2c-3,0-5.5,2.5-5.5,5.5v43.6c0,3,2.5,5.5,5.5,5.5h89.7c3,0,5.5-2.5,5.5-5.5   v-43.6c0-3-2.5-5.5-5.5-5.5H212.5z"/>
            </g>
        </svg>
    );
}
