export let required = value => value ? undefined : 'Required';

export let validateUrl = value => {
    let validUrl;
    try {
        let url = new URL(value);
        validUrl = url.protocol === "http:" || url.protocol === "https:";
    } catch (error) {
        validUrl = false;
    }
    if (!validUrl) return 'Invalid URL';
};

export let validateOptionalUrl = value => {
    if (value) return validateUrl(value);
};

export let validateNonInternalUrl = value => {
    if (!value) return;
    if (value.includes('aggielacroix.com')) return 'Must not be a link to aggielacroix.com';
};

export let validateNumber = value => {
    if (value && isNaN(value)) return 'Enter a valid number'
};

export let compose = (validator, ...otherValidators) => value => {
    if (!validator) return;
    return validator(value) || compose(...otherValidators)(value);
};
