import React from 'react';

export default function Burger({className}) {
    let color1 = '#DEB198';
    let color2 = '#F69B77';
    let color3 = '#FAE58F';
    let color4 = '#ACD263';
    let color5 = '#000000';
    let color6 = '#F16A6D';
    return (
        <svg height="91px" viewBox="0 0 91 91" width="91px" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g>
                <g>
                    <g>
                        <path
                            d="M69.159,32.057l-3.406,9.89c-0.118,0.345-0.443,0.563-0.789,0.563c-0.089,0-0.183-0.016-0.271-0.046     c-0.435-0.149-0.667-0.625-0.516-1.061l3.478-10.096c-5.574-2.675-11.995-4.347-18.795-4.717l-4.17,15.306     c-0.103,0.371-0.438,0.615-0.805,0.615c-0.072,0-0.147-0.01-0.221-0.031c-0.442-0.121-0.706-0.58-0.584-1.024l4.063-14.921     c-0.388-0.01-0.77-0.039-1.159-0.039c-2.458,0-4.875,0.181-7.233,0.506l-1.668,0.279c-3.177,0.547-6.222,1.379-9.097,2.463     l-3.938,12.187c-0.114,0.355-0.442,0.58-0.795,0.58c-0.083,0-0.17-0.014-0.254-0.041c-0.438-0.144-0.681-0.611-0.539-1.049     l3.517-10.885C16.297,34.706,8.903,41.799,6.114,50.401h79.74C83.379,42.768,77.267,36.328,69.159,32.057z"
                            fill={color1}/>
                        <path
                            d="M80.889,52.072H11.018v3.613c0.178-0.02,0.362-0.004,0.523,0.093l8.184,4.907l8.187-4.907     c0.264-0.159,0.592-0.159,0.859,0l8.175,4.907l8.186-4.907c0.264-0.159,0.593-0.159,0.857,0l8.183,4.907l8.184-4.907     c0.266-0.159,0.593-0.159,0.857,0l8.183,4.907l8.18-4.907c0.264-0.159,0.592-0.159,0.855,0l0.458,0.273V52.072z"
                            fill={color2}/>
                        <path d="M8.257,73.064c0,2.937,2.39,5.324,5.324,5.324h64.799c2.937,0,5.328-2.388,5.328-5.324v-3.301H8.257     V73.064z"
                              fill={color3}/>
                        <path
                            d="M71.824,62.375c-0.035,0.021-0.073,0.023-0.11,0.039c-0.053,0.023-0.103,0.045-0.159,0.056     c-0.054,0.012-0.104,0.008-0.159,0.008c-0.054,0-0.104,0.004-0.161-0.008c-0.054-0.011-0.103-0.032-0.154-0.054     c-0.037-0.018-0.078-0.02-0.113-0.041l-8.183-4.907l-8.184,4.907c-0.004,0.002-0.01,0.002-0.014,0.002     c-0.126,0.074-0.27,0.116-0.415,0.116s-0.289-0.042-0.415-0.116c-0.004,0-0.01,0-0.014-0.002l-8.183-4.907l-8.188,4.907     c-0.006,0.002-0.01,0.002-0.016,0.004c-0.021,0.012-0.042,0.016-0.066,0.025c-0.097,0.045-0.2,0.071-0.3,0.077     c-0.018,0-0.031,0.012-0.047,0.012c-0.021,0-0.042-0.016-0.064-0.018c-0.104-0.008-0.204-0.036-0.297-0.081     c-0.021-0.012-0.048-0.008-0.07-0.02l-8.173-4.907l-8.188,4.907c-0.005,0.002-0.009,0.002-0.015,0.004     c-0.021,0.012-0.041,0.014-0.064,0.023c-0.099,0.047-0.2,0.073-0.303,0.079c-0.018,0-0.031,0.012-0.046,0.012     c-0.023,0-0.043-0.016-0.064-0.018c-0.104-0.008-0.206-0.036-0.298-0.083c-0.021-0.01-0.045-0.006-0.066-0.018l-8.184-4.907     l-0.095,0.057v10.57h69.871V57.997l-0.885-0.529L71.824,62.375z"
                            fill={color4}/>
                    </g>
                    <g>
                        <path
                            d="M86.977,53.501H4.995c-0.704,0-1.367-0.327-1.792-0.888c-0.427-0.559-0.566-1.286-0.378-1.963     c4.362-15.779,22.514-27.229,43.161-27.229c20.648,0,38.8,11.452,43.162,27.229c0.188,0.677,0.047,1.404-0.378,1.963     C88.343,53.174,87.679,53.501,86.977,53.501z M8.15,48.997h75.671c-5.146-12.369-20.48-21.072-37.836-21.072     C28.631,27.925,13.296,36.628,8.15,48.997z"
                            fill={color5}/>
                        <path
                            d="M78.383,81.488H13.583c-4.638,0-8.41-3.772-8.41-8.41v-4.136c0-1.245,1.007-2.254,2.252-2.254h77.12     c1.243,0,2.252,1.009,2.252,2.254v4.136C86.797,77.716,83.021,81.488,78.383,81.488z M9.677,71.194v1.884     c0,2.153,1.753,3.906,3.906,3.906h64.799c2.154,0,3.91-1.753,3.91-3.906v-1.884H9.677z"
                            fill={color5}/>
                        <path
                            d="M23.256,43.941c-0.23,0-0.463-0.037-0.694-0.111c-1.183-0.382-1.833-1.652-1.451-2.835l4.071-12.604     c0.384-1.183,1.658-1.831,2.835-1.451c1.184,0.382,1.833,1.652,1.451,2.836l-4.071,12.604     C25.089,43.333,24.207,43.941,23.256,43.941z"
                            fill={color5}/>
                        <path
                            d="M43.886,43.941c-0.196,0-0.396-0.027-0.593-0.082c-1.201-0.326-1.909-1.563-1.581-2.764l4.362-16.014     c0.327-1.2,1.563-1.91,2.766-1.581c1.2,0.326,1.908,1.563,1.581,2.764l-4.362,16.013C45.785,43.281,44.877,43.941,43.886,43.941z     "
                            fill={color5}/>
                        <path
                            d="M64.966,43.941c-0.242,0-0.49-0.041-0.733-0.124c-1.175-0.406-1.802-1.688-1.396-2.863l3.754-10.891     c0.405-1.176,1.684-1.8,2.862-1.395c1.176,0.406,1.802,1.688,1.396,2.863L67.096,42.42C66.773,43.353,65.9,43.941,64.966,43.941z     "
                            fill={color5}/>
                        <path
                            d="M81.725,68.683c-1.243,0-2.252-1.009-2.252-2.254V51.748c0-1.243,1.009-2.252,2.252-2.252     c1.245,0,2.252,1.009,2.252,2.252v14.681C83.977,67.674,82.97,68.683,81.725,68.683z"
                            fill={color5}/>
                        <path
                            d="M10.185,68.683c-1.245,0-2.251-1.009-2.251-2.254V51.748c0-1.243,1.006-2.252,2.251-2.252     c1.244,0,2.252,1.009,2.252,2.252v14.681C12.437,67.674,11.429,68.683,10.185,68.683z"
                            fill={color5}/>
                        <path
                            d="M19.725,63.925c-0.396,0-0.793-0.103-1.156-0.322l-7.454-4.469l-7.456,4.471     c-1.064,0.637-2.45,0.293-3.09-0.773c-0.638-1.067-0.292-2.45,0.774-3.09l8.614-5.165c0.712-0.427,1.604-0.427,2.316,0     l8.612,5.165c1.067,0.64,1.412,2.022,0.774,3.09C21.235,63.535,20.489,63.925,19.725,63.925z"
                            fill={color5}/>
                        <path
                            d="M19.729,63.925c-0.767,0-1.513-0.39-1.934-1.094c-0.64-1.067-0.293-2.452,0.774-3.09l8.616-5.165     c0.712-0.429,1.604-0.427,2.316,0l8.604,5.165c1.067,0.64,1.412,2.022,0.772,3.09c-0.64,1.066-2.023,1.412-3.09,0.771     l-7.446-4.469l-7.456,4.471C20.521,63.822,20.122,63.925,19.729,63.925z"
                            fill={color5}/>
                        <path
                            d="M36.948,63.925c-0.766,0-1.513-0.39-1.934-1.094c-0.64-1.067-0.293-2.452,0.774-3.09l8.616-5.165     c0.712-0.427,1.604-0.427,2.315,0l8.612,5.165c1.066,0.64,1.414,2.022,0.773,3.09c-0.64,1.066-2.022,1.412-3.09,0.773     l-7.453-4.471l-7.458,4.471C37.742,63.822,37.342,63.925,36.948,63.925z"
                            fill={color5}/>
                        <path
                            d="M54.176,63.925c-0.765,0-1.511-0.39-1.934-1.094c-0.639-1.067-0.293-2.45,0.773-3.09l8.612-5.165     c0.712-0.427,1.604-0.427,2.317,0l8.61,5.165c1.066,0.64,1.412,2.022,0.774,3.09c-0.643,1.066-2.023,1.412-3.092,0.771     l-7.452-4.469l-7.454,4.469C54.971,63.822,54.571,63.925,54.176,63.925z"
                            fill={color5}/>
                        <path
                            d="M88.616,63.925c-0.395,0-0.794-0.103-1.156-0.32l-7.456-4.471l-7.448,4.469     c-1.066,0.641-2.45,0.295-3.09-0.771c-0.64-1.067-0.295-2.45,0.772-3.09l8.607-5.165c0.714-0.427,1.604-0.429,2.316,0     l8.613,5.165c1.067,0.638,1.414,2.022,0.774,3.09C90.127,63.535,89.382,63.925,88.616,63.925z"
                            fill={color5}/>
                        <g>
                            <polyline fill={color6} points="37.916,32.866 37.916,11.85 47.457,15.482 39.427,20.178     "/>
                            <path
                                d="M37.916,35.118c-1.243,0-2.251-1.009-2.251-2.252V11.85c0-0.739,0.364-1.434,0.974-1.854      c0.611-0.419,1.391-0.512,2.079-0.25l9.541,3.631c0.811,0.309,1.371,1.055,1.443,1.92c0.071,0.863-0.359,1.691-1.107,2.129      l-8.03,4.696c-0.128,0.076-0.26,0.136-0.396,0.182v10.562C40.168,34.109,39.162,35.118,37.916,35.118z M40.168,15.118v2.018      l2.091-1.222L40.168,15.118z"
                                fill={color5}/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
