import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import axios from 'axios';

import Input from '../../../../input/input';
import {createUrl} from '../../../../../util/formatters';

import './admin-utils.css';

export class AdminUtils extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        setCurrentlyEditing: PropTypes.func.isRequired
    };

    Users = () => {
        let {userSearch, selectedUser} = this.props;
        let {users, selectedUserIds} = this.state;
        if (!users || !selectedUserIds) return null;

        let userRows = selectedUserIds.sort(this.sortUsers).map(userId => {
            let user = users[userId];
            let onClick = this.selectUser(userId);
            let label;
            if (selectedUser === userId) {
                label = <div className="adminUtils-selectedLabel">Selected</div>;
                onClick = this.selectUser(null);
            }
            return (
                <button
                    className="adminUtils-userButton"
                    type="button"
                    onClick={onClick}
                    key={userId}
                >
                    <div>{user.name.fullName}</div>
                    {label}
                </button>
            );
        });
        if (userSearch && !userRows.length) {
            userRows = <div className="adminUtils-noUsers">No users found</div>
        }
        return (
            <div className="adminUtils-users">
                {userRows}
            </div>
        );
    };

    Error = () => {
        let {error} = this.state;
        if (!error) return null;
        return <div className="adminUtils-error">Failed to Submit</div>;
    };

    selectUser = userId => () => this.props.change('selectedUser', userId);

    onKeyPress = event => {
        if (event.key === 'Enter') event.preventDefault();
    };

    clearSearch = () => {
        this.props.change('userSearch', '');
        document.getElementById('adminUtils-userInput').focus();
    };

    loadUsers = async params => {
        let users = (await axios.get(createUrl('/users'), {params}))
            .data
            .reduce((aggregate, user) => ({
                ...aggregate,
                [user.id]: user
            }), {});
        this.setState(({users: previousUsers = {}}) => ({
            users: {...previousUsers, ...users},
            selectedUserIds: Object.keys(users)
        }));
    };

    onUserChange = async () => {
        let {userSearch} = this.props;
        let params = {name: userSearch, limit: 10};
        if (userSearch) {
            await this.loadUsers(params);
        } else {
            this.setState({selectedUserIds: []});
        }
    };

    onSubmit = async values => {
        let {setCurrentlyEditing} = this.props;
        try {
            await axios.post(createUrl(`/users/${values.selectedUser}/enable-debugging`));
            setCurrentlyEditing(null)()
        } catch (error) {
            this.setState(error);
        }
    };

    state = {};

    componentDidMount = async () => {
        document.getElementById('adminUtils-userInput').focus();
    };

    componentDidUpdate = async prevProps => {
        if (prevProps.userSearch !== this.props.userSearch) await this.onUserChange();
    };

    render() {
        let {Users, Error} = this;
        let {setCurrentlyEditing, handleSubmit, disabled, userSearch} = this.props;
        let clearButton = userSearch && (
            <button className="adminUtils-searchInputCloseButton" onClick={this.clearSearch} type="button">
                <div className="adminUtils-closeBlackIcon" />
            </button>
        );
        return (
            <form onSubmit={handleSubmit(this.onSubmit)} onKeyPress={this.onKeyPress} className="adminUtils">
                <div className="adminUtils-mainContent">
                    <label htmlFor="adminUtils-userInput">Enter a Name or Email Address</label>
                    <div className="adminUtils-searchInputWrapper">
                        <Field
                            name="userSearch"
                            component={Input}
                            type="text"
                            autoComplete="off"
                            placeholder="Search"
                            className="adminUtils-searchInput"
                            id="adminUtils-userInput"
                        />
                        {clearButton}
                    </div>
                    <Error />
                    <Users />
                </div>
                <div className="adminUtils-buttonContainer">
                    <button
                        type="button"
                        className="adminUtils-cancelButton"
                        onClick={setCurrentlyEditing(null)}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={disabled}
                        className="adminUtils-submitButton"
                    >
                        Submit
                    </button>
                </div>
            </form>
        );
    }
}

let mapStateToProps = state => ({
    selectedUser: formValueSelector('adminUtils')(state, 'selectedUser'),
    userSearch: formValueSelector('adminUtils')(state, 'userSearch')
});

let reduxFormConfig = {
    form: 'adminUtils',
    validate: values => {
        let errors = {};
        if (!values.selectedUser) errors._error = 'Must select a user';
        return errors;
    }
};

let ReduxForm = reduxForm(reduxFormConfig)(AdminUtils);
export default connect(mapStateToProps)(ReduxForm);
