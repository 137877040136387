import React from 'react';
import {reduxForm, Field} from 'redux-form'
import PropTypes from 'prop-types';
import axios from 'axios';
import classnames from 'classnames';

import {createUrl} from '../../../../../util/formatters';
import Input from '../../../../../components/input/input';

import './add-gift.css';

export class AddGift extends React.Component {
    static propTypes = {
        isCollaborator: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        listId: PropTypes.string.isRequired,
        listType: PropTypes.string.isRequired,
        saveGift: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        valid: PropTypes.bool.isRequired
    };

    validatePrice = value => {
        try {
            if (!value) return;
            let numericValue = Number(value);
            if (isNaN(numericValue)) return 'Please enter a valid number';

            let includesDecimal = value.toString().includes('.');
            let decimals = value.toString().split('.')[1];
            if (includesDecimal && decimals.length > 2) return 'Only two decimal places are allowed';
        } catch (error) {
            return 'Please enter a valid number';
        }
    };

    MoreOptions = () => {
        let {optionsExpanded, showContent} = this.state;
        let optionsClassName = classnames(
            'addGift-moreOptionInputs',
            {'addGift-moreOptionInputs--expanded': optionsExpanded},
            {'addGift-moreOptionInputs--contentRendered': showContent}
        );
        let buttonLabel = optionsExpanded ? 'Less Details' : 'More Details';
        let options = (
            <React.Fragment>
                <div className="addGift-leftSection">
                    <label htmlFor="addGift-description1">Details</label>
                    <Field
                        name="description"
                        component="textarea"
                        className="addGift-description"
                        id="addGift-description1"
                        rows={5}
                    />
                </div>
                <div className="addGift-rightSection">
                    <label htmlFor="addGift-customLink1">Website URL</label>
                    <Field
                        name="customLink"
                        component={Input}
                        type="text"
                        autoComplete="off"
                        className="addGift-customLink"
                        id="addGift-customLink1"
                    />
                    <label htmlFor="addGift-price1">Price</label>
                    <div className="addGift-priceWrapper">
                        <span className="addGift-priceDollarSign">$</span>
                        <Field
                            name="price"
                            component={Input}
                            type="text"
                            autoComplete="off"
                            fieldClassName="addGift-priceField"
                            className="addGift-price"
                            id="addGift-price1"
                            validate={this.validatePrice}
                            normalize={value => value.replace(/[^0-9.]/g, '')}
                            inputMode="decimal"
                            pattern="^\d*\.?\d{0,2}$"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
        return (
            <div className="addGift-moreOptions">
                <div className={optionsClassName}>
                    {showContent && options}
                </div>
                <button type="button" className="addGift-moreOptionsButton" onClick={this.toggleOptions}>{buttonLabel}</button>
            </div>
        );
    };

    submit = async values => {
        let {listId, saveGift} = this.props;
        if (values.price) values.price = Number(values.price);
        let gift = (await axios.post(createUrl(`/lists/${listId}/gifts`), values)).data;
        saveGift(gift);
    };

    toggleOptions = event => {
        let {change} = this.props;
        let {optionsExpanded} = this.state;
        change('description', '');
        change('customLink', '');
        change('price', '');
        event.target.blur();
        if (optionsExpanded) {
            this.setState({optionsExpanded: false});
            setTimeout(() => {
                if (!this.state.optionsExpanded) {
                    this.setState({showContent: false});
                }
            }, 250)
        } else {
            this.setState({optionsExpanded: true, showContent: true});
        }
    };

    state = {
        optionsExpanded: false,
        showContent: false
    };

    render() {
        let {handleSubmit, submitting, valid, listType, isCollaborator} = this.props;
        let {MoreOptions} = this;
        if (isCollaborator || listType === 'reference') return null;
        return (
            <form onSubmit={handleSubmit(this.submit)} className="addGift">
                <div className="addGift-mainSection">
                    <Field
                        name="label"
                        component={Input}
                        type="text"
                        placeholder="Add a Gift Idea..."
                        className="addGift-label"
                        fieldClassName="addGift-labelField"
                        autoComplete="off"
                    />
                    <button
                        type="submit"
                        disabled={submitting || !valid}
                        className={classnames("addGift-submitButton", {'addGift-submitButton--active': valid})}
                    >
                        Add
                    </button>
                </div>
                <MoreOptions/>
            </form>
        );
    }
}

let reduxConfig = {
    form: 'addGift',
    enableReinitialize: true,
    touchOnChange: true,
    onSubmitSuccess: (result, dispatch, props) => props.reset(),
    validate: values => {
        let errors = {};
        if (!values.label) errors._error = 'Required';
        else if (values.label.length > 75) errors.label = 'Label is too long... Try adding a description instead.';
        if (values.customLink) {
            let validUrl;
            try {
                let url = new URL(values.customLink);
                validUrl = url.protocol === "http:" || url.protocol === "https:";
            } catch (error) {
                validUrl = false;
            }
            if (!validUrl) errors.customLink = 'Invalid URL';
        }
        return errors;
    }
};

export default reduxForm(reduxConfig)(AddGift);
