import React, {useState} from 'react';
import {useDrag, useDrop} from 'react-dnd'
import classnames from 'classnames';
import {Field} from 'react-final-form';
import uuid from 'uuid/v4';

import {DRAGGABLE_ITEMS} from '../../../../../util/constants';
import DragHandle from '../../../../../images/react-icons/drag-handle';
import {FIELD_NAMES} from '../recipe-form';
import PhotoPreview from '../../../components/photo-preview/photo-preview';
import {required} from '../../../../../util/validation';
import FieldLabel from '../../../components/field-label/field-label';

import './instruction-fields.css';

let InstructionButtons = ({photo, instructionId, instructionToAddIngredients, setInstructionToAddIngredients, setShowDeleteNag, form, index}) => {
    let content = (
        <>
            <button
                type="button"
                className="instructionFields-addPhotoButton"
                onClick={() => document.getElementById(instructionId).click()}
            >{photo ? 'Change Photo' : 'Add Photo'}</button>
            <button
                type="button"
                className="instructionFields-addIngredientsButton"
                onClick={() => setInstructionToAddIngredients(instructionId)}
            >Select Ingredients</button>
            <button
                type="button"
                className="instructionFields-deleteButton"
                onClick={() => setShowDeleteNag(true)}
            >Delete</button>
        </>
    );
    let isAddingIngredients = instructionToAddIngredients === instructionId;
    if (isAddingIngredients) {
        content = (
            <>
                <div className="instructionFields-infoText">Select ingredients above</div>
                <button
                    type="button"
                    className="instructionFields-finishAddingIngredientsButton"
                    onClick={() => setInstructionToAddIngredients()}
                >Finish</button>
            </>
        )
    } else if (instructionToAddIngredients) content = null;
    return (
        <div className="instructionFields-photoAndButtons">
            {photo && !instructionToAddIngredients && (
                <div className="instructionFields-photo">
                    <PhotoPreview
                        photo={photo}
                        deletePhoto={() => form.change(FIELD_NAMES.getInstructionPhotoName(index))}
                        imageClassName="instructionFields-photoPreview"
                    />
                </div>
            )}
            <div className={classnames('instructionFields-buttonContainer', {'instructionFields-buttonContainer--stretch': !photo})}>{content}</div>
        </div>
    );
};

let SelectedIngredients = ({ingredients, selectedIngredients}) => {
    let formattedIngredients = selectedIngredients.map(ingredientId => {
        let ingredient = ingredients.find(({id}) => id === ingredientId);
        if (!ingredient) return null;
        let {description, quantity} = ingredient;
        return quantity ? `${quantity} ${description}` : description;
    })
        .filter(formattedIngredient => formattedIngredient)
        .join(', ');
    if (!formattedIngredients) return null;
    return <div className="instructionFields-selectedIngredients"><span>Ingredients:</span> {formattedIngredients}</div>;
};

export default function InstructionFields ({index, instruction, ingredients, moveInstruction, removeInstruction, instructionToAddIngredients, setInstructionToAddIngredients, formProps}) {
    let {form} = formProps;
    let {description, id: instructionId, photo, ingredients: selectedIngredients} = instruction;
    let [showDeleteNag, setShowDeleteNag] = useState();
    let [{isDragging}, drag, dragPreview] = useDrag(() => ({
        type: DRAGGABLE_ITEMS.INSTRUCTION,
        item: {
            id: instructionId,
            index,
            description
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    }), [index, instructionId, description]);
    let [{isOver}, drop] = useDrop(() => ({
        accept: DRAGGABLE_ITEMS.INSTRUCTION,
        drop: item => moveInstruction(item.id, index),
        collect: monitor => ({
            isOver: !!monitor.isOver()
        })
    }), [index, instructionId, moveInstruction]);

    return (
        <div className={classnames('instructionFields', {'instructionFields-droppable': isOver})} ref={drop}>
            <div className="instructionFields-instruction" ref={dragPreview} style={{opacity: isDragging ? 0.3 : 1}}>
                <div className="instructionFields-grabIconContainer" ref={drag}>
                    <DragHandle colors={{primaryColor: '#7a7a7a'}} className="instructionFields-grabIcon" />
                </div>
                <div className="instructionFields-content">
                    <div className="instructionFields-number">{index + 1}.</div>
                    <div className="instructionFields-descriptionAndButtons">
                        <div className="instructionFields-descriptionLabelAndField">
                            <FieldLabel
                                fieldName={FIELD_NAMES.getInstructionDescriptionName(index)}
                                className="instructionFields-descriptionLabel"
                                {...formProps}
                            />
                            <Field
                                name={FIELD_NAMES.getInstructionDescriptionName(index)}
                                component="textarea"
                                className="instructionFields-descriptionTextArea"
                                validate={required}
                            />
                            <SelectedIngredients ingredients={ingredients} selectedIngredients={selectedIngredients} />
                        </div>
                        <InstructionButtons
                            photo={photo}
                            instructionId={instructionId}
                            instructionToAddIngredients={instructionToAddIngredients}
                            setInstructionToAddIngredients={setInstructionToAddIngredients}
                            setShowDeleteNag={setShowDeleteNag}
                            form={form}
                            index={index}
                        />
                    </div>
                </div>
                {showDeleteNag && (
                    <div className="instructionFields-deleteNag">
                        <div className="instructionFields-deleteNagText">Are you sure you want to delete this instruction?</div>
                        <div className="instructionFields-deleteNagButtonContainer">
                            <button onClick={() => removeInstruction(instructionId)} type="button" className="instructionFields-yesButton">Yes</button>
                            <button onClick={() => setShowDeleteNag(false)} type="button" className="instructionFields-noButton">No</button>
                        </div>
                    </div>
                )}
                <input
                    type="file"
                    accept="image/*"
                    className="instructionFields-photoFileInput"
                    id={instructionId}
                    onChange={event => {
                        let file = event.target.files?.[0];
                        if (!file) return;
                        file.id = uuid();
                        form.change(FIELD_NAMES.getInstructionPhotoName(index), file)
                    }}
                />
            </div>
        </div>
    )
}
