import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Map} from 'immutable';

import Alerts from './alerts/alerts';
import {toggleLeftNav as _toggleLeftNav} from '../../../../redux/view/view-actions';
import circleGift from '../../../../images/icons/circle-gift.svg';
import greenTag from '../../../../images/icons/tag-green.svg';

import '../../../../util/zian.ttf';
import './top-nav.css';

export class TopNav extends React.Component {
    static propTypes = {
        toggleLeftNav: PropTypes.func.isRequired,
        pendingRequestStats: PropTypes.object.isRequired,
        user: PropTypes.object
    };

    LeftActions = () => {
        let {toggleLeftNav, pendingRequestStats} = this.props;
        return (
            <div className="topNav-leftActions">
                <button className="topNav-hamburgerButton" onClick={toggleLeftNav}>
                    <div className="topNav-hamburgerIcon" />
                    {!!pendingRequestStats.get('totalCount') && <div className="topNav-alertDot" />}
                </button>
            </div>
        );
    };

    Title = () => {
        let giftStyle = {backgroundImage: `url(${circleGift})`};
        let greenTagStyle = {backgroundImage: `url(${greenTag})`};
        return (
            <div className="topNav-title">
                <span className="topNav-titleText">gift</span>
                <span className="topNav-icons">
                    <div className="topNav-giftIcon" style={giftStyle}/>
                    <div className="topNav-tagIcon" style={greenTagStyle}/>
                </span>
                <span className="topNav-titleText">tags</span>
            </div>
        );
    };

    RightActions = () => {
        let {user = new Map()} = this.props;
        let fullName = user.getIn(['name', 'fullName']);
        return (
            <div className="topNav-rightActions">
                <div className="topNav-usersName">{fullName}</div>
                <Alerts activeUserId={user.get('id')} />
            </div>
        );
    };

    render() {
        let {LeftActions, Title, RightActions} = this;
        return (
            <div className="topNav">
                <LeftActions/>
                <Title/>
                <RightActions />
            </div>
        );
    }
}

let mapStateToProps = state => {
    return {
        user: state.users.get('user')
    };
};

export default connect(mapStateToProps, {toggleLeftNav: _toggleLeftNav})(TopNav);
