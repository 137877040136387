import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import SpinnerIcon from '../../../../images/react-icons/spinner-icon';

import './spinner.css';

export let promiseHandler = (asyncFunc, setPromise) => {
    let promise = asyncFunc();
    setPromise(promise);
    return promise;
};

// todo: prevent keyboard input while spinner is active
export default function Spinner({active: _active = false, promise, fullPage = true, children, className, iconClassName, spinnerColor = '#fff'}) {
    let [active, setActive] = useState(_active);
    let handlePromise = async () => {
        if (!promise) return;
        try {
            setActive(true);
            await promise;
        } catch (error) {}
        setActive(false);
    };
    useEffect(() => {handlePromise()}, [promise]);
    useEffect(() => setActive(_active), [_active]);

    if (!active) return children || null;
    return (
        <div className={classnames('spinner', {'spinner-fullPage': fullPage}, className)}>
            {children}
            <SpinnerIcon className={classnames('spinnerComponent', iconClassName)} colors={{primaryColor: spinnerColor}} />
        </div>
    )
}
