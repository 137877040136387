import React from 'react';

export default function Cauldron({className}) {
    let color1 = '#edb798';
    let color2 = '#dea17e';
    let color3 = '#918291';
    let color4 = '#4f4c5f';
    let color5 = '#857485';
    let color6 = '#8bdf9e';
    let color7 = '#72c485';
    return (
        <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fill={color1} d="M62.959,1.175a3.078,3.078,0,0,1,0,4.358L49.72,18.772,44.438,20l-4.018-.659L58.6,1.175A3.084,3.084,0,0,1,62.959,1.175Z"/>
            <path fill={color2}
                  d="M63.863,3.35a3.084,3.084,0,0,1-.9,2.179L49.72,18.768,44.438,20,58.971,5.519a3.078,3.078,0,0,0,0-4.358c-.052-.052-.113-.093-.175-.144A3.069,3.069,0,0,1,63.863,3.35Z"/>
            <path fill={color3} d="M59.752,32.134a4.112,4.112,0,1,1,0,8.223H54.068a24.132,24.132,0,0,0,.545-5.139,23.373,23.373,0,0,0-.2-3.084Z"/>
            <path fill={color3} d="M9.932,40.357H4.248a4.112,4.112,0,0,1,0-8.223H9.583a23.373,23.373,0,0,0-.2,3.084A24.132,24.132,0,0,0,9.932,40.357Z"/>
            <path fill={color3}
                  d="M56.473,32.018a23.9,23.9,0,0,0-1.223-5.161L32,22.045,8.75,26.857a23.9,23.9,0,0,0-1.223,5.161,23.546,23.546,0,0,0-.2,3.1,24.4,24.4,0,0,0,.544,5.161,24.738,24.738,0,0,0,12.345,16.6A24.6,24.6,0,0,0,48.785,53.26a24.7,24.7,0,0,0,7.339-12.985,24.4,24.4,0,0,0,.544-5.161A23.546,23.546,0,0,0,56.473,32.018Z"/>
            <rect fill={color4} height="8.223" rx="3" width="54.476" x="4.248" y="18.634"/>
            <path fill={color5}
                  d="M40.223,56.8a24.656,24.656,0,0,1-16.785-6.627A24.7,24.7,0,0,1,16.1,37.192a24.339,24.339,0,0,1-.545-5.161,23.571,23.571,0,0,1,.2-3.1c.085-.7.21-1.393.356-2.077H8.75a23.9,23.9,0,0,0-1.223,5.161,23.546,23.546,0,0,0-.2,3.1,24.4,24.4,0,0,0,.544,5.161,24.738,24.738,0,0,0,12.345,16.6,24.524,24.524,0,0,0,23.558,0c.269-.146.528-.307.79-.463A24.614,24.614,0,0,1,40.223,56.8Z"/>
            <path fill={color6}
                  d="M38.167,13.633c-.051,0-.1.01-.154.01a3.859,3.859,0,0,0,.154-1.038,4.1,4.1,0,0,0-7.328-2.539l-.062-.01a6.158,6.158,0,0,0-12.057.586,5.111,5.111,0,0,0-8.3,4.019,5.148,5.148,0,0,0,2.056,4.111,8.181,8.181,0,0,1,4.957,7.6V38.3A3.027,3.027,0,0,0,20.4,41.385a2.916,2.916,0,0,0,2.1-.905,3.131,3.131,0,0,0,.873-2.179V30.079A3.026,3.026,0,0,1,26.351,27a2.917,2.917,0,0,1,2.1.9,3.137,3.137,0,0,1,.872,2.18V42.413A3.026,3.026,0,0,0,32.3,45.5a2.913,2.913,0,0,0,2.1-.9,3.131,3.131,0,0,0,.873-2.179V26.368a8.648,8.648,0,0,1,5.255-7.734,3.348,3.348,0,0,0,.722-1.918A3.08,3.08,0,0,0,38.167,13.633Z"/>
            <path fill={color7}
                  d="M20.4,37.135V25.125a6.567,6.567,0,0,0-6.648-6.353H12.471a8.181,8.181,0,0,1,4.957,7.6V38.3A3.027,3.027,0,0,0,20.4,41.385a2.913,2.913,0,0,0,2.1-.906l-.105-.431A3.072,3.072,0,0,1,20.4,37.135Z"/>
            <path fill={color7}
                  d="M32.3,41.247V28.913a3.131,3.131,0,0,0-.873-2.179,2.89,2.89,0,0,0-4.468.327,2.948,2.948,0,0,1,1.494.838,3.136,3.136,0,0,1,.872,2.18V42.413A3.026,3.026,0,0,0,32.3,45.5a2.917,2.917,0,0,0,2.1-.9,3.069,3.069,0,0,0,.26-.328A3.053,3.053,0,0,1,32.3,41.247Z"/>
            <path fill={color3} d="M52.886,59.146a3.081,3.081,0,1,1-5.047,3.536l-4.06-5.8a4.751,4.751,0,0,1,5.006-3.6Z"/>
            <path fill={color3} d="M20.221,56.885l-4.06,5.8a3.081,3.081,0,1,1-5.047-3.536l4.1-5.859C18.9,53.166,19.775,54.9,20.221,56.885Z"/>
        </svg>
    );
}
