import React from 'react';

export default function BookIcon9({className, colors = {}}) {
    let {
        primaryColor = '#323232', // book
        primaryShade = '#161616', // background
        bookmarkColor = '#db4342', // bookmark
        white = '#FFFFFF', // stripe
        black = '#000000' // shadow
    } = colors;
    return (
        <svg height="64px" version="1.1" viewBox="0 0 64 64" width="64px" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="Layer_1">
                <g><circle cx="32" cy="32" fill={primaryShade} r="32"/></g>
                <g opacity="0.2">
                    <path d="M48,50c0,2.209-1.791,4-4,4H20c-2.209,0-4-1.791-4-4V18c0-2.209,1.791-4,4-4h24c2.209,0,4,1.791,4,4V50z" fill={black}/>
                </g>
                <g>
                    <path d="M48,48c0,2.209-1.791,4-4,4H20c-2.209,0-4-1.791-4-4V16c0-2.209,1.791-4,4-4h24c2.209,0,4,1.791,4,4V48z" fill={primaryColor}/>
                </g>
                <g opacity="0.2">
                    <g>
                        <path d="M35,14v7.846c0,0.264,0.163,0.503,0.411,0.604c0.083,0.033,0.169,0.05,0.255,0.05     c0.173,0,0.344-0.067,0.471-0.192L39,19.501l2.862,2.807c0.127,0.125,0.298,0.192,0.471,0.192c0.086,0,0.173-0.016,0.255-0.05     C42.837,22.349,43,22.11,43,21.846V14H35z" fill={black}/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M35,12v7.846c0,0.264,0.163,0.503,0.411,0.604c0.083,0.033,0.169,0.05,0.255,0.05     c0.173,0,0.344-0.067,0.471-0.192L39,17.501l2.862,2.807c0.127,0.125,0.298,0.192,0.471,0.192c0.086,0,0.173-0.016,0.255-0.05     C42.837,20.349,43,20.11,43,19.846V12H35z" fill={bookmarkColor}/>
                    </g>
                </g>
                <g><rect fill={white} height="40" width="3" x="20" y="12"/></g>
             </g>
            <g id="Layer_2"/>
        </svg>
    );
}
