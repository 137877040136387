import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import axios from 'axios';

import Input from '../../../input/input';
import {createUrl} from '../../../../util/formatters';

import './join-modal.css';

export class JoinModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired
    };

    Hubs = () => {
        let {hubs} = this.state;
        if (!hubs) return null;
        let hubComponents = hubs.map(hub => {
            return (
                <button onClick={this.selectHub(hub.id)} className="joinModal-hubButton" key={hub.id}>
                    {hub.name}
                </button>
            )
        });
        return (
            <div className="joinModal-hubs">
                {hubComponents}
            </div>
        )
    };

    selectHub = hubId => () => this.props.history.push('/gift-tags/hubs/' + hubId);

    onHubNameChange = async () => {
        let {hubName} = this.props;
        if (!hubName) return this.setState({hubs: []});
        let hubs = (await axios.get(createUrl('/hubs'), {params: {name: hubName}})).data;
        this.setState({hubs});
    };

    clearHubNameInput = () => {
        this.props.change('hubName', '');
        document.getElementById('joinModal-userInput').focus();
    };

    onSubmit = async () => {};

    state = {};

    componentDidMount = async () => {
        document.getElementById('joinModal-userInput').focus();
    };

    componentDidUpdate = async prevProps => {
        if (prevProps.hubName !== this.props.hubName) await this.onHubNameChange();
    };

    render() {
        let {Hubs} = this;
        let {handleSubmit, closeModal, hubName} = this.props;
        let clearButton = hubName && (
            <button className="joinModal-searchInputCloseButton" onClick={this.clearHubNameInput} type="button">
                <div className="joinModal-closeBlackIcon" />
            </button>
        );
        return (
            <form className="joinModal" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="joinModal-mainContent">
                    <label htmlFor="joinModal-userInput">Enter a Hub's Name</label>
                    <div className="joinModal-searchInputWrapper">
                        <Field
                            name="hubName"
                            component={Input}
                            type="text"
                            autoComplete="off"
                            placeholder="Hub Name"
                            className="joinModal-searchInput"
                            id="joinModal-userInput"
                        />
                        {clearButton}
                    </div>
                    <Hubs />
                </div>
                <div className="joinModal-buttonContainer">
                    <button
                        type="button"
                        className="joinModal-cancelButton"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        );
    }
}

let mapStateToProps = state => ({
    hubName: formValueSelector('joinHubSearch')(state, 'hubName')
});

let reduxFormConfig = {
    form: 'joinHubSearch'
};

let ReduxForm = reduxForm(reduxFormConfig)(JoinModal);
export default connect(mapStateToProps)(ReduxForm);