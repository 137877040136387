import React from 'react';

import Alert from '../../alert/alert';

export default ({alert, toggleNotificationRead, closeMenu, push, openModal}) => {
    let message = <span><b>Your List Cleanup Crew</b>:<br />Got a sec to help us update your gift ideas?</span>;
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            closeMenu={closeMenu}
            push={push}
            onClick={() => openModal(
                'GIFT_RECTIFICATION_MODAL',
                {},
                {title: 'Your List Cleanup Crew', width: 600}
            )}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}