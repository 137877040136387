import React, {useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';

import TitleBar from '../components/title-bar/title-bar';
import {createUrl} from '../../../util/formatters';
import CookbookPreview from './cookbook-preview/cookbook-preview';
import ErrorMessenger from '../components/error-messenger/error-messenger';

import './cookbooks.css';

export default function Cookbooks({history, toggleSidebar}) {
    let [data, setData] = useState();
    let [errorMessage, setErrorMessage] = useState();

    let loadData = async () => {
        try {
            // todo: sort by most recently updated using creation or updates to recipes
            let cookbooks = (await axios.get(createUrl('/cookbooks'))).data.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
            setData({cookbooks});
        } catch (error) {
            console.error(error);
            setErrorMessage('Oops, something went wrong with loading your cookbooks... Try again later.');
        }
    };
    useEffect(() => {loadData()}, []);

    let CookbookActions = () => {
        return (
            <div className="cookbooks-cookbookActions">
                <button
                    className="cookbooks-createCookbookButton"
                    onClick={() => history.push('/sous-chef/cookbooks/new?referrer=cookbooks')}
                >Create a Cookbook</button>
            </div>
        )
    };

    let content;
    if (errorMessage) content = <ErrorMessenger>{errorMessage}</ErrorMessenger>;
    else if (data) {
        let {cookbooks} = data;
        let cookbookComponents = cookbooks.map(cookbook => <CookbookPreview cookbook={cookbook} history={history} key={cookbook.id}/>);
        content = (
            <>
                <CookbookActions />
                {cookbookComponents}
            </>
        )
    }
    return (
        <div className="cookbooks">
            <TitleBar title="Cookbooks" enableSearch={true} toggleSidebar={toggleSidebar} />
            {content}
        </div>
    )
}
