import React from 'react';

export default function BookIcon3({className, colors = {}}) {
    let {
        primaryColor = '#323232',
        primaryShade = '#161616',
        white = '#f1f2f2'
    } = colors;
    return (
        <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg" className={className}>
            <title/>
            <g id="Book">
                <rect fill={primaryColor} height="48" rx="2" ry="2" width="34" x="8"/>
                <path fill={primaryShade} d="M10,0h2a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H10a2,2,0,0,1-2-2V2A2,2,0,0,1,10,0Z"/>
                <path fill={white} d="M40,44H10a2,2,0,0,0,0,4H40a2,2,0,0,0,2-2V42A2.006,2.006,0,0,1,40,44Z"/>
                <path fill={primaryShade} d="M42,44v2a2.006,2.006,0,0,1-2,2H10a2,2,0,0,1-1.73-3A2,2,0,0,0,10,46H40A2.006,2.006,0,0,0,42,44Z"/>
                <rect fill={primaryShade} height="10" rx="1" ry="1" width="19" x="17" y="6"/>
            </g>
        </svg>
    );
}
