import React from 'react';
import {Route, Switch} from "react-router-dom";

import Register from './register/register';
import Login from './login/login';
import Logout from './logout/logout';
import ResetLink from './reset-link/reset-link';
import PasswordReset from './password-reset/password-reset';
import greenTag from '../../../images/icons/tag-green.svg';
import circleGift from '../../../images/icons/circle-gift.svg';

import './authenticator.css';

export default class Authenticator extends React.Component {
    render() {
        let giftStyle = {backgroundImage: `url(${circleGift})`};
        let greenTagStyle = {backgroundImage: `url(${greenTag})`};
        return (
            <div className="authenticator">
                <div className="authenticator-icons">
                    <div className="authenticator-giftIcon" style={giftStyle} />
                    <div className="authenticator-tagIcon" style={greenTagStyle} />
                </div>
                <div className="authenticator-title">gift tags</div>
                <Switch>
                    <Route path="/gift-tags/register" component={Register} />
                    <Route path="/gift-tags/login" component={Login} />
                    <Route path="/gift-tags/logout" component={Logout} />
                    <Route path="/gift-tags/password-reset" component={ResetLink} exact />
                    <Route path="/gift-tags/password-reset/:resetToken" component={PasswordReset} exact />
                </Switch>
            </div>
        );
    }
}
