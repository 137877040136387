import React, {useState} from 'react';
import {useDrag, useDrop} from 'react-dnd'
import classnames from 'classnames';
import {Field} from 'react-final-form';

import {DRAGGABLE_ITEMS} from '../../../../../util/constants';
import DragHandle from '../../../../../images/react-icons/drag-handle';
import {FIELD_NAMES} from '../recipe-form';
import {compose, required, validateNumber} from '../../../../../util/validation';
import FieldLabel from '../../../components/field-label/field-label';

import './time-estimate-fields.css';

export default function TimeEstimateFields ({index, estimate, moveEstimate, removeEstimate, formProps}) {
    let {form} = formProps;
    let {description, duration, units, id: estimateId} = estimate;
    let [showDeleteNag, setShowDeleteNag] = useState();
    let [{isDragging}, drag, dragPreview] = useDrag(() => ({
        type: DRAGGABLE_ITEMS.TIME_ESTIMATE,
        item: {
            id: estimateId,
            index,
            description,
            duration,
            units
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    }), [index, estimateId, description, duration, units]);
    let [{isOver}, drop] = useDrop(() => ({
        accept: DRAGGABLE_ITEMS.TIME_ESTIMATE,
        drop: item => moveEstimate(item.id, index),
        collect: monitor => ({
            isOver: !!monitor.isOver()
        })
    }), [index, estimateId, moveEstimate]);

    return (
        <div className={classnames('timeEstimateFields', {'timeEstimateFields-droppable': isOver})} ref={drop}>
            <div className="timeEstimateFields-estimate" ref={dragPreview} style={{opacity: isDragging ? 0.3 : 1}}>
                <div className="timeEstimateFields-grabIconContainer" ref={drag}>
                    <DragHandle colors={{primaryColor: '#7a7a7a'}} className="timeEstimateFields-grabIcon" />
                </div>
                <div className="timeEstimateFields-content">
                    <div className="timeEstimateFields-fieldsAndButtons">
                        <div className="timeEstimateFields-formFields">
                            <div className="timeEstimateFields-descriptionSection">
                                <FieldLabel
                                    fieldName={FIELD_NAMES.getEstimateDescriptionName(index)}
                                    className="timeEstimateFields-descriptionLabel"
                                    {...formProps}
                                >Description</FieldLabel>
                                <Field
                                    name={FIELD_NAMES.getEstimateDescriptionName(index)}
                                    component="input"
                                    autoComplete="off"
                                    className="timeEstimateFields-descriptionInput"
                                    validate={required}
                                />
                            </div>
                            <div className="timeEstimateFields-durationAndUnits">
                                <div className="timeEstimateFields-durationSection">
                                    <FieldLabel
                                        fieldName={FIELD_NAMES.getEstimateDurationName(index)}
                                        className="timeEstimateFields-durationLabel"
                                        {...formProps}
                                    >Duration</FieldLabel>
                                    <Field
                                        name={FIELD_NAMES.getEstimateDurationName(index)}
                                        component="input"
                                        autoComplete="off"
                                        className="timeEstimateFields-durationInput"
                                        parse={value => (value || '').toString().replace(/[^0-9.]/g, '')}
                                        inputMode="decimal"
                                        pattern="^\d*\.?\d*$"
                                        validate={compose(required, validateNumber)}
                                    />
                                </div>
                                <div className="timeEstimateFields-unitsSection">
                                    <button
                                        onClick={() => form.change(FIELD_NAMES.getEstimateUnitsName(index), 'minutes')}
                                        className={classnames(
                                            'timeEstimateFields-timeUnitButton',
                                            {'timeEstimateFields-timeUnitButton--active': units === 'minutes'}
                                        )}
                                        type="button"
                                    >Minutes</button>
                                    <button
                                        onClick={() => form.change(FIELD_NAMES.getEstimateUnitsName(index), 'hours')}
                                        className={classnames(
                                            'timeEstimateFields-timeUnitButton',
                                            {'timeEstimateFields-timeUnitButton--active': units === 'hours'}
                                        )}
                                        type="button"
                                    >Hours</button>
                                </div>
                            </div>
                        </div>
                        <div className="timeEstimateFields-buttonContainer">
                            <button
                                type="button"
                                className="timeEstimateFields-deleteButton"
                                onClick={() => setShowDeleteNag(true)}
                            >Delete</button>
                        </div>
                    </div>
                </div>
                {showDeleteNag && (
                    <div className="timeEstimateFields-deleteNag">
                        <div className="timeEstimateFields-deleteNagText">Are you sure you want to delete this estimate?</div>
                        <div className="timeEstimateFields-deleteNagButtonContainer">
                            <button onClick={() => removeEstimate(estimateId)} type="button" className="timeEstimateFields-yesButton">Yes</button>
                            <button onClick={() => setShowDeleteNag(false)} type="button" className="timeEstimateFields-noButton">No</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
