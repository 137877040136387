import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import {createUrl} from '../../../../util/formatters';

export class Lists extends React.Component {
    static propTypes = {
        activeUserId: PropTypes.string.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                listId: PropTypes.string
            }).isRequired
        }).isRequired
    };

    componentDidMount = async () => {
        let {activeUserId, history} = this.props;
        let lists = (await axios.get(
            createUrl('/lists'),
            {params: {ownedBy: activeUserId}}
        )).data;
        let listId = lists.find(list => list.type === 'origin' && list.defaultList).id;
        history.replace(`/gift-tags/lists/${listId}`);
    };

    render() {
        return null;
    }
}

let mapStateToProps = state => {
    let activeUserId = state.users.getIn(['user', 'id']);
    return {
        activeUserId
    };
};

export default connect(mapStateToProps)(Lists);
