import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './request-row.css';

export default class RequestRow extends React.Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        reloadRows: PropTypes.func.isRequired,
        request: PropTypes.shape({
            sender: PropTypes.object.isRequired,
            type: null
        }).isRequired
    };

    onClick = () => {
        let {request, history} = this.props;
        if (request.type === 'hubInvite') {
            history.push(`/gift-tags/hubs/${request.sender.id}/join/${request.id}`);
        } else if (request.type === 'hubApplication') {
            history.push(`/gift-tags/hubs/${request.recipient.id}/join/${request.id}`);
        }
    };

    render() {
        let {request} = this.props;
        let hub, titleInfo, rightContent;
        if (request.type === 'hubInvite') {
            hub = request.sender;
            titleInfo = <div className="requestRow-titleInfo">You've been invited to join:</div>;
            rightContent = (
                <div className="requestRow-right">
                    <button className="requestRow-viewInviteButton">
                        <span>View</span>
                        <span>Invite</span>
                    </button>
                </div>
            );
        } else if (request.type === 'hubApplication') {
            hub = request.recipient;
            titleInfo = <div className="requestRow-titleInfo">Request Pending Admin Approval:</div>;
            rightContent = (
                <div className="requestRow-right">
                    <button className="requestRow-viewInviteButton">
                        <span>View</span>
                        <span>Request</span>
                    </button>
                </div>
            );
        } else return null;
        return (
            <div className={classnames('requestRow', {'requestRow--highlighted': request.type === 'hubInvite'})} onClick={this.onClick}>
                <div className="requestRow-left">
                    {titleInfo}
                    <div className="requestRow-name">{hub.name}</div>
                    {hub.description && <div className="requestRow-description">{hub.description}</div>}
                </div>
                {rightContent}
            </div>
        );
    }
}
